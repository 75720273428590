import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Pessoa} from "../../../../../model/Pessoa";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {UserProvider} from "../../../../../providers/user/user";
import {Snackbar} from "../../../../../util/snackbar";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorUtil} from "../../../../../util/error";

@Component({
  selector: 'app-permissao-colaborador',
  templateUrl: './permissao-colaborador.component.html',
  styleUrls: ['./permissao-colaborador.component.scss']
})
export class PermissaoColaboradorComponent implements OnInit {
  @Input() public colaborador: Pessoa;

  displayedColumns = ['id', 'name', 'permissao'];
  dataSource: MatTableDataSource<Pessoa>;
  clientes: Pessoa[] = [];

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(public activeModal: NgbActiveModal,
              public snackBar: Snackbar,
              public errorUtil: ErrorUtil,
              public userProvider: UserProvider) {

  }

  ngOnInit() {
    this.userProvider.getAllCompanys().then((clientes: Pessoa[]) => {
      this.clientes = clientes;
      this.dataSource = new MatTableDataSource(this.clientes);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }).catch(() =>
    {
    });
  }

  checkPermissionCompany(id: number): boolean {
    let permissao: boolean = false;
    this.colaborador.companyIds.forEach((idCompany: any, index: number) => {
      if (idCompany == id) {
        permissao = true;
      }
    });
    return permissao;
  }

  addPermissionAllCompanys(){
    let companyIds = [];
    this.clientes.forEach(empresa => {
      if(!this.colaborador.companyIds.includes(empresa.id)){
        companyIds.push(empresa.id);
      }
    });
    this.userProvider.postCompanyCollaborator(this.colaborador.id,companyIds).then(() => {
      this.snackBar.openLong('Permissão alterada com sucesso!', 'success');
      this.colaborador.companyIds = this.colaborador.companyIds.concat(companyIds);
    }).catch((err: HttpErrorResponse) => {
      this.snackBar.openLong('Erro ao alterar! ' + this.errorUtil.checkErrorStatus(err, err.status, err.error, 'colaborator'), 'erro');
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  changePermission(event: boolean, idEmpresa: number) {
    if (!event) {
      this.userProvider.postCompanyCollaborator(this.colaborador.id,[idEmpresa]).then(() => {
        this.snackBar.openLong('Permissão alterada com sucesso!', 'success');
        this.colaborador.companyIds.push(idEmpresa);
      }).catch((err: HttpErrorResponse) => {
        this.snackBar.openLong('Erro ao alterar! ' + this.errorUtil.checkErrorStatus(err, err.status, err.error, 'colaborator'), 'error');
      });
    } else {
      this.colaborador.companyIds.forEach((id: number, index: number) => {
        if (idEmpresa == id) {
          this.colaborador.companyIds.splice(index, 1);
        }
      });
      this.userProvider.delCompanyCollaborator(this.colaborador.id, idEmpresa).then(() => {
        this.snackBar.openLong('Permissão alterada com sucesso!', 'success');
      }).catch((err: HttpErrorResponse) => {
        this.snackBar.openLong('Erro ao alterar! ' + this.errorUtil.checkErrorStatus(err, err.status, err.error, 'colaborator'), 'erro');
      });
    }
  }

}
