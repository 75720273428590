import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {DuplicatesMapper} from "../../../../../model/dto/mapper/duplicatesMapper";
import {Duplicatas} from "../../../../../model/Duplicatas";
import {HttpErrorResponse} from "@angular/common/http";
import {PlannedPaymentProvider} from "../../../../../providers/dfe/plannedPayment";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-pagamento-planejado',
  templateUrl: './pagamento-planejado.component.html',
  styleUrls: ['./pagamento-planejado.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})

export class PagamentoPlanejadoComponent implements OnInit {

  enviado: boolean = false;
  enviando: boolean = false;
  paymentForm: FormGroup;
  duplicatesHistoric = [];
  duplicatesAccount = []; //p salvar as accountChart + accountChartId personalizadas de cada duplicata
  duplicatesBankAccount = []; //p salvar as bankAccount + bankAccountId personalizadas de cada duplicata

  @Input() public duplicatesPlanned;
  @Input() public dfeId;
  @Input() public total;

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public plannedPaymentProvider: PlannedPaymentProvider,
              public snackBar: Snackbar,
              public duplicatesMapper: DuplicatesMapper,
              public erroUtil: ErrorUtil) {
  }

  ngOnInit() {
    this.configuracaoForm();
    this.setDuplicatesPlanForm();
    let total = this.total.replaceAll('.', "").replace(',', '.');
    this.total = Number(total);
  }

  private configuracaoForm() {
    this.paymentForm = this.fb.group({
      duplicatas: this.fb.array([this.fb.group({
        id: [''],
        numero: [''],
        valor: ['', Validators.required],
        vencimento: ['', Validators.required],
        escriturada: ['']
      })])
    });
  }

  get duplicatas(): FormArray {
    return this.paymentForm.get('duplicatas') as FormArray;
  }

  addNewItem(duplicate: Duplicatas) {
    let controls = <FormArray>this.paymentForm.controls['duplicatas'];
    let control = controls.controls[controls.length - 1].value.vencimento
    let vari = moment(control).add(1, 'month')

    let formGroup;
    if (duplicate != undefined) {
      formGroup = this.fb.group({
        id: [duplicate.id, Validators.required],
        numero: [(controls.length + 1).toString()],
        valor: [duplicate.valor, Validators.required],
        vencimento: [duplicate.vencimento, Validators.required],
        escriturada: [duplicate.escriturada]
      });
      controls.push(formGroup);
    } else {
      formGroup = this.fb.group({
        id: [''],
        numero: [(controls.length + 1).toString()],
        valor: ['', Validators.required],
        vencimento: [vari.format('YYYY-MM-DD'), Validators.required],
        escriturada: [false]
      });
      controls.push(formGroup);
    }
  }

  public duplicata_total = 0

  distribuiTotal() {
    let controls: any = this.paymentForm.controls['duplicatas'];
    let totalAUX = this.total
    let counter = 0
    let controlsNaoaEsctrituradas = []
    controls.controls.forEach((element: any, i) => {
      if (element.controls.escriturada.value != true) {
        controlsNaoaEsctrituradas.push(element)
      } else {
        totalAUX = Number((totalAUX - element.controls.valor.value).toFixed(2))
      }
    })

    let div = totalAUX / controlsNaoaEsctrituradas.length
    controlsNaoaEsctrituradas.forEach((element: any, i) => {
      counter += Number(div.toFixed(2))
      element.controls.valor.setValue(Number(div.toFixed(2)))
    })

    if (Number((totalAUX - counter).toFixed(2)) != 0) {
      controlsNaoaEsctrituradas[0].controls.valor.setValue(div + Number((this.total - counter).toFixed(2)))
    }
    this.calcularParcelasTotal()
  }

  calcularParcelasTotal() {
    this.duplicata_total = 0
    let controls: any = this.paymentForm.controls['duplicatas'];
    controls.controls.forEach((element: any, i) => {
      this.duplicata_total += Number((element.value.valor))
    })
  }

  removeItem(i: number, duplicataForm: any) {
    let duplicata = duplicataForm.value;
    const controls = <FormArray>this.paymentForm.controls['duplicatas'];
    if (duplicata.id == '') {
      controls.removeAt(i);
    } else {
      this.plannedPaymentProvider.delPayment(duplicata.id, this.dfeId).then(() => {
        controls.removeAt(i);
        this.snackBar.openLong('Removido com sucesso!', 'success');
        this.duplicatesPlanned.forEach((duplicataListLancamento, index) => {
          if (duplicataListLancamento.id == duplicata.id) {
            this.duplicatesPlanned.splice(index, 1);
            this.duplicatesHistoric.splice(index, 1);
            this.duplicatesAccount.splice(index, 1);
            this.duplicatesBankAccount.splice(index, 1);
          }
        });
      }).catch((result: HttpErrorResponse) => {
        this.snackBar.openLong('Não foi possível remover! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'planPayment'), 'erro');
      });
    }
    this.calcularParcelasTotal()
  }

  editPaymentPlanned() {
    if (this.paymentForm.valid) {
      this.enviando = true;
      this.prepareToPostAndPut(this.convertObject(this.paymentForm.getRawValue().duplicatas));
    } else {
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

  convertObject(duplicates: any[]) {
    let duplicatesSendFormat = [];

    duplicates.forEach((duplicate) => {
      let duplicateSendFormat = {
        id: duplicate.id,
        companyId: +localStorage.getItem("idEmpresa"),
        dfeId: this.dfeId,
        number: duplicate.numero,
        expiration: duplicate.vencimento,
        value: duplicate.valor,
        isPaid: duplicate.escriturada
      }
      duplicatesSendFormat.push(duplicateSendFormat);
    });

    return duplicatesSendFormat;
  }

  private setDuplicatesPlanForm() {
    this.duplicatesPlanned.forEach((duplicata, index) => {
      this.duplicatesHistoric.push(duplicata.historic);
      delete duplicata['historic'];
      this.duplicatesAccount.push({accountsChart: duplicata.accountsChart, accountsChartId: duplicata.accountsChartId});
      delete duplicata['accountsChart'];
      delete duplicata['accountsChartId'];
      this.duplicatesBankAccount.push({bankAccount: duplicata.bankAccount, bankAccountId: duplicata.bankAccountId});
      delete duplicata['bankAccount'];
      delete duplicata['bankAccountId'];
      if (index == 0) {
        this.duplicatas.controls[0].setValue(duplicata);
        if (duplicata.escriturada) {
          this.duplicatas.controls[0].disable();
        }
      } else {
        this.addNewItem(duplicata);
        if (duplicata.escriturada) {
          this.duplicatas.controls[this.duplicatas.length - 1].disable();
        }
      }
    });
    this.calcularParcelasTotal()

  }

  private prepareToPostAndPut(duplicatesDTOResponses: any[]) {
    this.enviando = true;
    let putDuplicates = [];
    let postDuplicates = [];
    let paidDuplicates = [];
    duplicatesDTOResponses.forEach((duplicate) => {
      // @ts-ignore
      if (duplicate.id == '') {
        duplicate.id = undefined;
        duplicate.isPaid = false;
        postDuplicates.push(duplicate);
      } else if (duplicate.isPaid) {
        paidDuplicates.push(duplicate);
      } else {
        putDuplicates.push(duplicate);
      }
    });

    putDuplicates.length > 0 ? this.putDuplicates(paidDuplicates, putDuplicates, postDuplicates) : this.postDuplicates(postDuplicates);
  }


  postDuplicates(duplicates) {
    this.plannedPaymentProvider.postPayment(duplicates).then((result: []) => {
      this.duplicatesPlanned.forEach((dup: any, index) => {
        dup.historic = this.duplicatesHistoric[index]
        dup.accountsChart = this.duplicatesAccount[index].accountsChart;
        dup.accountsChartId = this.duplicatesAccount[index].accountsChartId;
        dup.bankAccount = this.duplicatesBankAccount[index].bankAccount;
        dup.bankAccountId = this.duplicatesBankAccount[index].bankAccountId;
      });
      this.duplicatesMapper.arrayToClientModel(result).forEach((dup: any, i) => {
        // console.log(result);
        // console.log(dup);
        this.duplicatesPlanned.push(dup);
        this.activeModal.close();
      });
      this.snackBar.openLong('Duplicatas modificadas com sucesso!', 'success');
    }).catch((result: HttpErrorResponse) => {
      this.enviado = false;
      console.error(result);
      this.snackBar.openLong('Não foi possível inserir novas duplicatas  na etapa 2! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'planPayment'), 'erro');
    });
  }

  putDuplicates(paidDuplicates, duplicates, postDuplicates) {
    this.plannedPaymentProvider.putPayment(duplicates).then((result: []) => {
      let dupsPut = this.duplicatesMapper.arrayToClientModel(result.concat(paidDuplicates));
      this.duplicatesPlanned.forEach((dup: Duplicatas, index) => {
        this.duplicatesPlanned[index] = dupsPut[index];
      });
      this.duplicatesPlanned.sort(function (a, b) {
        return (+a.numero) < (+b.numero) ? -1 : (+a.numero) > (+b.numero) ? 1 : 0;
      });
      if (postDuplicates.length == 0) {
        this.duplicatesPlanned.forEach((dup: any, index) => {
          dup.historic = this.duplicatesHistoric[index]
          dup.accountsChart = this.duplicatesAccount[index].accountsChart;
          dup.accountsChartId = this.duplicatesAccount[index].accountsChartId;
          dup.bankAccount = this.duplicatesBankAccount[index].bankAccount;
          dup.bankAccountId = this.duplicatesBankAccount[index].bankAccountId;
        });
        this.activeModal.close();
      } else {
        this.postDuplicates(postDuplicates);
      }
    }).catch((result: HttpErrorResponse) => {
      this.enviando = false;
      console.error(result);
      this.snackBar.openLong('Não foi possível inserir novas duplicatas na etapa 1! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'planPayment'), 'erro');
    });
  }

}
