import { Injectable } from "@angular/core";
import { HttpClientJsonpModule, HttpClient } from "@angular/common/http";

@Injectable()
export class CheckCNPJProvider {
  constructor(public http: HttpClient) {}

  getCNPJ(cnpj: string) {
    return new Promise((resolve, reject) => {
      this.http
        .jsonp("https://www.receitaws.com.br/v1/cnpj/" + cnpj, "callback")
        .subscribe(
          (result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getCNPJWS(cnpj: string) {
    //console.log("cnpj que ta chegando", cnpj)
    return new Promise((resolve, reject) => {
      this.http
        .get(
          "https://comercial.cnpj.ws/cnpj/" +
            cnpj +
            "?token=LNXcXboFORKvMQrwnhiHH42jjqjApj4KiyeDSJ8droPA"
        )
        .subscribe(
          (result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
