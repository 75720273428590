import {Injectable} from "@angular/core";
import {HistoricDTOResponse} from '../model/dto/HistoricDTOResponse';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';

@Injectable()
export class historicoUtil {

  public allHistorics: string[] = ['@participante@','@participante-CpfCnpj@', '@numero-documento@', '@conta@', '@mes@', '@mes-anterior@','@mes-e-ano-anterior@', '@ano@', '@ano-anterior@'];
  public separatorKeysCodesHistoric: number[] = [ENTER, SPACE, COMMA];

  public historics: string[] = [];
  public filteredHistoric: Observable<string[]>;
  public historicCtrl = new FormControl();
  public editMode = false
  public historic: HistoricDTOResponse = new HistoricDTOResponse();
  public existingHistoric: HistoricDTOResponse;

  constructor(){
    this.initializeSearchAutoCompleteHistoric()
  }

  ngOnInit() {
   //
   // console.log("filtered:",this.filteredHistoric)
  }

  initializeSearchAutoCompleteHistoric() {
    this.filteredHistoric = this.historicCtrl.valueChanges.pipe(
      startWith(null),
      map((historic: string | null) => historic ? this._filterHistoric(historic) : this.allHistorics.slice()));
      //console.log("filtered2:",this.filteredHistoric)

  }


  checkIfIsEditMode(existingHistoric) {
  //console.log("existing",existingHistoric)
      this.historics = existingHistoric.split('#');
      this.historics.forEach((strings, index) => {
        if (strings.trim() === '') {
          this.historics.splice(index, 1);
        }
      });
      const objHistoric = {...existingHistoric};
      //console.log("obj hisotric",objHistoric)
      return objHistoric

  }

  setObjectEdit(historic: HistoricDTOResponse) {
    this.existingHistoric.model = historic.model;
    this.existingHistoric.id = historic.id;
    this.existingHistoric.description = historic.description;
    this.existingHistoric.code = historic.code;
  }

  addHistoric(event): void {
   //console.log("teste1kkk")
    const input = event.input;
    const value = event.value;

    // Add our historics
    if ((value || '').trim()) {
      this.historics.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.historicCtrl.setValue(null);
  }

  checkHistoricOrObject(y) {
    y.forEach(x => {
      if (x.historic.includes('modelo') && x.historic.includes('historico')) {
        x.historic = x.historic.normalize('NFD').replace(/[^\x00-\x7F]/g, "").replace(/[\u0300-\u036f]/g, '');
        let json = JSON.parse(x.historic);
        x.historic = json.historico.trim();
        x.modelo = (json.modelo ? json.modelo.trim() : x.historic);
      }
    })
  }
  removeHistoric(historic: string): void {
    const index = this.historics.indexOf(historic);

    if (index >= 0) {
      this.historics.splice(index, 1);
    }
  }

  insertHistoricList(description) {
    // console.log(description);
    // console.log(this.allHistorics);
    this.allHistorics.push(description);
    this.filteredHistoric = this.historicCtrl.valueChanges.pipe(
      startWith(null),
      map((historic: string | null) => historic ? this._filterHistoric(historic) : this.allHistorics.slice()));
  }


  private _filterHistoric(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allHistorics.filter(historic => historic.toLowerCase().indexOf(filterValue) === 0);
  }

  genereteHistoric(): string {
    if (this.historics.length !== 0) {
      let historic = '';
      this.historics.forEach(option => {
        if (this.isChip(option)) {
          historic = historic + ' ' + '#' + option.trim()  + '#';
        } else {
            historic = historic + ' ' + option.trim();
        }
      });
      return  historic;
    }
  }



  isChip(historic: string): boolean {
    return this.allHistorics.findIndex(val => val === historic) >= 0;
  }
}
