import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PessoaFiscalDTO } from 'src/model/dto/PessoaFiscalDTO';
import { MemberProvider } from 'src/providers/company/member/member';
import { ConvertObjectPipe } from 'src/util/pipes/ConvertObjectPipe';
import { ReplaySubject } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessKeyProvider } from 'src/providers/dfe/access-key/access-key';
import { Snackbar } from 'src/util/snackbar';
import { ErrorUtil } from 'src/util/error';
import { AccessKeysStatus } from 'src/model/AccessKeysStatus';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'app-edit-member-modal',
  templateUrl: './edit-member-modal.component.html',
  styleUrls: ['./edit-member-modal.component.scss']
})
export class EditMemberModalComponent implements OnInit {

  @Input() public currentAccessKey: AccessKeysStatus;

  @ViewChild('selectMember', {static: true}) selectMember: MatSelect;

  public members: PessoaFiscalDTO[];
  // tslint:disable-next-line: variable-name
  public filter_member: ReplaySubject<PessoaFiscalDTO[]> = new ReplaySubject<PessoaFiscalDTO[]>(1);
  public carregando = false;
  public finalizado = false;
  public formGroup: FormGroup;
  public cpfCnpjSelecionado: string;

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal,
              private memberProvider: MemberProvider,
              private accessKeyProvider: AccessKeyProvider,
              public snackBar: Snackbar,
              public erroUtil: ErrorUtil,
              public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initializeMembers();
    this.formGroup = this.formBuilder.group({
      produtor: ['', Validators.required]
    });
  }

  initializeMembers() {
    const pipe = new ConvertObjectPipe();
    this.memberProvider.getAllMembers().then((result: any[]) => {
      this.members = pipe.transform(result, 'membros').filter(x => x.certificateDTORespComp != null);
      // this.members = pipe.transform(result, 'membros');
      this.filter_member.next(this.members);
    });
  }

  selecionarProdutor(valor: string) {
    this.cpfCnpjSelecionado = valor;

    this.selectMember.close();
    this.selectMember.focus();
  }

  updateMember() {
    const accessKey = this.currentAccessKey;
    accessKey.cpfCnpj = this.cpfCnpjSelecionado;
    accessKey.document = null;
    this.accessKeyProvider.editDfeKeyFarmer(accessKey).then((response: any[]) => {
      this.snackBar.openLong('Produtor editado com sucesso!', 'success');

      this.accessKeyProvider.reintegrateByAccessKey(accessKey.key).then(() => {
        this.carregando = false;
        this.finalizado = true;
        this.activeModal.close(this.finalizado);
      }).catch((res: HttpErrorResponse) => {
        const errorString = `Erro ao solicitar reintegração! ${this.erroUtil.checkErrorStatus(res, res.status, res.error, 'grouper')}`;
        this.carregando = false;
        this.snackBar.openLong(errorString, 'erro');
      });

    }).catch((result: HttpErrorResponse) => {
      const errorString = `Erro ao editar produtor! ${this.erroUtil.checkErrorStatus(result, result.status, result.error, 'grouper')}`;
      this.carregando = false;
      this.finalizado = true;
      this.snackBar.openLong(errorString, 'erro');
    });
  }

}
