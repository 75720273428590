import moment from "moment";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {Bookkeeping} from "../../../../model/dto/bookkeping/Bookkeeping";
import {Injectable} from "@angular/core";
import {ImovelDTO} from "../../../../model/dto/ImovelDTO";
import {GroupByPipeMonth} from "../../../../util/pipes/GroupByMonth";

class ErrorLCA {
  message: string;
  errors: Error[];
}

class Error {
  property: string;
  section: string;
}

@Injectable()
export class GeneratePdfLCA {

  lcaErrors: Error[] = [];

  private static formatBRLValue(value) {
    const balanceFormated = ((Math.abs(value.toFixed(2)))).toLocaleString('pt-br', {minimumFractionDigits: 2});
    if (value >= 0) {
      return balanceFormated;
    } else {
      return '-' + balanceFormated;
    }
  }

  public generate(generalInformation, periodResume, bookkeppings: Bookkeeping[], farmerProperties: { allProperties: ImovelDTO[]; properties: any }, showParticipantCNPJ: boolean) {
    this.lcaErrors = [];
    return new Promise((accept, reject) => {

      if(farmerProperties.properties.length === 0) {
        const errorLCA = new ErrorLCA();
        errorLCA.errors = [];
        errorLCA.message = 'CADASTROS INCOMPLETOS';
        const error = new Error();
        error.property = 'Nenhum imóvel encontrado';
        error.section = 'IMÓVEIS'
        errorLCA.errors.push(error);
        reject(errorLCA);
      }

      if(bookkeppings.length === 0) {
        const errorLCA = new ErrorLCA();
        errorLCA.errors = [];
        errorLCA.message = 'CADASTROS INCOMPLETOS';
        const error = new Error();
        error.property = 'Nenhum lançamento encontrado';
        error.section = 'LANÇAMENTOS'
        errorLCA.errors.push(error);
        reject(errorLCA);
      }

      const farmer = generalInformation.member;
      const properties = this.getProperties(farmerProperties);
      const consolidacao = this.getConsolidated(periodResume);
      const firstBookkepping = moment.tz(bookkeppings[0].paymentDate, 'America/Sao_Paulo').locale('pt-br').format('LL');
      const lastBookkepping = moment.tz(bookkeppings[bookkeppings.length - 1].paymentDate, 'America/Sao_Paulo').locale('pt-br').format('LL');
      const periodYear = moment.tz(bookkeppings[0].paymentDate, 'America/Sao_Paulo').format('YYYY');
      const openInformation = {
        title: 'TERMO DE ABERTURA',
        subtitle: 'Primeiro lançamento efetuado em: ' + firstBookkepping
      }
      const closeInformation = {
        title: 'TERMO DE ENCERRAMENTO',
        subtitle: 'Último lançamento efetuado em: ' + lastBookkepping
      }

      const centeredText = function (text, y) {
        const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(textOffset, y, text);
      }

      const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages()
        let docSize: number;
        doc.setFont('helvetica', 'italic')
        doc.setFontSize(8)
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          doc.text('Página ' + String(i), 190, 295)
          doc.line(5, 290, 205, 290)
        }
        doc.setPage(1)
        doc.setFont('helvetica', 'normal')
        doc.setFontSize(14);
        docSize = pageCount;
        doc.text(5, 65, 'Este livro contém ' + docSize + ' folhas numeradas sequencialmente de 1 a ' + docSize + ' e servirá para o \n' +
          'lançamento das receitas e despesas, durante o ano-calendário de ' + periodYear + ', relativos à \n' +
          'atividade rural do contribuinte abaixo identificado:')
        doc.setPage(pageCount)
        doc.text(5, 65, 'Este livro contém ' + docSize + ' folhas numeradas sequencialmente de 1 a ' + docSize + ' e servirá para o \n' +
          'lançamento das receitas e despesas, durante o ano-calendário de ' + periodYear + ', relativos à \n' +
          'atividade rural do contribuinte abaixo identificado:')
      }

      const doc = new jsPDF("p", "mm", "a4");
      this.getFarmerInfo(doc, generalInformation, properties, openInformation, centeredText);
      doc.addPage();
      doc.autoTable({
        startY: 60,
        showHead: 'everyPage',
        margin: {top: 60, bottom: 10, left: 5, width: 205},
        head: [['Inscrição estadual / Municipal', 'Nome do imóvel / Município', 'Nirf']],
        body: properties,
        didDrawPage: function (data) {
          doc.setFontSize(20);
          doc.setDrawColor(0);
          doc.setFillColor(215, 208, 208);
          doc.rect(5, 10, 200, 12, 'FD');
          centeredText('Livro Caixa da Atividade Rural (Lei nº 9.250/95, art. 18)', 18);
          doc.setFillColor(215, 208, 208);
          doc.rect(5, 22, 200, 12, 'FD');
          centeredText('BRASIL', 30);
          doc.setFontSize(14);
          doc.setFillColor(215, 208, 208);
          doc.rect(5, 35, 200, 12, 'FD');
          centeredText(generalInformation.member.name.toUpperCase(), 42);
          doc.text(295, 55, moment(generalInformation.signature).format('DD/MM/YY').toString());
          doc.setFontSize(8);
          doc.text(5, 295, 'CPF: ' + generalInformation.member.cpfCNPJ);
        },
        headStyles: {
          fillColor: "#adacac",
          textColor: "#000000"
        },
        columnStyles: {
          0: {cellWidth: 66},
          1: {cellWidth: 66},
          2: {cellWidth: 67},
        }
      });
      doc.addPage();
      this.groupBookkeppingByMonth(generalInformation, periodResume, bookkeppings, doc, showParticipantCNPJ, centeredText);
      this.getYearResume(doc, consolidacao, generalInformation, centeredText);
      doc.addPage();
      this.getFarmerInfo(doc, generalInformation, properties, closeInformation, centeredText);
      addFooters(doc);

      if(this.lcaErrors.length === 0) {
        accept( doc.save('LCA-' + periodYear + '-' + farmer.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "") + '.pdf'));
      } else {
        const errorLCA = new ErrorLCA();
        errorLCA.message = 'CADASTROS INCOMPLETOS';
        errorLCA.errors = this.lcaErrors;
        reject(errorLCA);
      }
    });
  }

  private getProperties(farmerProperties) {
    const properties = [];
    const sectionName = 'IMÓVEIS';
    farmerProperties.properties.forEach(e => {
      const property: ImovelDTO = farmerProperties.allProperties.find(obj => obj.propertyCode === e.imovel.propertyCode);
      const tempObj = [];
      tempObj.push(this.setNullOrUndefinedToWhiteSpace(property.address.countryDTORespComp.stateDTORespComp.abbreviation, sectionName, 'ENDEREÇO - ESTADO - IMÓVEL ' + e.imovel.propertyCode, true));
      tempObj.push(property.name.toUpperCase() + ' / ' + this.setNullOrUndefinedToWhiteSpace(property.address.countryDTORespComp.stateDTORespComp.cityDTORespComp.name, sectionName, 'ENDEREÇO - CIDADE - IMÓVEL ' + e.imovel.propertyCode, true).toUpperCase());
      tempObj.push(this.setNullOrUndefinedToWhiteSpace(property.itrCode, sectionName, 'ITR - IMÓVEL ' + e.imovel.propertyCode, true));
      properties.push(tempObj);
    });
    return properties;
  }

  private getConsolidated(periodResume) {
    const consolidated = [];
    const sumResume = [];
    sumResume.push('TOTAL');
    sumResume.push(GeneratePdfLCA.formatBRLValue(periodResume.reduce((total, valor) => total + valor.incoming, 0)));
    sumResume.push(GeneratePdfLCA.formatBRLValue(periodResume.reduce((total, valor) => total + valor.outlay, 0)));
    sumResume.push('');
    periodResume.forEach(e => {
      const tempObj = [];
      tempObj.push(e.key);
      tempObj.push(GeneratePdfLCA.formatBRLValue(e.incoming));
      tempObj.push(GeneratePdfLCA.formatBRLValue(e.outlay));
      tempObj.push(GeneratePdfLCA.formatBRLValue(e.balance));
      consolidated.push(tempObj);
    });
    consolidated.push(sumResume);
    return consolidated;
  }

  private getYearResume(doc, consolidacao, generalInformation, centeredText) {
    const farmer = generalInformation.member;
    doc.setFillColor(215, 208, 208);
    doc.rect(5, 58, 200, 12, 'FD');
    centeredText('Resumo do ano', 65);
    doc.autoTable({
      startY: 72,
      showHead: 'everyPage',
      margin: {top: 60, bottom: 10, left: 5, width: 205},
      head: [['Mês', 'Entrada', 'Saída', 'Saldo']],
      body: consolidacao,
      didDrawPage: function (data) {
        doc.setFontSize(20);
        doc.setDrawColor(0);
        doc.setFillColor(215, 208, 208);
        doc.rect(5, 10, 200, 12, 'FD');
        centeredText('Livro Caixa da Atividade Rural (Lei nº 9.250/95, art. 18)', 18);
        doc.setFillColor(215, 208, 208);
        doc.rect(5, 22, 200, 12, 'FD');
        centeredText('BRASIL', 30);
        doc.setFontSize(14);
        doc.setFillColor(215, 208, 208);
        doc.rect(5, 35, 200, 12, 'FD');
        centeredText(farmer.name.toUpperCase(), 42);
        doc.text(295, 55, moment(generalInformation.signature).format('DD/MM/YY').toString());
        doc.setFontSize(8);
        doc.text(5, 295, 'CPF: ' + farmer.cpfCNPJ);
      },
      headStyles: {
        fillColor: "#adacac",
        textColor: "#000000"
      },
      columnStyles: {
        0: {cellWidth: 50},
        1: {cellWidth: 50},
        2: {cellWidth: 50},
        3: {cellWidth: 50},
      }
    });
  }

  private getFarmerInfo(doc, generalInformation, properties, typeInformation, centeredText) {
    const sectionName = 'PRODUTOR';
    const farmer = generalInformation.member;
    doc.setFontSize(20);
    doc.setDrawColor(0);
    doc.setFillColor(215, 208, 208);
    doc.rect(5, 10, 200, 12, 'FD');
    centeredText('Livro Caixa da Atividade Rural (Lei nº 9.250/95, art. 18)', 18);
    doc.setFillColor(215, 208, 208);
    doc.rect(5, 22, 200, 12, 'FD');
    centeredText('BRASIL', 30);
    doc.setFontSize(14);
    centeredText(typeInformation.subtitle, 42);
    doc.setFillColor(215, 208, 208);
    doc.rect(5, 45, 200, 12, 'FD');
    centeredText(typeInformation.title, 53);
    doc.text(265, 80, moment(generalInformation.signature).format('DD/MM/YY').toString());
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 85, 200, 80, 'FD');
    doc.text(20, 95, 'CPF: ' + farmer.cpfCNPJ);
    doc.text(20, 105, 'NOME: ' + farmer.name);
    doc.text(20, 115, 'ENDEREÇO: ' + this.setNullOrUndefinedToWhiteSpace(farmer.addressDTORespComp.street, sectionName ,'ENDEREÇO - LOGRADOURO', true));
    doc.text(130, 115, 'Nr: ' + this.setNullOrUndefinedToWhiteSpace(farmer.addressDTORespComp.houseNumber, sectionName , 'ENDEREÇO - NÚMERO', true));
    doc.text(20, 125, 'COMPLEMENTO: ' + this.setNullOrUndefinedToWhiteSpace(farmer.addressDTORespComp.complement, sectionName , 'ENDEREÇO - COMPLEMENTO', false));
    doc.text(130, 125, 'BAIRRO: ' + this.setNullOrUndefinedToWhiteSpace(farmer.addressDTORespComp.neighborhood, sectionName , 'ENDEREÇO - BAIRRO', true));
    doc.text(20, 135, 'MUNICÍPIO: ' + this.setNullOrUndefinedToWhiteSpace(farmer.addressDTORespComp.countryDTORespComp.stateDTORespComp.cityDTORespComp.name, sectionName, 'ENDEREÇO - MUNICÍPIO', true));
    doc.text(130, 135, 'CEP: ' + this.setNullOrUndefinedToWhiteSpace(farmer.addressDTORespComp.cep, sectionName , 'ENDEREÇO - CEP', true));
    doc.text(20, 145, 'U.F.: ' + this.setNullOrUndefinedToWhiteSpace(farmer.addressDTORespComp.countryDTORespComp.stateDTORespComp.abbreviation, sectionName, 'ENDEREÇO - U.F.', true));
    doc.text(130, 145, 'TELEFONE: ' + this.setNullOrUndefinedToWhiteSpace(farmer.addressDTORespComp.telephone, sectionName, 'ENDEREÇO - TELEFONE.', true));
    doc.text(20, 155, 'NOME MOEDA: Real');
    doc.line(5, 220, 205, 220);
    // doc.text(14, 225, 'CRC/' + generalInformation.crc);
    centeredText(`${generalInformation.crc}`, 225);
    doc.line(5, 190, 205, 190);
    centeredText(farmer.name.toUpperCase(), 195);
    // doc.text(14, 195, farmer.name.toUpperCase());
    centeredText(farmer.addressDTORespComp.countryDTORespComp.stateDTORespComp.cityDTORespComp.name + ', ' + moment(generalInformation.signature).locale('pt-br').format('LL'), 260);

  }

  private groupBookkeppingByMonth(generalInformation, periodResume, bookkepping: Bookkeeping[], doc, showParticipantCNPJ, centeredText) {
    const farmer = generalInformation.member;
    let groupByMonth = new GroupByPipeMonth();
    const bookkeppingByMonth = groupByMonth.transform(bookkepping, 'month');
    const tablesMonth = doc => {
      bookkeppingByMonth.forEach((bookkepingMonth) => {
        const prepare = [];
        let collumns = [['Data', 'Nº Doc', 'Histórico', 'Conta', 'Entrada', 'Saída', 'Saldo']];
        if (showParticipantCNPJ) {
          collumns = [['Data', 'Nº Doc',  'CNPJ', 'Histórico', 'Conta', 'Entrada', 'Saída', 'Saldo']];
        }
        bookkepingMonth.list.forEach(e => {
          //console.log("e", e);
          const tempObj = [];
          tempObj.push(moment.tz(e.paymentDate, 'America/Sao_Paulo').format('DD/MM/YYYY'));
          tempObj.push(e.doc.number);
          if (showParticipantCNPJ) {
            if (e.doc.participant !== null) {
              tempObj.push(e.doc.participant.cpfCNPJParticipant);
            } else {
              tempObj.push('');
            }
          }
          tempObj.push(e.historic.toUpperCase());
          tempObj.push(e.account.description.toUpperCase());
          const valueFormated = ((Math.abs(e.amountPaid.toFixed(2)))).toLocaleString('pt-br', {minimumFractionDigits: 2});
          if (e.amountPaid < 0) {
            tempObj.push('');
            tempObj.push('-' + valueFormated);
          } else {
            tempObj.push(valueFormated);
            tempObj.push('');
          }
          if (e.balance < 0) {
            const balanceFormated = ((Math.abs(e.balance.toFixed(2)))).toLocaleString('pt-br', {minimumFractionDigits: 2});
            tempObj.push('-' + balanceFormated);
          } else {
            const balanceFormated = ((Math.abs(e.balance.toFixed(2)))).toLocaleString('pt-br', {minimumFractionDigits: 2});
            tempObj.push(balanceFormated);
          }
          prepare.push(tempObj);
        });
        if (showParticipantCNPJ) {
          doc.autoTable({
            startY: 60,
            showHead: 'everyPage',
            margin: {top: 60, bottom: 10, left: 5, width: 205},
            head: collumns,
            body: prepare,
            didDrawPage: function (data) {
              doc.setFontSize(20);
              doc.setDrawColor(0);
              doc.setFillColor(215, 208, 208);
              doc.rect(5, 10, 200, 12, 'FD');
              centeredText('Livro Caixa da Atividade Rural (Lei nº 9.250/95, art. 18)', 18);
              doc.setFillColor(215, 208, 208);
              doc.rect(5, 22, 200, 12, 'FD');
              centeredText('BRASIL', 30);
              doc.setFontSize(14);
              doc.setFillColor(215, 208, 208);
              doc.rect(5, 35, 200, 12, 'FD');
              centeredText(farmer.name.toUpperCase(), 42);
              doc.text(265, 55, moment(generalInformation.signature).format('DD/MM/YY').toString());
              doc.text(5, 55, bookkepingMonth.key + ' - ' + moment(generalInformation.period[0]).format('YYYY'));
              doc.setFontSize(8);
              doc.text(5, 295, 'CPF: ' + farmer.cpfCNPJ);
              centeredText(bookkepingMonth.key + ' - ' + moment(generalInformation.period[0]).format('YYYY'), 295);
            },
            drawRow(row) {
              doc.setFontSize(10);
              if (row.index > 0 && row.index % 10 === 0) {
                doc.autoTableAddPage();
              }
            },
            headStyles: {
              fillColor: "#adacac",
              textColor: "#000000"
            },
            bodyStyles: {
              fontSize: 8
            },
            columnStyles: {
              0: {cellWidth: 20},
              1: {cellWidth: 20},
              2: {cellWidth: 20},
              3: {cellWidth: 35},
              4: {cellWidth: 30},
              5: {cellWidth: 25},
              6: {cellWidth: 25},
              7: {cellWidth: 30}
            }
          });
        } else {
          doc.autoTable({
            startY: 60,
            showHead: 'everyPage',
            margin: {top: 60, bottom: 10, left: 5, width: 205},
            head: collumns,
            body: prepare,
            didDrawPage: function (data) {
              doc.setFontSize(20);
              doc.setDrawColor(0);
              doc.setFillColor(215, 208, 208);
              doc.rect(5, 10, 200, 12, 'FD');
              centeredText('Livro Caixa da Atividade Rural (Lei nº 9.250/95, art. 18)', 18);
              doc.setFillColor(215, 208, 208);
              doc.rect(5, 22, 200, 12, 'FD');
              centeredText('BRASIL', 30);
              doc.setFontSize(14);
              doc.setFillColor(215, 208, 208);
              doc.rect(5, 35, 200, 12, 'FD');
              centeredText(farmer.name.toUpperCase(), 42);
              doc.text(265, 55, moment(generalInformation.signature).format('DD/MM/YY').toString());
              doc.text(5, 55, bookkepingMonth.key + ' - ' + moment(generalInformation.period[0]).format('YYYY'));
              doc.setFontSize(8);
              doc.text(5, 295, 'CPF: ' + farmer.cpfCNPJ);
              centeredText(bookkepingMonth.key + ' - ' + moment(generalInformation.period[0]).format('YYYY'), 295);
            },
            drawRow(row) {
              doc.setFontSize(10);
              if (row.index > 0 && row.index % 10 === 0) {
                doc.autoTableAddPage();
              }
            },
            headStyles: {
              fillColor: "#adacac",
              textColor: "#000000"
            },
            bodyStyles: {
              fontSize: 8
            },
            columnStyles: {
              0: {cellWidth: 20},
              1: {cellWidth: 20},
              2: {cellWidth: 50},
              3: {cellWidth: 30},
              4: {cellWidth: 25},
              5: {cellWidth: 25},
              6: {cellWidth: 30}
            }
          });
        }
        
        let finalY = doc.previousAutoTable.finalY;
        if (finalY > 150) {
          doc.addPage();
          finalY = 15;
        }
        let i = 0;
        doc.autoTable({
          startY: finalY + 15,
          showHead: 'everyPage',
          margin: {top: 60, bottom: 10, left: 5, width: 205},
          head: [['Entrada', 'Saída', 'Saldo']],
          columnStyles: {
            0: {cellWidth: 66},
            1: {cellWidth: 66},
            2: {cellWidth: 67},
            // etc
          },
          didDrawPage: function (data) {
            if (i === 0) {
              i = 1;
              doc.setFontSize(15);
              doc.setDrawColor(0);
              doc.setFillColor(215, 208, 208);
              doc.rect(5, finalY, 199, 12, 'FD');
              centeredText('Resumo do mês', finalY + 8);
            }
            doc.setFontSize(8);
            doc.text(5, 295, 'CPF: ' + farmer.cpfCNPJ);
            centeredText(bookkepingMonth.key + ' - ' + moment(generalInformation.period[0]).format('YYYY'), 295);
          },
          drawRow: function(row) {
            if (row.index > 0 && row.index % 10 === 0) {
              doc.autoTableAddPage();
            }
          },
          body: this.generateConsolidatedMonth(bookkepingMonth.key, periodResume),
          headStyles: {
            fillColor: "#adacac",
            textColor: "#000000"
          },
        });
        doc.addPage();
      });
    };
    return tablesMonth(doc);
  }

  private generateConsolidatedMonth(month, periodResume) {
    periodResume = periodResume.filter(obj => obj.key === month);
    const prepare = [];
    periodResume.forEach(e => {
      const tempObj = [];
      tempObj.push(GeneratePdfLCA.formatBRLValue(e.incoming));
      tempObj.push(GeneratePdfLCA.formatBRLValue(e.outlay));
      tempObj.push(GeneratePdfLCA.formatBRLValue(e.balance));
      prepare.push(tempObj);
    });
    return prepare;
  }

  private setNullOrUndefinedToWhiteSpace(value, section, propertyName, isMandatory: boolean) {
    if (value === undefined || value === null || value.trim() === '') {
      if(isMandatory) {
        const error = new Error();
        error.property = propertyName;
        error.section = section;
        if (this.lcaErrors.filter(obj => obj.property === propertyName).length === 0) {
          this.lcaErrors.push(error);
        }
      }
      return '';
    } else {
      return value;
    }
  }

}


