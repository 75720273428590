import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorUtil} from "../../../../../util/error";
import {Snackbar} from "../../../../../util/snackbar";
import {HttpErrorResponse} from "@angular/common/http";
import {FolderDTOResponse} from "../../../../../model/dto/FolderDTOResponse";
import {FolderProvider} from "../../../../../providers/folder/folder";

@Component({
  selector: 'app-deletar-pasta',
  templateUrl: './deletar-pasta.component.html',
  styleUrls: ['./deletar-pasta.component.scss']
})
export class DeletarPastaComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeletarPastaComponent>,
              public folderProvider: FolderProvider,
              public errorUtil: ErrorUtil,
              public  snackbar: Snackbar,
              @Inject(MAT_DIALOG_DATA) public data: FolderDTOResponse) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(): void {
    this.folderProvider.delFolder(this.data.id).then(() => {
      this.dialogRef.close(this.data);
      this.snackbar.openLong('Excluído com sucesso!', 'success');
    }).catch((result: HttpErrorResponse) => {
      console.error(result);
      this.snackbar.openLong('Não foi possível excluir! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'folder'), 'erro');
    })
    ;
  }

}
