import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";

@Injectable()
export class BelongingsProvider {

  apiBase: string = "/main-records/v1/belongings/";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getByYear(year: number) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/by-year/${year}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getByCategory(category: number) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/by-code/${category}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  post(obj) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}`, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  put(obj) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}`, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/${id}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
}
