import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {Pessoa} from "../../../model/Pessoa";

@Injectable()
export class CompanyProvider {

  apiCompany: string = "/user/v1/company/";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  postCompany(company: Pessoa) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiCompany, company, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  hideCompany(companyId: number) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiCompany + 'hide-company', companyId, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putCompany(company: Pessoa) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiCompany, company, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delCompany(company: Pessoa) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiCompany + company.id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

}
