import {Component, Injectable, OnInit} from '@angular/core';
import {AccountProvider} from "../../../../../providers/account/account";
import {PostingProvider} from "../../../../../providers/bookkeeping/posting/posting";
import {BrMaskerIonicServices3} from "brmasker-ionic-3";
import {ExplorationDTOResponse} from "../../../../../model/dto/ExplorationDTOResponse";
import {ExplorationProvider} from "../../../../../providers/company/exploration/exploration";
import {PropertyProvider} from "../../../../../providers/company/property/property";
import {DateUtil} from "../../../../../util/dateUtil";
import 'moment-timezone';
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {BalanceChart} from "../charts";
import {exportIRPFUtil} from "../../../../../util/exportIRPF";

@Component({
  selector: 'app-tree-table-consolidacao',
  template: `
  `
})

@Injectable()
export class TreeTableConsolidacao implements OnInit {
  aux: any;
  saldo: any;
  exploration: ExplorationDTOResponse[];

  constructor(private accountProvider: AccountProvider,
              private brMasker: BrMaskerIonicServices3,
              public dateUtil: DateUtil,
              public snackBar: Snackbar,
              public errorUtil: ErrorUtil,
              public charts: BalanceChart,
              private propertyProvider: PropertyProvider,
              private explorationProvider: ExplorationProvider,
              private postingProvider: PostingProvider) {
  }

  public consultando: boolean;
  public id: number;
  public data: any = [];
  public year: string;
  private rootID: any;
  public dataSimp;
  public dataIRPF;
  public loadingSimp = false;

  ngOnInit(): void {
  }

  start() {
    this.data = [];
    this.dataSimp = [];
    this.dataIRPF = null;
    this.consultando = false;
  }

  getLancamentos(ano: string, bankAccounts, properties, members, dedutiveis) {
    this.consultando = true;
    this.year = ano;
    this.loadingSimp = true;
    this.data = [];
    this.aux = [];
    this.charts.destroyChart();
    let filtros = {
      "bankAccountIds": bankAccounts,
      "propertyCodes": properties,
      "cpfCnpjs": members,
      "deductible": dedutiveis
    };

    this.postingProvider.getAllPostingNew(ano, filtros).then((result: any) => {
      if (result.children) {
        this.aux = result.children;
        this.saldo = result.SALDO;
        this.filterLancamentos();
        this.charts.aux = result.children;
      }
      this.postingProvider.getPostingSimplificado(ano, filtros).then((result) => {
        this.dataSimp = result;
        this.dataIRPF = filtros.cpfCnpjs.length == 1 ? exportIRPFUtil.generateDataIRPF('SIMPLIFIED_REPORT', result, ano, filtros.cpfCnpjs[0]) : null;
        this.mappearDataSimplificada();
        this.charts.generate();
      }).catch(result => {
        this.loadingSimp = false;
        this.dataSimp = []
        this.snackBar.openLong(this.errorUtil.checkErrorStatus(result, result.staus, result.error, 'livroCaixa'), 'erro')
      });
    }).catch(result => {
      this.consultando = false;
      this.snackBar.openLong(this.errorUtil.checkErrorStatus(result, result.staus, result.error, 'livroCaixa'), 'erro')
    });
  }

  mappearDataSimplificada() {
    let dataSimpAUX = []
    dataSimpAUX.push({
      nome: 'Janeiro',
      despesa: this.dataSimp.expense.jan != null ? this.dataSimp.expense.jan : 0.00,
      receita: this.dataSimp.revenue.jan != null ? this.dataSimp.revenue.jan : 0.00,
      resultado: this.dataSimp.balance.jan != null ? this.dataSimp.balance.jan : 0.00
    });
    dataSimpAUX.push({
      nome: 'Fevereiro',
      despesa: this.dataSimp.expense.fev != null ? this.dataSimp.expense.fev : 0.00,
      receita: this.dataSimp.revenue.fev != null ? this.dataSimp.revenue.fev : 0.00,
      resultado: this.dataSimp.balance.fev != null ? this.dataSimp.balance.fev : 0.00
    });
    dataSimpAUX.push({
      nome: 'Março',
      despesa: this.dataSimp.expense.mar != null ? this.dataSimp.expense.mar : 0.00,
      receita: this.dataSimp.revenue.mar != null ? this.dataSimp.revenue.mar : 0.00,
      resultado: this.dataSimp.balance.mar != null ? this.dataSimp.balance.mar : 0.00
    });
    dataSimpAUX.push({
      nome: 'Abril',
      despesa: this.dataSimp.expense.abr != null ? this.dataSimp.expense.abr : 0.00,
      receita: this.dataSimp.revenue.abr != null ? this.dataSimp.revenue.abr : 0.00,
      resultado: this.dataSimp.balance.abr != null ? this.dataSimp.balance.abr : 0.00
    });
    dataSimpAUX.push({
      nome: 'Maio',
      despesa: this.dataSimp.expense.mai != null ? this.dataSimp.expense.mai : 0.00,
      receita: this.dataSimp.revenue.mai != null ? this.dataSimp.revenue.mai : 0.00,
      resultado: this.dataSimp.balance.mai != null ? this.dataSimp.balance.mai : 0.00
    });
    dataSimpAUX.push({
      nome: 'Junho',
      despesa: this.dataSimp.expense.jun != null ? this.dataSimp.expense.jun : 0.00,
      receita: this.dataSimp.revenue.jun != null ? this.dataSimp.revenue.jun : 0.00,
      resultado: this.dataSimp.balance.jun != null ? this.dataSimp.balance.jun : 0.00
    });
    dataSimpAUX.push({
      nome: 'Julho',
      despesa: this.dataSimp.expense.jul != null ? this.dataSimp.expense.jul : 0.00,
      receita: this.dataSimp.revenue.jul != null ? this.dataSimp.revenue.jul : 0.00,
      resultado: this.dataSimp.balance.jul != null ? this.dataSimp.balance.jul : 0.00
    });
    dataSimpAUX.push({
      nome: 'Agosto',
      despesa: this.dataSimp.expense.ago != null ? this.dataSimp.expense.ago : 0.00,
      receita: this.dataSimp.revenue.ago != null ? this.dataSimp.revenue.ago : 0.00,
      resultado: this.dataSimp.balance.ago != null ? this.dataSimp.balance.ago : 0.00
    });
    dataSimpAUX.push({
      nome: 'Setembro',
      despesa: this.dataSimp.expense.set != null ? this.dataSimp.expense.set : 0.00,
      receita: this.dataSimp.revenue.set != null ? this.dataSimp.revenue.set : 0.00,
      resultado: this.dataSimp.balance.set != null ? this.dataSimp.balance.set : 0.00
    });
    dataSimpAUX.push({
      nome: 'Outubro',
      despesa: this.dataSimp.expense.out != null ? this.dataSimp.expense.out : 0.00,
      receita: this.dataSimp.revenue.out != null ? this.dataSimp.revenue.out : 0.00,
      resultado: this.dataSimp.balance.out != null ? this.dataSimp.balance.out : 0.00
    });
    dataSimpAUX.push({
      nome: 'Novembro',
      despesa: this.dataSimp.expense.nov != null ? this.dataSimp.expense.nov : 0.00,
      receita: this.dataSimp.revenue.nov != null ? this.dataSimp.revenue.nov : 0.00,
      resultado: this.dataSimp.balance.nov != null ? this.dataSimp.balance.nov : 0.00
    });
    dataSimpAUX.push({
      nome: 'Dezembro',
      despesa: this.dataSimp.expense.dez != null ? this.dataSimp.expense.dez : 0.00,
      receita: this.dataSimp.revenue.dez != null ? this.dataSimp.revenue.dez : 0.00,
      resultado: this.dataSimp.balance.dez != null ? this.dataSimp.balance.dez : 0.00
    });
    dataSimpAUX.push({
      nome: 'Total',
      despesa: this.dataSimp.expense.total != null ? this.dataSimp.expense.total : 0.00,
      receita: this.dataSimp.revenue.total != null ? this.dataSimp.revenue.total : 0.00,
      resultado: this.dataSimp.balance.total != null ? this.dataSimp.balance.total : 0.00
    });

    dataSimpAUX.forEach((data) => {
      data.despesa = Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(data.despesa);
      data.receita = Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(data.receita);
      data.resultado = Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(data.resultado);
    });
    this.dataSimp = dataSimpAUX;
    this.loadingSimp = false;
  }

  filterLancamentos() {
    let data = []

    this.aux.forEach(element => {
      let value = this.recursaoFunc(element);
      if (value != null) {
        data.push(value);
        data.sort((a, b) => +a.customId - +b.customId);
      }
    });


    if (data.length > 0) this.data = data;

    this.data.push(this.recursaoFunc({
      description: "SALDO",
      id: 69,
      customId: "69",
      months: this.saldo
    }));

    let saldoAcumulado = [];
    if (this.saldo && Array.isArray(this.saldo)) {
      for (let i = 0; i < this.saldo.length - 1; i++) {
        saldoAcumulado[i] = (saldoAcumulado[i - 1] || 0) + (this.saldo[i] || 0);
      }
    }

    this.data.push(this.recursaoFunc({
      description: "SALDO ACUMULADO",
      id: 69,
      customId: "69",
      months: saldoAcumulado
    }));

    this.consultando = false;
  }

  recursaoFunc(x: any) {
    if (x != null && x.months != null) {
      let data = {
        "conta": x.description,
        "customId": x.customId,
        "janeiro": "",
        "fevereiro": "",
        "marco": "",
        "abril": "",
        "maio": "",
        "junho": "",
        "julho": "",
        "agosto": "",
        "setembro": "",
        "outubro": "",
        "novembro": "",
        "dezembro": "",
        "total": "",
        "id": x.id,
        "children": []
      };
      if (x.months != null) {
        x.months.forEach((element2, i) => {
          this.giveMonth(i, data, element2);
        });
      }
      if (x.children) {
        x.children.forEach((element, i) => {
          if (element != null) {
            let value = this.recursaoFunc(element);
            if (value != null) {
              data.children.push(value);
              data.children.sort((a, b) => +a.customId - +b.customId);
            }

          }
        })
      }
      return data;
    } else {
      return null;
    }
  }

  giveMonth(i, data, valor) {
    if (valor == null) {
      valor = "0,00"
    } else {
      if (valor > 0) {
        valor = this.formatarValor2(valor, false)
      } else {
        valor = this.formatarValor2(valor, false)
      }
    }

    switch (i) {
      case 0:
        data.janeiro = valor
        break;
      case 1:
        data.fevereiro = valor
        break;
      case 2:
        data.marco = valor
        break;
      case 3:
        data.abril = valor
        break;
      case 4:
        data.maio = valor
        break;
      case 5:
        data.junho = valor
        break;
      case 6:
        data.julho = valor
        break;
      case 7:
        data.agosto = valor
        break;
      case 8:
        data.setembro = valor
        break;
      case 9:
        data.outubro = valor
        break;
      case 10:
        data.novembro = valor
        break;
      case 11:
        data.dezembro = valor
        break;
      case 12:
        data.total = valor
        break;
    }
  }


  formatarValor(valorTotal: string) {
    if (valorTotal != undefined) {
      if (+valorTotal < 0) {
        return '-' + this.brMasker.writeValueMoney(valorTotal.toString(), {
          decimal: 2,
          decimalCaracter: ",",
          len: 0,
          mask: "",
          numberAndTousand: false,
          percent: false,
          person: false,
          phone: false,
          thousand: ".",
          type: undefined,
          userCaracters: false,
          money: true
        });
      } else {
        return this.brMasker.writeValueMoney(valorTotal.toString(), {
          decimal: 2,
          decimalCaracter: ",",
          len: 0,
          mask: "",
          numberAndTousand: false,
          percent: false,
          person: false,
          phone: false,
          thousand: ".",
          type: undefined,
          userCaracters: false,
          money: true
        });
      }
    }
  }

  formatarValor2(valorTotal: number, menos: Boolean) {
    if (valorTotal == 0) {
      return "0,00"
    }
    if (valorTotal != undefined) {
      if (menos == true && valorTotal) {
        return '-' + ((valorTotal.toFixed(2)).toString()).replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      } else {
        return ((valorTotal.toFixed(2)).toString()).replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
    }
  }

}
