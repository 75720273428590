import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../../environments/environment.prod";
import { DfeDTOResponse } from "../../../model/dto/dfe/DfeDTOResponse";

@Injectable()
export class OFXProvider {

  apiBase: string = "/bookkeeping/v1/ofx";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAllOFX(before, after, bankAccount) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}/download-file/${localStorage.getItem('idEmpresa')}/${bankAccount}/${before}/${after}`, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);          
        },
          (error) => {
            reject(error);
          });

    });
  }

  getFilesOFX(before, after, bankAccount) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}/ofx-info/${localStorage.getItem('idEmpresa')}/${bankAccount}/${before}/${after}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {            
            reject(error);
          });

    });
  }

  postOFX(ofx, idConta) {
    const formData = new FormData();
    formData.append('file', ofx);

    return new Promise((resolve, reject) => {

      this.http.post(`${environment.apiLogin}${this.apiBase}/upload-file/${localStorage.getItem('idEmpresa')}/${idConta}`, formData, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            if (error.status === 400) {
              resolve(error.error);
            } else if (error.status === 200) {
              resolve(error.error.text)
            }
            reject(error);
          });

    });
  }

  conciliateTransactions(postingIds, transactionId) {
    let request = {
      "companyId": localStorage.getItem('idEmpresa'),
      "ofxTransactionId": transactionId,
      "postingIds": postingIds,
    }

    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}${this.apiBase}/conciliate`, request, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  undoConciliateTransactions(postingIds, transactionId) {
    let request = {
      "companyId": localStorage.getItem('idEmpresa'),
      "ofxTransactionId": transactionId,
      "postingIds": postingIds,
    }

    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}${this.apiBase}/undo-conciliation`, request, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  deleteFile(id) {
    return new Promise((resolve, reject) => {

      this.http.put(`${environment.apiLogin}${this.apiBase}/delete-file/${localStorage.getItem('idEmpresa')}/${id}`, '', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getAllSugestions(arr) {

    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiBase}/suggest/${localStorage.getItem('idEmpresa')}`, arr, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
}