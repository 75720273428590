import {Injectable} from "@angular/core";
import {GridComponent} from "@syncfusion/ej2-angular-grids";

@Injectable()
export class ReportsHelper {
  constructor() {
  }

  isDate(date) {
    // @ts-ignore
    return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
  }


  getFont(dataSourceMain: any[], lastOrder: any) {
    const getNestedValue = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);

    let result = dataSourceMain;
    if (lastOrder.field) {
      result = result.sort((a, b) => {
        const aValue = getNestedValue(a, lastOrder.field);
        const bValue = getNestedValue(b, lastOrder.field);
        let comparison;

        if (lastOrder.field.toLowerCase().includes('date') || lastOrder.field.toLowerCase().includes('data') || lastOrder.field.toLowerCase().includes('created')) {
          if (this.isDate(aValue) && this.isDate(bValue)) {
            comparison = new Date(aValue).getTime() - new Date(bValue).getTime();
          } else {
            comparison = aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
          }
        } else
          if (typeof aValue === 'number' && typeof bValue === 'number') {
          comparison = aValue - bValue;
        } else {
          comparison = aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
        }

        return lastOrder.direction === 'Ascending' ? comparison : -comparison;
      });
    }

    if (lastOrder.searching) {
      const search = lastOrder.searching.toLowerCase();
      result = result.filter((obj) =>
        Object.values(obj).some(value => value != null && value.toString().toLowerCase().includes(search))
      );
    }
    return result;
  }

  getHeaderFromGrid(grid: GridComponent) {
    return grid.getVisibleColumns().map((column: any) => ({
      header: column.headerText,
      dataKey: column.field,
    }));
  }
}
