import { Component,  OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImportarOFX } from '../importarOFX';
import { Snackbar } from 'src/util/snackbar';
import { ErrorUtil } from 'src/util/error';
import { BankAccountProvider } from 'src/providers/company/bank_account/bank_account';
import { ContaBancariaDTO } from 'src/model/dto/ContaBancariaDTO';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-import-ofx',
  templateUrl: './modal-import-ofx.component.html',
  styleUrls: ['./modal-import-ofx.component.scss']
})
export class ModalImportOfxComponent implements OnInit {

  // @Input() public xmlSended: any[];

  public bankAccount = new FormControl();
  public toppings = new FormControl();
  public form = new FormGroup({
    ofx: new FormControl(null, [Validators.required]),
    conta: this.bankAccount,
  });
  public contas: ContaBancariaDTO[];
  public carregando: boolean;
  public eventInput: any;
  public conta: ContaBancariaDTO;

  constructor(public modalService: NgbModal,
              private contasProvider: BankAccountProvider,
              public errorUtil: ErrorUtil,
              public snackBar: Snackbar,
              public importarOFX: ImportarOFX,
              public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.initializeData();
  }

  initializeData() {
    this.contasProvider.getAllBanksAccounts().then((accounts: ContaBancariaDTO[]) => {
      this.contas = accounts;
      //console.log(this.form.controls);

      this.form.controls.conta.setValue(this.contas.find((conta) => conta.id === this.conta.id));
    }).catch((result: any) => {
      this.snackBar.openLong(
        this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'),
        'erro'
      );
    });
  }

  requiredFileType(type: string) {
    // tslint:disable-next-line:only-arrow-functions
    return function (control: FormControl) {
      const file = control.value;
      if (file) {
        const extension = file.split('.')[1].toLowerCase();
        if (type.toLowerCase() !== extension.toLowerCase()) {
          return {
            requiredFileType: true
          };
        }
        return null;
      }
      return null;
    };
  }


  saveEventInput(event) {
    this.eventInput = event;
    const names = [];

    for (const file of event.target.files) {
      names.push(file.name);
    }

    document.getElementById('name-file').innerHTML = names.join(', ');
  }

  importarFile() {
    this.carregando = true;

    if (this.form.value.ofx == null){
      this.carregando = false;
      this.snackBar.openLong('Selecione um arquivo OFX!', 'erro');
      return;
    }
    if(this.form.value.conta == null) {
      this.carregando = false;
      this.snackBar.openLong('Selecione uma conta bancária!', 'erro');
      return;
    }

    if (this.form.valid) {
      const idConta = this.form.value.conta.id;
      const reader = new FileReader();
      const file = this.eventInput.target.files[0];

        reader.onload = () => {
          this.importarOFX.import(file, idConta).then((result: any) => {
            const tmp = result.split(',');
            const txt = [];
            tmp.forEach(element => {
              txt.push(element.split(" = ")[1]);
            });
            if (txt[0] == 0 || txt[1] == '0') {
              Swal.fire({
                title: 'Arquivo já inserido!',
                html: 'Nenhuma transação nova foi encontrada!',
                icon: 'warning'
              }).then(() => {
                this.carregando = false;
                this.form.patchValue({ofx: null});
                document.getElementById('name-file').innerHTML = '';
              });
            } else {
              Swal.fire({
                title: 'Sucesso ao enviar o arquivo OFX!',
                html: `Transações salvas: ${txt[0]}
                      <br>Transações não salvas: ${(txt[1] - txt[0])}
                      <br>Total de transações: ${txt[1]}`,
                icon: 'success'
              }).then(() => {
                this.carregando = false;
                this.form.patchValue({ofx: null, conta: null});
                document.getElementById('name-file').innerHTML = '';
                this.activeModal.close();
              });
            }
          }).catch(result => {
            let description = result.error.error_description;
            description = description.substring(description.indexOf('"') + 1, description.lastIndexOf('"'));
            Swal.fire({
              title: 'Erro!',
              html: description,
              icon: 'error'
            }).then(() => {
              this.carregando = false;
              this.form.patchValue({ofx: null});
              document.getElementById('name-file').innerHTML = '';
            });
          });
        };
      reader.readAsText(file);
    }
  }
}
