import { NgModule } from '@angular/core';

import {LayoutConsultorComponent} from './layout.component';
import {OffsidebarConsultorComponent} from './offsidebar/offsidebar.component';
import {NavsearchConsultorComponent} from './header/navsearch/navsearch.component';
import {SidebarConsultorComponent} from './sidebar/sidebar.component';
import {UserblockConsultorComponent} from './sidebar/userblock/userblock.component';
import {FooterConsultorComponent} from './footer/footer.component';
import {HeaderConsultorComponent} from './header/header.component';

import { UserblockService } from './sidebar/userblock/userblock.service';
import { SharedModule } from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DropDownListModule} from '@syncfusion/ej2-angular-dropdowns';
import {DatePickerAllModule} from '@syncfusion/ej2-angular-calendars';
import {PagesModule} from '../routes/pages/pages.module';
import { SupportModalComponent } from '../layout/header/support-modal/support-modal.component';

@NgModule({
    imports: [
        SharedModule,
        TranslateModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        // RouterModule.forRoot(),
        MatIconModule,
        MatDatepickerModule,
        DropDownListModule,
        DatePickerAllModule,
        PagesModule
    ],
    providers: [
        UserblockService
    ],
    declarations: [
        LayoutConsultorComponent,
        SidebarConsultorComponent,
        UserblockConsultorComponent,
        HeaderConsultorComponent,
        NavsearchConsultorComponent,
        OffsidebarConsultorComponent,
        FooterConsultorComponent
    ],
    exports: [
        LayoutConsultorComponent,
        SidebarConsultorComponent,
        UserblockConsultorComponent,
        HeaderConsultorComponent,
        NavsearchConsultorComponent,
        OffsidebarConsultorComponent,
        FooterConsultorComponent
    ]
})
export class LayoutConsultorModule { }
