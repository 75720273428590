import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Snackbar} from "../../../../../util/snackbar";
import {OAuthService} from "angular-oauth2-oidc";
import {ErrorUtil} from "../../../../../util/error";
import {UserProvider} from "../../../../../providers/user/user";
import {Pessoa} from "../../../../../model/Pessoa";
import {CustomValidators} from "ng2-validation";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit {

  @Input() public clienteExistente;

  public colaboradorForm: FormGroup;
  public enviado: boolean = false;
  public editar: boolean = true;
  public passwordRepeat: string;
  public enviando: boolean = false;
  public adminColaborator: boolean = false;
  public valForm: FormGroup;
  public passwordForm: FormGroup;

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public snackBar: Snackbar,
              public oauthService: OAuthService,
              public errorUtil: ErrorUtil,
              public userProvider: UserProvider) {
  }

  ngOnInit() {
    this.configuracaoForms();
    this.configuracaoFormPassword();
    this.setDataForm();
  }

  configuracaoForms() {
    this.colaboradorForm = this.fb.group({
      email: [{disabled: true}],
      oldPassword: []
    });
  }

  configuracaoFormPassword() {
    let password = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\w\\W]{8,}$')]));
    let certainPassword = new FormControl('', [Validators.required, CustomValidators.equalTo(password)]);

    this.passwordForm = this.fb.group({
      'password': password,
      'confirmPassword': certainPassword
    });

    this.valForm = this.fb.group({
      'accountagreed': [null, Validators.required],
      'passwordGroup': this.passwordForm
    });
  }

  setDataForm() {
    if (this.clienteExistente != undefined) {
      this.colaboradorForm.patchValue(this.clienteExistente);
      this.colaboradorForm.controls.email.disable();
    }
  }

  changePassword() {
    this.enviando = true;
    this.enviado = true;
    if (this.colaboradorForm.valid && this.passwordForm.valid) {
      let passwordChangeObject = {
        olderPassword: this.colaboradorForm.value.oldPassword,
        newerPassword: this.passwordForm.value.password
      }
      this.userProvider.putUserPassword(passwordChangeObject).then((response) => {
        this.snackBar.openLong("Sucesso! Senha modificada com sucesso!", 'success');
        this.activeModal.close();
        this.enviando = false;
      }).catch((result: HttpErrorResponse) => {
        console.error(result);
        this.enviando = false;
        this.snackBar.openLong('Não foi possível editar! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'password'), 'erro');
      });
    } else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

}


