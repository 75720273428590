import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DomSanitizer} from "@angular/platform-browser";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-xml-viewer',
  templateUrl: './xml-viewer.component.html',
  styleUrls: ['./xml-viewer.component.scss']
})
export class XmlViewerComponent implements OnInit {

  @Input() public xml: string;
  @Input() public documento: any;

  constructor(public modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  donwloadXML(){
    var content = this.documento.xml;
    var filename = this.documento.modelo + this.documento.chave + ".xml";
    var blob = new Blob([content], {
      type: "text/plain;charset=utf-8"
    });
    saveAs(blob, filename);
  }
}
