import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-produtos',
  templateUrl: './modal-produtos.component.html',
  styleUrls: ['./modal-produtos.component.scss']
})
export class ModalProdutosComponent implements OnInit {

  public data: any;
  public produtos: Produto[] = [];
  public nota: any;
  public carregando: boolean = false;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    // console.log(this.data);
    if (this.data) this.formatarProdutos();
    this.carregando = false;
  }

  formataValor(value: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

  formatarProdutos() {
    this.carregando = true;
    this.data.map((map: any) => {
      let atual = new Produto();
      atual.id = map.id;
      atual.descricao = map.description;
      atual.ncm = map.ncm;
      atual.cfop = map.cfop;
      atual.unidade = map.unit;
      atual.quantidade = map.amount;
      atual.valorUnitario = map.value;
      atual.valorTotal = map.productValue;
      this.produtos.push(atual)
    });
    console.log(this.produtos);
  }
}

class Produto {
  public id: number;
  public descricao: string;
  public ncm: string;
  public cfop: string;
  public unidade: string;
  public quantidade: number;
  public valorUnitario: number;
  public valorTotal: number;
}
