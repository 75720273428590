import {Injectable} from "@angular/core";
import {OAuthService} from "angular-oauth2-oidc";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable()
export class CompanyNotesProvider {

  api: string = environment.apiLogin + "/user/v1/company-notes";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.api}/${localStorage.getItem('idEmpresa')}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

  getAllForToday() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.api}/${localStorage.getItem('idEmpresa')}/notifications`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

  post(note: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.api}/${localStorage.getItem('idEmpresa')}`, note, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

  put(note: any) {
    return new Promise((resolve, reject) => {
      this.http.put(`${this.api}/${localStorage.getItem('idEmpresa')}`, note, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

  delete(noteId: number) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${this.api}/${localStorage.getItem('idEmpresa')}/${noteId}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }
}
