import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Snackbar} from "../../util/snackbar";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {PdfViewerComponent} from "../../app/routes/documentosFiscais/pdf-viewer/pdf-viewer.component";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable()
export class ConsultaPdfXml {

  constructor(public http: HttpClient, public snackbar: Snackbar, public modalService: NgbModal, public oauthService: OAuthService) {
  }

  public apiNfe= "/fiscal/v1/generator/visualize/"
  public generalUrl = 'viewpdfapp.php';
  public nfseUrl = 'viewpdfapp2.php';

  getPdf(xml) {
        return new Promise((resolve, reject) => {
      let url = 'https://apinfe.farmcont.com.br/';
      xml.modelo == 'nfse' ? url += this.nfseUrl : url += this.generalUrl;
      this.http.post(url, xml)
        .subscribe((result: any) => {
          let url = "https://apinfe.farmcont.com.br/pdf/" + result.pdf;
            resolve(url);
            window.open(url);
        },
          (error) => {
            reject(error);
            this.snackbar.open('Não foi possível conectar-se com a internet!', 'error');
          });
    });
  }

  getPdfModal(xml) {
    return new Promise((resolve, reject) => {
      let url = 'https://apinfe.farmcont.com.br/';
      xml.modelo == 'nfse' ? url += this.nfseUrl : url += this.generalUrl;

      this.http.post(url, xml)
        .subscribe((result: any) => {
            let url = "https://apinfe.farmcont.com.br/pdf/" + result.pdf;
            resolve(url);
      const modalOptions: NgbModalOptions = {};
      modalOptions.windowClass = 'custom-css-modal'
      const modalRef = this.modalService.open(PdfViewerComponent, modalOptions);
      modalRef.componentInstance.pdfUrl = url;
          },
          (error) => {
            reject(error);
            this.snackbar.open('Não foi possível conectar-se com a internet!', 'error');
          });
    });
  }

  getXml(xml) {
    return new Promise((resolve, reject) => {
      this.http.post('https://apinfe.farmcont.com.br/viewxmlapp.php', xml)
        .subscribe((result: any) => {
            let url = "https://apinfe.farmcont.com.br/pdf/" + result.xml;
            resolve(url);
            window.open(url);
          },
          (error) => {
            reject(error);
            this.snackbar.open('Não foi possível conectar-se com a internet!', 'error');
      });
        });

  }
}

