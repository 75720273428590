import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ContaBancaria} from "../../../../model/ContaBancaria";
import {EstadosMunicipiosUtil} from "../../../../util/estados-municipios";
import {Banks} from "../../../../util/banks";
import {BankAccountProvider} from "../../../../providers/company/bank_account/bank_account";
import {Snackbar} from "../../../../util/snackbar";
import {ContaBancariaDTO} from "../../../../model/dto/ContaBancariaDTO";
import {Country} from "../../../../model/basicos/Country";
import {Observable, ReplaySubject, Subject, Subscription} from "rxjs";
import {map, startWith, take, takeUntil} from "rxjs/operators";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Bank} from "../../../../model/basicos/Bank";
import {ErrorUtil} from "../../../../util/error";
import {HttpErrorResponse} from "@angular/common/http";
import {TypeValidator} from "../../../../util/customValidators/typeValidator";
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-cadastro-conta-bancaria',
  templateUrl: './cadastro-conta-bancaria.component.html'
})

export class CadastroContaBancariaComponent implements OnInit {
  @ViewChild('trigger', {static: false}) trigger: MatAutocompleteTrigger;
  @ViewChild('triggerBrasil', {static: false}) triggerBrasil: MatAutocompleteTrigger;
  @ViewChild('triggerCountry', {static: false}) triggerCountry: MatAutocompleteTrigger;
  @Input() public contaExistente;
  contaBancaria: ContaBancaria = new ContaBancaria();
  contaBancariaAux: ContaBancariaDTO = new ContaBancariaDTO();
  editar: boolean = false;
  contaBancariaForm: FormGroup;
  enviado: boolean = false;
  enviando: boolean = false;

  banks: Bank[] = this.banksUtil.banks;
  public bankFilterCtrl: FormControl = new FormControl();
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);
  protected _onDestroy = new Subject<void>();

  countrys: Country[] = this.estadosMunicipiosUtil.paises;
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountrys: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  protected _onDestroyCountry = new Subject<void>();

  @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;
  @ViewChild('countrySelect', {static: true}) countrySelect: MatSelect;
  @Input() enableClearOnEscapePressed = true;

  constructor(public activeModal: NgbActiveModal,
              public banksUtil: Banks,
              public snackbar: Snackbar,
              public fb: FormBuilder,
              public erroUtil: ErrorUtil,
              private bankAccountProvider: BankAccountProvider,
              public estadosMunicipiosUtil: EstadosMunicipiosUtil) {

  }

  ngOnInit() {
    this.configBanks();
    this.configCountry();
    this.configFormAccount();
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._onDestroyCountry.next();
    this._onDestroyCountry.complete();
  }

  protected setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: Bank, b: Bank) => a && b && a.id === b.id;
      });

    this.filteredCountrys
      .pipe(take(1), takeUntil(this._onDestroyCountry))
      .subscribe(() => {
        this.countrySelect.compareWith = (a: Bank, b: Bank) => a && b && a.id === b.id;
      });
  }

  filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    // this.categorias.filter(s => s.customId.includes(name) || s.description.toLowerCase().includes(valor))
    this.filteredBanks.next(
      this.banks.filter(bank => (bank.name.toLowerCase().indexOf(search) > -1) || (bank.code.toLowerCase().indexOf(search) > -1))
    );
  }

  filterCountrys() {
    if (!this.countrys) {
      return;
    }
    // get the search keyword
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountrys.next(this.countrys.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countrys
    this.filteredCountrys.next(
      this.countrys.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  configBanks() {
    this.banks = JSON.parse(localStorage.getItem("bancos"));
    this.filteredBanks.next(this.banks.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
  }

  configCountry() {
    this.countrys = JSON.parse(localStorage.getItem("paises"));
    this.filteredCountrys.next(this.countrys.slice());
    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyCountry))
      .subscribe(() => {
        this.filterCountrys();
      });
  }

  configFormAccount() {

    this.contaBancariaForm = this.fb.group({
      description: ['', Validators.required],
      code: ['', Validators.required],
      country: [''],
      bank: [''],
      agencyWithoutDV: [''],
      accountNumberWithDV: ['']
    });

    if (this.contaExistente != undefined) {
      Object.assign(this.contaBancariaAux, this.contaExistente);
      this.contaBancariaAux = Object.create(this.contaExistente);
      this.parseDTO();
      this.editar = true;
    } else {
      this.contaBancariaForm.controls.country.setValue({id: 32, name: "Brasil", abbreviation: "BR"});
      this.getCountryId();
    }
  }

  parseDTO() {
    this.contaBancaria.id = this.contaBancariaAux.id;
    this.contaBancariaForm.controls.description.setValue(this.contaBancariaAux.description);
    this.contaBancariaForm.controls.code.setValue(this.contaBancariaAux.code);
    this.contaBancariaForm.controls.agencyWithoutDV.setValue(this.contaBancariaAux.agencyWithoutDV);
    this.contaBancariaForm.controls.accountNumberWithDV.setValue(this.contaBancariaAux.accountNumberWithDV);
    if (this.contaBancariaAux.bankDTORespComp != null) {
      this.contaBancariaForm.controls.bank.setValue(this.contaBancariaAux.bankDTORespComp);
      this.contaBancaria.bankId = this.contaBancariaAux.countryDTORespComp.id;
    }

    if (this.contaBancariaAux.countryDTORespComp != null) {
      this.contaBancariaForm.controls.country.setValue(this.contaBancariaAux.countryDTORespComp);
      this.contaBancaria.countryId = this.contaBancariaAux.countryDTORespComp.id;
    }
  }

  getCountryId() {
    this.contaBancariaForm.controls.bank.reset();
    if (this.contaBancariaForm.controls.country.value.id == 32) {
      this.contaBancariaForm.controls.bank.setErrors(null);
      // this.contaBancariaForm.controls.bank.setValidators(Validators.compose([TypeValidator]));
    } else {
      this.contaBancariaForm.controls.bank.setErrors(null);
      this.contaBancariaForm.controls.bank.setValue(undefined);
      this.contaBancariaForm.controls.bank.reset();
      // this.contaBancariaForm.controls.bank.setValidators(TypeValidator);
    }
    this.contaBancariaForm.controls.bank.setValue(undefined);
  }

  setEditedObject(conta: ContaBancariaDTO) {
    this.contaExistente.id = conta.id;
    this.contaExistente.accountNumberWithDV = conta.accountNumberWithDV;
    this.contaExistente.agencyWithoutDV = conta.agencyWithoutDV;
    this.contaExistente.countryDTORespComp = conta.countryDTORespComp;
    this.contaExistente.description = conta.description;
    this.contaExistente.code = conta.code;
    this.contaExistente.bankDTORespComp = conta.bankDTORespComp;
  }

  setContaForm() {
    if (this.contaBancariaForm.controls.bank.value != undefined) {
      this.contaBancaria.bankId = this.contaBancariaForm.controls.bank.value.id;
    }
    this.contaBancaria.countryId = this.contaBancariaForm.controls.country.value.id;
    this.contaBancaria.description = this.contaBancariaForm.controls.description.value;
    this.contaBancaria.code = this.contaBancariaForm.controls.code.value;
    this.contaBancaria.companyId = +localStorage.getItem('idEmpresa');
    this.contaBancaria.agencyWithoutDV = this.contaBancariaForm.controls.agencyWithoutDV.value;
    this.contaBancaria.accountNumberWithDV = this.contaBancariaForm.controls.accountNumberWithDV.value;
  }

  novaContaBancaria() {
    this.enviando = true;
    if (this.contaBancariaForm.valid) {
      this.setContaForm();
      this.bankAccountProvider.postBankAccount(this.contaBancaria).then((conta: ContaBancariaDTO) => {
        this.snackbar.openLong("Sucesso! " + conta.description + " inserida com sucesso!", 'success');
        this.activeModal.close(conta);
      }).catch((result: HttpErrorResponse) => {
        console.error(result);
        this.enviando = false;
        this.snackbar.openLong('Não foi possível inserir! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'bankAccount'), 'erro');
      });
    } else {
      this.enviando = false;
      this.enviado = true;
      this.snackbar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

  editarContaBancaria() {
    this.enviando = true;
    if (this.contaBancariaForm.valid) {
      this.setContaForm();
      this.bankAccountProvider.putBankAccount(this.contaBancaria).then((conta: ContaBancariaDTO) => {
        this.snackbar.openLong("Sucesso! " + conta.description + " editado com sucesso!", 'success');
        this.setEditedObject(conta);
        this.activeModal.close(conta);
      }).catch((error: HttpErrorResponse) => {
        console.error(error);
        this.enviando = false;
        this.snackbar.openLong(this.erroUtil.checkErrorStatus(error, error.status, error.error, 'bankAccount'), 'erro');
      });
    } else {
      this.enviado = true;
      this.enviando = false;
      this.snackbar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

}
