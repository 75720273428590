import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment.prod";

@Injectable()
export class BillingProvider {
  constructor(public http: HttpClient, public oauthService: OAuthService) {}

  oldBillings: string = "/user/v1/asaas-billing/old-billing?offset=";
  newBillings: string = "/user/v1/asaas-billing/new-billing?offset=";

  getoldBillings(offset) {
    offset.toString();
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiLogin + this.oldBillings + offset, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getnewBillings(offset) {
    offset.toString();
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiLogin + this.newBillings + offset, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
