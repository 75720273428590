import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {Estate} from "../../model/basicos/Estate";

@Injectable()
export class StatesProvider {

  apiState: string = "/general-data/v1/state";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAllStates() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiState,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Estate[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

}
