import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-events-modal',
  templateUrl: './events-modal.component.html',
  styleUrls: ['./events-modal.component.scss']
})

export class EventsModalComponent implements OnInit {

  @Input() dataSource: any;

  public dataSourceMain: DataSource[] = [];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {

  }

}

class DataSource {
  code: string;
  description: string;
  date: string;
  sequenceNumber: number;
  justification: string;
}
