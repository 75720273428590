import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ConsultaDfe} from "../../model/ConsultaDfe";
import {Certificado} from "../../model/Certificado";
import {environment} from "../../environments/environment.prod";
import {PessoaFiscalDTO} from "../../model/dto/PessoaFiscalDTO";
import {OAuthService} from "angular-oauth2-oidc";

@Injectable()
export class CertificadoProvider {

  apiCertificado: string = "/certifier/v1/certificate";

  constructor(public http: HttpClient, private oauthService: OAuthService) {
  }

  getAllCertificadoById(certificados: Certificado[]) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiCertificado,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Certificado[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getCertificadoById(idEmpresa: number, cpfCNPJ: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiCertificado + '/' + idEmpresa + '/' + cpfCNPJ,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Certificado[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postCertificado(certificado: Certificado) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiCertificado, certificado,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Certificado[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putCertificado(certificado: Certificado) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiCertificado, certificado,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Certificado[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  delCertificado(idEmpresa: number, cpfCNPJ: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiCertificado + '/' + idEmpresa + '/' + cpfCNPJ,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Certificado[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }


  // postCertificado(empresa: string, cpf: string, estado: string, certificado: Certificado){
  //   return new Promise((resolve, reject) => {
  //
  //     this.http.post("https://dfe.farmcont.com.br/certificado" + '/' + empresa + '/' + cpf + '/' + estado, certificado, {headers:{Authorization:"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXphbmFAZmFybWNvbnQuY29tLmJyIiwiaWF0IjoxNTgzMTcxMzE0LCJleHAiOjE1ODQyMTA1MTJ9.mdIyYKz4pSMs3stvLaVhP4hFIdlGM2O9LgG707gHyElQ-OaFzBK8HLx28znVEe7WqGuOxv3dGfVlxt-zg31U2Q"
  //
  //       }})
  //       .subscribe((result: Certificado) => {
  //           resolve(result);
  //         },
  //         (error) => {
  //           reject(error.message);
  //         });
  //
  //   });
  // }

  // putCertificado(empresa: string, cpf: string){
  //   return new Promise((resolve, reject) => {
  //
  //     this.http.get("https://dfe.farmcont.com.br/certificado" + '/' + empresa + '/' + cpf, {headers:{Authorization:"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXphbmFAZmFybWNvbnQuY29tLmJyIiwiaWF0IjoxNTgzMTcxMzE0LCJleHAiOjE1ODQyMTA1MTJ9.mdIyYKz4pSMs3stvLaVhP4hFIdlGM2O9LgG707gHyElQ-OaFzBK8HLx28znVEe7WqGuOxv3dGfVlxt-zg31U2Q"
  //
  //       }})
  //       .subscribe((result: ConsultaDfe[]) => {
  //           resolve(result);
  //         },
  //         (error) => {
  //           reject(error.message);
  //         });
  //
  //   });
  // }

}
