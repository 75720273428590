import {Component, OnInit, ViewChild} from '@angular/core';
import {MatInput} from '@angular/material';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {AdicionarArquivosComponent} from './adicionar-arquivos/adicionar-arquivos.component';
import {ReceiptProvider} from 'src/providers/receipt/receipt';
import Swal from 'sweetalert2';
import {DateUtil} from "../../../../util/dateUtil";
import {ConsultaDfe} from "../../../../model/ConsultaDfe";
import {Snackbar} from "../../../../util/snackbar";
import {GridComponent} from "@syncfusion/ej2-angular-grids";

@Component({
  selector: 'app-enviar-arquivos',
  templateUrl: './enviar-arquivos.component.html',
  styleUrls: ['./enviar-arquivos.component.scss']
})
export class EnviarArquivosComponent implements OnInit {

  @ViewChild('grid', {static: false}) grid: GridComponent;
  @ViewChild(MatInput, {static: false}) search: MatInput;

  public carregandoArquivos = false;
  public consultando = false;
  public files: any = '';

  public dateRange = new ConsultDate();
  public year: number;
  public years: any;
  public keyword = '';
  public months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
  public activeMonth = '';
  public monthly = true;
  public activeLink = 'Mensal';
  public links = ['Mensal', 'Personalizado'];

  public dataSourceMain: DataSource[] = [];

  public selected: any[] = [];
  public docsVisible = true;
  public selectedAll: boolean = false;
  public fileSelected = [];


  constructor(public adicionarArquivosModalService: NgbModal,
              public snackbar: Snackbar,
              public dateUtil: DateUtil,
              private receiptProvider: ReceiptProvider) {
    setTimeout(() => {
      this.clearSearch();
    }, 600);
  }

  ngOnInit() {
    this.defaultConfiguration();
    this.getFilesByDate(true);
  }

  defaultConfiguration() {
    try {
      const workDate = localStorage.getItem('workDate');
      this.dateRange.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('year').format();
      this.dateRange.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('year').format();
      this.activeMonth = this.months[+moment.tz(workDate, 'America/Sao_Paulo').format('MM') - 1]; // set current month
      this.year = +moment(localStorage.getItem('workDate')).format('YYYY');  // set current year
      this.years = getYears(+moment().format('YYYY') - 20);  // generate range of years
    } catch (exception) {
      console.error('Erro ao realizar as configurações padrão', exception);
    }
  }

  getFilesByDate(first = false) {
    this.getFiles(this.getDateForRequest(first).startDate, this.getDateForRequest(first).endDate);
  }

  getFiles(startDate, endDate) {
    this.dataSourceMain = [];
    if (this.selected.length > 0) this.clearSelection();
    this.consultando = true;
    this.receiptProvider.getReceiptsByPeriod(startDate, endDate).then((result: DataSource[]) => {
      this.dataSourceMain = result;
      this.consultando = false;
    }).catch((exception) => {
      this.consultando = false;
      console.error('Erro ao carregar arquivos', exception);
    });
  }

  openFileModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
      const modalRef = this.adicionarArquivosModalService.open(AdicionarArquivosComponent, modalOptions);
      modalRef.result.then((result: any) => {
        if (result !== 'Cancelou') {
          this.getFilesByDate();
        }
      });
    } catch (error) {
      console.error('', error);
    }
  }

  searchTable() {
    if (this.keyword === '') {
      this.grid.searchSettings.key = '';
    }
    const wordSearch = this.keyword;
    let time = 400;
    if (this.dataSourceMain.length > 500) {
      time = 800;
    }
    setTimeout(() => {
      if (wordSearch === this.keyword) {
        if (this.keyword) {
          const searchText: string = (document.getElementsByClassName('searchtext')[0] as any).value;
          this.grid.search(searchText);
        }
      } else {
        this.grid.searchSettings.key = '';
      }
    }, time);
  }

  clearSearch() {
    this.grid.searchSettings.key = '';
    this.keyword = '';
  }

  getFile(id: number) {
    const fileName = this.dataSourceMain.filter(x => x.id === id)[0].file;
    this.receiptProvider.downloadFile(fileName).then((value: any) => {
      const blob = new Blob([value], {type: 'application/octet-stream'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    }).catch((ex) => {
      console.error('Erro ao baixar arquivo', ex);
      this.snackbar.openLong('Erro ao baixar arquivo', 'erro');
    });
  }

  deleteFile(document) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir o arquivo?',
      text: 'Você não poderá reverter essa ação!',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.receiptProvider.deleteFile(document.id).then((value: any) => {
          this.getFilesByDate()
        }).catch((error) => {
          this.snackbar.open('Erro ao remover arquivo: ' + error.error.error_description, 'erro');
        });
      }
    })
  }

  getActiveMonth(mes) {
    switch (mes) {
      case 'JAN':
        mes = '01';
        break;
      case 'FEV':
        mes = '02';
        break;
      case 'MAR':
        mes = '03';
        break;
      case 'ABR':
        mes = '04';
        break;
      case 'MAI':
        mes = '05';
        break;
      case 'JUN':
        mes = '06';
        break;
      case 'JUL':
        mes = '07';
        break;
      case 'AGO':
        mes = '08';
        break;
      case 'SET':
        mes = '09';
        break;
      case 'OUT':
        mes = '10';
        break;
      case 'NOV':
        mes = '11';
        break;
      case 'DEZ':
        mes = '12';
        break;
      default:
        mes = '01';
    }
    return mes;
  }

  tabs(link: string) {
    if (link === 'Mensal') {
      this.changeToMonthlyMode(true);
    } else if (link === 'Personalizado') {
      this.changeToMonthlyMode(false);
    }
  }

  changeYear(type: string) {
    if (type === '+') this.year = +this.year + 1;
    else this.year = +this.year - 1;

    let existe = this.years.includes(this.year) ? 1 : 0;
    if (existe === 0) {
      this.years.push(this.year);
      // tslint:disable-next-line:only-arrow-functions
      this.years.sort(function (b, a) {
        return a < b ? -1 : a > b ? 1 : 0;
      });
    }
    this.getFilesByDate();
  }

  changeToMonthlyMode(b: any, a?: any) {
    this.monthly = b;
    this.getFilesByDate();
  }

  changePeriod(data) {
    if (data.value != null && this.dateRange.startDate != null && this.dateRange.endDate != null) {
      this.getFilesByDate();
    }
  }

  getDateForRequest(firstRequest: boolean): ConsultDate {
    // tslint:disable-next-line: no-use-before-declare
    const consultDate: ConsultDate = new ConsultDate();
    if (this.monthly) {
      if (firstRequest) {
        // tslint:disable-next-line: prefer-const
        let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
        consultDate.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
        consultDate.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
      } else {
        const month = this.getActiveMonth(this.activeMonth);
        const date = this.year + '-' + month + '-' + '01';
        consultDate.startDate = moment.tz(date, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
        consultDate.endDate = moment.tz(date, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
      }
    } else {
      consultDate.startDate = moment(this.dateRange.startDate).locale('pt-BR').format('YYYY-MM-DD');
      consultDate.endDate = moment(this.dateRange.endDate).locale('pt-BR').format('YYYY-MM-DD');
    }
    return consultDate;
  }

  donwloadLote() {
    if (this.selected.length === 0) {
      this.snackbar.openLong('Nenhum documento selecionado! Selecione ao menos um!', 'erro');
    } else {
      this.snackbar.openLong('O arquivo em lote está sendo gerado...', 'atencao');
      this.getFileInLote(this.selected);
    }
  }

  getFileInLote(file) {
    file = file.map((element) => element.id);
    this.consultando = true;
    this.receiptProvider.downloadFileInBatch(file).then((value: any) => {
      const blob = new Blob([value], {type: 'application/zip'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'arquivos.zip';
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        this.consultando = false;
      }, 1000);
      this.snackbar.openLong('Arquivos gerado com sucesso!', 'success');
    }).catch(() => {
      this.consultando = false;
      this.snackbar.openLong('Não foi possível gerar o arquivo em lote.', 'erro');
    });
  }

  clearSelection(){
    this.selected = [];
    this.selectedAll = false;
    this.grid.clearSelection();
  }

  removeSelection(doc){
    this.selectedAll = false;
    this.selected.splice(this.selected.findIndex(val => val.id === doc), 1);
    this.grid.clearSelection();
    let index = [];
    this.selected.forEach(element => {
      index.push(this.grid.getRowIndexByPrimaryKey(element.code));
    });
    this.grid.selectRows(index);
  }

  getRow(event) {
    let data = (this.grid.getRowObjectFromUID(event.row.getAttribute('data-uid')).data);
    this.toggleSelectRows(data);
  }

  togglecheckAll() {
    this.selectedAll = !this.selectedAll;
    if (this.selectedAll) {
      this.grid.selectRowsByRange(0, this.grid.getRows().length - 1);
      this.selected = this.grid.getRowsObject().map((row) => row.data);
    } else {
      this.grid.clearSelection();
      this.selected = [];
    }
  }

  toggleSelectRows(document) {
    this.selectedAll = false;
    if (this.checkIfFileAlreadySelected(document)) {
      this.selected.push(document);
    } else {
      this.selected.splice(this.selected.findIndex(val => val.id === document.id), 1);
    }
  }

  checkIfFileAlreadySelected(document): boolean {
    return this.selected.findIndex(val => val.id === document.id) === -1;
  }

  setFirstPageTableData(event) {
    this.fileSelected = [];
    if (event.rows !== undefined) {
      event.rows.forEach(row => {
        this.fileSelected.push(row.data);
      });
    }
  }
}

class ConsultDate {
  endDate: any;
  startDate: any;
}

class DataSource {
  id: number;
  code: string;
  date: string;
  description: string;
  file: string;
}

function getYears(startYear) {
  // tslint:disable-next-line:one-variable-per-declaration
  const currentYear = new Date().getFullYear() + 1, years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}
