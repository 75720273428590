import {Component, OnInit} from '@angular/core';
import {PessoaFiscalDTO} from "../../../model/dto/PessoaFiscalDTO";
import {MemberProvider} from "../../../providers/company/member/member";
import * as moment from "moment";
import {DateUtil} from "../../../util/dateUtil";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Snackbar} from "../../../util/snackbar";
import {PostingProvider} from "../../../providers/bookkeeping/posting/posting";
import {ReportsUtil} from "../../../util/Reports";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {ModalExportComponent} from "../relatorios/modal-export/modal-export.component";

@Component({
  selector: 'app-simulador-ir',
  templateUrl: './simulador-ir.component.html',
  styleUrls: ['./simulador-ir.component.scss']
})
export class SimuladorIrComponent implements OnInit {

  public panelExpanded: boolean = false;
  public carregando: boolean = false;
  public loading: boolean = false;
  public changed: boolean = false;
  public error: boolean = false;
  public error_message: string = '';
  public ano: number;

  public members: any[] = [];
  public years: any;

  removable: boolean = true;

  public taxSimulations: any = null;

  public savedData: any = {
    memberCpfCnpj: '02939358079',
    year: 2024,
    otherRevenue: '12.34',
    otherExpense: '56.78',
    incomingRevenue: '90.12',
    incomingExpense: '34.56',
    withLosses: true,
    sourceLosses: 'MANUAL',
    extraExpense: '12345678.99',
  }

  public simulatorForm: FormGroup = new FormGroup({
    memberCpfCnpj: new FormControl(null, Validators.required),
    year: new FormControl(null, Validators.required),
    otherRevenue: new FormControl('0.00'),
    otherExpense: new FormControl('0.00'),
    incomingRevenue: new FormControl('0.00'),
    incomingExpense: new FormControl('0.00'),
    withLosses: new FormControl(false, Validators.required),
    sourceLosses: new FormControl(null),
    extraExpense: new FormControl('0.00'),
    extraYearsExpense: new FormControl([]),
  });

  constructor(
    private memberProvider: MemberProvider,
    private dateUtil: DateUtil,
    public snackBar: Snackbar,
    public postingProvider: PostingProvider,
    public modalService: NgbModal,
    public reportsUtil: ReportsUtil,
  ) {
  }

  ngOnInit() {
    this.initializeData();
    // this.simulatorForm.patchValue(this.savedData);
  }

  updateChanged() {
    this.changed = true;
  }

  initializeData() {
    this.years = getYears(+moment().format('YYYY') - 20);
    this.ano = +moment(this.dateUtil.removeTimeZone(localStorage.getItem('workDate'))).format('YYYY');
    this.getMembers();
  }

  getMembers() {
    this.carregando = true;
    this.memberProvider.getAllMembers().then((members: PessoaFiscalDTO[]) => {
      this.members = [...members].sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.carregando = false;
    }).catch((error) => {
      this.error = true;
      this.snackBar.openLong('Erro ao buscar membros: ' + error.error.error_description, 'erro');
      this.carregando = false;
    });
  }

  checkSavedData(event) {
    return;
    // let cpfCnpj = event.value;
    // // aqui na verdade vai ser um get pra buscar os dados salvos, por enquanto só simula
    // if (this.savedData.memberCpfCnpj == cpfCnpj) {
    //   Swal.fire({
    //     title: 'Deseja continuar de onde parou?',
    //     text: 'Preenchimento anterior encontrado para o CPF/CNPJ informado',
    //     icon: 'info',
    //     showCancelButton: true,
    //     reverseButtons: true,
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Sim, continuar',
    //     cancelButtonText: 'Não, começar do zero',
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.savedData.memberCpfCnpj = (this.members.find(member => member.cpfCNPJ == cpfCnpj)).cpfCNPJ;
    //       this.simulatorForm.patchValue(this.savedData);
    //     }
    //   });
    // }
  }

  selectFarmcontYear(event) {
    let eventValue = event.value;
    if (this.simulatorForm.controls.extraYearsExpense.value.includes(eventValue)) {
      this.snackBar.open('Ano já selecionado!', 'atencao');
    } else {
      this.simulatorForm.controls.extraYearsExpense.value.push(eventValue);
      this.simulatorForm.controls.extraYearsExpense.value.sort();
    }
  }

  setTaxSubmittedObj(data) {
    let obj = {
      memberCpfCnpj: data.memberCpfCnpj,
      year: data.year,
      otherRevenue: data.otherRevenue,
      otherExpense: data.otherExpense,
      incomingRevenue: data.incomingRevenue,
      incomingExpense: data.incomingExpense,
    }
    if (data.withLosses) {
      if (data.sourceLosses == 'MANUAL') {
        obj['extraExpense'] = data.extraExpense;
      } else if (data.sourceLosses == 'FARMCONT') {
        obj['extraYearsExpense'] = data.extraYearsExpense;
      }
    }
    return obj;
  }

  submitForm() {
    if (this.simulatorForm.invalid) {
      this.snackBar.open('Preencha todos os campos obrigatórios!', 'atencao');
      return;
    }
    this.loading = true;
    this.taxSimulations = null;
    this.changed = false;
    this.error = false;
    this.error_message = '';
    let obj = this.setTaxSubmittedObj(this.simulatorForm.getRawValue());
    this.postingProvider.postTaxSimulation(obj).then((result) => {
      this.taxSimulations = result;
      this.loading = false;
    }).catch((error) => {
      console.error(error);
      this.loading = false;
      this.error = true;
      this.error_message = error.error.error_description;
    });
  }

  toolbarClick(): void {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : '',
    };
    const modalRef = this.modalService.open(ModalExportComponent, modalOptions);
    modalRef.componentInstance.relatorio = 'simulador-ir';
    modalRef.componentInstance.texto = "as opções de apuração";
    modalRef.result.then((result: any) => {
      if (result.type === 'pdf') {
        this.exportPDF(result.img);
      }
    });
  }

  exportPDF(img) {
    let valores = [];

    let tax = this.taxSimulations;
    valores.push({
      titulo: '20% da receita bruta',
      subtitulo: 'Dedução Legal',
      valores: tax.complete20,
      meiaPagina: true,
      melhor: tax.complete20.isMinor,
    }, {
      titulo: '20% da receita bruta',
      subtitulo: 'Simplificada',
      valores: tax.simple20,
      meiaPagina: true,
      melhor: tax.simple20.isMinor,
    }, {
      titulo: 'Livro Caixa',
      subtitulo: 'Dedução Legal',
      valores: tax.completeBook,
      meiaPagina: true,
      melhor: tax.completeBook.isMinor,
    }, {
      titulo: 'Livro Caixa',
      subtitulo: 'Simplificada',
      valores: tax.simpleBook,
      meiaPagina: true,
      melhor: tax.simpleBook.isMinor,
    });

    let produtor = this.members.find(member => member.cpfCNPJ == this.simulatorForm.controls.memberCpfCnpj.value);

    this.reportsUtil.exportPDFGrid({
      doc: {
        arquivo: `Simulacao de IR`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Simulação de IR ${this.ano.toString()}`,
        data: moment().format('DD/MM/YYYY'),
        periodo: this.ano.toString(),
        parametros: {
          "Produtor": produtor.name + " (" + produtor.cpfCNPJ + ")",
        },
        image: img,
      },
      dados: valores,
    });
  }

  formatCurrency(value) {
    return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
  }

  formatPercentage(value) {
    return value.toString().replace('.', ',') + '%';
  }
}

function getYears(startYear) {
  const currentYear = new Date().getFullYear() + 1, years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}
