import { Component } from "@angular/core";

import {
  NgbModal,
  NgbModalOptions,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ErrorUtil } from "src/util/error";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Snackbar } from "src/util/snackbar";
import { AuthProvider } from "src/providers/auth/authProvider";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ReplaySubject, Subject } from "rxjs";
import { BatchProvider } from "src/providers/batch/batchProvider";
import { CheckPermission } from "src/service/checkPermission";

import { AccountPlanDTOResponse } from "src/model/dto/AccountPlanDTOResponse";
import { AccountProvider } from "src/providers/account/account";
import { Bookkeeping } from "src/model/dto/bookkeping/Bookkeeping";
import Swal from "sweetalert2";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-editar-conta-lancamento",
  templateUrl: "./editar-conta-lancamento.component.html",
  styleUrls: ["./editar-conta-lancamento.component.scss"],
})
export class EditarContaLancamentoComponent {
  closeResult = "";

  protected _onDestroy = new Subject<void>();

  public batchForm: FormGroup;
  public formPassword: FormGroup;

  public conta: FormControl = new FormControl();
  public accountFilterCtrl: FormControl = new FormControl();
  public tipoLancamento: FormControl = new FormControl();

  public filteredAccounts: ReplaySubject<AccountPlanDTOResponse[]> =
    new ReplaySubject<AccountPlanDTOResponse[]>(1);
  public planosDeConta: AccountPlanDTOResponse[];

  public selectedBookkeeping: Bookkeeping[];

  public enviado: boolean;
  public enviando: boolean;
  public contaErro: boolean = false;
  public tipoErro: boolean = false;

  constructor(
    private accountProvider: AccountProvider,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public errorUtil: ErrorUtil,
    private authProvider: AuthProvider,
    public snackbar: Snackbar,
    public formBuilder: FormBuilder,
    private batchProvider: BatchProvider,
    public checkPermission: CheckPermission
  ) {
    this.initializeData();

    this.formPassword = this.formBuilder.group({
      password: [null, Validators.required],
    });
    this.batchForm = this.formBuilder.group({
      conta: [null, Validators.required],
    });
  }

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {}
      );
  }

  ngOnInit() {
    this.accountFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAccountMulti();
      });
  }

  initializeData() {
    this.accountProvider.getAllAccountsLeafs().then((categorias: []) => {
      this.planosDeConta =
        this.convertPlanAccountObjectSearchSelect(categorias);
      this.filteredAccounts.next(this.planosDeConta.slice());
    });
  }

  convertPlanAccountObjectSearchSelect(planAccounts) {
    const newPlanAccounts: AccountPlanDTOResponse[] = [];
    planAccounts.forEach((categoria) => {
      const categoriaMod: any = {
        id: categoria.id,
        customId: categoria.customId,
        description: categoria.customId + " - " + categoria.description,
        featureType: categoria.featureType,
        participateRuralActivity: categoria.participateRuralActivity,
        releaseType: categoria.releaseType,
      };
      newPlanAccounts.push(categoriaMod);
    });

    return newPlanAccounts.sort((a, b) => a.customId - b.customId);
  }

  checkConta(conta) {
    if (this.conta.value) {
      return this.conta.value.featureType == conta;
    }
    return false;
  }

  filterAccountMulti() {
    if (!this.planosDeConta) {
      return;
    }
    // get the search keyword
    let search = this.accountFilterCtrl.value;
    if (!search) {
      this.filteredAccounts.next(this.planosDeConta.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredAccounts.next(
      this.planosDeConta.filter(
        (bank) => bank.description.toLowerCase().indexOf(search) > -1
      )
    );
  }

  async onYesClick() {
    this.enviado = true;
    this.tipoErro = this.contaErro = false;
    if (this.conta.value && this.tipoLancamento.value) {
      this.enviando = true;
      await this.authProvider
        .revalidateLogin(this.formPassword.value.password)
        .then(async (value) => {
          let bookkeepingIds = this.selectedBookkeeping.map((b) => b.id);
          await this.batchProvider
            .postContaLancamento(
              bookkeepingIds,
              this.conta.value,
              this.tipoLancamento.value
            )
            .then((result: []) => {
              this.enviando = false;
              if (result.length == this.selectedBookkeeping.length && result.length != 0) {
                Swal.fire({
                  title: "Sucesso!",
                  text: "Os lançamentos selecionados foram alterados com sucesso!",
                  icon: "success",
                });
              } else if (result.length > this.selectedBookkeeping.length && result.length != 0) {
                Swal.fire({
                  title: "Atenção!",
                  text:
                    "Foi solicitada alteração em " +
                    this.selectedBookkeeping.length +
                    " lançamentos, porém, somente " +
                    result.length +
                    " foram alterados.",
                  icon: "warning",
                });
              } else if (result.length == 0) {
                Swal.fire({
                  title: "Erro!",
                  text: "Nenhum lançamento foi alterado",
                  icon: "error",
                });
              }
              this.activeModal.close(result);
            }).catch((response: HttpErrorResponse) => {
              this.enviando = false;
              this.activeModal.dismiss();
              if (response.error.error_description.includes("There is blocked periods")) {
                Swal.fire({
                  title: "Atenção!",
                  text:
                    "Há lançamentos cadastrados dentro de um bloqueio de período. " +
                    (this.checkPermission.isAccountantAdmin()
                      ? "Remova o bloqueio para poder alterar uma escrituração com um lançamento nesta data."
                      : "Contate um administrador para que o bloqueio seja removido."),
                  icon: "error",
                });
              } else {
                Swal.fire({
                  title: "Erro!",
                  text: "Nenhum lançamento foi alterado. Houve um erro de conexão com o servidor!",
                  icon: "error",
                });
              }
            });
        }).catch((reason) => {
          this.enviando = false;
          this.activeModal.dismiss();
          Swal.fire({
            title: "Falha na autenticação",
            text: "Não foi possível alterar os lançamentos. Ocorreu um problema na autenticação!",
            icon: "error",
          });
        });
    } else {
      if (this.conta.value == null) {
        this.contaErro = true;
      }
      if (this.tipoLancamento.value == null) {
        this.tipoErro = true;
      }
    }
    this.enviando = false;
  }
}
