import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {PessoaFiscalDTO} from "../../../model/dto/PessoaFiscalDTO";
import {PessoaFiscal} from "../../../model/PessoaFiscal";
import {Certificado} from "../../../model/Certificado";

@Injectable()
export class MemberProvider {

  apiMember: string = "/main-records/v1/member/";
  apiMonitorLogs: string = "/monitor/v1/dfe-log-today/"

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAllMembers() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiMember  + 'by_company/' + localStorage.getItem('idEmpresa'),
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: PessoaFiscalDTO[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getMembersByCompanyId(idCompany: number) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiMember + 'by_company/' + idCompany,
        {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: PessoaFiscalDTO[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getTotalMembers(){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiMember  + 'total-members',
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: number) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getAllMembersWithCertificate() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiMember  + 'all/has-certificate/',
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getMemberById(idAccount: number) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiMember + idAccount,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: PessoaFiscalDTO[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postMember(member: PessoaFiscal) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiMember + "by_company/" + localStorage.getItem('idEmpresa'), member,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: PessoaFiscalDTO[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putMember(member: PessoaFiscal) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiMember + "by_company/" + localStorage.getItem('idEmpresa'), member,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: PessoaFiscalDTO[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  deleteMember(idAccount: number, cpfCNPJ: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiMember + idAccount  + "/" + cpfCNPJ,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: PessoaFiscalDTO[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getCertificadoLogs(cpfCNPJ: string, data: string, companyId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiMonitorLogs + companyId + '/' + cpfCNPJ + '/' + data,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Certificado[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
}
