import { Component,  OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { LivroCaixa } from '../../../../util/livroCaixa';
import { FormBuilder, FormControl} from '@angular/forms';
import { ContaBancariaDTO } from '../../../../model/dto/ContaBancariaDTO';
import { Snackbar } from '../../../../util/snackbar';
import { HttpErrorResponse } from '@angular/common/http';
import { PostingProvider } from '../../../../providers/bookkeeping/posting/posting';
import { ErrorUtil } from '../../../../util/error';
import { MatDialog } from '@angular/material/dialog';
import { DateUtil } from "../../../../util/dateUtil";
import { DfeProvider } from 'src/providers/dfe/dfe';
import { ConsultaPdfXml } from 'src/providers/dfe/consultaPdfXml';
import { DateLockProvider } from 'src/providers/bookkeeping/date-lock/date-lock';
import { CheckPermission } from 'src/service/checkPermission';
import { OFXProvider } from 'src/providers/bookkeeping/ofx/ofx';
import { FilterSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DateAdapter } from '@angular/material';
import { Bookkeeping } from 'src/model/dto/bookkeping/Bookkeeping';
import { DocumentsBookkepingMapper } from 'src/model/dto/mapper/documentsBookkepingMapper';
import { BrMaskerIonicServices3 } from 'brmasker-ionic-3';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-conciliacao-multipla',
  templateUrl: './conciliacao-multipla.component.html',
  styleUrls: ['./conciliacao-multipla.component.scss']
})
export class ConciliacaoMultiplaComponent implements OnInit {
  @ViewChild('grid', { static: false }) grid;

  public filterSettings: FilterSettingsModel;
  public selectionSettings: SelectionSettingsModel;
  public dataSourceMain: Bookkeeping[];
  public customAttributes: any;
  public keyword: string = null;

  public dados: any;
  public conciliacao = false;

  public filter: { type: string };
  public monthly = true;
  public months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
  public activeMonth = this.months[0];
  public years: any;
  public year: number;
  public dateRange: ConsultDate = new ConsultDate();
  public consultDate: ConsultDate = new ConsultDate();
  public dateStart: string;
  public dateEnd: string;

  public carregando: boolean = false;
  public enviando: boolean = false;
  public errado: boolean = true;
  public erroDoc: boolean;
  public erroTipo: string;

  public contaSelecionada: ContaBancariaDTO;
  public data;
  public selectedBookkeeping: Bookkeeping[] = [];
  public totalSelectedBookkeeping: number = 0;
  public totalBookkeeping: number = 0;
  public diferenca: any = 0;
  public className: string;

  public lancamentos: Bookkeeping[];
  public toppings = new FormControl();
  public tab: string;
  public totalPagamentos: number;
  public totalRecebimentos: number;

  constructor(public activeModal: NgbActiveModal,
    private documentsBookkepingMapper: DocumentsBookkepingMapper,
    public dateUtil: DateUtil,
    private postingProvider: PostingProvider,
    private _adapter: DateAdapter<any>,
    private brMasker: BrMaskerIonicServices3,
    private snackBar: Snackbar,
    public errorUtil: ErrorUtil,
    public ofxProvider: OFXProvider,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public snackbar: Snackbar,
    public livroCaixa: LivroCaixa,
    public modalService: NgbModal,
    public dfeProvider: DfeProvider,
    public consultaPdfXml: ConsultaPdfXml,
    public dateLockProvider: DateLockProvider,
    public checkPermission: CheckPermission) { }

  ngOnInit() {
    this.getLancamentosByPeriod(true);
    this.tableConfiguration();
    this.defaultConfiguration();
    this._adapter.setLocale('pt-BR');
    this.totalBookkeeping = this.data.value;
    this.diferenca = this.totalBookkeeping;
  }

  actionBegin(args) {
    if (args.requestType === 'filterchoicerequest') {
      args.filterChoiceCount = 1000000; //here you can change the filterchoice count as per your requirement
    }
  }

  tableConfiguration() {
    this.customAttributes = { class: 'customcss' };
    this.filterSettings = { type: 'Menu' };
    this.filter = { type: 'CheckBox' };
    this.selectionSettings = { persistSelection: false, type: 'Multiple', checkboxOnly: true };
  }

  defaultConfiguration() {
    // tslint:disable-next-line: prefer-const
    let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
    this.dateRange.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('year').format();
    this.dateRange.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('year').format();
    this.activeMonth = this.months[+moment.tz(workDate, 'America/Sao_Paulo').format('MM') - 1]; // set month
    this.year = +moment.tz(workDate, 'America/Sao_Paulo').format('YYYY'); // set year
    this.years = getYears(+moment().format('YYYY') - 20);  // set years select    
  }

  getLancamentosByPeriod(firstRequest: boolean) {
    this.carregando = true;
    // tslint:disable-next-line: no-use-before-declare
    // const consultDate: ConsultDate = new ConsultDate();
    if (this.monthly) {
        // tslint:disable-next-line: prefer-const
        let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
        this.consultDate.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
        this.consultDate.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
    } else {
      this.consultDate.startDate = moment.tz(this.dateRange.startDate, 'America/Sao_Paulo').format('YYYY-MM-DD');
      this.consultDate.endDate = moment.tz(this.dateRange.endDate, 'America/Sao_Paulo').format('YYYY-MM-DD');
    }
    // console.log("consultDate ", this.consultDate);
    // console.log("dateRange ", this.dateRange);
    this.dateStart = this.consultDate.startDate;
    this.dateEnd = this.consultDate.endDate;
    
    this.getPosting(this.consultDate.startDate, this.consultDate.endDate);
  }

  async getPosting(startDate, endDate) {
    this.carregando = true;
    await this.postingProvider.getAllPosting(startDate, endDate).then(async (lancamentos: []) => {
      this.lancamentos = this.documentsBookkepingMapper.arrayToClientModel(lancamentos);
      this.lancamentos = this.lancamentos.filter((lancamento) => lancamento.ofxId == null || lancamento.ofxId == undefined);
      this.totalBookkeeping > 0 ? this.lancamentos = this.lancamentos.filter((lancamento) => lancamento.amountPaid > 0) : this.lancamentos = this.lancamentos.filter((lancamento) => lancamento.amountPaid < 0);
      this.carregando = false;
      this.erroDoc = false;
      this.tabsTypeFilter();
    }).catch((result: HttpErrorResponse) => {
      this.snackBar.openLong('Erro ao carregar lançamentos!', 'erro');
      console.error(result);
      this.carregando = false;
      this.erroDoc = true;
      this.erroTipo = 'Não foi possível obter os dados. ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping');
    });
  }

  changePeriod(data, type: string) {
    if (type === 'startDate') {
      this.dateStart = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
    } else if (type === 'endDate') {
      this.dateEnd = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
    }
    this.erroDoc = false;
    this.erroTipo = '';   

    if (this.dateStart <= this.dateEnd) {
      this.getPosting(this.dateStart, this.dateEnd);
    } else {
      this.erroDoc = true;
      this.erroTipo = 'Data inicial não pode ser maior que a data final.';
    }
  }

  tabs(link: string) {
    this.clearSearch();
    this.tab = link;
    this.tabsTypeFilter();
  }

  async tabsTypeFilter() {
    const lancamentosModificado = [...this.lancamentos];
    let lancamentosFiltrados = [];
    if (this.tab === 'Recebimentos') {
      this.totalRecebimentos = 0;
      await lancamentosModificado.forEach((lancamento: any, index: number) => {
        if (lancamento.account.featureType !== 'EXPENSE') {
          this.totalRecebimentos = lancamento.amountPaid + this.totalRecebimentos;
          lancamentosFiltrados.push(lancamento);
        }
      });
    } else if (this.tab === 'Pagamentos') {
      this.totalPagamentos = 0;
      await lancamentosModificado.forEach((lancamento: any, index: number) => {
        if (lancamento.account.featureType !== 'REVENUE') {
          this.totalPagamentos = lancamento.amountPaid + this.totalPagamentos;
          lancamentosFiltrados.push(lancamento);
        }
      });
    } else {
      lancamentosFiltrados = lancamentosModificado;
    }
    this.grouperFilter(lancamentosFiltrados);
  }

  grouperFilter(lancamentosModificado) {
    const lancamentosFiltrados = [];
    if ((this.toppings.value) == null || this.toppings.value.length === 0) {
      this.dataSourceMain = lancamentosModificado;
    } else {
      this.toppings.value.forEach((accountSelect) => {
        lancamentosModificado.forEach((lancamento: any, index: number) => {
          if (lancamento.grouper.length !== 0) {
            let existGrouper: boolean;
            lancamento.grouper.forEach((grouper) => {
              if (!(grouper.name.toLowerCase().indexOf(accountSelect.name.toLowerCase()) > -1)) {
              } else {
                existGrouper = true;
              }
            });
            if (existGrouper) {
              lancamentosFiltrados.push(lancamento);
            }
          }
        });
        this.dataSourceMain = lancamentosFiltrados;
      });
    }
  }

  selectAllToggle() {
    if (!this.checkIfIsAllSelected()) {
      this.grid.getCurrentViewRecords().forEach(row => {
        if (this.selectedBookkeeping.findIndex(value => value.id === row.id) >= 0) {
          this.selectedBookkeeping.splice(this.selectedBookkeeping.findIndex(value => value.id === row.id), 1);
        }
        this.selectFinancialReleaseForDelete(row);
      });
    } else {
      this.grid.getCurrentViewRecords().forEach(row => {
        if (this.selectedBookkeeping.findIndex(value => value.id === row.id) >= 0) {
          this.selectedBookkeeping.splice(this.selectedBookkeeping.findIndex(value => value.id === row.id), 1);
        }
      });
    }
  }

  checkIfIsAllSelected(): boolean {
    for (let i = 0; i < this.grid.getCurrentViewRecords().length; i++) {
      if (this.selectedBookkeeping.findIndex(value => value.id === this.grid.getCurrentViewRecords()[i].id) < 0) {
        return false;
      } else {
        if (i === (this.grid.getCurrentViewRecords().length - 1)) {
          return true;
        }
      }
    }
  }

  checkIfFinancialReleaseAlreadySelected(financialRelease): boolean {
    return this.selectedBookkeeping.findIndex(val => val.id === financialRelease.id) === -1;
  }

  selectFinancialReleaseForDelete(financialRelease) {
    if (this.checkIfFinancialReleaseAlreadySelected(financialRelease)) {
      this.selectedBookkeeping.push(financialRelease);
    } else {
      this.selectedBookkeeping.splice(this.selectedBookkeeping.findIndex(val => val.id === financialRelease.id), 1);
    }
    this.setIndicadores();
  }

  setIndicadores(){
    this.totalSelectedBookkeeping = 0;
    this.selectedBookkeeping.forEach((financialRelease) => {
      this.totalSelectedBookkeeping += financialRelease.amountPaid;
    });

    // if (this.totalSelectedBookkeeping < 0 && this.data.type == "DEBIT") 
    //   this.totalSelectedBookkeeping *= -1;
   // console.log(this.totalSelectedBookkeeping)
    //this.totalSelectedBookkeeping = this.totalSelectedBookkeeping.toFixed(2)
    this.diferenca = (this.totalBookkeeping - this.totalSelectedBookkeeping);

    if (this.totalSelectedBookkeeping == 0)
      this.diferenca = this.totalBookkeeping;

    this.verificaIndicadores();
  }

  verificaIndicadores(){
   // console.log(Number(this.diferenca.toFixed(2)), this.totalSelectedBookkeeping , this.totalBookkeeping)
    if (this.selectedBookkeeping.length <= 0) {
      this.erroDoc = true;
      this.erroTipo = 'Não há lançamentos selecionados!';
      this.className = "text-danger";
    } 
    else if (Number(this.diferenca.toFixed(2)) == 0 && Number(this.totalSelectedBookkeeping.toFixed(2)) == this.totalBookkeeping) {
      this.errado = false;
      this.erroDoc = false;
      this.erroTipo = '';
      this.className = "text-success";
    }
    
    else if (this.diferenca < 0) {
      this.erroDoc = true;
      this.erroTipo = 'O valor selecionado é maior que o valor total do lançamento!';
      this.className = "text-danger";
    } else if (this.diferenca > 0) {
      this.erroDoc = true;
      this.erroTipo = 'O valor selecionado é menor que o valor total do lançamento!';
      this.className = "text-danger";
    } 
  }

  clearSearch() {
    this.grid.searchSettings.key = '';
    this.keyword = '';
  }

  searchTable() {
    if (this.keyword === '') {
      this.grid.searchSettings.key = '';
    }
    const wordSearch = this.keyword;
    let time = 400;
    if (this.dataSourceMain.length > 500) {
      time = 800;
    }
    setTimeout(() => {
      if (wordSearch === this.keyword) {
        if (this.keyword) {
          const searchText: string = (document.getElementsByClassName('searchtext')[0] as any).value;
          this.grid.search(searchText);
        }
      } else {
        this.grid.searchSettings.key = '';
      }
    }, time);
  }

  formatarValor(valorTotal: string) {
    return this.brMasker.writeValueMoney(valorTotal.toString(), {
      decimal: 2,
      decimalCaracter: ',',
      len: 0,
      mask: '',
      numberAndTousand: false,
      percent: false,
      person: false,
      phone: false,
      thousand: '.',
      type: undefined,
      userCaracters: false,
      money: true
    });
  }

  autoFillOthers() {
    this.grid.autoFitColumns(['checkbox', 'amountPaid', 'doc.docTypeFormated',
      'doc.participant.name', 'doc.participant.cpfCNPJParticipant', 'historic', 'bankAccount.description', 'account.description', 'checkbox',
      'postingType', 'doc.property.name', 'doc.number', 'paymentDate', 'createdBy', 'createdAt']);
  }

  getActiveMonth(mes) {
    switch (mes) {
      case 'JAN':
        mes = '01';
        break;
      case 'FEV':
        mes = '02';
        break;
      case 'MAR':
        mes = '03';
        break;
      case 'ABR':
        mes = '04';
        break;
      case 'MAI':
        mes = '05';
        break;
      case 'JUN':
        mes = '06';
        break;
      case 'JUL':
        mes = '07';
        break;
      case 'AGO':
        mes = '08';
        break;
      case 'SET':
        mes = '09';
        break;
      case 'OUT':
        mes = '10';
        break;
      case 'NOV':
        mes = '11';
        break;
      case 'DEZ':
        mes = '12';
        break;
      default:
        mes = '01';
    }
    return mes;
  }

  tabsMonth(link: string) {
    if (link === 'Mensal') {
      this.changeToMonthlyMode(true);
    } else {
      this.changeToMonthlyMode(false);
    }
  }

  changeToMonthlyMode(b: any) {
    this.monthly = b;
    this.getLancamentosByPeriod(false);
  }

  clearFilters() {
    this.clearSearch();
    
    this.grid.clearFiltering();
    this.grid.clearSorting();

    this.toppings = new FormControl();
    this.tabsTypeFilter();

    this.snackBar.open('Filtros removidos com sucesso', 'success');
  }

  salvar() {
    this.enviando = true;
    this.errado = true;
    const arrayIds = [];
    this.selectedBookkeeping.forEach(element => {
      arrayIds.push(element.id);
    });
    
    this.ofxProvider.conciliateTransactions(arrayIds, this.data.id).then((value: any) => {
      if (value.status === "error") {
        Swal.fire({
          title: 'Erro!',
          html: value.message,
          icon: 'error'
        }).then(() => {
          this.enviando = false;
          this.errado = false;
        });
      } else if (value.status === "ok") {
        Swal.fire({
          title: 'Conciliação realizada com sucesso',
          icon: 'success'
        }).then(() => {
          this.errado = false;
        this.enviando = false;
          this.activeModal.close();
        });
      }
    }).catch((result) => {
      Swal.fire({
        title: 'Erro!',
        html: this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'),
        icon: 'error'
      }).then(() => {
        this.enviando = false;
        this.errado = false;
      });
    });
  }

  getFileDfe(planPaymentId, location) {
    this.carregando = true;
    this.dfeProvider.getDfeByPlannedPayment(planPaymentId).then((dfe: any) => {
      const document = {
        xml: atob(dfe.dfeBytes),
        modelo: dfe.modelType,
        chave: dfe.key
      };
      switch (location) {
        case 'modal':
          this.consultaPdfXml.getPdfModal(document).then((result) => {
            this.carregando = false;
          }).catch((result: HttpErrorResponse) => {
            this.carregando = false;
            console.error(result);
            this.snackBar.openLong('Não foi possível consultar o PDF/XML. ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
          });
          break;
        case 'blank':
          this.consultaPdfXml.getPdf(document).then((result) => {
            this.carregando = false;
          }).catch((result: HttpErrorResponse) => {
            this.carregando = false;
            console.error(result);
            this.snackBar.openLong('Não foi possível consultar o PDF/XML. ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
          });
          break;
      }
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
      console.error(result);
      this.snackBar.openLong('Não foi possível consultar o PDF/XML. ' +
      this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
    });
  }

}

function getYears(startYear) {
  const currentYear = new Date().getFullYear() + 1;
  const years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

class ConsultDate {
  endDate: any;
  startDate: any;
}