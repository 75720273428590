import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";

@Injectable()
export class PaymentProvider {

  apiBase: string = "/bookkeeping/v1/payment/";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  postPayment(payment) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBase, payment, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putPayment(payment) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase, payment, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delPayment(id) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiBase + localStorage.getItem('idEmpresa') + '/' + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }


}
