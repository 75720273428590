import {AddressDTOResponse} from "./AddressDTOResponse";
import {Participant} from "./bookkeping/Participant";

export class ImovelDTO {
  id: number;
  code: string;
  propertyCode: string;
  name: string;
  stateRegistration: string;
  explorationType: string;
  currency: string;
  itrCode: string;
  caepf: string;
  address: AddressDTOResponse;
  default: boolean;
  active: boolean;
}


