import {Injectable} from "@angular/core";
import * as moment from "moment";

@Injectable()
export class DateUtil {

  removeTimeZone(date) {
    // console.log(date);
    // console.log(moment(date).format().split('T')[0]);
    return moment(date).format().split('T')[0];
  }

}
