import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl} from "@angular/forms";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Snackbar} from "../../../../util/snackbar";
import {ErrorUtil} from "../../../../util/error";
import {CertificadoProvider} from "../../../../providers/certificado/certificadoProvider";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import moment from "moment";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {MemberProvider} from "../../../../providers/company/member/member";

@Component({
  selector: 'app-monitor-log',
  templateUrl: './certificado-logs.component.html',
  styleUrls: ['./certificado-logs.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
})
export class CertificadoLogsComponent implements OnInit {

  public carregando: Boolean = false;
  public obj: any;
  public date = new FormControl(moment());
  public data: any;

  constructor(private formBuilder: FormBuilder,
              public modalService: NgbModal,
              public activeModal: NgbActiveModal,
              public snackbar: Snackbar,
              public erroUtil: ErrorUtil,
              private provider: MemberProvider) { }

  ngOnInit() {
    this.getLogs(this.date.value);
  }

  getLogs(date: string) {
    let cpfCNPJ = this.obj.cpfCNPJ;
    this.carregando = true;
    date = moment(date).format("YYYY-MM-DD");
    this.provider.getCertificadoLogs(cpfCNPJ, date, this.obj.companyId).then((logs) => {
      this.carregando = false;
      this.data = logs;
      console.log(logs)
    }).catch((error) => {
      this.carregando = false;
      this.snackbar.openLong(this.erroUtil.checkErrorStatus(error, error.status, error.error, "member"), "erro");
    });
  }

  retornaData(data: string) {
    return moment(data).format('DD/MM/YY HH:mm:ss');
  }

  changeDate($event: MatDatepickerInputEvent<any>) {
    this.getLogs($event.value);
  }
}
