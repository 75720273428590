import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {DfeDTOResponse} from "../../../model/dto/dfe/DfeDTOResponse";

@Injectable()
export class DocumentProvider {

  apiBase: string = "/bookkeeping/v1/doc/";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAllDFE(before, after) {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + localStorage.getItem('idEmpresa') + '/' + before  + '/' + after, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  checkIfDocumentIsCarried(document) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBase + "exist", document,{headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: boolean) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getDocumentBookkeepingByPayment(id: number) {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + localStorage.getItem('idEmpresa') + '/payment' + "/" + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DfeDTOResponse) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }


  postDFE(DFE) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBase, DFE, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putDocument(document) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase, document, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  postPlanilhaEscrituracao(document: File) {
    const formData = new FormData();
    formData.append('file', document);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + "/bookkeeping/v1/in_batch/import/" + localStorage.getItem('idEmpresa'), formData, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postCsvEscrituracao(document: File) {
    const formData = new FormData();
    formData.append('file', document);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + "/bookkeeping/v1/in_batch/import-csv/" + localStorage.getItem('idEmpresa'), formData, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });

    });
  }

  delDocument(id) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiBase + localStorage.getItem('idEmpresa') + '/' + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getFilesByDocId(id){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + "/bookkeeping/v1/doc-file/" + localStorage.getItem('idEmpresa') + '/' + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postImportedFilePlanilhaCsv(file: File, importType: string) {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + "/bookkeeping/v1/in_batch/data/" + importType + '/' + localStorage.getItem('idEmpresa'), formData, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  posImportedDatatPlanilhaCsv(data: any, importType: string, receiptFileId: string) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + "/bookkeeping/v1/in_batch/sheet/" + importType + '/' + localStorage.getItem('idEmpresa') + '/' + receiptFileId, data, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

}
