import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-alert-doc-duplicado',
  templateUrl: './alert-doc-duplicado.component.html',
  styleUrls: ['./alert-doc-duplicado.component.scss']
})
export class AlertDocDuplicadoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AlertDocDuplicadoComponent>) {}

  ngOnInit() {
  }

}
