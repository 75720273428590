export class PessoaFiscal {
  id: number;
  cpfCNPJ: string;
  companyId: string;
  name: string;
  certificateId: number;
  telephone: string;
  email: string;
  street: string;
  houseNumber: string;
  neighborhood: string;
  complement: string;
  cep: string;
  stateId: number;
  cityId: number;
  isClient: boolean;
}
