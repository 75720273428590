import {Component, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Snackbar} from "../../../../util/snackbar";
import {HttpErrorResponse} from "@angular/common/http";
import {UserProvider} from "../../../../providers/user/user";
import {ErrorUtil} from "../../../../util/error";
import {BasicAlertComponent} from "../../../shared/alert/basic-alert/basic-alert.component";
import {Dialog} from "../../../../model/Dialog";
import {MatDialog} from "@angular/material/dialog";
import {UserInfoAppComponent} from "./user-info-app/user-info-app.component";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cadastro-convite-app',
  templateUrl: './cadastro-convite-app.component.html',
  styleUrls: ['./cadastro-convite-app.component.scss']
})
export class CadastroConviteAppComponent implements OnInit {

  @Input() public member;
  @Input() public type;
  @Input() public editar: boolean;
  @Input() public existingUser;
  public roles = [];
  public putOrPost: string;
  public invitationForm: FormGroup;
  public sentForm = false;
  public enviando: boolean;
  public carregando = false;

  constructor(public activeModal: NgbActiveModal,
              public snackBar: Snackbar,
              public dialog: MatDialog,
              private errorUtil: ErrorUtil,
              private userProvider: UserProvider,
              public fb: FormBuilder) {
  }

  ngOnInit() {
    //console.log(this.type);
    
    this.configFormInvite();
    this.setMemberInfoToForm();
  }

  configFormInvite() {
    //console.log("this roles= ", this.roles)
    this.invitationForm = this.fb.group({
      // id: [],
      name: [Validators.required],
      memberId: ['', Validators.required],
      lastName: ['', Validators.required],
      notBefore: [null],
      notAfter: [null],
      companyId: ['', Validators.required],
      email: [Validators.email, Validators.required],
      password: [Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\w\\W]{8,}$')])],
      roles: [this.roles],
    });
  }

  setMemberInfoToForm() {
    if (!this.editar) {
      if (this.member.name.split(' ').length > 1) {
        this.invitationForm.controls.name.setValue(this.member.name.substr(0, this.member.name.indexOf(' ')));
        this.invitationForm.controls.lastName.setValue(this.member.name.substr(this.member.name.indexOf(' ') + 1));
      } else {
        this.invitationForm.controls.name.setValue(this.member.name);
      }
      this.invitationForm.controls.email.setValue(this.member.addressDTORespComp.email);
      this.invitationForm.controls.memberId.setValue(this.member.cpfCNPJ);
      //console.log("roles: ",this.member.roles);
      //this.invitationForm.controls.roles.setValue(this.member.roles);
      this.invitationForm.controls.companyId.setValue(this.member.companyId);
    } else {
      this.invitationForm.patchValue(this.existingUser);
      this.invitationForm.controls.name.disable();
      this.invitationForm.controls.lastName.disable();
      this.invitationForm.controls.email.disable();
     // this.invitationForm.controls.roles.setValue(this.member.roles);
    }
  }

  sendInvitation() {    
    this.sentForm = true;
    this.carregando = true;
    if (this.invitationForm.valid) {
      if(this.putOrPost == "post"){
      //provider
      this.userProvider.postCollaborator(this.invitationForm.value).then((result: any) => {
        this.userProvider.postCompanyCollaborator(result.id,[this.member.companyId]).then(() => {
          this.carregando = false;
          this.snackBar.openLong("Sucesso! Produtor cadastrado!", 'success');
          if (this.type.includes('Nf-e')) {
            this.modalSwal();
          } else if (this.type.includes('App produtor')) {
            const dialogRef = this.dialog.open(UserInfoAppComponent, {
              data: this.invitationForm.value,
            });
          }
          this.activeModal.close(true);
        }).catch((err: HttpErrorResponse) => {
          this.carregando = false;
          this.snackBar.openLong('Erro ao cadastrar a empresa! Contate o suporte' + this.errorUtil.checkErrorStatus(err, err.status, err.error, 'invitation'), 'error');
        });
      }).catch(result => {
        this.carregando = false;
        this.snackBar.openLong('Não foi possível inserir! ' +
          this.errorUtil.checkErrorStatus(result, result.status, result.error, 'invitation'), 'erro');
      });
    }else{
      this.userProvider.putCollaborator(this.invitationForm.value).then((result: any) => {
        this.userProvider.putCompanyCollaborator(result.id,[this.member.companyId]).then(() => {
          this.snackBar.openLong("Sucesso! Produtor cadastrado!", 'success');
          if (this.type.includes('Nf-e')) {
            this.modalSwal();
          } else if (this.type.includes('App produtor')) {
            const dialogRef = this.dialog.open(UserInfoAppComponent, {
              data: this.invitationForm.value,
            });
          }
          this.activeModal.close(true);
        }).catch((err: HttpErrorResponse) => {
          this.carregando = false;
          this.snackBar.openLong('Erro ao cadastrar a empresa! Contate o suporte' + this.errorUtil.checkErrorStatus(err, err.status, err.error, 'invitation'), 'error');
        });
      }).catch(result => {
        this.carregando = false;
        this.snackBar.openLong('Não foi possível inserir! ' +
          this.errorUtil.checkErrorStatus(result, result.status, result.error, 'invitation'), 'erro');
      });
    }
    } else {
      this.carregando = false;
      this.snackBar.openLong('Há erros no formulários! Confira antes de enviar novamente.', 'erro');
    }
  }

  modalSwal(){
    Swal.fire({
      title: 'Emissor habilitado com sucesso!',
      icon: 'success',
    });
  }

  changePassword() {
    let passwordChangeObject = {
      olderPassword: this.invitationForm.value.password,
      newerPassword: this.invitationForm.value.newerPassword
    }
    // this.userProvider.putUserPassword(passwordChangeObject).then((response) => {
    //   this.snackBar.openLong("Sucesso! Dados atualizados com sucesso!", 'success');
    //   this.activeModal.close(true);
    //   this.enviando = false;
    // }).catch((result: HttpErrorResponse) => {
    //   console.error(result);
    //   this.enviando = false;
    //   this.snackBar.openLong('Não foi possível editar! ' +
    //     this.errorUtil.checkErrorStatus(result, result.status, result.error, 'invitation'), 'erro');
    // });
  }

  deleteUser() {
    const dialogText: Dialog = new Dialog();
    dialogText.button = 'Excluir';
    dialogText.buttonCancel = 'Cancelar';
    dialogText.header = 'Você tem certeza deseja remover esse usuário?';
    dialogText.subHeader = 'Se removido, o usuário perderá o acesso ao aplicativo.';
    const dialogRef = this.dialog.open(BasicAlertComponent, {
      data: dialogText,
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result == dialogText.button) {
        this.userProvider.delCollaborator(this.existingUser).then(() => {
          this.snackBar.openLong('Usuário foi removido com suceso!', 'success');
          this.activeModal.close(true);
        }).catch((reason: HttpErrorResponse) => {
          console.error(reason);
          this.enviando = false;
          this.snackBar.openLong('Não foi possível excluir! ' + reason.error.error_description, 'erro');
        })
      }
    });
  }
}
