
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionSettingsModel } from '@syncfusion/ej2-angular-dropdowns';
import { FilterSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Subject } from 'rxjs';
import { DocumentProvider } from 'src/providers/bookkeeping/document/document';
import { PostingProvider } from 'src/providers/bookkeeping/posting/posting';
import { Snackbar } from 'src/util/snackbar';

@Component({
  selector: 'app-importacao-planilha-csv',
  templateUrl: './importacao-planilha-csv.component.html',
  styleUrls: ['./importacao-planilha-csv.component.scss']
})
export class ImportacaoPlanilhaCsvComponent implements OnInit {

  protected _onDestroy = new Subject<void>();
  public loading: boolean = false;
  public foundError: boolean = true;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  public importType: string = null;
  public importTitle = { csv: "Importação de arquivo .CSV", xlsx: "Importação de arquivo .XLSX" }
  public importExtension = { csv: ".csv", xlsx: ".xlsx" }
  public importLink = { csv: "https://farmcont-files.s3.sa-east-1.amazonaws.com/modelo-importacao-escrituracao.csv", xlsx: "https://farmcont-files.s3.sa-east-1.amazonaws.com/modelo-importacao-farmcont.xlsx" }

  public importedFile: any = null;

  public dataSource: any[] = [];
  @ViewChild('grid', { static: false }) previewGrid;
  public filterSettings: FilterSettingsModel;
  public selectionSettings: SelectionSettingsModel;
  public customAttributes: any;
  public keyword: string = null;
  public filter: any;

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public snackBar: Snackbar,
    public documentProvider: DocumentProvider,
    public postingProvider: PostingProvider
  ) { }

  ngOnInit() {
    this.tableConfiguration();

  }

  tableConfiguration() {
    this.customAttributes = { class: 'customcss' };
    this.filterSettings = { type: 'Menu' };
    this.filter = { type: 'CheckBox' };
    this.selectionSettings = { type: 'Multiple', checkboxOnly: true } as SelectionSettingsModel;
  }

  customiseCell(args: any) {
    args.column.field != "line" && args.data[args.column.field].flagColor != '#FFFFFF' ? this.foundError = true : null;
    args.cell.style.background = args.data[args.column.field].flagColor;
    if (args.data[args.column.field].flagColor != '#FFFFFF') args.cell.classList += ' bg-colorido';
  }

  insertFile() {
    try {
      document.getElementById('fileInsert').click();
    } catch (exception) {
      console.error('Erro ao inserir arquivo!', exception);
    }
  }

  fileChange() {
    try {
      const fileInput = (<HTMLInputElement>document.getElementById('fileInsert'));
      this.importedFile = fileInput.files[0];
      this.stepper.selected.completed = true;
    } catch (exception) {
      console.error('Erro ao inserir arquivo!', exception);
    }
  }

  importedFileRemove() {
    this.importedFile = null;
    this.foundError = true;
    this.stepper.selected.completed = false;
  }

  submitImportedFile() {
    this.loading = true;
    this.documentProvider.postImportedFilePlanilhaCsv(this.importedFile, this.importType.toUpperCase()).then((result: any) => {
      if (result) {
        this.foundError = false;
        this.dataSource = result;
        this.stepper.selected.completed = true;
        this.stepper.next();
        this.loading = false;
      }
    }).catch((result) => {
      this.loading = false;
      this.snackBar.openLong('Ocorreu algum erro ao enviar a planilha: ' + result.error.error_description, 'erro');
    });
  }

  submitImportedData() {
    this.loading = true;
    this.postingProvider.postImportedFileToPosting(this.importedFile, this.importType.toUpperCase()).then((receiptFileId: any) => {
      this.documentProvider.posImportedDatatPlanilhaCsv(this.dataSource, this.importType.toUpperCase(), receiptFileId).then((result: any) => {
        if (result) {
          // this.snackBar.openLong('CSV enviado com sucesso! ' + result.postedCount + ' linha(s) adicionada(s).', 'success');
          this.loading = false;
          this.activeModal.close()
        }
      });
    }).catch((result) => {
      this.snackBar.openLong('Ocorreu algum erro ao enviar a planilha: ' + result.error.error_description, 'erro');
      this.loading = false;
    });
  }

}
