import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Snackbar } from 'src/util/snackbar';

@Component({
  selector: 'app-clone-automacao',
  templateUrl: './clone-automacao.component.html',
  styleUrls: ['./clone-automacao.component.scss']
})
export class CloneAutomacaoComponent implements OnInit {

  public loading: boolean = false;
  public automationCode: string = "";
  public automationDescription: string = "";
  @Input () public oldAutomationCode: string = "";

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public snackBar: Snackbar,
    public fb: FormBuilder,
  ) { }

  ngOnInit() {
  }

  cloneAutomation(){
    let obj = {
      automationCode: this.automationCode,
      automationDescription: this.automationDescription,
      oldAutomationCode: this.oldAutomationCode
    }
  }

  checkFormValidity(){
    return this.automationCode.length == 3 && this.automationDescription.length > 3 ? true : false;
  }

}
