import {Component, Injectable, OnInit} from "@angular/core";
import {DateUtil} from "../../../../util/dateUtil";
import {Snackbar} from "../../../../util/snackbar";
import {ErrorUtil} from "../../../../util/error";
import {Chart} from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";

@Component({
  selector: 'app-dashboard-charts',
  template: ``
})

@Injectable()
export class AccountsCharts implements OnInit {
  public charts = [];
  public doubleChartData: any = {};
  public expanded: boolean = false;
  public hidden: boolean = true;
  public loadingGraf1: boolean = false;
  public loadingGraf2: boolean = false;
  public isForExport: boolean = false;
  public loading: boolean = false;

  public aux: any = [];
  public byAccount: any = [];

  public links = ['Receita', 'Despesa'];
  public activeLink = this.links[0];
  public accounts = [];

  public mesesExtensos = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  constructor(public dateUtil: DateUtil,
              public snackBar: Snackbar,
              public errorUtil: ErrorUtil,) {
  }

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  start() {
    this.aux = [];
    this.byAccount = [];
    this.hidden = true;
    this.expanded = false;
  }

  generate() {
    this.byAccount = [];

    this.formatData();
    this.destroyCharts();

    if (this.byAccount == null || this.byAccount.length <= 0) {
      this.loadingGraf1 = false;
      this.loadingGraf2 = false;
      this.loading = false;
      this.hidden = true;
    } else {
      this.doubleChartData = this.setChartsData(this.byAccount);
      this.createCharts();

      this.loadingGraf1 = false;
      this.loadingGraf2 = false;
      this.expanded = true;
      this.loading = false;
      this.hidden = false;
    }
  }

  private formatData() {
    if (this.aux == null) return;
    let tmp: Array<{ description: string; total: number }> = [];
    let max = 0;
    let min = 0;
    this.aux.forEach(element => {
      let number = parseFloat(element.amountPaidFormatted.replaceAll(".", "").replace(',', '.'));
      let elem = tmp.find((a) => a.description == element.accountDescription);
      if (elem == null) {
        tmp.push({
          description: element.accountDescription,
          total: number
        });
      } else {
        elem.total += number;
      }

      if (number >= 0) {
        max += number;
      }
      if (number < 0) {
        min += number;
      }
    });

    if (tmp.length > 0) {
      tmp.sort((a, b) => {
        if (a.total < b.total) {
          return 1;
        }
        if (a.total > b.total) {
          return -1;
        }
        return 0;
      });

      tmp.push({
        description: 'max',
        total: max
      }, {
        description: 'min',
        total: min
      });

      this.byAccount = tmp;
    }
  }

  changeTab(tab: 'Receita' | 'Despesa') {
    this.activeLink = tab;
    this.loadingGraf1 = true;
    this.loadingGraf2 = true;

    this.doubleChartData = this.setChartsData(this.byAccount);
    this.destroyCharts();
    this.createCharts();

    this.loadingGraf1 = false;
    this.loadingGraf2 = false;

    if (this.byAccount != null && this.byAccount.length > 0) {
      this.hidden = false;
    }
  }

  setChartsData(byAccount) {
    let byAccountChart = {
      labels: [],
      labels2: [],
      pie: [],
      bar: []
    }
    if (byAccount.length > 0) {
      let sum = byAccount.find((element) => {
        if (this.activeLink == 'Receita') {
          return element.description == 'max'
        }
        if (this.activeLink == 'Despesa') {
          return element.description == 'min'
        }
      }).total
      byAccount = byAccount.filter((element) => {
        if (this.activeLink == 'Receita') {
          return element.total > 0 && element.description != 'max'
        }
        if (this.activeLink == 'Despesa') {
          return element.total < 0 && element.description != 'min'
        }
      });
      byAccount.sort((a, b) => (Math.abs(a.total) < Math.abs(b.total)) ? 1 : ((Math.abs(b.total) < Math.abs(a.total)) ? -1 : 0));
      if (byAccount.length > 10) {
        let byAccountAux = [];
        byAccountAux = byAccount.slice(10, byAccount.length);
        byAccount = byAccount.slice(0, 9);
        byAccount.push({
          description: 'OUTROS',
          total: byAccountAux.reduce((acc, item) => acc + item.total, 0)
        });
      }
      byAccountChart = {
        labels: byAccount.map(a => this.accounts.find((account) => account.description.split(/- (.*)/)[1] == a.description) ? this.accounts.find((account) => account.description.split(/- (.*)/)[1] == a.description).description  : a.description),
        labels2: byAccount.map(a => this.accounts.find((account) => account.description.split(/- (.*)/)[1] == a.description) ? this.accounts.find((account) => account.description.split(/- (.*)/)[1] == a.description).customId : a.description),
        pie: byAccount.map(a => Math.abs((a.total / sum * 100)).toFixed(2)),
        bar: byAccount.map(a => Math.abs(a.total))
      }
    }
    return byAccountChart;
  }

  createCharts() {
    let height = this.isForExport ? "400px" : "300px";
    document.getElementById('divChart0').style.height = height;
    document.getElementById('divChart1').style.height = height;
    Chart.plugins.unregister(ChartDataLabels);

    let chartBackgroundColors = this.generateColors();
    let valores = this.doubleChartData.bar;
    this.charts[0] = new Chart('myChart0', {
      type: 'pie',
      data: {
        labels: this.doubleChartData.labels,
        datasets: [
          {
            data: this.doubleChartData.pie,
            backgroundColor: chartBackgroundColors,
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 0.1,
          },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        rotation: this.isForExport ? 160 : 0,
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: function (tooltipItems) {
              return labels[tooltipItems[0].index];
            },
            label: function (tooltipitems, data) {
              return `${data.datasets[0].data[tooltipitems.index]}% (${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(valores[tooltipitems.index])})`;
            }
          }
        }
      }
    });

    let labels = this.doubleChartData.labels;
    this.charts[1] = new Chart('myChart1', {
      type: 'bar',
      data: {
        labels: this.doubleChartData.labels2,
        datasets: [{
          data: this.doubleChartData.bar,
          backgroundColor: chartBackgroundColors,
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 0.1,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value: number, index, values) {
                return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
              }
            }
          }],
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: function (tooltipItems) {
              return labels[tooltipItems[0].index];
            },
            label: function (tooltipitems, data) {
              // @ts-ignore
              return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(data.datasets[0].data[tooltipitems.index]);
            }
          }
        }
      }
    });
  }

  destroyCharts() {
    if (this.charts.length > 0) {
      this.charts[0].destroy();
      this.charts[1].destroy();
    }
    document.getElementById('divChart0').style.height = "0px";
    document.getElementById('divChart1').style.height = "0px";
  }

  generateColors() {
    return [
      `rgba(255, 211, 79, 1)`,
      `rgba(25, 197, 148, 0.7)`,
      `rgba(237, 85, 101, 0.93)`,
      `rgba(28, 132, 198, 0.78)`,
      `rgba(187, 107, 217, 0.85)`,
      `rgba(242, 152, 73, 0.95)`,
      `rgba(109, 146, 227, 0.89)`,
      `rgba(111, 63, 170, 0.67)`,
      `rgba(59, 214, 152, 0.84)`,
      `rgba(167, 166, 214, 0.84)`,
      `rgba(255, 99, 132, 0.8)`,
      `rgba(54, 162, 235, 0.8)`,
      `rgba(75, 192, 192, 0.8)`,
      `rgba(153, 102, 255, 1)`,
      `rgb(255, 234, 0)`,
    ];
  }

  getSuitableY(y, yArray = [], direction) {
    let result = y;
    yArray.forEach((existedY) => {
      if (existedY - 15 < result && existedY + 15 > result) {
        if (direction === "right") {
          result = existedY + 15;
        } else {
          result = existedY - 15;
        }
      }
    });

    return result;
  };
}
