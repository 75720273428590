export class ContaBancaria {
  id: number;
  description: string;
  bankId: number
  code: string;
  companyId: number;
  countryId: number;
  agencyWithoutDV: string;
  accountNumberWithDV: string;
  default: boolean;
}
