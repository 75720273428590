import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StockProvider} from 'src/providers/stock/stock';
import {Snackbar} from '../../../../../util/snackbar';

@Component({
  selector: 'app-enviar-planilha',
  templateUrl: './enviar-planilha.component.html',
  styleUrls: ['./enviar-planilha.component.scss']
})
export class EnviarPlanilhaComponent implements OnInit {

  constructor(    public modalService: NgbModal, public activeModal: NgbActiveModal,public stockProvider: StockProvider, public snackBar: Snackbar,
    ) { }
   enviando = false
  file
  ngOnInit() {
  }

  newFile(){
    //console.log("FUUUU")
    let file: File;
    const fileInput =  (<HTMLInputElement>document.getElementById('swal-file'));
    file = fileInput.files[0];
    this.file = file

    //console.log(file)
  }
  inserir(){
        this.enviando = true

    this.stockProvider.postImportacao(this.file).then((result: any) =>{
      this.snackBar.openLong('CSV enviado com sucesso!', 'success');
      this.enviando = false
      this.activeModal.close()
    }).catch((result) => {
      //console.log(result)

      this.snackBar.openLong('Ocorreu algum erro ao enviar a planilha: ' + result.error.error_description, 'error');
      this.enviando = false

    });
  }
}
