import {Pessoa} from './Pessoa';
import {Duplicatas} from './Duplicatas';
import {FolderDTOResponse} from './dto/FolderDTOResponse';
import { EventsDTOResponse } from './dto/dfe/EventsDTOResponse';

export class ConsultaDfe {
  id: number;
  chaveId: string;
  numeroDoc: string;
  numberParcela: string;
  dataEmissao: any;
  dataExpiracao: any;
  pago:any;
  dataDownloadXML: any;
  valorTotal: number;
  dfeValido: boolean;
  producao: boolean;
  modelo: string;
  tipoNFe: string;
  emitente: Pessoa;
  destinatario: Pessoa;
  duplicatas: Duplicatas[];
  pagamentoPlanejado: Duplicatas[];
  valor: number;
  pasta: FolderDTOResponse;
  valorDesconto: string;
  natOperacao: string;
  valorTotalFormatado: string;
  dataFormatada: string;
  month: string;
  escrituracao: string;
  events: EventsDTOResponse[];
  eventType: string;
  automaticInsertion: boolean;
  valorDFE: any;
  historicoUtil: any;
}

export class ConsultaDfe2 {
  id: number;
  parcelaId: number;
  emitente: any;
  valorDFE: any;
  destinatario: any;
  chaveId: string;
  numeroDoc: string;
  numberParcela: string;
  pagoFormatado: string;
  dataEmissao: any;
  dataExpiracao: any;
  pago:any;
  dataDownloadXML: any;
  valorTotal: number;
  dfeValido: boolean;
  producao: boolean;
  modelo: string;
  tipoNFe: string;
  valor: number;
  pasta: FolderDTOResponse;
  valorDesconto: string;
  natOperacao: string;
  valorTotalFormatado: string;
  dataFormatada: string;
  month: string;
  escrituracao: string;
  events: EventsDTOResponse[];
  eventType: string;
  automaticInsertion: boolean;
}
