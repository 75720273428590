import {AddressDTOResponse} from "./AddressDTOResponse";
import {CountryDTOResponse} from "./CountryDTOResponse";
import {Certificado} from "../Certificado";
import { ExplorationTypeProd } from "../enum/ExplorationType";

export class PessoaFiscalDTO {

  id: number;
  cpfCNPJ: string;
  companyId: number;
  cpfCNPJParticipant: string;
  name: string;
  isClient: boolean;
  certificateId: number;
  addressDTORespComp: AddressDTOResponse;
  countryDTORespComp: CountryDTOResponse;
  certificateDTORespComp: Certificado;
  explorationTypeProd: ExplorationTypeProd;
}
