import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductProvider } from 'src/providers/product/product';
import { ErrorUtil } from 'src/util/error';
import { Snackbar } from 'src/util/snackbar';

@Component({
  selector: 'app-cadastro-produtos',
  templateUrl: './cadastro-produtos.component.html',
  styleUrls: ['./cadastro-produtos.component.scss']
})
export class CadastroProdutosComponent implements OnInit {

  editar: boolean = false;
  produtosForm: FormGroup;
  enviado: boolean = false;
  enviando: boolean = false;

  @Input() public produtoExistente;
  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public snackBar: Snackbar,
    public productProvider: ProductProvider,
    public erroUtil: ErrorUtil
  ) { }

  ngOnInit() {
    this.configuracaoForm();
    this.checkEdit();
  }

  configuracaoForm() {
    this.produtosForm = this.fb.group({
      id: [''],
      code: ['', Validators.required],
      description: ['', Validators.required],
      ncm: ['', Validators.required]
    });
  }

  checkEdit() {
    if (this.produtoExistente != undefined) {
      let objProduto = { ...this.produtoExistente };
      this.produtosForm.setValue(objProduto);
    }
  }

  novoProduto() {
    this.enviado = true;
    this.enviando = true;
    if (this.produtosForm.valid) {
      if (!this.editar) {
        this.productProvider.postProduct(this.produtosForm.getRawValue()).then((produto: any) => {
          this.snackBar.openLong("Sucesso! " + produto.description + " inserido com sucesso!", 'success');
          this.activeModal.close(produto);
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          this.snackBar.openLong('Não foi possível inserir! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'grouper'), 'erro');
        });
      } else {
        this.productProvider.putProduct(this.produtosForm.getRawValue()).then((produto: any) => {
          this.snackBar.openLong("Sucesso! " + produto.description + " editado com sucesso!", 'success');
          this.activeModal.close(produto);
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          this.snackBar.openLong('Não foi possível editar! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'grouper'), 'erro');
        });
      }
    } else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

}
