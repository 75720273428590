import { HttpErrorResponse } from "@angular/common/http";
import { PostingProvider } from "../../../../providers/bookkeeping/posting/posting";
import { DocumentsBookkepingMapper } from "../../../../model/dto/mapper/documentsBookkepingMapper";
import { Bookkeeping } from "../../../../model/dto/bookkeping/Bookkeeping";
import { BankDTOResponse } from "../../../../model/dto/BankDTOResponse";
import { Property } from "../../../../model/dto/bookkeping/Property";
import { Injectable } from "@angular/core";

@Injectable()
export class PostingFilter {
  constructor(
    private postingProvider: PostingProvider,
    private documentsBookkepingMapper: DocumentsBookkepingMapper
  ) {}

  private static filterBookeping(
    bookkepings: Bookkeeping[],
    properties: any[],
    bankAccounts: any[]
  ) {

    

    const bookKepingsDeductible = PostingFilter.filterDeductible(bookkepings);
    const bookKepingsPropertiesFiltered = PostingFilter.filterProperties(
      bookKepingsDeductible,
      properties
    );


    const banksFiltered = PostingFilter.filterBankAccountsLCA(
      bookKepingsPropertiesFiltered,
      bankAccounts
    );


    const bookkeepingsBankAccountCashInTransitSet =
      PostingFilter.setBankAccountsCashInTransit(banksFiltered, bankAccounts);
    const apportionmentFiltered = PostingFilter.filterApportionment(
      bookkeepingsBankAccountCashInTransitSet,
      properties
    );
    return apportionmentFiltered;
  }

  private static filterDeductible(bookkepings: Bookkeeping[]) {
    return bookkepings.filter((obj) => obj.postingReleaseType !== "NONE");
  }

  private static setBankAccountsCashInTransit(
    bookkepings: Bookkeeping[],
    bankAccounts: any[]
  ) {
    if (
      bankAccounts[0].numerarioEmTransito !== undefined &&
      bankAccounts[0].numerarioEmTransito !== null
    ) {
      const bankAccountsCashInTransit = bankAccounts.filter(
        (bankAccount) => bankAccount.numerarioEmTransito
      );
      bookkepings.forEach((bookkepping) => {
        if (
          bankAccountsCashInTransit.find(
            (bankAccount) =>
              bookkepping.bankAccount.code === bankAccount.conta.code
          )
        ) {
          bookkepping.bankAccount.code = "999";
          bookkepping.bankAccount.description = "Numerário em trânsito";
        }
      });
    }
    return bookkepings;
  }

  private static filterProperties(
    bookkepings: Bookkeeping[],
    properties: any[]
  ) {
    return bookkepings.filter((obj) =>
      properties.find(
        (property) =>
          property.imovel.propertyCode === obj.doc.property.propertyCode
      )
    );
  }

  private static filterBankAccounts(
    bookkepings: Bookkeeping[],
    bankAccounts: any[]
  ) {
    return bookkepings.filter((obj) =>
      bankAccounts.find(
        (bankAccount: any) =>
          bankAccount !== undefined &&
          obj.bankAccount !== null &&
          bankAccount.conta.id === obj.bankAccount.id
      )
    );
  }

  private static filterBankAccountsLCA(
    bookkepings: Bookkeeping[],
    bankAccounts: any[]
  ) {
    return bookkepings.filter((obj) =>
      bankAccounts.find(
        (bankAccount: any) =>
          bankAccount !== undefined
      )
    );
  }


  private static filterApportionment(
    bookkepings: Bookkeeping[],
    properties: any[]
  ) {
    let saldo = 0;
    bookkepings.forEach((bookkeping: Bookkeeping) => {
      bookkeping.amountPaid =
        bookkeping.amountPaid *
        (PostingFilter.getPercentage(properties, bookkeping) / 100);
      saldo = bookkeping.amountPaid + saldo;
      bookkeping.balance = saldo;
    });
    return bookkepings;
  }

  private static getPercentage(properties, bookkeping) {
    let property = properties.find(
      (property) =>
        bookkeping.doc.property.propertyCode === property.imovel.propertyCode
    );
    const apportionmentPercentage = property.exploradores
      .filter((obj) => obj.apportionment)
      .reduce((total, valor) => total + valor.percentage, 0);
    return (property.porcentagem * 100) / apportionmentPercentage;
  }

  public getPosting(
    period,
    properties: Property[],
    bankAccounts: BankDTOResponse[]
  ) {
    //console.log("entrou no get");
    return new Promise((resolve, reject) => {
      this.postingProvider
        .getAllPosting(period.startDate, period.endDate)
        .then((bookkeepings: Bookkeeping[]) => {
          //console.log("antes filtro", bookkeepings);
          resolve(
            PostingFilter.filterBookeping(
              this.documentsBookkepingMapper.arrayToClientModel(bookkeepings),
              properties,
              bankAccounts
            )
          
          );
        })
        .catch((result: HttpErrorResponse) => {
          console.error(result);
          reject(result);
        });
    });
  }
}
