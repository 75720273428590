import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorUtil} from "../../../../../util/error";
import {Snackbar} from "../../../../../util/snackbar";
import {HttpErrorResponse} from "@angular/common/http";
import {AccountProvider} from "../../../../../providers/account/account";
import {AccountPlanDTOResponse} from "../../../../../model/dto/AccountPlanDTOResponse";

@Component({
  selector: 'app-deletar-categoria',
  templateUrl: './deletar-categoria.component.html',
  styleUrls: ['./deletar-categoria.component.scss']
})
export class DeletarCategoriaComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeletarCategoriaComponent>,
              public accountProvider: AccountProvider,
              public errorUtil: ErrorUtil,
              public  snackbar: Snackbar,
              @Inject(MAT_DIALOG_DATA) public data: AccountPlanDTOResponse) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(): void {
    this.accountProvider.delAccount(this.data.id).then(() => {
      this.dialogRef.close(this.data);
      this.snackbar.openLong('Excluído com sucesso!', 'success');
    }).catch((result: HttpErrorResponse) => {
      console.error(result);
      this.snackbar.openLong('Não foi possível excluir! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'account'), 'erro');
    })
    ;
  }
}
