import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {CadastroPlanoDeContasComponent} from './cadastro-plano-de-contas/cadastro-plano-de-contas.component';
import {AccountProvider} from '../../../../providers/account/account';
import {HttpErrorResponse} from '@angular/common/http';
import {TreeComponent} from 'angular-tree-component';
import {ReleaseType} from '../../../../model/enum/ReleaseType';
import {PdfExportProperties, ExcelExportProperties} from '@syncfusion/ej2-grids';
import {
 ToolbarService, PdfExportService, ExcelExportService
} from '@syncfusion/ej2-angular-treegrid';
// @ts-ignore
import moment from 'moment';
import {ReportsUtil} from "../../../../util/Reports";

export class Nodes {
  name: string;
  id: number;
  customId: number;
  children: Nodes[];
  featureType: string;
  releaseType: any;
  participateRuralActivity: boolean;
  historicId: number;
  participantIds: number[];
  "@type": string;
}

@Component({
  selector: 'app-plano-de-contas.component',
  templateUrl: 'plano-de-contas.component.html',
  styleUrls: ['plano-de-contas.component.scss'],
  providers: [ ToolbarService, ExcelExportService, PdfExportService]

})
export class PlanoDeContasComponent implements OnInit {

  @ViewChild('treeComp', {static: true}) treeComp: TreeComponent;
  @ViewChild('exportDocument', {static: false}) public treeGridObj;

  public nodes: any;
  public rootID: number;
  public customIds: string[] = [];
  public descriptions: string[] = [];
  private bodyPDF: any[];

  public exportExcelProperties: ExcelExportProperties = {
    includeHiddenColumn: true,
    fileName: 'planodecontas' + '.xlsx',
  };

  constructor(public accountProvider: AccountProvider,
              public reportsUtil: ReportsUtil,
              public modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.initicializeData();
  }

  accountToTree(accountComponent) {
    if (accountComponent.description === 'root') {
      let c = (this.printToTree(accountComponent.accountComponents));
      c = this.ordenacaoBolha(c)
      c.forEach(element => {
       element.children = this.ordenacaoBolhaChildren(element.children)
       //if (element.children.children.children){
       // element.children.children.children  = this.ordenacaoBolhaChildren(element.children.children.children)
      // }
      });
      return c
    }
  }

  initicializeData() {
    this.accountProvider.getAllAccounts().then((planoDeContas: any) => {
      this.customIds = [];
      this.descriptions = [];
      this.rootID = planoDeContas.id;
      this.nodes = (this.accountToTree(planoDeContas));
      //console.log(this.nodes)
    }).catch((result: HttpErrorResponse) => {
      console.error(result);
    });
  }
   ordenacaoBolha(vetor) {
    let n = vetor.length;
   // console.log(vetor)
    for (let i = 0; i < n - 1; i++)
      for (let j = 0; j < n - i - 1; j++)
        if (parseInt(vetor[j].name.charAt(0)) > parseInt(vetor[j + 1].name.charAt(0))) {
          // troca temp and vetor[i]
          let temp = vetor[j];
          vetor[j] = vetor[j + 1];
          vetor[j + 1] = temp;
        }
        return vetor
    //Console.log(vetor);
  }
  ordenacaoBolhaChildren(vetor) {
   // //console.log("antes", vetor)
    let n = vetor.length;
    for (let i = 0; i < n - 1; i++)
      for (let j = 0; j < n - i - 1; j++)
        if (parseInt(vetor[j].customId) > parseInt(vetor[j + 1].customId)) {
          // troca temp and vetor[i]
          let temp = vetor[j];
          vetor[j] = vetor[j + 1];
          vetor[j + 1] = temp;
        }
        return vetor
    //Console.log(vetor);
  }
  printToTree(accountComponent): any {
    const nodes = [];
    accountComponent.forEach((account: any) => {
      this.customIds.push(account.customId);
      this.descriptions.push(account.description);
      const node = new Nodes();
      // console.log("ACCONT PRA MAPEAR", account)
      node['@type'] = account['@type'];
      node.name = account.description;
      node.id = account.id;
      node.customId = account.customId;
      node.children = [];
      node.featureType = account.featureType;
      node.participateRuralActivity = account.participateRuralActivity;
      node.historicId = account.historicId;
      node.participantIds = account.participantIds;
      node.releaseType = ReleaseType[account.releaseType];
      if (account.accountComponents !== undefined || account.accountComponents != null) {
        node.children = (this.printToTree(account.accountComponents));
      }
      nodes.push(node);
    });
    return nodes;
  }

  abrirPlanoDeContas(node: any, editar: boolean, root: boolean) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroPlanoDeContasComponent, modalOptions);
    // console.log("previous node", node)
    modalRef.componentInstance.node = node;
    modalRef.componentInstance.editar = editar;
    modalRef.componentInstance.root = root;
    modalRef.componentInstance.customIds = this.customIds;
    modalRef.componentInstance.descriptions = this.descriptions;

    modalRef.result.then((result: any) => {
      if (editar) {
        this.descriptions.push(node.name);
        this.customIds.push(node.customId);
      }
      if (result !== 'cancelou') {
        if (result === 'excluido') {
          this.nodes.forEach((nodeFind, index: number) => {
            if (node.id === nodeFind.id) {
              this.nodes.splice(index, 1);
            }
          });
          this.initicializeData();
        }
        this.initicializeData();
      }

    }, () => {
    });

  }

  exportPDF(){
    const date = moment().format('DD/MM/YY HH:mm').toString();

    let headers = [
      {header: 'Conta', dataKey: 'conta'},
      {header: 'Código reduzido ', dataKey: 'codigo'},
      {header: 'Movimentação', dataKey: 'movimentacao'},
    ];
    let colunas = [
      {dataKey: 'codigo', halign: 'center'},
      {dataKey: 'movimentacao',   halign: 'center'},
    ];

    this.bodyPDF = [];
    this.processBodyPDF(this.nodes);

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Plano de contas`,
        orientacao: 'Portrait',
      },
      cabecalho: {
        titulo: `Plano de Contas`,
        data: date,
        periodo: "",
        parametros: {},
        image: false,
      },
      tabela: {
        colunas: headers,
        dados: this.bodyPDF,
        estiloColunas: colunas,
        hierarquico: true,
      },
    });
  }

  processBodyPDF(data, depth = 0) {
    data.forEach((item) => {
      let tmp = {
        conta: "  ".repeat(depth * 2) + item.name,
        codigo: item.customId,
        movimentacao: item.releaseType,
        depth: depth
      };

      this.bodyPDF.push(tmp);

      if (item.children.length > 0) {
        this.processBodyPDF(item.children,depth + 1);
      }
    });
  }

}

