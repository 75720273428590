import {Component, Inject, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ErrorUtil} from '../../../../../util/error';
import {Snackbar} from '../../../../../util/snackbar';
import {HistoricDTOResponse} from '../../../../../model/dto/HistoricDTOResponse';
import {HistoricProvider} from "../../../../../providers/bookkeeping/historic/historic";

@Component({
  selector: 'app-deletar-historico',
  templateUrl: './deletar-historico.component.html',
  styleUrls: ['./deletar-historico.component.scss']
})
export class DeletarHistoricoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeletarHistoricoComponent>,
              public errorUtil: ErrorUtil,
              public  snackbar: Snackbar,
              private historicProvider: HistoricProvider,
              @Inject(MAT_DIALOG_DATA) public data: HistoricDTOResponse) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close('cancelou');
  }

  onYesClick(): void {
    this.dialogRef.close(this.data);
    this.snackbar.openLong('Excluído com sucesso!', 'success');
    this.historicProvider.delHistoric(this.data.id).then(() => {

    }).catch((reason: HttpErrorResponse) => {
      this.snackbar.openLong('Não foi possível excluir! ' +
        this.errorUtil.checkErrorStatus(reason, reason.status, reason.error, 'historic'), 'erro');
    })
    ;
  }

}
