import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterSettingsModel, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { PostingProvider } from 'src/providers/bookkeeping/posting/posting';
import { Snackbar } from 'src/util/snackbar';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-preview-lancamentos',
  templateUrl: './preview-lancamentos.component.html',
  styleUrls: ['./preview-lancamentos.component.scss']
})
export class PreviewLancamentosComponent implements OnInit {

  @ViewChild('grid', { static: false }) previewGrid;

  public filterSettings: FilterSettingsModel;
  public selectionSettings: SelectionSettingsModel;
  public customAttributes: any;
  public keyword: string = null;
  public filter: any;
  public producerId = null;
  public competenceMonth = null;
  public importModel = null;
  public dataSource = null;
  public loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public postingProvider: PostingProvider,
    public snackBar: Snackbar,
  ) { }

  ngOnInit() {
    this.tableConfiguration();
  }

  tableConfiguration() {
    this.customAttributes = { class: 'customcss' };
    this.filterSettings = { type: 'Menu' };
    this.filter = { type: 'CheckBox' };
    this.selectionSettings = { persistSelection: false, type: 'Multiple', checkboxOnly: true };
    this.previewGrid.autoFitColumns([]);
    this.previewGrid.refreshHeader();
  }

  submitForm() {
    Swal.fire({
      title: 'Tem certeza que deseja escriturar estes lançamentos?',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, escriturar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        // this.postingProvider.importPayroll(this.dataSource, this.importModel, this.producerId, this.competenceMonth).then((result) => {
        //   this.loading = false;
        //   this.snackBar.open('Lançamentos escriturados com sucesso!', 'sucesso');
        //   this.activeModal.close({ operation: 'success' });
        // }).catch((error) => {
        //   this.loading = false;
        //   this.snackBar.open('Erro ao escriturar lançamentos. ' + error.error.error_description, 'erro');
        // })
      }
    });
  }

}
