import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Bookkeeping } from "../bookkeping/Bookkeeping";
import { docOfx, docOfxConsulta, OfxConsulta, OfxResponse, PostingConsulta, PostingResponse } from "../bookkeping/Ofx";
import {BrMaskerIonicServices3} from "brmasker-ionic-3";
import {DocType} from "../../enum/DocType";
import { UserProvider } from "src/providers/user/user";
import { Account } from "../bookkeping/Account";
import { BankDTOResponse } from "../BankDTOResponse";

@Injectable()
export class OfxMapper {

  constructor(
    public brMasker: BrMaskerIonicServices3,
    public UserProvider: UserProvider,) {
  }

  public arrayToClientModel(apiModel: OfxResponse[]): OfxConsulta[] {
    let documentos: OfxConsulta[] = [];

    apiModel.forEach((resultBack) => {
      documentos.push(this.serverModelToClientModel(resultBack));
    });

    return documentos;
  }

  public serverModelToClientModel(resultBack: OfxResponse): OfxConsulta {
    try {
      // tslint:disable-next-line: prefer-const      
      let ofx = new OfxConsulta();
      ofx.id           = resultBack.transactionId;
      ofx.companyId    = resultBack.ofx.companyId;
      ofx.userId       = resultBack.ofx.userId;
      ofx.bankId       = resultBack.ofx.bankId;
      ofx.accountId    = resultBack.ofx.accountId;
      ofx.ofxId        = resultBack.ofx.id;
      ofx.filename     = resultBack.ofx.fileName;
      ofx.dataUpload   = (moment(resultBack.ofx.dataUpload).format());
      ofx.isDeleted    = resultBack.ofx.isDeleted;
      ofx.deletedBy    = resultBack.ofx.deletedBy;
      ofx.deletedDate  = (resultBack.ofx.deletedDate) ? (moment(resultBack.ofx.deletedDate).format()) : null;
      ofx.value        = resultBack.valueTransaction;
      ofx.date         = (moment(resultBack.dateTransaction).format('DD/MM/YYYY'));
      ofx.status       = (resultBack.postings.length == 0) ? 'A conciliar' : 'Conciliado';
      ofx.description  = resultBack.historicMemoTransaction;
      ofx.type         = resultBack.typeTransaction;
      ofx.fitid        = resultBack.fitidTransaction;
      ofx.postings     = this.arrayPostingsToClientModel(resultBack.postings);
      ofx.sugestion    = {};
      
      return ofx;
    } catch (e) {
      //console.log(e);
    }
  }

  public arrayPostingsToClientModel(postings: PostingResponse[]): PostingConsulta[] {
    let posting: PostingConsulta[] = [];

    postings.forEach((resultBack) => {
      posting.push(this.postingToClientModel(resultBack));
    });

    return posting;
  }

  public postingToClientModel(resultBack: PostingResponse): PostingConsulta {
    try {
      // tslint:disable-next-line: prefer-const
      let posting = new PostingConsulta();
      posting.id          = resultBack.idPosting;
      posting.value       = resultBack.amountPaidPosting;
      posting.description = resultBack.historicPosting;
      posting.paymentDate = (moment(resultBack.paymentDate).format('DD/MM/YYYY'));

      return posting;
    } catch (e) {
      //console.log(e);
    }
  }

  public arrayDocToClientModel(apiModel: docOfx[]): docOfxConsulta[] {
    let documentos: docOfxConsulta[] = [];
    
    apiModel.forEach((resultBack) => {
      documentos.push(this.docToClientModel(resultBack));
    });
    
    return documentos;
  }

  public docToClientModel(resultBack: docOfx): docOfxConsulta {
    try {
      // tslint:disable-next-line: prefer-const
      let ofx = new docOfxConsulta();
      ofx.id           = resultBack.id;
      ofx.companyId    = resultBack.companyId;
      ofx.userId       = resultBack.userId;
      ofx.bankId       = resultBack.bankId;
      ofx.accountId    = resultBack.accountId;
      ofx.filename     = resultBack.fileName;
      ofx.data         = moment(resultBack.dataUpload).format("DD/MM/YYYY");
      ofx.isDeleted    = resultBack.isDeleted;
      ofx.deletedBy    = resultBack.deletedBy;
      ofx.deletedDate  = (resultBack.deletedDate) ? (moment(resultBack.deletedDate).format()) : null;
      return ofx
    } catch (e) {
      //console.log(e);
    }
  }

  public arraySugestionsToClientModel(apiModel): Suggestion[] {
    let documentos: Suggestion[] = [];

    apiModel.forEach((resultBack) => {
      documentos.push(this.serverSugestionsModelToClientModel(resultBack));
    });

    return documentos;
  }

  public serverSugestionsModelToClientModel(apiModel): Suggestion {
    try {
      // tslint:disable-next-line: prefer-const
      let lancamento = new Suggestion();

      lancamento.amountPaid          = apiModel.amountPaid;
      lancamento.paymentDate         = (moment(apiModel.paymentDate).format('DD/MM/YYYY'));
      lancamento.account             = apiModel.account;
      lancamento.account.description = apiModel.account.description.toUpperCase();
      lancamento.bankAccount         = apiModel.bankAccount;
      lancamento.id                  = apiModel.postingId;
      lancamento.transactionId       = apiModel.transactionId;
      lancamento.historic            = apiModel.historic;
      // lancamento.account = apiModel.account;
      return lancamento; 
    } catch (error) {
      console.error('', error);
    }
  }

  formatarValor(valorTotal: string) {
    return this.brMasker.writeValueMoney(valorTotal.toString(), {
      decimal: 2,
      decimalCaracter: ",",
      len: 0,
      mask: "",
      numberAndTousand: false,
      percent: false,
      person: false,
      phone: false,
      thousand: ".",
      type: undefined,
      userCaracters: false,
      money: true
    });
  }
}

export class Suggestion {
  id: number;
  transactionId: number;
  amountPaid: number;
  historic: string;
  paymentDate: any;
  account: {
    id: number;
    description: string;
  };
  bankAccount: {
    id: number;
    code: number;
    description: string;
  };
}
