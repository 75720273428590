import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ExplorationProvider} from "../../../../../../providers/company/exploration/exploration";
import {Snackbar} from "../../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../../util/error";
import {ExplorationTypeName, ExplorationTypeProdName} from "../../../../../../model/enum/ExplorationType";

@Component({
  selector: 'app-exploracao-por-imovel',
  templateUrl: './exploracao-por-imovel.component.html',
  styleUrls: ['./exploracao-por-imovel.component.scss']
})
export class ExploracaoPorImovelComponent implements OnInit {
  @Input() public propertyCode;
  public exploration

  constructor(public activeModal: NgbActiveModal,
              private explorationProvider: ExplorationProvider,
              private snackbar: Snackbar,
              private errorUtil: ErrorUtil,
  ) {
  }

  ngOnInit() {
    this.getExploration()
  }

  getExploration() {
    this.explorationProvider.getByProperty(this.propertyCode).then((exploration: []) => {
      this.exploration = exploration;
    }).catch((result) => {
      this.snackbar.openLong('Erro ao carregar exploração! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'property'), 'erro');
    });
  }

  editProperty() {
    this.activeModal.close('editProperty');
  }

  getExplorationTypeName(type) {
    return ExplorationTypeName[type].charAt(0).toUpperCase() + ExplorationTypeName[type].toLowerCase().slice(1);
  }

  getExplorationTypeProdName(type) {
    return ExplorationTypeProdName[type].charAt(0).toUpperCase() + ExplorationTypeProdName[type].toLowerCase().slice(1);
  }


}
