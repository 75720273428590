export enum ReleaseType {
  NONE,
  REVENUE_RURAL_ACTIVITY,
  INVESTMENT_COST_EXPENSES,
  PRODUCTS_REVENUE_YEAR_ADVANCES_FINANCIAL_RESOURCES

}

export enum ReleaseTypeText {
  NONE = 'SEM MOVIMENTAÇÃO FISCAL',
  REVENUE_RURAL_ACTIVITY = 'RECEITA DA ATIVIDADE RURAL',
  INVESTMENT_COST_EXPENSES = 'DESPESA DE CUSTEIO E INVESTIMENTO',
  PRODUCTS_REVENUE_YEAR_ADVANCES_FINANCIAL_RESOURCES = 'RECEITA DE PRODUTOS ENTREGUES NO ANO REFERENTE A ADIAMENTO DE RECURSOS FINANCEIROS'
}

export enum ReleaseTypeString{
  NONE = "0",
  REVENUE_RURAL_ACTIVITY = "1",
  INVESTMENT_COST_EXPENSES = "2",
  PRODUCTS_REVENUE_YEAR_ADVANCES_FINANCIAL_RESOURCES = "3"
}
