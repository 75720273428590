import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {HistoricDTOResponse} from "../../../model/dto/HistoricDTOResponse";

@Injectable()
export class HistoricProvider {

  apiBase: string = "/bookkeeping/v1/historic/";

  public showLastEntries: boolean;

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAllHistorics() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
          resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getHistoricById(id) {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + id,{headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: HistoricDTOResponse) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  getHistoricByPlannedPayment(id){
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin +'/bookkeeping/v1/posting/historic-by-planned-payment/'+ localStorage.getItem('idEmpresa')+"/"+ id,{headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: HistoricDTOResponse) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postHistoric(historic) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBase, historic, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: HistoricDTOResponse) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putHistoric(historic) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase, historic, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: HistoricDTOResponse) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delHistoric(id) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiBase + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  setLastEntries(value: boolean) {
    this.showLastEntries = value;
  }

  getLastEntries(){
    return this.showLastEntries;
  }

}
