import {Component, Injectable, OnInit} from "@angular/core";
import {DateUtil} from "../../../../util/dateUtil";
import {Snackbar} from "../../../../util/snackbar";
import {ErrorUtil} from "../../../../util/error";
import {Chart} from 'chart.js';

@Component({
  selector: 'app-dashboard-charts',
  template: ``
})

@Injectable()
export class AnnualCharts implements OnInit {

  public charts = [];
  public chartData: any = {};

  public graphExpanded: boolean = false;
  public hidden: boolean = true;

  public loadingGraf1: boolean = false;
  public loadingGraf2: boolean = false;

  public isForExport: boolean = false;
  public exportando: boolean = false;

  public byAccountAux: any = [];
  public byAccount: any = [];

  public meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  public mesesExtensos = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  public anos = []

  constructor(public dateUtil: DateUtil,
              public snackBar: Snackbar,
              public errorUtil: ErrorUtil,) {
  }

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  generate() {
    this.loadingGraf1 = true;
    this.loadingGraf2 = true;
    this.exportando = true;
    this.graphExpanded = false;
    this.byAccount = [];

    this.formatByAccount();

    if (this.byAccount == null || this.byAccount.length <= 0) {
      this.loadingGraf1 = false;
      this.loadingGraf2 = false;
      this.exportando = false;
      this.hidden = true;
      return;
    }
    this.chartData = this.setChartData(this.byAccount);
    this.generateCharts();

    this.loadingGraf1 = false;
    this.loadingGraf2 = false;
    this.exportando = false;

    if (this.byAccount != null && this.byAccount.length > 0) {
      this.graphExpanded = true;
      this.hidden = false;
    }
  }

  private formatByAccount() {
    if (this.byAccountAux == null) return;
    this.byAccountAux.forEach(element => {
      if (element != null) {
        let value = this.filtrarFolhas(element);
        if (value != null && value.length > 0) {
          this.byAccount = [...this.byAccount, ...value];
        }
      }
    });

    this.byAccount.sort((a, b) => {
      if (+a.customId < +b.customId) {
        return -1;
      }
      if (+a.customId > +b.customId) {
        return 1;
      }
      return 0;
    });
  }

  filtrarFolhas(node: any) {
    if (node == null) return [];
    if (node.tipo === "Leaf") {
      return [node];
    }

    if (node.children) {
      return node.children.reduce((acc, child) => {
        const result = this.filtrarFolhas(child);
        if (result.length > 0) {
          return acc.concat(result);
        }
        return acc;
      }, []);
    }

    return [];
  }

  destroyCharts() {
    if (this.charts.length > 0) {
      this.charts.forEach((chart) => {
        chart.destroy();
      })
    }
    document.getElementById('divChart0').style.height = "0px";
    document.getElementById('divChart1').style.height = "0px";
  }

  setChartData(byAccount) {
    let byAccountChart = {
      receitas1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      receitas2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      despesas1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      despesas2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    }
    if (byAccount.length > 0) {
      byAccount = byAccount.filter((element) => {
        return element.description != 'TOTAL'
      });
      byAccount.sort((a, b) => (Math.abs(a.total) < Math.abs(b.total)) ? 1 : ((Math.abs(b.total) < Math.abs(a.total)) ? -1 : 0));
      byAccount.forEach((element) => {
        for (let i = 0; i < 12; i++) {
          if (element.meses1[i] != null) {
            if (element.meses1[i] >= 0) byAccountChart.receitas1[i] += Math.abs(element.meses1[i]);
            else byAccountChart.despesas1[i] += Math.abs(element.meses1[i]);
          }
          if (element.meses2[i] != null) {
            if (element.meses2[i] >= 0) byAccountChart.receitas2[i] += Math.abs(element.meses2[i]);
            else byAccountChart.despesas2[i] += Math.abs(element.meses2[i]);
          }

        }
      });
    }
    return byAccountChart;
  }

  // geração random de cor baseado em quantos itens o gráfico vai ter
  generateColors(value) {
    let pallete = [
      `rgba(28,132,198,0.78)`,
      `rgba(59,214,152,0.84)`,
      `rgba(255,211,79,1)`,
      `rgba(237,85,101,0.93)`,
      `rgba(187,107,217,0.85)`,
      `rgba(242,152,73,0.95)`,
      `rgba(109,146,227,0.89)`,
      `rgba(111,63,170,0.67)`,
      `rgba(25,197,148,0.7)`,
      `rgba(167,166,214,0.84)`,
    ]

    return pallete;
  }

  generateCharts() {
    this.destroyCharts();

    let height = this.isForExport ? "400px" : "300px";
    document.getElementById('divChart0').style.height = height;
    document.getElementById('divChart1').style.height = height;

    let chartBackgroundColors = this.generateColors(4);

    let anos = this.anos;
    let meses = this.mesesExtensos;

    this.charts[0] = new Chart('myChart0', {
      type: 'bar',
      data: {
        labels: this.meses,
        datasets: [{
          label: anos[0],
          data: this.chartData.receitas1,
          backgroundColor: chartBackgroundColors[0],
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 0.1,
        }, {
          label: anos[1],
          data: this.chartData.receitas2,
          backgroundColor: chartBackgroundColors[1],
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 0.1,
        }]
      },
      options: {
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom'
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value, index, values) {
                return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
              }
            }
          }],
          y: {
            beginAtZero: true,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: function (tooltipItems, data) {
              return meses[tooltipItems[0].index] + ' - ' + anos[tooltipItems[0].datasetIndex];
            },
            label: function (tooltipitems, data) {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(data.datasets[tooltipitems.datasetIndex].data[tooltipitems.index]);
            }
          }
        }
      }
    });

    this.charts[1] = new Chart('myChart1', {
      type: 'bar',
      data: {
        labels: this.meses,
        datasets: [{
          label: this.anos[0],
          data: this.chartData.despesas1,
          backgroundColor: chartBackgroundColors[2],
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 0.1,
        }, {
          label: this.anos[1],
          data: this.chartData.despesas2,
          backgroundColor: chartBackgroundColors[3],
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 0.1,
        }],
      },
      options: {
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom'
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value, index, values) {
                return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
              }
            }
          }],
          y: {
            beginAtZero: true,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: function (tooltipItems, data) {
              return meses[tooltipItems[0].index] + ' - ' + anos[tooltipItems[0].datasetIndex];
            },
            label: function (tooltipitems, data) {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(data.datasets[tooltipitems.datasetIndex].data[tooltipitems.index]);
            }
          }
        }
      }
    });
  }
}
