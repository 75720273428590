import {DfeDTOResponse} from '../dfe/DfeDTOResponse';
import {ConsultaDfe, ConsultaDfe2} from '../../ConsultaDfe';
import {PessoaMapper} from './pessoaMapper';
import {Injectable} from '@angular/core';
import {DuplicatesMapper} from './duplicatesMapper';
import {BrMaskerIonicServices3} from 'brmasker-ionic-3';
import * as moment from 'moment';

@Injectable()
export class DfeMapper {

  constructor(public pessoaMapper: PessoaMapper,
              public duplicatesMapper: DuplicatesMapper,
              public brMasker: BrMaskerIonicServices3) {
  }

  public arrayToClientModel(apiModel: any,type?) {
  //  //console.log("apimodeeeeel", apiModel);
    const dfes = [];
    if(type !== true){
       apiModel.forEach((resultBack) => {
      if (this.serverModelToClientModel(resultBack).modelo !== 'mdfe') {
        dfes.push(this.serverModelToClientModel(resultBack));
      }
    });
    }else{
      apiModel.forEach((resultBack) => {
        if (this.serverModelToClientModel2(resultBack).modelo !== 'mdfe') {
          dfes.push(this.serverModelToClientModel2(resultBack));
        }
      });
    }


    return dfes;

  }

  public serverModelToClientModel2(apiModel: any): ConsultaDfe2 {
    //console.log("KKKKKKKKKKKKK",apiModel)
    const dfe = new ConsultaDfe2(); // your Dfe constructor
    const cancelado = apiModel.dfeInfo.isCanceled
    const denegada = apiModel.dfeInfo.isDenied
    // const correcao = apiModel.events.filter(x => x.code === '110110').length > 0;
    // const autorizado = apiModel.events.length > 0 && !cancelado && !correcao ? true : false;
    //-----------------------------------------------------------------------------------------------------------------------//console.log("apimodel", apiModel);
    if (apiModel.modelType !== 'mdfe') {
      // map each property
      //console.log("1")
      dfe.id = apiModel.id;
     dfe.parcelaId= apiModel.dfeInfo.id
      //console.log("1id")
      dfe.chaveId = apiModel.dfeInfo.key;//console.log("key")
      dfe.numberParcela = apiModel.number;
      dfe.numeroDoc = apiModel.dfeInfo.dfeNumber;//console.log("dfeNumber")
      dfe.dataEmissao = apiModel.dfeInfo.issueDate;//console.log("issueDate")
      dfe.dataExpiracao = apiModel.expiration
      dfe.dataFormatada = moment(apiModel.expiration).format('DD/M/YY');//console.log("issueDate", dfe.dataFormatada)
      dfe.pago = apiModel.isPaid
      if(dfe.pago == true){
        dfe.pagoFormatado = 'ESCRITURADO'
      }else{
        dfe.pagoFormatado = 'EM ABERTO'

      }

      dfe.dataDownloadXML = apiModel.dfeInfo.downloadXMLDate;//console.log("downloadXMLDate")
     dfe.natOperacao = apiModel.dfeInfo.operationNature.toUpperCase();//console.log("operationNature")
      dfe.dataFormatada = moment(apiModel.expiration).format('DD/M/YY');//console.log("issueDate", dfe.dataFormatada)
      dfe.valorTotal = +apiModel.value;//console.log("amount")
      dfe.valorDFE = this.formatarValor(apiModel.dfeInfo.amount);

      dfe.tipoNFe = apiModel.dfeInfo.nfeType;//console.log("nfeType")
      dfe.producao = apiModel.dfeInfo.production;//console.log("production")
      // dfe.duplicatas = this.duplicatesMapper.arrayToClientModel(apiModel.duplicates);
      dfe.valorDesconto = apiModel.dfeInfo.discountValue;//console.log("discountValue")
      //dfe.emitente = this.pessoaMapper.serverModelToClientModel(apiModel.dfeInfo.issuer);//console.log("issuer")
      //dfe.destinatario = this.pessoaMapper.serverModelToClientModel(apiModel.dfeInfo.addressee);//console.log("addressee")
      dfe.pasta = apiModel.dfeInfo.folder;//console.log("folder")
      dfe.automaticInsertion = apiModel.dfeInfo.automaticInsertion;//console.log("automaticInsertion")
       dfe.valorTotalFormatado = this.formatarValor(apiModel.value);//console.log("valorTotalFormatado")
      dfe.dfeValido = apiModel.dfeInfo.dfeValid;//console.log("dfeValid")

      dfe.emitente = this.pessoaMapper.serverModelToClientModel(apiModel.dfeInfo.issuer);
      dfe.destinatario = this.pessoaMapper.serverModelToClientModel(apiModel.dfeInfo.addressee);

        dfe.modelo = apiModel.dfeInfo.key.charAt(20) === '6' && apiModel.dfeInfo.key.charAt(21) === '5' ? 'nfce' : apiModel.dfeInfo.modelType;//console.log("modelo")
      //erro
     //  dfe.pagamentoPlanejado = this.duplicatesMapper.arrayToClientModel(apiModel.dfeInfo.plannedPayments);//console.log("plannedPayments")
     // dfe.escrituracao = this.checkEscrituracao(dfe.pagamentoPlanejado);//console.log("pagamentoPlanejado")
      //dfe.events = apiModel.events;//console.log("events")
      if (cancelado) {
        dfe.eventType = 'Cancelado';//console.log("eventType")
      }
      else if (denegada) {
        dfe.eventType = 'Denegada';
      }
      // else if (correcao) {
      //   dfe.eventType = 'Carta de correção';
      // } else if (autorizado) {
      //   dfe.eventType = 'Autorizado';
      // }
    } else {
      dfe.modelo = 'mdfe';
    }
    //console.log("--------------------------------------DFE", dfe,'-----------------------------------')
    return dfe; // and return YOUR model

  }

  public serverModelToClientModel(apiModel: DfeDTOResponse): ConsultaDfe {

    const dfe = new ConsultaDfe(); // your Dfe constructor
    const cancelado = apiModel.isCanceled
    const denegada = apiModel.isDenied
    // const correcao = apiModel.events.filter(x => x.code === '110110').length > 0;
    // const autorizado = apiModel.events.length > 0 && !cancelado && !correcao ? true : false;

    //console.log("apimodel", apiModel);

    if (apiModel.modelType !== 'mdfe') {
      // map each property
      dfe.id = apiModel.id;
      dfe.chaveId = apiModel.key;
      dfe.numeroDoc = apiModel.dfeNumber;
      dfe.dataEmissao = apiModel.issueDate;
      dfe.dataDownloadXML = apiModel.downloadXMLDate;
      dfe.dataFormatada = moment(apiModel.issueDate).format('DD/M/YY');//console.log("issueDate", dfe.dataFormatada)
      dfe.valorTotal = +apiModel.amount;
      dfe.valorTotalFormatado = this.formatarValor(apiModel.amount);
      dfe.dfeValido = apiModel.dfeValid;
      dfe.modelo = apiModel.key.charAt(20) === '6' && apiModel.key.charAt(21) === '5' ? 'nfce' : apiModel.modelType;
      dfe.tipoNFe = apiModel.nfeType;
      dfe.producao = apiModel.production;
      // dfe.duplicatas = this.duplicatesMapper.arrayToClientModel(apiModel.duplicates);
      dfe.pagamentoPlanejado = this.duplicatesMapper.arrayToClientModel(apiModel.plannedPayments);
      dfe.valorDesconto = apiModel.discountValue;

      dfe.emitente = this.pessoaMapper.serverModelToClientModel(apiModel.issuer);
      dfe.destinatario = this.pessoaMapper.serverModelToClientModel(apiModel.addressee);
      //console.log("apimodel.operationnature", apiModel)
      dfe.natOperacao = apiModel.modelType != 'nfse' ? apiModel.operationNature.toUpperCase() : (apiModel.serviceDescription ? apiModel.serviceDescription.toUpperCase() : '');
      //console.log("file: dfeMapper.ts:138 ~ DfeMapper ~ serverModelToClientModel ~ dfe.natOperacao:", dfe.natOperacao)
      dfe.pasta = apiModel.folder;
      dfe.escrituracao = this.checkEscrituracao(dfe.pagamentoPlanejado);
      dfe.events = apiModel.events;
      dfe.automaticInsertion = apiModel.automaticInsertion;
      if (cancelado) {
        dfe.eventType = 'Cancelado';
      }
      if (denegada) {
        dfe.eventType = 'Denegada';
      }
      // else if (correcao) {
      //   dfe.eventType = 'Carta de correção';
      // } else if (autorizado) {
      //   dfe.eventType = 'Autorizado';
      // }

    } else {
      dfe.modelo = 'mdfe';
    }
    //console.log("--------------------------------------DFE", dfe,'-----------------------------------')

    return dfe; // and return YOUR model

  }


  checkEscrituracao(duplicatas): string {
    let quantidadeEscriturada = 0;
    duplicatas.forEach((duplicata) => {
      if (duplicata !== undefined) {
        if (duplicata.escriturada) {
          quantidadeEscriturada++;
        }
      }
    });
    if (quantidadeEscriturada === duplicatas.length) {
      return 'TOTAL';
    } else if (duplicatas.length > quantidadeEscriturada && quantidadeEscriturada !== 0) {
      return 'PARCIAL';
    } else if (quantidadeEscriturada === 0) {
      return 'NÃO ESCRITURADA';
    }
  }

formatarValor(valorTotal: string) {
    return this.brMasker.writeValueMoney(valorTotal.toString(), {
      decimal: 2,
      decimalCaracter: ',',
      len: 0,
      mask: '',
      numberAndTousand: false,
      percent: false,
      person: false,
      phone: false,
      thousand: '.',
      type: undefined,
      userCaracters: false,
      money: true
    });
  }

}

