import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthProvider} from "../../../../providers/auth/authProvider";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HistoricDTOResponse} from "../../../../model/dto/HistoricDTOResponse";
import {Snackbar} from "../../../../util/snackbar";

@Component({
  selector: 'app-dialog-password-confirmation',
  templateUrl: './dialog-password-confirmation.component.html',
  styleUrls: ['./dialog-password-confirmation.component.scss']
})
export class DialogPasswordConfirmationComponent implements OnInit {

  formPassword: FormGroup;
  sending: boolean;

  constructor(public dialogRef: MatDialogRef<DialogPasswordConfirmationComponent>,
              private authProvider: AuthProvider,
              public formBuilder: FormBuilder,
              private snackbar: Snackbar,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.formPassword = this.formBuilder.group({
      password: [null, Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.sending = true;
    this.authProvider.revalidateLogin(this.formPassword.value.password).then(value => {
      this.sending = false;
      this.dialogRef.close(true);
    }).catch(reason => {
      this.snackbar.openLong('Não foi possível validar seu usuário!', 'erro');
      this.sending = false;
    });
  }

}
