import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment.prod";
import { Injectable } from "@angular/core";

@Injectable()
export class StockProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  apiBase: string = "/main-records/v1/stock-post/";

  getAllLancamentos(before, after, id) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase + localStorage.getItem('idEmpresa') + '/' + before + '/' + after + '/' + id, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  postLancamento(obj) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiBase + obj.companyId, obj, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  postImportacao(obj) {
    //console.log(obj)
    const formData = new FormData();
    formData.append('file', obj)
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiBase + "import/" +  localStorage.getItem('idEmpresa'), formData, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }


  putLancamento(obj) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiBase + obj.companyId, obj, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  deleteLancamento(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiBase + localStorage.getItem('idEmpresa') + '/' + id, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  getRelatorioEstoque(obj) {
    let str = '';
    if (obj.memberCpfCnpj) str = `?cpfCnpj=${obj.memberCpfCnpj}`;

    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}stock-report/${localStorage.getItem('idEmpresa')}/${obj.startDate}/${obj.endDate}${str}`, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }


}
