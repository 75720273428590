import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Snackbar} from "../../../../../util/snackbar";
import {ParticipantProvider} from "../../../../../providers/participant/participant";
import {PessoaFiscalDTO} from "../../../../../model/dto/PessoaFiscalDTO";
import {ErrorUtil} from "../../../../../util/error";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-deletar-pessoa',
  templateUrl: './deletar-pessoa.component.html',
  styleUrls: ['./deletar-pessoa.component.scss']
})
export class DeletarPessoaComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeletarPessoaComponent>,
              public participanteProvider: ParticipantProvider,
              public errorUtil: ErrorUtil,
              public  snackbar: Snackbar,
              @Inject(MAT_DIALOG_DATA) public data: PessoaFiscalDTO) { }

  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(): void {
    this.participanteProvider.delParticipant(this.data.id).then(() => {
      this.dialogRef.close(this.data);
      this.snackbar.openLong('Excluído com sucesso!', 'success');
    }).catch((reason: HttpErrorResponse) => {
      this.snackbar.openLong('Não foi possível excluir! ' + this.errorUtil.checkErrorStatus(reason, reason.status, reason.error, 'participant'), 'erro');
    })
    ;
  }

}
