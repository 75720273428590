import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {PostingProvider} from "../../../../../providers/bookkeeping/posting/posting";
import {Snackbar} from "../../../../../util/snackbar";
import {ModalExportComponent} from "../../../relatorios/modal-export/modal-export.component";
import * as moment from "moment";
import {ReportsUtil} from "../../../../../util/Reports";
import Swal from "sweetalert2";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-modal-salvar',
  templateUrl: './modal-ir.component.html',
  styleUrls: ['./modal-ir.component.scss']
})
export class ModalIRComponent implements OnInit {

  public loading: boolean = false;
  public simulations = [];
  public page = 0;
  public last: boolean = false;



  constructor(public activeModal: NgbActiveModal,
              public snackBar: Snackbar,
              public reportsUtil: ReportsUtil,
              public modalService: NgbModal,
              public provider: PostingProvider,) {
  }

  ngOnInit() {
    this.getByPage();
  }

  getByPage() {
    // console.log("page: " + this.page);
    this.loading = true;
    this.provider.getPaged(this.page).then((result: any) => {
      if (result.length !== 0) {
        let tmp = result.map((sim) => ({
          id: sim.id,
          simulation: sim.simulation,
          params: JSON.parse(sim.params),
          createdAt: moment(sim.createdAt).format('DD/MM/YYYY HH:mm'),
          createdBy: sim.createdBy,
        }));
        this.simulations = this.simulations.concat(tmp);
        // console.log(this.simulations);
        this.page++;
      }
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      console.error(error);
      this.snackBar.open('Erro ao buscar simulações: ' + error.error.error_description, 'erro');
    });
  }

  onScroll(index: number) {
    // console.log("index: " + index);
    // a cada 10 itens, carrega mais
    // é 8 pois aparece no mínimo 2 itens por vez
    if (index.toString().endsWith('8')) this.getByPage();
  }

  formatCpfCnpj(arr: string) {
    return arr.length === 11 ? arr.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : arr.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  formatCurrency(value) {
    return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
  }

  formatArray(arr: any) {
    return arr.join(", ");
  }


  toolbarClick(obj: any): void {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : '',
    };
    const modalRef = this.modalService.open(ModalExportComponent, modalOptions);
    modalRef.componentInstance.relatorio = 'simulador-ir';
    modalRef.componentInstance.texto = "as opções de apuração";
    modalRef.result.then((result: any) => {
      if (result.type === 'pdf') {
        this.exportPDF(result.img, obj);
      }
    });
  }

  exportPDF(img, obj) {
    let valores = [];

    let tax = JSON.parse(obj.simulation);
    let params = obj.params;
    valores.push({
      titulo: '20% da receita bruta',
      subtitulo: 'Dedução Legal',
      valores: tax.complete20,
      meiaPagina: true,
      melhor: tax.complete20.isMinor,
    }, {
      titulo: '20% da receita bruta',
      subtitulo: 'Simplificada',
      valores: tax.simple20,
      meiaPagina: true,
      melhor: tax.simple20.isMinor,
    }, {
      titulo: 'Livro Caixa',
      subtitulo: 'Dedução Legal',
      valores: tax.completeBook,
      meiaPagina: true,
      melhor: tax.completeBook.isMinor,
    }, {
      titulo: 'Livro Caixa',
      subtitulo: 'Simplificada',
      valores: tax.simpleBook,
      meiaPagina: true,
      melhor: tax.simpleBook.isMinor,
    });

    let produtores = this.formatCpfCnpj(params.memberCpfCnpj);

    this.reportsUtil.exportPDFGrid({
      doc: {
        arquivo: `Simulacao de IR`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Simulação de IR ${params.year.toString()}`,
        data: `Gerado em: ${obj.createdAt}`,
        periodo: params.year.toString(),
        parametros: {
          "Produtor": produtores,
        },
        image: img,
      },
      dados: valores,
    });
  }

  useThis(simulation: any) {
    this.activeModal.close(simulation);
  }

  deleteSimulacao(row) {
    Swal.fire({
      title: "<strong>Excluir simulação?</strong>",
      icon: "warning",
      html: "Essa ação não poderá ser desfeita!",
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      confirmButtonText: "Excluir",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.provider.deleteSimulation(row.id).then((resp: any) => {
          this.loading = false;
          this.snackBar.openLong('Simulação excluída com sucesso!', 'success');
          this.simulations = [];
          this.page = 0;
          this.getByPage();
        }).catch((result: HttpErrorResponse) => {
          this.loading = false;
          this.snackBar.open('Erro ao excluir simulação: ' + result.error.error_description, 'erro');
        });
      }
    });
  }
}
