export class AutomationsConfig {

  actionList() {
    return [
      {
        action: "MOVE_CANCELLED_DFE_TO_FOLDER",
        name: "Mover notas canceladas para pasta",
        description: "lerelerelere",
        conditions: []
      },
      {
        action: "MOVE_DFE_TO_FOLDER",
        name: "Mover notas para pasta",
        conditions: this.dfeConditionList()
      }];
  }

  dfeConditionList() {
    return [
      {
        name: "Denegada",
        operators: {isDenied: "="},
        inputType: "boolean"
      },
      {
        name: "Emitente - CPF/CNPJ",
        operators: {issuerCpfCnpjs: "=", notIssuerCpfCnpjs: "≠"},
        inputType: "text-multiple"
      },
      {
        name: "Emitente - IE",
        operators: {issuerIes: "=", notIssuerIes: "≠"},
        inputType: "text-multiple"
      },
      {
        name: "Destinatário - CPF/CNPJ",
        operators: {addressedCpfCnpjs: "=", notAddressedCpfCnpjs: "≠"},
        inputType: "text-multiple"
      },
      {
        name: "Destinatário - IE",
        operators: {addressedIes: "=", notAddressedIes: "≠"},
        inputType: "text-multiple"
      },
      {
        name: "CFOP",
        operators: {cfops: "="},
        inputType: "text-multiple"
      }
    ];
  }
}
