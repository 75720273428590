import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {HttpErrorResponse} from "@angular/common/http";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {PostingProvider} from "../../../../../providers/bookkeeping/posting/posting";
import Swal from 'sweetalert2'
import { CheckPermission } from 'src/service/checkPermission';

@Component({
  selector: 'app-deletar-lancamentos',
  templateUrl: './deletar-lancamentos.component.html',
  styleUrls: ['./deletar-lancamentos.component.scss']
})
export class DeletarLancamentosComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeletarLancamentosComponent>,
              public errorUtil: ErrorUtil,
              public postingProvider: PostingProvider,
              public snackBar: Snackbar,
              public checkPermission: CheckPermission,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(): void {
    this.deletaLancamento(this.data);
  }

  deletaLancamento(lancamentos: any[]) {
    let postings = lancamentos;
    this.postingProvider.delPosting(lancamentos[0].id).then(() => {
      postings.splice(0, 1);
      if (postings.length != 0) {
        this.deletaLancamento(postings);
      }else{
        this.snackBar.openLong('Lançamentos removidos com sucesso!', 'success');
        this.dialogRef.close(true);
      }
    }).catch((result: HttpErrorResponse) => {
      if(result.error.error_description == "There is a blocked period") {
        Swal.fire({
          title: 'Atenção!', 
          text: 'Há lançamentos cadastrados dentro de um bloqueio de período. ' + 
                 (this.checkPermission.isAccountantAdmin() ? 
                 'Remova o bloqueio para poder remover uma escrituração com um lançamento nesta data.' :
                 'Contate um administrador para que o bloqueio seja removido.'),
          icon: 'error'
        });
      }
      this.snackBar.openLong('Erro ao remover o lançamento ID: ' + lancamentos[0].id + '! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'erro');
      this.dialogRef.close("cancelou");
    });
  }
}
