import {Component, Injectable, OnInit} from "@angular/core";
import {DateUtil} from "../../../../util/dateUtil";
import {Snackbar} from "../../../../util/snackbar";
import {ErrorUtil} from "../../../../util/error";
import {Chart} from 'chart.js';

@Component({
  selector: 'app-members-charts',
  template: ``
})

@Injectable()
export class MembersCharts implements OnInit {

  public charts = [];
  public doubleChartData: any = {};
  public expanded: boolean = false;
  public hidden: boolean = true;
  public loadingGraf1: boolean = false;
  public loadingGraf2: boolean = false;
  public isForExport: boolean = false;
  public loading: boolean = false;

  public aux: any = [];
  public byParticipant: any = [];

  public links = ['Receita', 'Despesa'];
  public activeLink = this.links[0];
  public accounts = [];

  public mesesExtensos = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];


  constructor(public dateUtil: DateUtil,
              public snackBar: Snackbar,
              public errorUtil: ErrorUtil,) {
  }

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  start() {
    this.aux = [];
    this.byParticipant = [];
    this.hidden = true;
    this.expanded = false;
  }

  generate() {
    this.byParticipant = [];

    this.formatData();
    this.destroyCharts();

    if (this.byParticipant == null || this.byParticipant.length <= 0) {
      this.loadingGraf1 = false;
      this.loadingGraf2 = false;
      this.loading = false;
      this.hidden = true;
    } else {
      this.doubleChartData = this.setChartsData(this.byParticipant);
      this.createCharts();

      this.loadingGraf1 = false;
      this.loadingGraf2 = false;
      this.expanded = true;
      this.loading = false;
      this.hidden = false;
    }
  }

  private formatData() {
    if (this.aux == null) return;
    let tmp: Array<{ id: number, name: string, incoming: number, outlay: number }> = [];
    let max = 0;
    let min = 0;
    this.aux.forEach(element => {
      tmp.push({
        id: element.participantId,
        name: element.name,
        incoming: element.incoming,
        outlay: element.outlay
      });
      max += element.incoming;
      min += element.outlay;
    });

    if (tmp.length > 0) {
      this.byParticipant = tmp;
    }
  }

  changeTab(tab: 'Receita' | 'Despesa') {
    this.activeLink = tab;
    this.loadingGraf1 = true;
    this.loadingGraf2 = true;

    this.doubleChartData = this.setChartsData(this.byParticipant);
    this.destroyCharts();
    this.createCharts();

    this.loadingGraf1 = false;
    this.loadingGraf2 = false;

    if (this.byParticipant != null && this.byParticipant.length > 0) {
      this.hidden = false;
    }
  }

  setChartsData(byAccount: Array<{ id: number, name: string, incoming: number, outlay: number }>) {
    let byAccountChart = {
      labels: [],
      labels2: [],
      pie: [],
      bar: []
    }
    if (byAccount.length > 0) {
      byAccount = byAccount.filter((element) => {
        if (this.activeLink == 'Receita') {
          return element.incoming != 0
        }
        if (this.activeLink == 'Despesa') {
          return element.outlay != 0
        }
      });
      if (this.activeLink == 'Receita') {
        byAccount.sort((a, b) => (Math.abs(a.incoming) < Math.abs(b.incoming)) ? 1 : ((Math.abs(b.incoming) < Math.abs(a.incoming)) ? -1 : 0));
      } else if (this.activeLink == 'Despesa') {
        byAccount.sort((a, b) => (Math.abs(a.outlay) < Math.abs(b.outlay)) ? 1 : ((Math.abs(b.outlay) < Math.abs(a.outlay)) ? -1 : 0));
      }

      if (byAccount.length > 10) {
        let byAccountAux = [];
        byAccountAux = byAccount.slice(10, byAccount.length);
        byAccount = byAccount.slice(0, 9);
        byAccount.push({
          id: 69,
          name: 'OUTROS',
          incoming: byAccountAux.reduce((acc, item) => acc + item.incoming, 0),
          outlay: byAccountAux.reduce((acc, item) => acc + item.outlay, 0)
        });
      }
      let sum = 0;
      let pie, bar;
      if (this.activeLink == 'Receita') {
        byAccount.forEach(a => sum += Math.abs(a.incoming));
        pie = byAccount.map(a => Math.abs((a.incoming / sum * 100)).toFixed(2));
        bar = byAccount.map(a => Math.abs(a.incoming));
      } else if (this.activeLink == 'Despesa') {
        byAccount.forEach(a => sum += Math.abs(a.outlay));
        pie = byAccount.map(a => Math.abs((a.outlay / sum * 100)).toFixed(2))
        bar = byAccount.map(a => Math.abs(a.outlay));
      }
      byAccountChart = {
        labels: byAccount.map(a => a.name),
        labels2: byAccount.map(a => a.name.split(' ')[0]),
        pie: pie,
        bar: bar
      }
    }
    return byAccountChart;
  }

  createCharts() {
    let height = this.isForExport ? "400px" : "300px";
    document.getElementById('divChart0').style.height = height;
    document.getElementById('divChart1').style.height = height;

    let chartBackgroundColors = this.generateColors();
    let valores = this.doubleChartData.bar;
    this.charts[0] = new Chart('myChart0', {
      type: 'pie',
      showTooltips: true,
      data: {
        labels: this.doubleChartData.labels,
        datasets: [
          {
            data: this.doubleChartData.pie,
            backgroundColor: chartBackgroundColors,
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 0.1,
            suffix: "%",
            polyline: {
              color: "gray",
              labelColor: "gray",
              formatter: (value) => `${value}`
            }
          },
        ]
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        rotation: this.isForExport ? 160 : 0,
        showAllTooltips: true,
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: function (tooltipItems) {
              return labels[tooltipItems[0].index];
            },
            label: function (tooltipitems, data) {
              return `${data.datasets[0].data[tooltipitems.index]}% (${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(valores[tooltipitems.index])})`;
            }
          }
        }
      },
    });

    let labels = this.doubleChartData.labels;
    this.charts[1] = new Chart('myChart1', {
      type: 'bar',
      data: {
        labels: this.doubleChartData.labels2,
        datasets: [{
          data: this.doubleChartData.bar,
          backgroundColor: chartBackgroundColors,
          borderColor: 'rgba(0, 0, 0, 1)',
          borderWidth: 0.1,
        }]
      },
      options: {
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value, index, values) {
                return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
              }
            }
          }],
          y: {
            beginAtZero: true,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: function (tooltipItems) {
              return labels[tooltipItems[0].index];
            },
            label: function (tooltipitems, data) {
              return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(data.datasets[0].data[tooltipitems.index]);
            }
          }
        }
      }
    });
  }

  destroyCharts() {
    if (this.charts.length > 0) {
      this.charts[0].destroy();
      this.charts[1].destroy();
    }
    document.getElementById('divChart0').style.height = "0px";
    document.getElementById('divChart1').style.height = "0px";
  }

  generateColors() {
    return [
      `rgba(153, 102, 255, 1)`,
      `rgba(255, 211, 79, 1)`,
      `rgba(28, 132, 198, 0.78)`,
      `rgba(187, 107, 217, 0.85)`,
      `rgba(242, 152, 73, 0.95)`,
      `rgba(109, 146, 227, 0.89)`,
      `rgba(111, 63, 170, 0.67)`,
      `rgba(237, 85, 101, 0.93)`,
      `rgba(59, 214, 152, 0.84)`,
      `rgba(54, 162, 235, 0.8)`,
      `rgba(167, 166, 214, 0.84)`,
      `rgba(255, 99, 132, 0.8)`,
      `rgba(75, 192, 192, 0.8)`,
      `rgba(25, 197, 148, 0.7)`,
      `rgb(255, 234, 0)`,
    ];
  }
}
