import {Injectable} from "@angular/core";
import * as moment from "moment";
import {DateUtil} from "./dateUtil";
import {NavigationEnd, Router} from "@angular/router";
@Injectable()
export class ConsultDateUtil {

  // data de inicio e fim do periodo
  public startDate: any;
  public endDate: any;

  // objeto de retorno para as requisições
  public consultDate: ConsultDate = new ConsultDate();

  // mes atual
  public month: any;
  // ano atual
  public year: any = +moment().format('YYYY');

  // anos para o select
  public years: any;
  // meses para o select
  public months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

  // periodo mensal ou anual
  public monthly = true;

  constructor(private router: Router, public dateUtil: DateUtil) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loadConsultDateUtil();
      }
    });
  }

  loadConsultDateUtil() {
    let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
    this.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('year').format();
    this.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('year').format();
    this.month = this.months[+moment.tz(workDate, 'America/Sao_Paulo').format('MM') - 1];
    this.year = +moment.tz(workDate, 'America/Sao_Paulo').format('YYYY');
    this.years = this.getYears(+moment().format('YYYY') - 20);
    this.monthly = true;
  }

  // retorna a data para a requisição
  getDateForRequest(firstRequest: boolean): ConsultDate {
    if (this.monthly) {
      if (firstRequest) { // primeira requisição
        let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
        this.consultDate.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
        this.consultDate.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
      } else { // select mensal
        const month = this.getMonth(this.month);
        const date = this.year + '-' + month + '-' + '01';
        this.consultDate.startDate = moment.tz(date, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
        this.consultDate.endDate = moment.tz(date, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
      }
    } else { // select personalizado
      this.consultDate.startDate = moment(this.startDate).locale('pt-BR').format('YYYY-MM-DD');
      this.consultDate.endDate = moment(this.endDate).locale('pt-BR').format('YYYY-MM-DD');
    }

    return this.consultDate;
  }

  // transforma o mes em numero
  getMonth(mes) {
    switch (mes) {
      case 'JAN':
        mes = '01';
        break;
      case 'FEV':
        mes = '02';
        break;
      case 'MAR':
        mes = '03';
        break;
      case 'ABR':
        mes = '04';
        break;
      case 'MAI':
        mes = '05';
        break;
      case 'JUN':
        mes = '06';
        break;
      case 'JUL':
        mes = '07';
        break;
      case 'AGO':
        mes = '08';
        break;
      case 'SET':
        mes = '09';
        break;
      case 'OUT':
        mes = '10';
        break;
      case 'NOV':
        mes = '11';
        break;
      case 'DEZ':
        mes = '12';
        break;
      default:
        mes = '01';
    }
    return mes;
  }

  // retorna os anos para o select
  getYears(startYear) {
    const currentYear = new Date().getFullYear() + 1;
    const years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  }

  // altera o ano a partir do select
  changeYear(type: string) {
    if (type === '+') {
      this.year = +this.year + 1;
    } else {
      this.year = +this.year - 1;
    }
    let existe = 0;
    this.years.forEach((ano: number) => {
      if (this.year === ano) {
        existe++;
      }
    });
    if (existe === 0) {
      this.years.push(this.year);
      this.years.sort(
        function (b, a) {
          return a < b ? -1 : a > b ? 1 : 0;
        });
    }
  }

  // funções dos relatórios

  // altera o mes dos coisos mensal
  chosenMonthHandler(mes: any) {
    this.month = mes;
  }

  // altera o ano dos coisos anual e mensal
  chosenYearHandler(ano: any) {
    this.year = ano;
  }

  // altera a data inicial de tudo que tem o periodo personalizado
  changePeriodStart(data) {
    if (data.value != null && this.startDate != null && this.endDate != null) {
      // tslint:disable-next-line: deprecation
      this.startDate = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
    }
  }

  // altera a data final de tudo que tem o periodo personalizado
  changePeriodEnd(data) {
    if (data.value != null && this.startDate != null && this.endDate != null) {
      // tslint:disable-next-line: deprecation
      this.endDate = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
    }
  }
}


class ConsultDate {
  endDate: any;
  startDate: any;
}

