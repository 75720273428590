import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { CadastroExportacoesComponent } from 'src/app/routes/cadastros/exportacoes/cadastro-exportacoes/cadastro-exportacoes.component';
import { CadastroLayoutsComponent } from 'src/app/routes/cadastros/exportacoes/cadastro-layouts/cadastro-layouts.component';
import { ExportProvider } from 'src/providers/export/export';
import { ExportUtil } from 'src/util/exportUtil';
import { Snackbar } from 'src/util/snackbar';
import { ReleaseType } from 'src/model/enum/ReleaseType';
import { PostingProvider } from 'src/providers/bookkeeping/posting/posting';

@Component({
  selector: 'app-exportar-lancamentos',
  templateUrl: './exportar-lancamentos.component.html',
  styleUrls: ['./exportar-lancamentos.component.scss']
})
export class ExportarLancamentosComponent implements OnInit {
  @ViewChild('grid', { static: false }) grid: GridComponent;
  
  public enviado: boolean = false;
  public enviando: boolean = false;
  public loading: boolean = false;

  public exportPostingsForm: FormGroup;
  public selectedLayout: any = null;
  public exportUtil = new ExportUtil();

  public dataSourceMain: any[] = this.exportUtil.ExportLayoutGridDummyData;
  public previewGridColumns: any = [];


  public exports: any[] = [];
  public layouts: any[] = [];
  public filters: any = null;

  constructor(
    public exportProvider: ExportProvider,
    public snackBar: Snackbar,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public postingProvider: PostingProvider
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.initializeData();
  }

  initializeForm() {
    this.exportPostingsForm = new FormGroup({
      exportId: new FormControl(null, Validators.required),
      layoutId: new FormControl(null, Validators.required),
      extension: new FormControl(null, Validators.required),
      withHeader: new FormControl(false, Validators.required),
    });
  }

  initializeData() {
    this.loading = true;
    this.selectedLayout = null;
    this.previewGridColumns = [];
    // this.grid.refreshColumns();
    Promise.all([this.exportProvider.getExports(), this.exportProvider.getLayouts()]).then(([exportsData, layoutsData]: any) => {
      this.exports = exportsData;
      this.layouts = layoutsData;
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      this.snackBar.openLong('Erro ao buscar exportações ' + error.error.error_description, 'erro');
    });

  }

  updatePreviewGridColumns() {
    if (this.exportPostingsForm.value.layoutId == undefined) {
      return;
    }
    this.loading = true;
    this.exportProvider.getLayout(this.exportPostingsForm.value.layoutId).then((layout: any) => {
      this.loading = false;
      this.selectedLayout = layout;
      this.previewGridColumns = [];
      Object.keys(this.selectedLayout.columnOrder).forEach((key) => {
        this.selectedLayout.columnExport[this.selectedLayout.columnOrder[key]] ? this.previewGridColumns.push(this.exportUtil.ExportLayoutGridColumns.find((column) => (column.field == this.selectedLayout.columnOrder[key]))) : '';
      });
      this.grid.refreshColumns();
    }).catch((error) => {
      this.loading = false;
      this.snackBar.openLong('Erro ao buscar layout ' + error.error.error_description, 'erro');
    })
  }

  createOrEditExport(exportacao?: any) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroExportacoesComponent, modalOptions);
    if (exportacao) {
      modalRef.componentInstance.exportacaoExistente = exportacao;
      modalRef.componentInstance.editar = true;
    }
    modalRef.result.then((result: any) => {
      if (result !== 'cancelou') {
        this.initializeData();
      }
    }, () => {
    });
  }

  createOrEditLayout(obj?: any) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : '',
      size: 'xl'
    };
    const modalRef = this.modalService.open(CadastroLayoutsComponent, modalOptions);
    if (obj) {
      modalRef.componentInstance.layoutExistente = { id: obj.id };
      modalRef.componentInstance.editar = true;
    }
    modalRef.result.then((result: any) => {
      if (result !== 'cancelou') {
        this.initializeData();
      }
    }, () => {
    });
  }

  setExportPostingsReqObj() {
    let reqObj = {
      ...this.exportPostingsForm.value,
      ...this.filters.filters
    }
    return reqObj;
  }

  submitForm() {
    if (!this.exportPostingsForm.valid) {
      this.snackBar.openLong('Preencha todos os campos obrigatórios!', 'erro');
      return;
    }
    this.loading = true;
    this.postingProvider.exportPostings(this.setExportPostingsReqObj(), this.filters.startDate, this.filters.endDate).then((result: any) => {
      this.loading = false;
      const src = `data:text/${(this.exportPostingsForm.controls.extension.value == "txt" ? 'plain' : 'csv')};base64,${result}`;
      const link = document.createElement("a")
      link.href = src
      link.download = `lancamentos_exportados.${this.exportPostingsForm.controls.extension.value}`
      link.click()
      this.snackBar.openLong('Lançamentos exportados com sucesso!', 'success');
    }).catch((error:any) => {
      this.loading = false;
      this.snackBar.openLong('Erro ao exportar lançamentos ' + JSON.parse(error.error).error_description, 'erro');
    }
    );
  }
}
