export class Pessoa {
  id: number;
  lcDigital: boolean;
  lastLogin: string;
  name: string;
  lastName: string;
  cpf: string;
  hide: boolean;
  cnpj: string;
  email: string;
  nome: string;
  cnpjCpf: string;
  accountId: string;
  telefone: string;
  ie: string;
  office: {
    id: number;
    name: string;
  };
  companyIds: number[];
  roles: any[];
}
