import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-cadastro-certificado',
  templateUrl: './cadastro-certificado.component.html',
  styleUrls: ['./cadastro-certificado.component.scss']
})
export class CadastroCertificadoComponent implements OnInit {
  senha: string;
  panelExpanded: boolean = true;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.panelExpanded = true;
  }

  inserir(){
    this.activeModal.close(this.senha);
  }

}
