import {Injectable} from "@angular/core";


@Injectable()
export class exportIRPFUtil {

  constructor() {
  }

  ngOnInit() {
  }

  static mapData(source, data) {
    switch (source) {
      case "SIMPLIFIED_REPORT":
        return this.mapSimplifiedReportData(data);
      case "LC":
        return this.mapLC(data);
    }
  }

  static mapSimplifiedReportData(data: { revenue: Record<string, number>; expense: Record<string, number>; }) {
    const mapper = (entries: Record<string, number>) =>
      Object.keys(entries).map(month => Math.abs(entries[month] || 0).toFixed(2).replace('.', ''));
    return {
      revenue: mapper(data.revenue),
      expense: mapper(data.expense),
    };
  }

  static mapLC(data) {
    const mapper = (key) =>
      Array.from({length: 12}, (_, i) => {
        const monthKey = `${(i + 1).toString().padStart(2, '0')} - ${new Date(2020, i).toLocaleString('pt-BR', {month: 'long'}).toUpperCase()}`;
        const month = data.find(m => m.key === monthKey);
        return month ? Math.abs(month[key]).toFixed(2).replace('.', '') : '000';
      });

    return {
      revenue: mapper('incoming'),
      expense: mapper('outlay')
    };
  }


static yearValuesIRPF(year) {
  const versions = {
    '2023': { version: 110, name: 'AAAAA' + ' '.repeat(55) },
    '2022': { version: 100, name: ' '.repeat(60) },
    '2021': { version: 110, name: ' '.repeat(60) },
  };
  return versions[year] || { version: 110, name: ' '.repeat(60) };
}


  static generateDataIRPF(source, data, ano, cpf) {
    const mappedData = this.mapData(source, data);
    const yearValues = this.yearValuesIRPF(ano);
    const header = `IRARURAL  ${(+ano + 1).toString()}${ano}${cpf}${yearValues.version}\n`;
    const subHeader = `01${cpf}${yearValues.name}${ano}${' '.repeat(128)}\n`;
    const section = `02105REAL${' '.repeat(26)}\n`;

    let stringIRPF = header + subHeader + section;

    for (let i = 0; i < 12; i++) {
      let letter = String.fromCharCode(97 + i).toUpperCase();
      let receita = '0'.repeat(13 - mappedData.revenue[i].length) + mappedData.revenue[i];
      let despesa = '0'.repeat(13 - mappedData.expense[i].length) + mappedData.expense[i];
      stringIRPF += `04${letter}${receita}${despesa}${'0'.repeat(39)}105\n`;
    }
    stringIRPF += this.calcCRC(stringIRPF);
    return stringIRPF;
  }

  static calcCRC(input: string) {
    let crc = 0;
    const lines = input.split(/\r?\n/);
    lines.forEach(line => {
      line = line.replace(/\r/g, '').replace(/\n/g, '');
      if (!line || line.startsWith('99')) {
        return;
      }
      crc = this.crc32(line, crc);
    });
    return `99${crc.toString()}\n`;
  }

  static crc32(str: string, crc: number) {
    const table = new Uint32Array(256).map((_, k) => {
      let c = k;
      for (let j = 0; j < 8; j++) {
        c = (c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1);
      }
      return c;
    });
    crc = crc ^ (-1);
    for (let i = 0; i < str.length; i++) {
      const byte = str.charCodeAt(i);
      crc = (crc >>> 8) ^ table[(crc ^ byte) & 0xFF];
    }
    return (crc ^ (-1)) >>> 0;
  }

  static exportFileIRPF(data, filename) {
    const blob = new Blob([data], {type: 'text/plain'});
    const link = document.createElement('a');
    link.download = filename + '.DEC';
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }
}
