export class OfxConsulta {
  id: number;
  companyId: number;
  userId: number;
  bankId: number;
  accountId: string;
  ofxId: number;
  filename: string;
  dataUpload: string;
  isDeleted: boolean;
  deletedBy:  boolean;
  deletedDate:  string;
  value: number;
  textValue: string;
  date: string;
  status: string;
  type: string;
  description: string;
  fitid: string;
  postings: PostingConsulta[];
  sugestion: any;
}

export class PostingConsulta {
  id: number;
  value: number;
  description: string;
  paymentDate: string;
}

export class OfxResponse {
  transactionId: number;
  dateTransaction: string;
  valueTransaction: number;
  historicMemoTransaction: string;
  typeTransaction: string;
  transactionPosition: number;
  fitidTransaction: string;
  ofx: docOfx;
  postings: PostingResponse[];
}

export class docOfxConsulta{
  id: number;
  companyId: number;
  userId: number;
  bankId: number;
  accountId: string;
  filename: string;
  data: string;
  isDeleted: boolean;
  deletedBy:  boolean;
  deletedDate:  string;
}

export class docOfx {
  id: number;
  companyId: number;
  userId: number;
  bankId: number;
  accountId: string;
  fileName: string;
  dataUpload: string;
  isDeleted: boolean;
  deletedBy:  boolean;
  deletedDate:  string;
}

export class PostingResponse {
  idPosting: number;
  amountPaidPosting: number;
  historicPosting: string;
  paymentDate: string;
}