import {LayoutComponent} from '../layout/layout.component';
import {LoginComponent} from './pages/login/login.component';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';
import {AuthGuardService as AuthGuard} from '../../service/auth-guard';
import {LayoutContadorComponent} from '../layoutContador/layout.component';
import {LayoutConsultorComponent} from '../layoutConsultor/layout.component';
import {EscrituracaoComponent} from './consultor/escrituracao/escrituracao.component';
import {MonitorFiscalComponent} from './consultor/monitor-fiscal/monitor-fiscal.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {AutomacoesComponent} from "./automacoes/automacoes.component";

export const routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      {path: '', redirectTo: 'minhas-propriedades', pathMatch: 'full'},
      {path: 'cadastros', loadChildren: () => import('./cadastros/cadastros.module').then(m => m.CadastrosModule)},
      {
        path: 'monitor-fiscal',
        loadChildren: () => import('./documentosFiscais/documentosFiscais.module').then(m => m.DocumentosFiscaisModule)
      },
      {
        path: 'conciliacao-bancaria',
        loadChildren: () => import('./conciliacao/conciliacao.module').then(m => m.ConciliacaoModule)
      },
      {
        path: 'escrituracao',
        loadChildren: () => import('./financeiro/financeiro.module').then(m => m.FinanceiroModule)
      },
      {path: 'relatorios', loadChildren: () => import('./relatorios/relatorios.module').then(m => m.RelatoriosModule)},
      {
        path: 'minhas-propriedades',
        loadChildren: () => import('./minhasPropriedades/minhasPropriedades.module').then(m => m.MinhasPropriedadesModule)
      },
      {
        path: 'livro-caixa',
        loadChildren: () => import('./livro-caixa/gerar-livro-caixa.module').then(m => m.GerarLivroCaixaModule)
      },
      {path: 'automacoes', component:  AutomacoesComponent}
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutContadorComponent,
    children: [
      {path: '', redirectTo: 'contador', pathMatch: 'full'},
      {path: 'contador', loadChildren: () => import('./contador/contador.module').then(m => m.ContadorModule)},
      {
        path: 'reintegrar',
        loadChildren: () =>
          import('./reintegrarDocumentosFiscais/reintegrar-documentos-fiscais.module').then(m => m.ReintegrarDocumentosFiscaisModule)
      },
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LayoutConsultorComponent,
    children: [
      {path: '', redirectTo: 'escrituracao-consultor', pathMatch: 'full'},
      {path: 'consultor', loadChildren: () => import('./consultor/consultor.module').then(m => m.ConsultorModule)},
      {path: 'escrituracao-consultor', component: EscrituracaoComponent},
      {path: 'monitor-fiscal-consultor', component: MonitorFiscalComponent},
    ]
  },

  // // Not lazy-loaded routes
  {path: 'login', component: LoginComponent},
  {path: 'reset-password/:token', component: ResetPasswordComponent},
  {path: 'reset-password-new/:token', component: ResetPasswordComponent},
  // {path: 'register', component: RegisterComponent},
  // {path: 'recover', component: RecoverComponent},
  // {path: 'lock', component: LockComponent},
  {path: 'manutencao', component: MaintenanceComponent},
  // {path: '404', component: Error404Component},
  // {path: '500', component: Error500Component},

  // Not found
  {path: '**', redirectTo: 'login'}

];
