import {CountryDTOResponse} from "./CountryDTOResponse";
import {BankDTOResponse} from "./BankDTOResponse";

export class ContaBancariaDTO {
  id: number;
  description: string;
  bankId: number
  code: string;
  countryId: number;
  agencyWithoutDV: string;
  accountNumberWithDV: string;
  bankDTORespComp: BankDTOResponse;
  countryDTORespComp: CountryDTOResponse;
  default: boolean;
}
