import { Component,  OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Snackbar } from 'src/util/snackbar';
import { ErrorUtil } from 'src/util/error';
import Swal from 'sweetalert2';
import { SelectionSettingsModel } from '@syncfusion/ej2-angular-dropdowns';
import { FilterSettingsModel, GridComponent, GridModel } from '@syncfusion/ej2-angular-grids';
import { OFXProvider } from 'src/providers/bookkeeping/ofx/ofx';
import { DateAdapter } from '@angular/material';

@Component({
  selector: 'app-modal-file',
  templateUrl: './modal-file.component.html',
  styleUrls: ['./modal-file.component.scss']
})

export class ModalFileComponent implements OnInit {
  
  @ViewChild('grid', { static: false }) grid: GridComponent;

  public data: any;
  public filterSettings: FilterSettingsModel;
  public selectionSettings: SelectionSettingsModel;
  public filter: { type: string };
  public customAttributes: any;
  public subLinhasGrid: GridModel;
  public subLinhasGridAux: GridModel;
  public sublinhas: any[] = [];
  public carregando: boolean;
  
  constructor(public modalService: NgbModal,
    public errorUtil: ErrorUtil,
    private _adapter: DateAdapter<any>,
    private ofxProvider: OFXProvider,
    public snackBar: Snackbar,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.tableConfiguration();
  }

  tableConfiguration() {
    this._adapter.setLocale('pt-BR');
    this.customAttributes = { class: 'customcss' };
    this.filterSettings = { type: 'Menu' };
    this.filter = { type: 'CheckBox' };
    // this.selectionSettings = { persistSelection: false, type: 'Multiple', checkboxOnly: true };
  }

  formataValor(value: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  }

  deleteFile(id: number) {
    this.carregando = true;

    Swal.fire({
      title: 'Tem certeza que deseja excluir o arquivo?',
      text: 'Você não poderá reverter essa ação!',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.ofxProvider.deleteFile(id).then((value: any) => {
          Swal.fire({
            title: 'Sucesso ao excluir o arquivo OFX!',
            icon: 'success'
          }).then(() => {
            this.carregando = false;
            this.activeModal.close(true);
          });
        }).catch((error: any) => {
          let msg = '';
          if (error.status == 500 && error.error.error_description.includes('postings linked')) {
            msg = 'Não foi possível excluir o arquivo OFX, pois ele está sendo utilizado em uma conciliação.';
          }
          if (error.status == 406 && error.error.error_description.includes('vinculadas')) {
            msg = error.error.error_description;
          }
          Swal.fire({
            title: 'Erro ao excluir o arquivo OFX!',
            html: msg,
            icon: 'error'
            }).then(() => {
              this.carregando = false;
            });
        });
      }
    });
  }

}
