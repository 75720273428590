import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Snackbar} from '../../../../../util/snackbar';
import {ErrorUtil} from '../../../../../util/error';
import {MatChipInputEvent} from '@angular/material/chips';
import {map, startWith} from 'rxjs/operators';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER, SPACE} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {HistoricDTOResponse} from '../../../../../model/dto/HistoricDTOResponse';
import {HistoricProvider} from "../../../../../providers/bookkeeping/historic/historic";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-cadastro-historico',
  templateUrl: './cadastro-historico.component.html'
})
export class CadastroHistoricoComponent implements OnInit {

  @ViewChild('historicInput', {static: false}) historicInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoHistoric', {static: false}) matAutocompleteHistoric: MatAutocomplete;
  @Input() public existingHistoric: HistoricDTOResponse;

  public historic: HistoricDTOResponse = new HistoricDTOResponse();
  public editMode = false;
  public sentForm = false;
  public sendingForm;
  public historicForm: FormGroup;
  public separatorKeysCodesHistoric: number[] = [ENTER, SPACE, COMMA];
  public historicCtrl = new FormControl();
  public filteredHistoric: Observable<string[]>;
  public historics: string[] = [];
  public allHistorics: string[] = ['@participante@','@participante-CpfCnpj@', '@numero-documento@', '@conta@', '@mes@', '@mes-anterior@','@mes-e-ano-anterior@', '@ano@', '@ano-anterior@'];

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              private historicProvider: HistoricProvider,
              public snackBar: Snackbar,
              public errorUtil: ErrorUtil) {
  }

  ngOnInit() {
    this.formConfiguration();
    this.initializeSearchAutoCompleteHistoric();
    this.checkIfIsEditMode();
  }

  initializeSearchAutoCompleteHistoric() {
    this.filteredHistoric = this.historicCtrl.valueChanges.pipe(
      startWith(null),
      map((historic: string | null) => historic ? this._filterHistoric(historic) : this.allHistorics.slice()));
  }

  formConfiguration() {
    this.historicForm = this.fb.group({
      id: [''],
      code: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],
      model: ['', Validators.compose([Validators.required])]
    });
  }

  checkIfIsEditMode() {
    if (this.existingHistoric !== undefined) {
      this.editMode = true;
      this.historics = this.existingHistoric.model.split('#');
      this.historics.forEach((strings, index) => {
        if (strings.trim() === '') {
          this.historics.splice(index, 1);
        }
      });
      const objHistoric = {...this.existingHistoric};
      this.historicForm.setValue(objHistoric);
    }
  }

  setObjectEdit(historic: HistoricDTOResponse) {
    this.existingHistoric.model = historic.model;
    this.existingHistoric.id = historic.id;
    this.existingHistoric.description = historic.description;
    this.existingHistoric.code = historic.code;
  }

  newOrEditHistoric() {
    this.sentForm = true;
    this.sendingForm = true;
    this.historicForm.controls.model.setValue(this.genereteHistoric());
    if (this.historicForm.valid) {
      if (!this.editMode) {
        this.historicProvider.postHistoric(this.historicForm.getRawValue()).then((historico: HistoricDTOResponse) => {
          this.snackBar.openLong('Sucesso! ' + historico.description + ' inserido com sucesso!', 'success');
          this.activeModal.close(historico);
        }).catch((result: HttpErrorResponse) => {
          this.sendingForm = false;
          this.snackBar.openLong('Não foi possível inserir! ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'historic'), 'erro');
        });
      } else {
        this.historicProvider.putHistoric(this.historicForm.getRawValue()).then((historico: HistoricDTOResponse) => {
          this.setObjectEdit(historico);
          this.snackBar.openLong('Sucesso! ' + historico.description + ' editado com sucesso!', 'success');
          this.activeModal.close(historico);
        }).catch((result: HttpErrorResponse) => {
          this.sendingForm = false;
          this.snackBar.openLong('Não foi possível editar! ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'historic'), 'erro');
        });
      }
    } else {
      this.sendingForm = false;
      this.snackBar.openLong('Há erros no formulário. Confira antes de tentar enviar novamente!', 'erro');
    }
  }

  addHistoric(event): void {
    const input = event.input;
    const value = event.value;

    // Add our historics
    if ((value || '').trim()) {
      this.historics.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.historicCtrl.setValue(null);
  }

  removeHistoric(historic: string): void {
    const index = this.historics.indexOf(historic);

    if (index >= 0) {
      this.historics.splice(index, 1);
    }
  }

  insertHistoricList(description) {
    // console.log(description);
    // console.log(this.allHistorics);
    this.allHistorics.push(description);
    this.filteredHistoric = this.historicCtrl.valueChanges.pipe(
      startWith(null),
      map((historic: string | null) => historic ? this._filterHistoric(historic) : this.allHistorics.slice()));
  }

  selectedHistoric(event: MatAutocompleteSelectedEvent): void {
    this.historics.push(event.option.viewValue);
    this.historicInput.nativeElement.value = '';
    this.historicCtrl.setValue(null);
  }

  private _filterHistoric(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allHistorics.filter(historic => historic.toLowerCase().indexOf(filterValue) === 0);
  }

  genereteHistoric(): string {
    if (this.historics.length !== 0) {
      let historic = '';
      this.historics.forEach(option => {
        if (this.isChip(option)) {
          historic = historic + ' ' + '#' + option.trim()  + '#';
        } else {
            historic = historic + ' ' + option.trim();
        }
      });
      return  historic;
    }
  }

  addOnBlur(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {input: this.historicInput.nativeElement, value : this.historicInput.nativeElement.value};
      this.addHistoric(matChipEvent);
    }
  }

  isChip(historic: string): boolean {
    return this.allHistorics.findIndex(val => val === historic) >= 0;
  }
}

