import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {HttpErrorResponse} from "@angular/common/http";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {PostingProvider} from "../../../../../providers/bookkeeping/posting/posting";
import {BrMaskerIonicServices3} from "brmasker-ionic-3";

@Component({
  selector: 'app-deletar-lancamento',
  templateUrl: './deletar-lancamento.component.html',
  styleUrls: ['./deletar-lancamento.component.scss']
})
export class DeletarLancamentoComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeletarLancamentoComponent>,
              public errorUtil: ErrorUtil,
              public brMasker: BrMaskerIonicServices3,
              public postingProvider: PostingProvider,
              public  snackbar: Snackbar,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  formatarValor(valorTotal: string) {
    return this.brMasker.writeValueMoney(valorTotal.toString(), {
      decimal: 2,
      decimalCaracter: ",",
      len: 0,
      mask: "",
      numberAndTousand: false,
      percent: false,
      person: false,
      phone: false,
      thousand: ".",
      type: undefined,
      userCaracters: false,
      money: true
    });
  }



  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(): void {
    this.postingProvider.delPosting(this.data.id).then(() => {
      this.snackbar.open('Lançamento apagado com sucesso!', 'success');
      this.dialogRef.close(true);
    }).catch((result: HttpErrorResponse) => {
      this.snackbar.snackBar.open('Não foi possível apagar o lançamento. ' + this.data.descricao + ' no valor de ' + this.data.valor.toFixed(2) + '.' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'erro');
      this.dialogRef.close("cancelou");
    });
  }

}
