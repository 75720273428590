import {Component, Injectable} from "@angular/core";
import {Chart} from 'chart.js';
import {DateUtil} from "../../../../../util/dateUtil";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {Financiamento} from "../../../../../model/Financiamento";
import {ParcelasFinanciamento} from "../../../../../model/Parcelas-Financiamento";
import ChartDataLabels from "chartjs-plugin-datalabels";

@Component({
  selector: 'app-financing-charts',
  template: ``
})

@Injectable()
export class FinancingCharts {

  public charts = [];
  public doubleChartData: any = {};
  public expanded: boolean = false;
  public hidden: boolean = true;
  public loadingGraf1: boolean = false;
  public loadingGraf2: boolean = false;
  public isForExport: boolean = false;
  public loading: boolean = false;

  public aux: Financiamento;
  public formatted: any = [];

  public links = ['Financiamento', 'Juros'];
  public activeLink = this.links[0];
  public accounts = [];

  public total = 0;
  public juros = 0;
  public amortizacao = 0;
  public pago = 0;
  public restante = 0;
  public somaTotal = 0;

  constructor(public dateUtil: DateUtil,
              public snackBar: Snackbar,
              public errorUtil: ErrorUtil,) {
  }

  start() {
    this.aux = null;
    this.formatted = [];
    this.hidden = true;
    this.expanded = false;
  }

  generate() {
    this.formatted = [];
    this.formatData();
    this.destroyCharts();
    this.loadingGraf1 = true;
    this.loadingGraf2 = true;

    if (this.formatted == null || this.formatted.length <= 0) {
      this.loadingGraf1 = false;
      this.loadingGraf2 = false;
      this.loading = false;
      this.hidden = true;
    } else {
      this.doubleChartData = this.setChartsData(this.formatted);
      setTimeout(() => {
        this.createCharts();
      }, 1000);

      this.loadingGraf1 = false;
      this.loadingGraf2 = false;
      this.expanded = true;
      this.loading = false;
      this.hidden = false;
    }
  }

  private formatData() {
    if (this.aux == null) return;
    let tmp: { pie: Array<{ name: string, value: number, color: string }>, doughnut: Array<{ name: string, value: number, color: string }> } = {
      pie: [],
      doughnut: []
    };
    let total = this.aux.installments.find((element: ParcelasFinanciamento) => element.number == 0).amount;
    let juros = 0;
    let amortizacao = 0;
    let pago = 0;
    let restante = 0;

    let installments = this.aux.installments.filter((element: ParcelasFinanciamento) => element.number != 0);
    installments.forEach((element: ParcelasFinanciamento) => {
      juros += element.fees;
      amortizacao += element.amortization;
      if (element.paid) {
        pago += element.amount;
      }
    });
    this.somaTotal = amortizacao + juros;
    restante = this.somaTotal - pago;

    this.total = total;
    this.juros = juros;
    this.amortizacao = amortizacao;
    this.pago = pago;
    this.restante = restante;

    tmp.pie.push({
      name: 'Pago',
      value: pago,
      color: `rgb(140, 206, 41)`
    }, {
      name: 'A pagar',
      value: restante,
      color: `rgb(211, 65, 65)`
    });
    tmp.doughnut.push({
      name: 'Juros',
      value: juros,
      color: `#f39c12`
    }, {
      name: 'Amortização',
      value: amortizacao,
      color: `#3c8dbc`
    });

    if (tmp.pie.length > 0 && tmp.doughnut.length > 0) {
      this.formatted = tmp;
    }
  }

  setChartsData(data: { pie: Array<{ name: string, value: number, color: string }>, doughnut: Array<{ name: string, value: number, color: string }> }): {
    pie: {
      labels: string[],
      percentage: number[],
      value: number[],
      color: string[]
    },
    doughnut: {
      labels: string[],
      percentage: number[],
      value: number[],
      color: string[]
    }
  } {
    let charts = {
      pie: {
        labels: [],
        percentage: [],
        value: [],
        color: []
      },
      doughnut: {
        labels: [],
        percentage: [],
        value: [],
        color: []
      }
    }


    if (data.pie.length > 0 && data.doughnut.length > 0) {
      let sumDoughnut = data.doughnut.map((a) => a.value).reduce((a, b) => a + b);
      charts = {
        pie: {
          labels: data.pie.map((a) => a.name),
          percentage: data.pie.map((a) => Math.abs((a.value / sumDoughnut * 100)).toFixed(2)),
          value: data.pie.map((a) => a.value),
          color: data.pie.map((a) => a.color)
        },
        doughnut: {
          labels: data.doughnut.map((a) => a.name),
          percentage: data.doughnut.map((a) => Math.abs((a.value / sumDoughnut * 100)).toFixed(2)),
          value: data.doughnut.map((a) => a.value),
          color: data.doughnut.map((a) => a.color)
        }
      }
    }
    return charts;
  }

  createCharts() {
    let height = this.isForExport ? "400px" : "300px";

    if (document.getElementById('divChart0') == null || document.getElementById('divChart1') == null) return;
    document.getElementById('divChart0').style.height = height;
    document.getElementById('divChart1').style.height = height;

    let doughnut = this.doubleChartData.doughnut;
    let pie = this.doubleChartData.pie;
    Chart.plugins.unregister(ChartDataLabels);

    this.charts[0] = new Chart('myChart0', {
      type: 'doughnut',
      data: {
        labels: doughnut.labels,
        datasets: [
          {
            data: doughnut.percentage,
            backgroundColor: doughnut.color,
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 0.1,
            datalabels: {
              color: 'white',
              clamp: true,
              formatter: (value, ctx) => {
                return `${Intl.NumberFormat('pt-BR').format(value)}%`;
              }
            }
          },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        rotation: this.isForExport ? 160 : 0,
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: function (tooltipItems) {
              return doughnut.labels[tooltipItems[0].index];
            },
            label: function (tooltipitems, data) {
              return `${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(doughnut.value[tooltipitems.index])}`;
            }
          }
        }
      },
      plugins: [ChartDataLabels]
    });

    this.charts[1] = new Chart('myChart1', {
      type: 'pie',
      data: {
        labels: pie.labels,
        datasets: [
          {
            data: pie.percentage,
            backgroundColor: pie.color,
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 0.1,
            datalabels: {
              color: 'white',
              clamp: true,
              formatter: (value, ctx) => {
                return `${Intl.NumberFormat('pt-BR').format(value)}%`;
              }
            }
          },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        rotation: this.isForExport ? 160 : 0,
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: function (tooltipItems) {
              return pie.labels[tooltipItems[0].index];
            },
            label: function (tooltipitems, data) {
              return `${new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(pie.value[tooltipitems.index])}`;
            }
          }
        }
      },
      plugins: [ChartDataLabels]
    });
  }

  destroyCharts() {
    if (this.charts.length > 0) {
      this.charts[0].destroy();
      this.charts[1].destroy();
      if (document.getElementById('divChart0'))
        document.getElementById('divChart0').style.height = "0px";
      if (document.getElementById('divChart1'))
        document.getElementById('divChart1').style.height = "0px";
    }
  }
}
