export enum ExplorationType {
  "INDIVIDUAL_EXPLORATION",
  "CONDOMINIUM",
  "RENTED_PROPERTY",
  "PARTNERSHIP",
  "LODGING",
  "OTHERS"
}

export enum ExplorationTypeLCDPR {
  "INDIVIDUAL_EXPLORATION" = 1,
  "CONDOMINIUM",
  "RENTED_PROPERTY",
  "PARTNERSHIP",
  "LODGING",
  "OTHERS"
}

export enum ExplorationTypePartnerLCDPR {
  "CONDOMINIUM" = 1,
  "RENTED_PROPERTY",
  "PARTNERSHIP",
  "LODGING",
  "OTHERS"
}

export enum ExplorationTypeName {
  "INDIVIDUAL_EXPLORATION" = "EXPLORAÇÃO INDIVIDUAL",
  "CONDOMINIUM" = "CONDOMÍNIO",
  "RENTED_PROPERTY" = "IMÓVEL ARRENDADO",
  "PARTNERSHIP" = "PARCERIA",
  "LODGING" = "COMODATO",
  "OTHERS" = "OUTROS"
}

export enum ExplorationTypeProd {
  "CONDOMINIUM",
  "LESSOR",
  "PARTNER",
  "COMMODANT",
  "OTHERS"
}

export enum ExplorationTypeProdName {
  "CONDOMINIUM" = "CONDOMÍNIO",
  "LESSOR" = "ARREDADOR",
  "PARTNER" = "PARCEIRO",
  "COMMODANT" = "COMODANTE",
  "OTHERS" = "OUTROS",
  "null" = "NÃO INFORMADO"
}
