import {BrowserAnimationsModule} from '@angular/platform-browser/animations'; // this is needed!
import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {LayoutModule} from './layout/layout.module';
import {SharedModule} from './shared/shared.module';
import {RoutesModule} from './routes/routes.module';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CadastroPessoasComponent} from './routes/cadastros/participantes/cadastro-pessoas/cadastro-pessoas.component';
import {CadastroPastasComponent} from './routes/cadastros/pastas/cadastro-pastas/cadastro-pastas.component';
import {
  CadastroAgrupadoresComponent
} from './routes/cadastros/agrupadores/cadastro-agrupadores/cadastro-agrupadores.component';
import {CadastroProdutosComponent} from './routes/cadastros/produtos/cadastro-produtos/cadastro-produtos.component';
import {LancamentoEstoqueComponent} from './routes/livro-caixa/estoque/lancamento-estoque/lancamento-estoque.component';
import {CadastroSocioComponent} from './routes/minhasPropriedades/cadastro-socio/cadastro-socio.component';
import {CadastroImovelComponent} from './routes/minhasPropriedades/cadastro-imovel/cadastro-imovel.component';
import {
  CadastroContaBancariaComponent
} from './routes/minhasPropriedades/cadastro-conta-bancaria/cadastro-conta-bancaria.component';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {EnviarPlanilhaComponent} from './routes/livro-caixa/estoque/enviar-planilha/enviar-planilha.component';
import {EnviarCsvComponent} from './routes/financeiro/enviar-csv/enviar-csv.component';
import {IntegracaoDominioComponent} from './routes/financeiro/enviar-dominio/integracao-dominio.component';
import {
  PreviewLancamentosComponent
} from './routes/financeiro/financeiro/preview-lancamentos/preview-lancamentos.component';

import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatOptionModule,
  ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCardModule} from '@angular/material/card';
// tslint:disable-next-line:max-line-length
import {
  CadastroPlanoDeContasComponent
} from './routes/cadastros/plano-de-contas/cadastro-plano-de-contas/cadastro-plano-de-contas.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS} from '@angular/material-moment-adapter';
import {MomentUtcDateAdapter} from '../util/MomentUtcDateAdapter';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';
import {NgxCurrencyModule} from 'ngx-currency';
import {MatSortModule} from '@angular/material/sort';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {DatePicker} from '../util/datePicker';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {AuthGuardService} from '../service/auth-guard';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {CadastrarLancamentoComponent} from './routes/financeiro/cadastrar-lancamento/cadastrar-lancamento.component';
import {EditarContaComponent} from './routes/financeiro/alteracao-lote/editar-conta/editar-conta.component';
import {
  EditarContaLancamentoComponent
} from './routes/financeiro/alteracao-lote/editar-conta-lancamento/editar-conta-lancamento.component';
import {
  AlterarLoteModalComponent
} from './routes/financeiro/alteracao-lote/alteracao-lote-modal/alterar-lote-modal.component';
import {EditarImovelComponent} from './routes/financeiro/alteracao-lote/editar-imovel/editar-imovel.component';
import {DateLockComponent} from './routes/financeiro/date-lock/date-lock.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenuModule} from '@angular/material/menu';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {Snackbar} from '../util/snackbar';
import {LayoutContadorModule} from './layoutContador/layout.module';
import {CadastrarEmpresaComponent} from './routes/contador/empresas/cadastrar-empresa/cadastrar-empresa.component';
import {
  CadastrarColaboradorComponent
} from './routes/contador/colaboradores/cadastrar-colaborador/cadastrar-colaborador.component';
import {LogAutomacaoComponent} from './routes/automacoes/log-automacao/log-automacao.component';
import {CloneAutomacaoComponent} from './routes/automacoes/clone-automacao/clone-automacao.component';
import {AuthGuardContadorFuncionarioService, AuthGuardContadorService} from '../service/auth-contador';
import {DfeProvider} from '../providers/dfe/dfe';
import {OFXProvider} from '../providers/bookkeeping/ofx/ofx';
import {BrMaskerIonicServices3} from 'brmasker-ionic-3';
import {ConsultaPdfXml} from '../providers/dfe/consultaPdfXml';
import {DonwloadLoteDfe} from '../providers/dfe/donwloadLoteDfe';
import {CertificadoProvider} from '../providers/certificado/certificadoProvider';
import {
  CadastroCertificadoComponent
} from './routes/minhasPropriedades/cadastro-certificado/cadastro-certificado.component';
import {EstadosMunicipiosUtil} from '../util/estados-municipios';
import {Banks} from '../util/banks';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {CountriesProvider} from '../providers/basicos/countries';
import {CitiesProvider} from '../providers/basicos/cities';
import {StatesProvider} from '../providers/basicos/states';
import {BanksProvider} from '../providers/basicos/banks';
import {BankAccountProvider} from '../providers/company/bank_account/bank_account';
import {
  DeletarContaComponent
} from './routes/minhasPropriedades/cadastro-conta-bancaria/deletar-conta/deletar-conta.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {PropertyProvider} from '../providers/company/property/property';
import {
  DeletarImovelComponent
} from './routes/minhasPropriedades/cadastro-imovel/deletar-imovel/deletar-imovel.component';
import {MemberProvider} from '../providers/company/member/member';
import {ErrorUtil} from '../util/error';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DeletarSocioComponent} from './routes/minhasPropriedades/cadastro-socio/deletar-socio/deletar-socio.component';
import {
  PermissaoColaboradorComponent
} from './routes/contador/colaboradores/permissao-colaborador/permissao-colaborador.component';
import {LivroCaixa} from '../util/livroCaixa';
import {MatListModule} from '@angular/material/list';
import {ExcelService} from './excel.service';
import {getPtPaginatorIntl} from '../util/paginatorConfig';
import {IMaskModule} from 'angular-imask';
import {DfeMapper} from '../model/dto/mapper/dfeMapper';
import {PessoaMapper} from '../model/dto/mapper/pessoaMapper';
import {UserProvider} from '../providers/user/user';
import {BillingProvider} from 'src/providers/billing/billing';
import {ParticipantProvider} from '../providers/participant/participant';
import {DeletarPessoaComponent} from './routes/cadastros/participantes/deletar-pessoa/deletar-pessoa.component';
import {
  CadastrarLancamentoDupComponent
} from './routes/financeiro/cadastrar-lancamento-dup/cadastrar-lancamento-dup.component';
import {CompanyProvider} from '../providers/company/company/company';
import {DeletarEmpresaComponent} from './routes/contador/empresas/deletar-empresa/deletar-empresa.component';
import {
  DeletarColaboradorComponent
} from './routes/contador/colaboradores/deletar-colaborador/deletar-colaborador.component';
import {DuplicatesMapper} from '../model/dto/mapper/duplicatesMapper';
import {LoginModalComponent} from './routes/pages/login-modal/login-modal.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AccountProvider} from '../providers/account/account';
import {GrouperProvider} from '../providers/grouper/grouper';
import {ProductProvider} from '../providers/product/product';
import {DeletarPastaComponent} from './routes/cadastros/pastas/deletar-pasta/deletar-pasta.component';
import {StockProvider} from 'src/providers/stock/stock';
import {AutomationProvider} from 'src/providers/automation/automation';
import {
  DeletarCategoriaComponent
} from './routes/cadastros/plano-de-contas/deletar-categoria/deletar-categoria.component';
import {DeletarAgrupadorComponent} from './routes/cadastros/agrupadores/deletar-agrupador/deletar-agrupador.component';
import {DeletarProdutoComponent} from './routes/cadastros/produtos/deletar-produto/deletar-produto.component';
import {FolderProvider} from '../providers/folder/folder';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {TreeModule} from 'angular-tree-component';
import {HighlightPipe} from '../util/pipes/highlightPipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DocumentProvider} from '../providers/bookkeeping/document/document';
import {AccessKeyProvider} from '../providers/dfe/access-key/access-key';
import {MatChipsModule} from '@angular/material/chips';
import {PostingProvider} from '../providers/bookkeeping/posting/posting';
import {CheckCNPJProvider} from '../providers/basicos/checkCNPJ';
import {
  DeletarLancamentoComponent
} from './routes/financeiro/cadastrar-lancamento/deletar-lancamento/deletar-lancamento.component';
import {ExplorationProvider} from '../providers/company/exploration/exploration';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {
  DeletarLancamentosComponent
} from './routes/financeiro/financeiro/deletar-lancamentos/deletar-lancamentos.component';
import {
  PagamentoPlanejadoComponent
} from './routes/financeiro/cadastrar-lancamento-dup/pagamento-planejado/pagamento-planejado.component';
import {PaymentProvider} from '../providers/bookkeeping/payment/payment';
import {PlannedPaymentProvider} from '../providers/dfe/plannedPayment';
import {DateLockProvider} from 'src/providers/bookkeeping/date-lock/date-lock';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {
  DeletarDocumentoComponent
} from './routes/financeiro/cadastrar-lancamento/deletar-documento/deletar-documento.component';
import {
  FiltroLancamentosComponent
} from './routes/financeiro/financeiro/filtro-lancamentos/filtro-lancamentos.component';
import {DocumentsBookkepingMapper} from '../model/dto/mapper/documentsBookkepingMapper';
import {OfxMapper} from '../model/dto/mapper/ofxMapper';
import {ExcelExportService, FreezeService, PdfExportService, TreeGridModule} from '@syncfusion/ej2-angular-treegrid';
import {
  AggregateService,
  ColumnChooserService,
  GridAllModule,
  GridModule,
  GroupService,
  ResizeService
} from '@syncfusion/ej2-angular-grids';
import {
  TreeTableConsolidacao
} from './routes/relatorios/consolidacao-mensal/treeTableConsolidacao/treeTableConsolidacao';
import {DataTrabalhoDialog} from './routes/financeiro/data-trabalho-dialog/data-trabalho-dialog.component';
import {DropDownListAllModule} from '@syncfusion/ej2-angular-dropdowns';
import {MatSelectFilterModule} from 'mat-select-filter';
import {ExportPlanoDeContas} from './routes/cadastros/plano-de-contas/export-plano-de-contas';
import {TextMaskModule} from 'angular2-text-mask';
import {RouterModule} from '@angular/router';
import {routes} from './routes/routes';
import {AlterarSenhaComponent} from './routes/contador/minha-conta/alterar-senha/alterar-senha.component';
import {
  AlertDocDuplicadoComponent
} from './routes/financeiro/cadastrar-lancamento/alert-doc-duplicado/alert-doc-duplicado.component';
import {
  CadastroHistoricoComponent
} from './routes/cadastros/historicos/cadastro-historico/cadastro-historico.component';
import {DeletarHistoricoComponent} from './routes/cadastros/historicos/deletar-historico/deletar-historico.component';
import {UpdateService} from './updateService';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {HistoricProvider} from '../providers/bookkeeping/historic/historic';
import {MatToolbarModule} from '@angular/material/toolbar';
import {HeaderComponent} from './layout/header/header.component';
import {BasicAlertComponent} from './shared/alert/basic-alert/basic-alert.component';
import {BackupProvider} from '../providers/backup/backup';
import {DateUtil} from '../util/dateUtil';
import {SuperUserProvider} from '../providers/user/superUser';
import {PostingFilter} from './routes/livro-caixa/lcdpr/postingFilter';
import {CheckPermission} from '../service/checkPermission';
import {GenerateLCDPR} from './routes/livro-caixa/lcdpr/generateLCDPR';
import {PdfViewerComponent} from './routes/documentosFiscais/pdf-viewer/pdf-viewer.component';
import {XmlViewerComponent} from './routes/documentosFiscais/xml-viewer/xml-viewer.component';
// tslint:disable-next-line: max-line-length
import {MatStepperModule} from '@angular/material/stepper';
import {
  ModalImportacaoComponent
} from './routes/documentosFiscais/importar-xml/modal-importacao/modal-importacao.component';
import {ImportarXML} from './routes/documentosFiscais/importar-xml/importarXML';
import {ImportarOFX} from './routes/conciliacao/importarOFX';
import {DialogModule} from '@syncfusion/ej2-angular-popups';
import {ConvertObjectPipe} from '../util/pipes/ConvertObjectPipe';
import {PagesModule} from './routes/pages/pages.module';
import {DonwloadLote} from './routes/documentosFiscais/donwload-lote/donwload-lote';
import {PipesModule} from '../util/pipes/pipes.module';
import {
  CadastroConviteAppComponent
} from './routes/minhasPropriedades/cadastro-convite-app/cadastro-convite-app.component';
import {
  UserInfoAppComponent
} from './routes/minhasPropriedades/cadastro-convite-app/user-info-app/user-info-app.component';
import {GeneratePdfLCA} from './routes/livro-caixa/livro-caixa-analogico/generatePdfLCA';
import {DatePickerModule, DateRangePickerModule} from '@syncfusion/ej2-angular-calendars';
import {MatExpansionModule} from '@angular/material/expansion';
import {ConvertImovelDTO} from '../util/pipes/ConvertImovelDTO';
import {ConvertBankAccountDTO} from '../util/pipes/ConvertBankAccountDTO';
import {OnlyNumberDirective} from './shared/directives/onlyNumber/onlyNumberDirective';

import {SupportModalComponent} from './layout/header/support-modal/support-modal.component';
import {
  AccessKeyModalComponent
} from './routes/documentosFiscais/download-codigo-acesso/access-key-modal/access-key-modal.component';
import {
  CheckKeyModalComponent
} from './routes/documentosFiscais/download-codigo-acesso/check-key-modal/check-key-modal.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import {LayoutConsultorModule} from './layoutConsultor/layout.module';
import {MatRadioModule} from '@angular/material/radio';
import {EventsModalComponent} from './routes/documentosFiscais/events-modal/events-modal.component';
import {
  AdicionarArquivosComponent
} from './routes/cadastros/enviar-arquivos/adicionar-arquivos/adicionar-arquivos.component';
import {AdicionarImagemComponent} from './routes/cadastros/enviar-arquivos/adicionar-imagem/adicionar-imagem.component';
import {ConsultorModule} from './routes/consultor/consultor.module';
import {
  EditMemberModalComponent
} from './routes/documentosFiscais/download-codigo-acesso/edit-member-modal/edit-member-modal.component';
import {ReceiptProvider} from 'src/providers/receipt/receipt';
import {MaintenanceProvider} from "../providers/status/maintenanceProvider";
import {AuthProvider} from "../providers/auth/authProvider";
import {BatchProvider} from 'src/providers/batch/batchProvider';
import {
  DialogPasswordConfirmationComponent
} from './routes/pages/dialog-password-confirmation/dialog-password-confirmation.component';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll'
import {ForgotPasswordComponent} from './routes/pages/forgot-password/forgot-password.component';
import {
  DuplicarImovelComponent
} from './routes/minhasPropriedades/cadastro-imovel/duplicar-imovel/duplicar-imovel.component';
import {ModalImportOfxComponent} from './routes/conciliacao/modal-import-ofx/modal-import-ofx.component';
import {ModalFileComponent} from './routes/conciliacao/modal-file/modal-file.component';
import {ConciliacaoMultiplaComponent} from './routes/conciliacao/conciliacao-multipla/conciliacao-multipla.component';
import {
  ContaMovimentoIndividualComponent
} from './routes/financeiro/cadastrar-lancamento-dup/conta-movimento-individual/conta-movimento-individual.component';
import {
  ContaIndividualComponent
} from './routes/financeiro/cadastrar-lancamento-dup/conta-individual/conta-individual.component';
import {
  HistoricoIndividualComponent
} from './routes/financeiro/cadastrar-lancamento-dup/historico-individual/historico-individual.component';
import {
  ImovelIndividualComponent
} from './routes/financeiro/cadastrar-lancamento-dup/imovel-individual/imovel-individual.component';
import {historicoUtil} from 'src/util/historico';
import {ReportsProvider} from "../providers/reports/ReportsProvider";
import {
  EditarParticipanteComponent
} from "./routes/financeiro/alteracao-lote/editar-participante/editar-participante.component";
import {EditarDataComponent} from "./routes/financeiro/alteracao-lote/editar-data/editar-data.component";
import {CadastroAutomacaoComponent} from './routes/automacoes/cadastro-automacao/cadastro-automacao.component';
import {AutomationsConfig} from 'src/util/automations';
import {UserConfig} from 'src/util/userConfig';
import {ModalProdutosComponent} from "./routes/documentosFiscais/modal-produtos/modal-produtos.component";
import {
  ImportacaoPlanilhaCsvComponent
} from './routes/financeiro/importacao-planilha-csv/importacao-planilha-csv.component';
import {CertificadoLogsComponent} from "./routes/minhasPropriedades/certificado-logs/certificado-logs.component";
import {
  CadastroExportacoesComponent
} from './routes/cadastros/exportacoes/cadastro-exportacoes/cadastro-exportacoes.component';
import {CadastroLayoutsComponent} from './routes/cadastros/exportacoes/cadastro-layouts/cadastro-layouts.component';
import {ExportProvider} from 'src/providers/export/export';
import {ExportUtil} from 'src/util/exportUtil';
import {
  ExportarLancamentosComponent
} from './routes/financeiro/financeiro/exportar-lancamentos/exportar-lancamentos.component';
import {BalanceChart} from "./routes/relatorios/consolidacao-mensal/charts";
import {ReportsUtil} from "../util/Reports";
import {ModalExportComponent} from "./routes/relatorios/modal-export/modal-export.component";
import {TreeTableAnual} from "./routes/relatorios/comparativo-anual/treeTable";
import {AnnualCharts} from "./routes/relatorios/comparativo-anual/charts";
import {AccountsCharts} from "./routes/relatorios/livro-razao/charts";
import {MembersCharts} from "./routes/relatorios/analise-participante/charts";
import {ModalAnotacoesComponent} from "./layout/header/anotacoes/modal-anotacoes/modal-anotacoes.component";
import {CompanyNotesProvider} from "../providers/company/company/company-notes";
import {DeletarAnotacaoComponent} from "./layout/header/anotacoes/deletar-anotacao/deletar-anotacao.component";
import {
  ProdutosEstoqueComponent
} from "./routes/financeiro/cadastrar-lancamento-dup/produtos-estoque/produtos-estoque.component";
import {
  ExploracaoPorImovelComponent
} from './routes/minhasPropriedades/minhas-propriedades/exploracao-modal/exploracao-por-imovel/exploracao-por-imovel.component';
import {ReportsHelper} from "../util/ReportsHelper";
import {BelongingsProvider} from "../providers/belongings/belongings";
import {CadastroBensComponent} from './routes/livro-caixa/bens/cadastro-bens/cadastro-bens.component';
import {
  CadastrarLancamentoDupVendaComponent
} from "./routes/financeiro/cadastrar-lancamento-dup-venda/cadastrar-lancamento-dup-venda.component";
import {ModalIRComponent} from "./routes/livro-caixa/simulador-ir/modal-ir/modal-ir.component";
import {OverlayModule} from "@angular/cdk/overlay";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localePt, 'pt-BR');


@NgModule({
  declarations: [
    ContaMovimentoIndividualComponent,
    ContaIndividualComponent,
    ExploracaoPorImovelComponent,
    HistoricoIndividualComponent,
    ProdutosEstoqueComponent,
    ImovelIndividualComponent,
    AppComponent,
    AlertDocDuplicadoComponent,
    AlterarSenhaComponent,
    BasicAlertComponent,
    ModalExportComponent,
    CadastroAgrupadoresComponent,
    CadastroProdutosComponent,
    LancamentoEstoqueComponent,
    EnviarPlanilhaComponent,
    EnviarCsvComponent,
    ImportacaoPlanilhaCsvComponent,
    IntegracaoDominioComponent,
    PreviewLancamentosComponent,
    CadastroPlanoDeContasComponent,
    CadastroCertificadoComponent,
    CadastroConviteAppComponent,
    CadastroHistoricoComponent,
    CadastrarEmpresaComponent,
    CadastrarColaboradorComponent,
    LogAutomacaoComponent,
    CloneAutomacaoComponent,
    CadastroAutomacaoComponent,
    CadastrarLancamentoComponent,
    EditarContaComponent,
    EditarParticipanteComponent,
    EditarDataComponent,
    EditarContaLancamentoComponent,
    AlterarLoteModalComponent,
    DuplicarImovelComponent,
    CertificadoLogsComponent,
    EditarImovelComponent,
    DateLockComponent,
    CadastrarLancamentoDupComponent,
    CadastrarLancamentoDupVendaComponent,
    CadastroContaBancariaComponent,
    CadastroImovelComponent,
    CadastroPastasComponent,
    CadastroPessoasComponent,
    CadastroSocioComponent,
    DataTrabalhoDialog,
    DeletarContaComponent,
    DeletarColaboradorComponent,
    DeletarEmpresaComponent,
    DeletarDocumentoComponent,
    DeletarImovelComponent,
    DeletarPessoaComponent,
    DeletarSocioComponent,
    DeletarPastaComponent,
    DeletarCategoriaComponent,
    DeletarAgrupadorComponent,
    DeletarAnotacaoComponent,
    DeletarProdutoComponent,
    DeletarLancamentoComponent,
    DeletarLancamentosComponent,
    DeletarHistoricoComponent,
    FiltroLancamentosComponent,
    ForgotPasswordComponent,
    PermissaoColaboradorComponent,
    LoginModalComponent,
    ModalImportacaoComponent,
    ModalImportOfxComponent,
    ModalFileComponent,
    ConciliacaoMultiplaComponent,
    OnlyNumberDirective,
    PagamentoPlanejadoComponent,
    UserInfoAppComponent,
    PdfViewerComponent,
    XmlViewerComponent,
    HighlightPipe,
    ConvertObjectPipe,
    ConvertImovelDTO,
    ConvertBankAccountDTO,
    AccessKeyModalComponent,
    CheckKeyModalComponent,
    EventsModalComponent,
    ModalProdutosComponent,
    EditMemberModalComponent,
    DialogPasswordConfirmationComponent,
    CadastroExportacoesComponent,
    CadastroLayoutsComponent,
    ExportarLancamentosComponent,
    CadastroBensComponent,
    ModalIRComponent
  ],
  imports: [

    GridAllModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    DialogModule,
    PipesModule,
    // ChartModule,
    FormsModule,
    // DragDropModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IMaskModule,
    LayoutModule,
    LayoutContadorModule,
    LayoutConsultorModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatSortModule,
    MatSelectFilterModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatCardModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatToolbarModule,
    MatTableModule,
    NgxMatSelectSearchModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDialogModule,
    MatButtonModule,
    MatAutocompleteModule,
    environment.production ? ServiceWorkerModule.register('/ngsw-worker.js') : [],
    MatListModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    NgxDaterangepickerMd.forRoot({
      separator: ' - ',
      format: 'DD/MM/YYYY',
      direction: 'ltr',
      customRangeLabel: 'Personalizado',
      applyLabel: 'Aplicar',
    }),
    OAuthModule.forRoot(),
    RoutesModule,
    RouterModule.forRoot(
      routes,
      {enableTracing: false} // <-- debugging purposes only
    ),
    GridModule,
    TreeModule.forRoot(),
    ReactiveFormsModule,
    SharedModule.forRoot(),
    TreeGridModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {enabled: environment.production}),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatTooltipModule,
    MatChipsModule,
    DropDownListAllModule,
    TextMaskModule,
    MatStepperModule,
    DragDropModule,
    PagesModule,
    DateRangePickerModule,
    DatePickerModule,
    MatExpansionModule,
    NgxSkeletonLoaderModule,
    NgxCaptchaModule,
    MatRadioModule,
    ConsultorModule,
    BrowserModule,
    MatSelectInfiniteScrollModule,
    OverlayModule
  ],
  providers: [
    AggregateService,
    AccountProvider,
    AuthProvider,
    AuthGuardService,
    AuthGuardContadorService,
    AuthGuardContadorService,
    AuthGuardContadorService,
    AuthGuardContadorFuncionarioService,
    Banks,
    BackupProvider,
    BankAccountProvider,
    BanksProvider,
    ReportsProvider,
    BatchProvider,
    BrMaskerIonicServices3,
    CertificadoProvider,
    CitiesProvider,
    CheckCNPJProvider,
    CompanyProvider,
    CompanyNotesProvider,
    CountriesProvider,
    ConsultaPdfXml,
    ColumnChooserService,
    DatePicker,
    DateUtil,
    DonwloadLoteDfe,
    DonwloadLote,
    DocumentsBookkepingMapper,
    OfxMapper,
    DfeProvider,
    OFXProvider,
    DfeMapper,
    DuplicatesMapper,
    ErrorUtil,
    EstadosMunicipiosUtil,
    ExcelService,
    ExcelExportService,
    ExplorationProvider,
    FolderProvider,
    GroupService,
    GrouperProvider,
    ProductProvider,
    StockProvider,
    AutomationProvider,
    HistoricProvider,
    LivroCaixa,
    MemberProvider,
    NgxSkeletonLoaderModule,
    BillingProvider,
    ParticipantProvider,
    historicoUtil,
    PessoaMapper,
    PaymentProvider,
    PlannedPaymentProvider,
    PropertyProvider,
    PostingProvider,
    PostingFilter,
    GeneratePdfLCA,
    GenerateLCDPR,
    ImportarXML,
    ImportarOFX,
    CheckPermission,
    MaintenanceProvider,
    DocumentProvider,
    StatesProvider,
    Snackbar,
    HeaderComponent,
    UserProvider,
    UpdateService,
    ResizeService,
    FreezeService,
    PdfExportService,
    ExportPlanoDeContas,
    SuperUserProvider,
    TreeTableConsolidacao,
    BalanceChart,
    AccountsCharts,
    MembersCharts,
    TreeTableAnual,
    AnnualCharts,
    TreeTableAnual,
    AnnualCharts,
    {provide: OAuthStorage, useValue: localStorage},
    {provide: MatPaginatorIntl, useValue: getPtPaginatorIntl()},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {provide: LOCALE_ID, useValue: 'pt-BR'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: false}},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    {provide: DateAdapter, useClass: MomentUtcDateAdapter},
    AccessKeyProvider,
    ReceiptProvider,
    DateLockProvider,
    AutomationsConfig,
    DateLockProvider,
    UserConfig,
    ExportProvider,
    ExportUtil,
    ReportsUtil,
    ReportsHelper,
    BelongingsProvider
  ],

  entryComponents: [
    AlterarSenhaComponent,
    AlertDocDuplicadoComponent,
    BasicAlertComponent,
    ModalExportComponent,
    ExploracaoPorImovelComponent,
    CadastroAgrupadoresComponent,
    CadastroProdutosComponent,
    LancamentoEstoqueComponent,
    EnviarPlanilhaComponent,
    EnviarCsvComponent,
    ImportacaoPlanilhaCsvComponent,
    IntegracaoDominioComponent,
    PreviewLancamentosComponent,
    CadastroPlanoDeContasComponent,
    DuplicarImovelComponent,
    CertificadoLogsComponent,
    CadastroCertificadoComponent,
    CadastroConviteAppComponent,
    CadastrarEmpresaComponent,
    CadastrarColaboradorComponent,
    LogAutomacaoComponent,
    CloneAutomacaoComponent,
    CadastroAutomacaoComponent,
    CadastrarLancamentoComponent,
    EditarContaComponent,
    EditarParticipanteComponent,
    EditarDataComponent,
    ContaMovimentoIndividualComponent,
    ContaIndividualComponent,
    HistoricoIndividualComponent,
    ProdutosEstoqueComponent,
    ImovelIndividualComponent,
    EditarContaLancamentoComponent,
    AlterarLoteModalComponent,
    EditarImovelComponent,
    DateLockComponent,
    CadastrarLancamentoDupComponent,
    CadastrarLancamentoDupVendaComponent,
    CadastroContaBancariaComponent,
    CadastroImovelComponent,
    CadastroPastasComponent,
    CadastroHistoricoComponent,
    CadastroPessoasComponent,
    CadastroSocioComponent,
    DataTrabalhoDialog,
    DeletarContaComponent,
    DeletarColaboradorComponent,
    DeletarEmpresaComponent,
    DeletarDocumentoComponent,
    DeletarImovelComponent,
    DeletarPessoaComponent,
    DeletarSocioComponent,
    DeletarPastaComponent,
    DeletarCategoriaComponent,
    DeletarAgrupadorComponent,
    DeletarAnotacaoComponent,
    DeletarProdutoComponent,
    DeletarLancamentoComponent,
    DeletarLancamentosComponent,
    DeletarHistoricoComponent,
    DialogPasswordConfirmationComponent,
    FiltroLancamentosComponent,
    ForgotPasswordComponent,
    LoginModalComponent,
    ModalImportacaoComponent,
    ModalImportOfxComponent,
    ModalFileComponent,
    ConciliacaoMultiplaComponent,
    UserInfoAppComponent,
    PagamentoPlanejadoComponent,
    PdfViewerComponent,
    XmlViewerComponent,
    PermissaoColaboradorComponent,
    SupportModalComponent,
    ModalAnotacoesComponent,
    AccessKeyModalComponent,
    CheckKeyModalComponent,
    EventsModalComponent,
    ModalProdutosComponent,
    AdicionarArquivosComponent,
    AdicionarImagemComponent,
    EditMemberModalComponent,
    CadastroExportacoesComponent,
    CadastroLayoutsComponent,
    ExportarLancamentosComponent,
    CadastroBensComponent,
    ModalIRComponent
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
