import {Injectable} from "@angular/core";
import {authPasswordFlowConfig} from "../../service/service";
import {HttpErrorResponse} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../environments/environment.prod";

@Injectable()
export class AuthProvider {

  constructor(private oauthService: OAuthService,
              private http: HttpClient) {
  }

  private givenAuthorities(): string {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['name'];
  }

  revalidateLogin(password: string): Promise<boolean> {
    this.oauthService.configure(authPasswordFlowConfig);
    return new Promise((resolve, reject) => {
      this.oauthService
        .fetchTokenUsingPasswordFlowAndLoadUserProfile(this.givenAuthorities(), password).then((resp) => {
        resolve(true);
      }).catch((err: HttpErrorResponse) => {
        reject(false);
      });
    })

  }

  getAuthValidator() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + '/auth/info', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
}
