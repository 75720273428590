import {Component, OnInit, ViewChild, Injector} from '@angular/core';
import {Router} from '@angular/router';

const screenfull = require('screenfull');
import {filter} from 'rxjs/operators';
import {UserblockService} from '../sidebar/userblock/userblock.service';
import {SettingsService} from '../../core/settings/settings.service';
import {MenuService} from '../../core/menu/menu.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {Snackbar} from '../../../util/snackbar';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SupportModalComponent } from 'src/app/layout/header/support-modal/support-modal.component';
import { environment } from 'src/environments/environment.prod';
import {DarkThemePickerService} from "../../core/themes/dark-theme-picker.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderContadorComponent implements OnInit {

  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  router: Router;
  environment;
  darkTheme: boolean;

  isNavSearchVisible: boolean;
  @ViewChild('fsbutton', {static: true}) fsbutton;  // the fullscreen button
  screenfull: any;

  constructor(public menu: MenuService,
              public userblockService: UserblockService,
              private oauthService: OAuthService,
              public routerAngular: Router,
              public snackBar: Snackbar,
              public settings: SettingsService,
              public injector: Injector,
              public pickThemeService: DarkThemePickerService,
              public supportModalService: NgbModal) {

    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
    this.oauthService.logoutUrl = "login";
    this.environment = environment.apiNfe;
    this.darkTheme = localStorage.getItem('theme') == 'dark';
  }

  ngOnInit() {
    this.isNavSearchVisible = false;
    var ua = window.navigator.userAgent;
    if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    // Switch fullscreen icon indicator
    const el = this.fsbutton.nativeElement.firstElementChild;
    screenfull.on('change', () => {
      if (el){
        el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
        this.screenfull = screenfull.isFullscreen;
      }
    });

    this.router = this.injector.get(Router);

    // Autoclose navbar on mobile when route change
    this.router.events.subscribe((val) => {
      // scroll view to top
      window.scrollTo(0, 0);
      // close collapse menu
      this.navCollapsed = true;
    });

  }

  toggleUserBlock(event) {
    event.preventDefault();
    this.userblockService.toggleVisibility();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }
  ClickRedirect(){
    //console.log("teste")
    document.cookie = `access_token=${localStorage.getItem('access_token')};path=/;domain=.farmcont.com.br`
  }

  logout() {
    document.cookie = `access_token=;path=/;domain=.farmcont.com.br`
    this.oauthService.logOut(true);
    this.routerAngular.navigate(["login"]);
    this.snackBar.open('Usuário deslogado com sucesso!', 'success');
  }

  openSupportModal() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
        this.supportModalService.open(SupportModalComponent, modalOptions);
    } catch(error) {
        console.error('', error);
    }
  }

  changeTheme() {
    this.darkTheme = !this.darkTheme;
    this.pickThemeService.selectTheme(this.darkTheme);
  }

}
