import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../../environments/environment.prod';
import {AccessKeysStatus} from '../../../model/AccessKeysStatus';
import * as moment from 'moment';

@Injectable()
export class AccessKeyProvider {
    apiBase = '/fiscal/v1/dfe/download-key/';

    constructor(public http: HttpClient, public oauthService: OAuthService) {}

    getAllByCompany() {
        const requestString = `${environment.apiLogin}${this.apiBase}by-company/${localStorage.getItem('idEmpresa')}`;
        return new Promise((resolve, reject) => {
            this.http.get(requestString, {headers: {Authorization: this.oauthService.authorizationHeader()}})
                .subscribe((result: AccessKeysStatus[]) => {
                    resolve(result);
                },
                (error) => {
                    console.error('Erro ao buscar os arquivos', error);
                    reject(error);
                });
        });
    }

    getAccessKeysByPeriod(start: string, end: string) {
        const startDate = moment(start).tz('America/Sao_Paulo').format('YYYY-MM-DD');
        const endDate = moment(end).tz('America/Sao_Paulo').format('YYYY-MM-DD');
        // tslint:disable-next-line: max-line-length
        const requestString = `${environment.apiLogin}${this.apiBase}by-company/${localStorage.getItem('idEmpresa')}/${startDate}/${endDate}`;
        return new Promise((resolve, reject) => {
            this.http.get(requestString, {headers: {Authorization: this.oauthService.authorizationHeader()}})
                .subscribe((result: AccessKeysStatus[]) => {
                    resolve(result);
                },
                (error) => {
                    console.error('Erro ao buscar os arquivos', error);
                    reject(error);
                });
        });
    }


    getUnintegratedDfes() {
        return new Promise((resolve, reject) => {
        // tslint:disable-next-line: max-line-length
        this.http.get(`${environment.apiLogin}${this.apiBase}reintegrate/all`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        // tslint:disable-next-line: deprecation
        .subscribe((result) => {
            resolve(result);
        }, (error) => {
            console.error(error);
            reject(error);
        });
        });
    }



    checkAccessKeys(idEmpresa, accessKeys){
        console.log(idEmpresa)
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiLogin + "/fiscal/v1/dfe/check-dfe-keys/" + idEmpresa, accessKeys, {headers: {Authorization: this.oauthService.authorizationHeader()}}).subscribe((result)=> {
                resolve(result);
            }, (error) => {
                console.error(error);
                reject(error)
            });
        });
        
    }


    postByAccessKeys(cpfCnpj: string, accessKeys: string[]) {
        try {
            // tslint:disable-next-line: prefer-const
            let postObjs: PostObject[] = [];
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < accessKeys.length; i++) {
                postObjs.push({
                    key: `${accessKeys[i]}`,
                    cpfCnpj: `${cpfCnpj}`
                });
            }

            const requestString = `${environment.apiLogin}${this.apiBase}by-company/${localStorage.getItem('idEmpresa')}`;
            return new Promise((resolve, reject) => {
                this.http.post(requestString, postObjs, {headers: {Authorization: this.oauthService.authorizationHeader()}})
                    .subscribe((result: any[]) => {
                        resolve(result);
                    }, (error) => {
                        console.error('Erro ao realizar o post', error);
                        reject(error);
                    });
            });
        } catch (error) {
            console.error('Erro ao executar post', error);
        }
    }

    reintegrateByAccessKey(accessKey: string) {
        try {
            // tslint:disable-next-line: max-line-length
            const requestString = `${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/reintegrate/${accessKey}`;
            return new Promise((resolve, reject) => {
                this.http.get(requestString, {headers: {Authorization: this.oauthService.authorizationHeader()}})
                    .subscribe((result: any[]) => {
                        resolve(result);
                    }, (error) => {
                        console.error('Erro ao realizar a reintegração', error);
                        reject(error);
                    });
            });
        } catch (error) {
            console.error('Erro ao reintegrar', error);
        }
    }

    reintegrateByDocumentIdsAndCompany(ids: any[]) {
        try {
            const requestString = `${environment.apiLogin}${this.apiBase}reintegrate/${localStorage.getItem('idEmpresa')}`;
            return new Promise((resolve, reject) => {
                this.http.post(requestString, ids, {headers: {Authorization: this.oauthService.authorizationHeader()}})
                    .subscribe((result: any[]) => {
                        resolve(result);
                    }, (error) => {
                        console.error('Erro ao realizar a reintegração múltipla', error);
                        reject(error);
                    });
            });
        } catch (error) {
            console.error('Erro ao reintegrar', error);
        }
    }

    reintegrateAllDfes() {
        return new Promise((resolve, reject) => {
          // tslint:disable-next-line: max-line-length
          this.http.post(`${environment.apiLogin}${this.apiBase}reintegrate/all`, null, {headers: {Authorization: this.oauthService.authorizationHeader()}})
            // tslint:disable-next-line: deprecation
            .subscribe((result: any[]) => {
                resolve(result);
              },
              (error) => {
                reject(error);
              });
        });
    }

    deleteDfeKey(id) {
        try {
            const requestString = `${environment.apiLogin}${this.apiBase}by-company/${localStorage.getItem('idEmpresa')}/${id}`;
            return new Promise((resolve, reject) => {
                this.http.delete(requestString, {headers: {Authorization: this.oauthService.authorizationHeader()}})
                .subscribe((result: any[]) => {
                    resolve(result);
                },
                (error) => {
                    console.error('Erro ao deletar chave de acesso', error);
                    reject(error);
                });
            });
        } catch (error) {
            console.error('Erro ao deletar chave de acesso', error);
        }
    }
    deleteDfeKeysLote(ids) {
        try {
            const requestString = `${environment.apiLogin}/fiscal/v1/dfe/delete-key-integrate-by-ids/by-company/${localStorage.getItem('idEmpresa')}`;
            return new Promise((resolve, reject) => {
                this.http.post(requestString,ids, {headers: {Authorization: this.oauthService.authorizationHeader()}})
                .subscribe((result: any[]) => {
                    resolve(result);
                },
                (error) => {
                    console.error('Erro ao deletar chave de acesso', error);
                    reject(error);
                });
            });
        } catch (error) {
            console.error('Erro ao deletar chave de acesso', error);
        }
    }
    editDfeKeyFarmer(accessKey: AccessKeysStatus) {
        try {
            let putObj = new PutObject();
            putObj.id = accessKey.id;
            putObj.key = accessKey.key;
            putObj.status = accessKey.status;
            putObj.cpfCnpj = accessKey.cpfCnpj;
            putObj.companyId = accessKey.companyId;
            putObj.taskId = accessKey.taskId;
            putObj.requestDate = "2021-03-06T19:18:02.493+00:00";
            putObj.requesterName = accessKey.requesterName;
            const requestString = `${environment.apiLogin}${this.apiBase}by-company/${localStorage.getItem('idEmpresa')}`;
            return new Promise((resolve, reject) => {
                this.http.put(requestString, putObj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
                    .subscribe((result: any[]) => {
                        resolve(result);
                    }, (error) => {
                        console.error('Erro ao alterar o produtor', error);
                        reject(error);
                    });
            });
        } catch (error) {
            console.error('Erro ao alterar o produtor', error);
        }
    }
}

class PostObject {
    key: string;
    cpfCnpj: string;
}

class PutObject {
    id: number;
    key: string;
    status: string;
    cpfCnpj: string;
    companyId: string;
    taskId: string;
    requestDate: string;
    requesterName: string;
}
