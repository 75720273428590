export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  clientId: +localStorage.getItem('idEmpresa'),
  apiLogin: 'https://api.aplicativo.farmcont.com.br',   // MAIN
  // apiLogin: 'https://d2xbe52wftm65r.cloudfront.net',  // TEST
  // apiLogin: 'http://192.168.0.27:6060',   // TEST ENV - INTERNO
  // apiLogin: 'http://ambtestesexterno.ddns.net:6060',  // TEST ENV - EXTERNO
   apiNfe: 'https://emissor.farmcont.com.br', //NFE
   // apiLogin: 'http://192.168.0.214:6060', // EDIMAR
  //
  // -------------------------------------------------------------
  //
  // apiLogin: "http://200.14.122.3:6060",
  // apiLogin: "https://200.14.122.3",AA
  // apiLogin: "http://localhost:6060",
  // apiLogin: "https://177.86.166.92"
  // apiLogin: "http://192.168.0.164:6060",
   requireHttps: false,
  // requireHttps: true
};
