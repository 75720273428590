import {Injectable} from "@angular/core";
import {BrMaskerIonicServices3} from "brmasker-ionic-3";
import moment from "moment";
import {Bookkeeping} from "../bookkeping/Bookkeeping";
import {DocType} from "../../enum/DocType";
import {UserProvider} from "src/providers/user/user";


@Injectable()
export class DocumentsBookkepingMapper {

  constructor(
    public brMasker: BrMaskerIonicServices3,
    public UserProvider: UserProvider,) {
  }

  public arrayToClientModel(apiModel: Bookkeeping[]): Bookkeeping[] {
    let documentos: Bookkeeping[] = [];

    apiModel.forEach((resultBack) => {
      documentos.push(this.serverModelToClientModel(resultBack));
    });

    return documentos;

  }

  public arrayToLastEntries(apiModel: Bookkeeping[]): Bookkeeping[] {
    let documentos: Bookkeeping[] = [];

    apiModel.forEach((resultBack) => {
      documentos.push(this.serverModelToLastEntriesModel(resultBack));
    });

    return documentos;

  }

  public serverModelToClientModel(apiModel: Bookkeeping): Bookkeeping {
    try {
      // tslint:disable-next-line: prefer-const
      let lancamento = new Bookkeeping();

      if (apiModel.account.featureType !== 'EXPENSE') {
        lancamento.amountPaid = apiModel.amountPaid;
        lancamento.incoming = this.formatarValor(apiModel.amountPaid.toString());
        lancamento.incomingNumber = +apiModel.amountPaid;
        lancamento.outlayNumber = null;
        lancamento.outlay =  null;
      } else {
        lancamento.incoming = null;
        lancamento.incomingNumber = null;
        lancamento.outlay = '-' + this.formatarValor((apiModel.amountPaid * -1).toFixed(2).toString());
        lancamento.outlayNumber = apiModel.amountPaid * -1;
        lancamento.amountPaid = apiModel.amountPaid * -1;
      }

      if (apiModel.postingReleaseType === 'NONE') {
        lancamento.postingType = 'NÃO DEDUTÍVEL';
      } else {
        lancamento.postingType = 'DEDUTÍVEL';
      }

      if (apiModel.balance < 0) {
        lancamento.balanceFormated = '- ' + this.formatarValor(apiModel.balance.toString());
      } else {
        lancamento.balanceFormated = this.formatarValor(apiModel.balance.toString());
      }

      // moment().locale('pt-BR');
      lancamento.month = moment(apiModel.paymentDate).format('MM') + ' - '
        +  moment(apiModel.paymentDate).locale('pt-BR').format('MMMM').toUpperCase();
      lancamento.paymentDate = (moment(apiModel.paymentDate).format());
      // lancamento.paymentDate2 = (moment(apiModel.paymentDate).format('YYYY-MM-DD')) + 'T00:00:00.000Z';
      lancamento.postingReleaseType = apiModel.postingReleaseType;
      lancamento.account = apiModel.account;
      lancamento.account.description = lancamento.account.description.toUpperCase();
      lancamento.account.code_description = apiModel.account.customId + ' - ' + apiModel.account.description;
      lancamento.balance = apiModel.balance;
      lancamento.isImported = apiModel.isImported;
      lancamento.companyId = apiModel.companyId;
      lancamento.bankAccount = apiModel.bankAccount;
      apiModel.bankAccount != null ?  lancamento.bankAccount.code_description = apiModel.bankAccount.code + ' - ' + apiModel.bankAccount.description : '';
      lancamento.doc = apiModel.doc;
      lancamento.doc.property.code_description = apiModel.doc.property.propertyCode + ' - ' + apiModel.doc.property.name;
      lancamento.createdBy = apiModel.createdBy;
      if(apiModel.createdAt){
         lancamento.createdAt = (moment(apiModel.createdAt).format())
      }
      if (lancamento.doc.participant != null) {
        lancamento.doc.participant.name =
          (lancamento.doc.participant.name.toUpperCase()).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');
      }
      lancamento.doc.docTypeFormated = DocType[apiModel.doc.docType];
      lancamento.planPaymentId = apiModel.planPaymentId;
      lancamento.payment = apiModel.payment;
      lancamento.id = apiModel.id;
      lancamento.historic = apiModel.historic;
      lancamento.grouper = apiModel.grouper;
      lancamento.digital = apiModel.digital;
      lancamento.amountPaidFormated = this.formatarValor(apiModel.amountPaid.toFixed(2).toString());
      lancamento.paymentDateFormated = moment(apiModel.paymentDate).format('DD/M/YYYY');
      lancamento.ofxId = (apiModel.ofxId) ? apiModel.ofxId : null;
      lancamento.importType = apiModel.importType;
      return lancamento; // and return YOUR model
    } catch (error) {
      console.error('', error);
    }
  }

  public serverModelToLastEntriesModel(apiModel: Bookkeeping): Bookkeeping {
    try {
      // tslint:disable-next-line: prefer-const
      let lancamento = new Bookkeeping();

      if (apiModel.account.featureType !== 'EXPENSE') {
        lancamento.amountPaid = apiModel.amountPaid;
        lancamento.incoming = this.formatarValor(apiModel.amountPaid.toString());
        lancamento.incomingNumber = +apiModel.amountPaid;
        lancamento.outlayNumber = null;
        lancamento.outlay =  null;
      } else {
        lancamento.incoming = null;
        lancamento.incomingNumber = null;
        lancamento.outlay = '-' + this.formatarValor((apiModel.amountPaid * -1).toFixed(2).toString());
        lancamento.outlayNumber = apiModel.amountPaid * -1;
        lancamento.amountPaid = apiModel.amountPaid * -1;
      }

      if (apiModel.postingReleaseType === 'NONE') {
        lancamento.postingType = 'NÃO DEDUTÍVEL';
      } else {
        lancamento.postingType = 'DEDUTÍVEL';
      }

      // moment().locale('pt-BR');
      lancamento.month = moment(apiModel.paymentDate).format('MM') + ' - '
        +  moment(apiModel.paymentDate).locale('pt-BR').format('MMMM').toUpperCase();
      lancamento.paymentDate = (moment(apiModel.paymentDate).format());
      // lancamento.paymentDate2 = (moment(apiModel.paymentDate).format('YYYY-MM-DD')) + 'T00:00:00.000Z';
      lancamento.postingReleaseType = apiModel.postingReleaseType;
      lancamento.account = apiModel.account;
      lancamento.account.description = lancamento.account.description.toUpperCase();
      lancamento.balance = apiModel.balance;
      lancamento.companyId = apiModel.companyId;
      lancamento.bankAccount = apiModel.bankAccount;
      lancamento.doc = apiModel.doc;
      lancamento.createdBy = apiModel.createdBy;
      if(apiModel.createdAt){
        lancamento.createdAt =(moment(apiModel.createdAt).format())
      }
      if (lancamento.doc.participant != null) {
        lancamento.doc.participant.name =
          (lancamento.doc.participant.name.toUpperCase()).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');
      }
      lancamento.doc.docTypeFormated = DocType[apiModel.doc.docType];
      lancamento.planPaymentId = apiModel.planPaymentId;
      lancamento.payment = apiModel.payment;
      lancamento.id = apiModel.id;
      lancamento.historic = apiModel.historic;
      lancamento.grouper = apiModel.grouper;
      lancamento.digital = apiModel.digital;
      lancamento.amountPaidFormated = this.formatarValor(apiModel.amountPaid.toFixed(2).toString());
      lancamento.paymentDateFormated = moment(apiModel.paymentDate).format('DD/M/YYYY');
      // console.log(lancamento);
      return lancamento; // and return YOUR model
    } catch (error) {
      console.error('', error);
    }
  }

  formatarValor(valorTotal: string) {
    return this.brMasker.writeValueMoney(valorTotal.toString(), {
      decimal: 2,
      decimalCaracter: ",",
      len: 0,
      mask: "",
      numberAndTousand: false,
      percent: false,
      person: false,
      phone: false,
      thousand: ".",
      type: undefined,
      userCaracters: false,
      money: true
    });
  }


}

