import {Injectable} from "@angular/core";
import {OAuthService} from "angular-oauth2-oidc";

@Injectable()
export class CheckPermission {

  constructor(private oauthService: OAuthService) {
  }

  isSuperuser(): boolean {
    let regra = this.givenAuthorities();
    // console.log(regra);
    return regra.find(role => role.authority === 'ROLE_SUPER') !== undefined;
  }

  isAccountant(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_ACCOUNTANT_ADMIN') !== undefined ||
           regra.find(role => role.authority === 'ROLE_ACCOUNTANT_COLLABORATOR') !== undefined;
  }

  isAccountantAdmin(): boolean{
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_ACCOUNTANT_ADMIN') !== undefined;
  }

  isAccountantCollaborator(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_ACCOUNTANT_COLLABORATOR') !== undefined;
  }

  isFarmer(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_FARMER_ADMIN') !== undefined ||
           regra.find(role => role.authority === 'ROLE_FARMER_COLLABORATOR') !== undefined;
  }

  isFarmerAdmin(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_FARMER_ADMIN') !== undefined
  }

  isFarmerCollaborator(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_FARMER_COLLABORATOR') !== undefined;
  }

  isConsultant(): boolean {
    let regra = this.givenAuthorities();
    return regra.find(role => role.authority === 'ROLE_CONSULTANT') !== undefined;
  }

  private givenAuthorities() {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['authorities'];
  }


}
