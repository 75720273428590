import {Component, Inject, OnInit} from '@angular/core';
import {ErrorUtil} from "../../../../../util/error";
import {Snackbar} from "../../../../../util/snackbar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HttpErrorResponse} from "@angular/common/http";
import {DocumentProvider} from "../../../../../providers/bookkeeping/document/document";

@Component({
  selector: 'app-deletar-documento',
  templateUrl: './deletar-documento.component.html',
  styleUrls: ['./deletar-documento.component.scss']
})
export class DeletarDocumentoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeletarDocumentoComponent>,
              public errorUtil: ErrorUtil,
              public documentProvider: DocumentProvider,
              public  snackbar: Snackbar,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(button): void {
    button.disabled = true;
    this.documentProvider.delDocument(this.data).then(() => {
      this.snackbar.open('Documento deletado com sucesso!', 'success');
      this.dialogRef.close(true);
      button.disabled = false;
    }).catch((result: HttpErrorResponse) => {
      button.disabled = false;
      this.snackbar.snackBar.open('Não foi possível deletar o documento! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'error');
      this.dialogRef.close("cancelou");
    });
  }

}
