import {Component, Injectable, OnInit} from "@angular/core";
import {DateUtil} from "../../../../util/dateUtil";
import {Snackbar} from "../../../../util/snackbar";
import {ReportsProvider} from "../../../../providers/reports/ReportsProvider";
import {AnnualCharts} from "./charts";

@Component({
  selector: 'app-tree-table-anual',
  template: `
  `
})

@Injectable()
export class TreeTableAnual implements OnInit {

  public data = [];
  public aux: any = [];
  public consultando: boolean = false;
  public carregando: boolean = false;
  public saldos: any = {ano1: 0, ano2: 0};
  public error: boolean = false;
  public errorMessage: string = '';

  constructor(public dateUtil: DateUtil,
              public snackBar: Snackbar,
              private reportsProvider: ReportsProvider,
              public charts: AnnualCharts) {
  }

  ngOnInit(): void {
  }

  start() {
    this.consultando = false;
  }

  getReport(ano1: string, ano2: string, bankAccount: any, properties: any, members: any, deducao: any) {
    this.carregando = true;
    this.error = false;
    this.data = [];
    this.aux = [];
    this.charts.hidden = true;

    this.reportsProvider.getReportAnnual(ano1, ano2, {
      bankAccountIds: bankAccount,
      propertyCodes: properties,
      cpfCnpjs: members,
      postingReleaseType: deducao
    }).then((result: any) => {
      this.aux = result;
      this.filterLancamentos();
    }).catch((error) => {
      this.error = true;
      console.log(error);
      const regex = /"(.*?)"/g;
      const matches = error.error.error_description.match(regex);
      this.errorMessage = matches.map(match => match.slice(1, -1));
      this.carregando = false;
    });
  }

  filterLancamentos() {
    this.data = [];
    this.saldos = {ano1: 0, ano2: 0};

    let first = [];
    this.aux[0].data.children.forEach((child) => {
      let value = this.organizarPorAnoEMesRecursivo(child, '1');
      if (value) {
        first.push(value);
        this.saldos.ano1 += value.ano1;
      }
    });

    let second = [];
    this.aux[1].data.children.forEach((child) => {
      let value = this.organizarPorAnoEMesRecursivo(child, '2');
      if (value) {
        second.push(value);
        this.saldos.ano2 += value.ano2;
      }
    });


    let mergedTreesArray = [];

    first.forEach(node1 => {
      const node2 = second.find(n => n.id === node1.id);
      if (node2) {
        mergedTreesArray.push(this.mergeTrees(node1, node2));
      } else {
        mergedTreesArray.push(node1);
      }
    });
    second.forEach(node2 => {
      const node1 = first.find(n => n.id === node2.id);
      if (!node1) {
        mergedTreesArray.push(node2);
      }
    });

    mergedTreesArray.sort((a, b) => +a.customId < +b.customId ? -1 : 1);

    let obj = {
      id: 69,
      conta: "SALDO",
      customId: "69",
      porcento: 0.00,
      absoluto: Math.abs(this.saldos.ano1) - Math.abs(this.saldos.ano2),
      ano1: this.saldos.ano1,
      ano2: this.saldos.ano2,
      meses1: [],
      meses2: [],
      children: []
    };

    obj.porcento = ((obj.ano1 - obj.ano2) / obj.ano1) * 100;
    mergedTreesArray.push(obj);

    this.data = mergedTreesArray;
    this.charts.byAccountAux = mergedTreesArray;
    this.charts.anos = [this.aux[0].year, this.aux[1].year];
    this.charts.generate();
    this.carregando = false;
    this.consultando = false;
  }

  mergeTrees(node1: any, node2: any) {
    if (!node1 && !node2) return null;
    if (!node1) return node2;
    if (!node2) return node1;

    let mergedNode = {
      id: node1.id || node2.id,
      conta: node1.conta || node2.conta,
      customId: node1.customId || node2.customId,
      porcento: 0.00,
      absoluto: 0.0,
      ano1: node1.ano1 || 0,
      ano2: node2.ano2 || 0,
      meses1: node1.meses1 || [],
      meses2: node2.meses2 || [],
      children: [],
      tipo: node1.tipo || node2.tipo
    };

    mergedNode.absoluto = Math.abs(Math.abs(mergedNode.ano1) - Math.abs(mergedNode.ano2));
    mergedNode.porcento = ((Math.abs(mergedNode.ano1) - Math.abs(mergedNode.ano2)) / mergedNode.ano1) * 100;
    if (mergedNode.ano1 > 0 && mergedNode.ano2 > 0) {
      mergedNode.porcento *= -1;
    }

    let childrenMap = new Map();

    if (node1.children) {
      node1.children.forEach(child => {
        if (child) childrenMap.set(child.id, child);
      });
    }

    if (node2.children) {
      node2.children.forEach(child => {
        if (child) {
          if (childrenMap.has(child.id)) {
            childrenMap.set(child.id, this.mergeTrees(childrenMap.get(child.id), child));
          } else {
            childrenMap.set(child.id, child);
          }
        }
      });
    }

    mergedNode.children = Array.from(childrenMap.values());
    return mergedNode;
  }

  organizarPorAnoEMesRecursivo(node: any, actualYear: string) {
    if (!node || !node.months) return null;
    let data: any = {
      id: node.id,
      conta: node.description,
      customId: node.customId,
      // isso daqui só persiste se a conta não existir no outro ano
      porcento: (actualYear == '1') ? -100 : 100,
      absoluto: node.months[12],
      ano1: 0,
      ano2: 0,
      meses1: [],
      meses2: [],
      children: [],
      tipo: node["@type"]
    };

    data['ano' + actualYear] = node.months[12];
    data['meses' + actualYear] = node.months;

    if (node.children) {
      node.children.forEach(child => {
        if (child !== null) {
          let children: any = this.organizarPorAnoEMesRecursivo(child, actualYear);
          data.children.push(children);
        }
      });
    }

    return data;
  }
}
