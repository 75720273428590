import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ProductProvider} from "../../../../../providers/product/product";
import {Snackbar} from "../../../../../util/snackbar";
import {HttpErrorResponse} from "@angular/common/http";
import {DfeProvider} from "../../../../../providers/dfe/dfe";
import {ParticipantDTOResponse} from "../../../../../model/dto/ParticipantDTOResponse";
import {ConvertObjectPipe} from "../../../../../util/pipes/ConvertObjectPipe";
import {ParticipantPageable} from "../../../../../model/dto/bookkeping/ParticipantPageable";
import {CadastroPessoasComponent} from "../../../cadastros/participantes/cadastro-pessoas/cadastro-pessoas.component";
import {ParticipantProvider} from "../../../../../providers/participant/participant";
import {takeUntil} from "rxjs/operators";
import {ReplaySubject, Subject} from "rxjs";
import {CadastroImovelComponent} from "../../../minhasPropriedades/cadastro-imovel/cadastro-imovel.component";
import {PropertyProvider} from "../../../../../providers/company/property/property";
import {ImovelDTO} from "../../../../../model/dto/ImovelDTO";
import {CadastroProdutosComponent} from "../../../cadastros/produtos/cadastro-produtos/cadastro-produtos.component";
import * as moment from "moment";
import {StockProvider} from "../../../../../providers/stock/stock";
import {LancamentoEstoqueComponent} from "../../../livro-caixa/estoque/lancamento-estoque/lancamento-estoque.component";

@Component({
  selector: 'app-produtos-estoque',
  templateUrl: './produtos-estoque.component.html',
  styleUrls: ['./produtos-estoque.component.scss']
})
export class ProdutosEstoqueComponent implements OnInit, OnDestroy {

  protected _onDestroy = new Subject<void>();

  public document: any;
  public carregando: boolean = false;
  public loading: boolean = false;

  public dfeProducts: any = [];
  public stockProducts: StockProduct[] = [];
  public objProducts: { produto: Produto, vinculated: FormControl, amount: FormControl, selected: boolean }[] = [];
  public filter_stock_products: ReplaySubject<StockProduct[]> = new ReplaySubject<StockProduct[]>(1);
  public stockProductFilterCtrl: FormControl = new FormControl();

  public form: FormGroup;
  public enviado: boolean = false;

  public properties = [];
  public filter_property: ReplaySubject<ImovelDTO[]> = new ReplaySubject<ImovelDTO[]>(1);
  public propertyFilterCtrl: FormControl = new FormControl();

  public participantes: ParticipantDTOResponse[] = [];
  public participantLoaded: boolean;
  public participantFilterCtrl: FormControl = new FormControl();
  public participantPage: number = 0;
  public filter_participant: ReplaySubject<ParticipantDTOResponse[]> = new ReplaySubject<ParticipantDTOResponse[]>(1);
  public useDestinatary: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal,
              public snackBar: Snackbar,
              public provider: ProductProvider,
              public dfeProvider: DfeProvider,
              public participantProvider: ParticipantProvider,
              public propertyProvider: PropertyProvider,
              public estoqueProvider: StockProvider,
  ) {
  }

  ngOnInit() {
    console.log(this.document);
    this.getParticipants();
    if (!this.properties.length) {
      this.getProperties();
    }
    this.getProducts();
    this.createForm();
    this.initializePropertySearch();
    if (this.properties){
      let p = this.properties.find(p => p.default);
      if (p){
        this.form.get('propertyCode').setValue(p.propertyCode);
      }
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getProducts() {
    let id = this.document.parcelaId || this.document.id;
    this.carregando = true;
    this.dfeProducts = [];
    this.dfeProvider.getAllDfesProducts(id).then((result: any) => {
      this.dfeProducts = result;
      if (this.dfeProducts.length > 0) {
        this.findStockProduct();
      }
      this.carregando = false;
    }).catch((result: HttpErrorResponse) => {
      console.log(result)
      this.snackBar.openLong('Não foi encontrar os produtos do documento fiscal!', 'erro');
      this.carregando = false;
    });
  }

  getParticipants() {
    this.getFirstPage();
  }

  getProperties() {
    this.carregando = true;
    this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
      let pipe = new ConvertObjectPipe();
      this.properties = pipe.transform(imoveis, 'imoveis');
      this.initializePropertySearch();
      this.carregando = false;
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }

  createForm() {
    let doc = this.document;

    this.form = new FormGroup({
      postDate: new FormControl(moment(doc.dataEmissao).toISOString(), Validators.required),
      docNumber: new FormControl(doc.numeroDoc, Validators.required),
      propertyCode: new FormControl(null, Validators.required),
      originId: new FormControl(null, Validators.required),
      participant: new FormControl(null, Validators.required),
      historic: new FormControl(null),
    });
  }

  findStockProduct() {
    this.carregando = true;
    this.objProducts = [];
    this.provider.getAllProducts().then((result: any) => {
      this.stockProducts = result;
      this.initializeStockProductsSearch();
      this.dfeProducts.forEach(produto => {
        this.objProducts.push({
          produto: produto,
          vinculated: new FormControl(null),
          amount: new FormControl(produto.amount),
          selected: false
        });
      });
      this.carregando = false;
    }).catch((error) => {
      this.snackBar.openLong('Não foi encontrar os produtos do documento fiscal!', 'erro');
      this.carregando = false;
      console.log(error);
    });
  }

  getFirstPage() {
    let pipe = new ConvertObjectPipe();
    this.participantPage = 0;
    this.participantFilterCtrl.setValue('');
    this.participantProvider.getParticipantsByPage(this.participantFilterCtrl.value, this.participantPage).then((participantes: ParticipantPageable) => {
      this.participantes = pipe.transform(participantes.content, 'participantes');
      let cnpj = this.document.emitente.cnpj;
      if (this.useDestinatary){
        cnpj = this.document.destinatario.cnpj;
      }
      this.searchParticipant(cnpj, true);
      this.participantPage++;
      this.filterParticipants();
    });
  }

  getNextBatch(isScroll: boolean) {
    let search = '';
    if (this.participantFilterCtrl.value != undefined) {
      search = this.participantFilterCtrl.value;
    }

    let pipe = new ConvertObjectPipe();
    if (isScroll) {
      this.participantProvider.getParticipantsByPage(search, this.participantPage).then((participantes: ParticipantPageable) => {
        this.participantLoaded = participantes.last;
        this.participantPage++;

        this.participantes = this.participantes.concat(
            pipe.transform(participantes.content, 'participantes')
        );
        this.filterParticipants();
      });
    } else {
      this.searchParticipant(search);
    }
  }

  searchParticipant(search, select: boolean = false) {
    if (search.length >= 3 || search.length == 0) {
      this.participantPage = 0;
      this.participantProvider.getParticipantsByPage(search, this.participantPage).then((participantes: ParticipantPageable) => {
        this.participantLoaded = participantes.last;
        this.participantPage++;
        let pipe = new ConvertObjectPipe();
        this.participantes = pipe.transform(participantes.content, 'participantes');
        if (select) {
          let cnpj = this.document.emitente.cnpj;
          if (this.useDestinatary){
            cnpj = this.document.destinatario.cnpj;
          }
          let p = this.participantes.find(participant => participant.cpfCNPJParticipant == cnpj);
          this.form.get('participant').setValue(p ? p.id : null);
        }
        this.filterParticipants();
      });
    }
  }

  newProperty() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroImovelComponent, modalOptions);
    modalRef.result.then((result) => {
      if (result) {
        this.getProperties();
      }
    }, () => {
    });
  }

  newParticipant() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroPessoasComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result != 'cancelou') {
        this.participantes.push(result);
        this.getFirstPage();
      }
    }, () => {
    });
  }

  newProduct(produto: Produto, i: number) {
    let tmp = {
      id: null,
      code: null,
      description: produto.description,
      ncm: produto.ncm,
    }
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroProdutosComponent, modalOptions);
    modalRef.componentInstance.produtoExistente = tmp;
    modalRef.result.then((result: any) => {
      if (result != 'cancelou') {
        this.objProducts[i].vinculated.setValue(result.id);
        this.findStockProduct();
      }
    }, () => {
    });
  }

  editProperty(property: any) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroImovelComponent, modalOptions);
    modalRef.componentInstance.imovelExistente = property;
    modalRef.result.then((result) => {
      if (result) {
        this.getProperties();
      }
    }, () => {
    });
  }

  editStockPost(id) {
    this.carregando = true;
    this.estoqueProvider.getStockPostById(id).then((result) => {
      this.carregando = false;
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
      };
      const modalRef = this.modalService.open(LancamentoEstoqueComponent, modalOptions);
      modalRef.componentInstance.editar = true;
      modalRef.componentInstance.lancamentoExistente = result;
      modalRef.result.then((result) => {
        if (result == 'deleted') {
          this.getProducts();
        }
      }, () => {
      });
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
      this.snackBar.openLong('Não foi possível encontrar o lançamento de estoque!', 'erro');
      console.log(result);
    });
  }

  initializeStockProductsSearch() {
    this.filter_stock_products.next(this.stockProducts.slice());
    this.stockProductFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterStockProducts();
        });
  }

  protected filterStockProducts() {
    if (!this.stockProducts) {
      return;
    }
    let search = this.stockProductFilterCtrl.value;
    if (!search) {
      this.filter_stock_products.next(this.stockProducts.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filter_stock_products.next(
        this.stockProducts.filter(f =>
            (f.code + ' - ' + f.description).toLowerCase().indexOf(search) > -1
        ).sort((a, b) =>
            (a.code + ' - ' + a.description).toLowerCase().indexOf(search) - (b.code + ' - ' + b.description).toLowerCase().indexOf(search)
        )
    );
  }

  initializePropertySearch() {
    this.filter_property.next(this.properties.slice());
    this.propertyFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterProperties();
        });
  }

  protected filterProperties() {
    if (!this.properties) {
      return;
    }
    // get the search keyword
    let search = this.propertyFilterCtrl.value;
    if (!search) {
      this.filter_property.next(this.properties.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the properties
    this.filter_property.next(
        this.properties.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterParticipants() {
    this.filter_participant.next(this.participantes.slice());
  }

  save() {
    let selected = this.objProducts.filter(item => item.selected);
    let vinculated = selected.filter(item => item.vinculated.value);

    if (!selected.length) {
      this.snackBar.openLong('Selecione pelo menos um produto!', 'erro');
      return;
    }
    if (!vinculated.length) {
      this.snackBar.openLong('Selecione pelo menos um produto do estoque!', 'erro');
      return;
    }
    // caso selected é diferente de vinculated
    if (selected.length != vinculated.length) {
      this.snackBar.openLong('Selecione um produto do estoque para cada produto selecionado!', 'erro');
      return;
    }
    if (this.form.invalid) {
      this.snackBar.openLong('Preencha todos os campos obrigatórios!', 'erro');
      return;
    }
    let form = this.form.value;
    const objs = selected.map(item => ({
      id: null,
      dfeId: this.document.id,
      dfeProductId: item.produto.id,
      participantId: form.participant,
      companyId: localStorage.getItem('idEmpresa'),
      docNumber: form.docNumber,
      propertyCode: form.propertyCode,
      postDate: moment(form.postDate).format('YYYY-MM-DD'),
      originId: form.originId,
      historic: form.historic,
      stockProductId: item.vinculated.value,
      amount: item.amount.value
    }));

    this.criarLancamentoEstoque(objs);
  }

  criarLancamentoEstoque(docs) {
    this.carregando = true;
    this.estoqueProvider.postLancamentoEmLote(docs).then((result) => {
      this.snackBar.openLong('Lançamento de estoque criado com sucesso!', 'success');
      this.carregando = false;
      this.activeModal.close(docs);
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
      console.log(result);
      this.snackBar.openLong('Não foi possível criar o lançamento de estoque', 'error');
    });
  }

  formataValor(value) {
    return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
  }

  toggleSelection(card: any) {
    card.selected = !card.selected;
  }
}

class Produto {
  id: number;
  description: string;
  ncm: string;
  cfop: string;
  unit: string;
  value: number;
  productValue: number;
  amount: number;
  stockPostId?: number;
}

class StockProduct {
  id: number;
  code: string;
  description: string;
  ncm: string;
}
