import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment.prod";
import { Injectable } from "@angular/core";

@Injectable()
export class ProductProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  apiBase: string = "/main-records/v1/stock-product/";

  getAllProducts() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });

    });
  }

  postProduct(product) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBase, product, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });

    });
  }

  putProduct(product) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiBase, product, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  deleteProduct(productId) {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiBase + productId, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }


}
