import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Agrupador} from "../../../../../model/Agrupador";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {GrouperProvider} from "../../../../../providers/grouper/grouper";
import {HttpErrorResponse} from "@angular/common/http";
import {GrouperDTOResponse} from "../../../../../model/dto/GrouperDTOResponse";

@Component({
  selector: 'app-cadastro-agrupadores',
  templateUrl: './cadastro-agrupadores.component.html'
})
export class CadastroAgrupadoresComponent implements OnInit {

  agrupador: Agrupador = new Agrupador();
  editar: boolean = false;
  enviado: boolean = false;
  enviando: boolean = false;
  agrupadoresForm: FormGroup;

  @Input() public agrupadorExistente;
  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public grouperProvider: GrouperProvider,
              public snackBar: Snackbar,
              public erroUtil: ErrorUtil) {
  }

  ngOnInit() {
    this.configuracaoForm();
    this.checkEdit();
  }

  configuracaoForm() {
    this.agrupadoresForm = this.fb.group({
      id: [''],
      companyId: [+localStorage.getItem('idEmpresa')],
      name: ['', Validators.compose([Validators.required])]
    });
  }

  checkEdit(){
    if (this.agrupadorExistente != undefined) {
      this.agrupadorExistente.companyId = +localStorage.getItem('idEmpresa');
      this.editar = true;
      let objAgrupador = {...this.agrupadorExistente};
      this.agrupadoresForm.setValue(objAgrupador);
    }
  }

  setObjectEdit(agrupador: GrouperDTOResponse) {
    this.agrupadorExistente.name = agrupador.name;
    this.agrupadorExistente.id = agrupador.id;
  }

  novoAgrupador() {
    this.enviado = true;
    this.enviando = true;
    if (this.agrupadoresForm.valid) {
      if (!this.editar) {
        this.grouperProvider.postGrouper(this.agrupadoresForm.getRawValue()).then((agrupador: GrouperDTOResponse) => {
          this.snackBar.openLong("Sucesso! " + agrupador.name + " inserido com sucesso!", 'success');
          this.activeModal.close(agrupador);
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          this.snackBar.openLong('Não foi possível inserir! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'grouper'), 'erro');
        });
      } else {
        let grouper = this.agrupadoresForm.getRawValue();
        grouper['status'] = this.agrupadorExistente.status;
        this.grouperProvider.putGrouper(grouper).then((agrupador: GrouperDTOResponse) => {
          this.setObjectEdit(agrupador);
          this.snackBar.openLong("Sucesso! " + agrupador.name + " editado com sucesso!", 'success');
          this.activeModal.close(agrupador);
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          this.snackBar.openLong('Não foi possível editar! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'grouper'), 'erro');
        });
      }
    } else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

}

