import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {ModalAnotacoesComponent} from "./modal-anotacoes/modal-anotacoes.component";
import * as moment from "moment";
import {CompanyNotesProvider} from "../../../../providers/company/company/company-notes";
import {MatDialog} from "@angular/material/dialog";
import {DeletarAnotacaoComponent} from "./deletar-anotacao/deletar-anotacao.component";

@Component({
  selector: 'app-anotacoes',
  templateUrl: './anotacoes.component.html',
  styleUrls: ['./anotacoes.component.scss']
})
export class AnotacoesComponent implements OnInit {
  @Output() reloadNotes = new EventEmitter<string>();
  @Input() expanded: boolean;

  public loading = false;
  public notes = [];
  public filters = [];
  public keyword: any;
  public order = 'ASC';
  public sequence = 0;

  constructor(public modalService: NgbModal,
              public dialog: MatDialog,
              public provider: CompanyNotesProvider) {
  }

  ngOnInit() {
    this.getAllNotes();
  }

  getAllNotes() {
    this.loading = true;
    this.provider.getAll().then((notes: any) => {
      this.notes = notes;
      this.filters = notes;
      this.loading = false;
      this.reloadNotes.emit("AAAAAAAAA");
    }).catch((err) => {
      console.log(err);
      this.loading = false;
    });
  }

  openNote(item: any) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ModalAnotacoesComponent, modalOptions);
    modalRef.componentInstance.existente = item;
    modalRef.componentInstance.visualizar = true;
    modalRef.componentInstance.editar = true;
    modalRef.result.then((result) => {
      this.getAllNotes();
    });
  }

  newNote() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ModalAnotacoesComponent, modalOptions);
    modalRef.result.then((result) => {
      this.getAllNotes();
    });
  }

  editNote(item: any) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ModalAnotacoesComponent, modalOptions);
    modalRef.componentInstance.existente = item;
    modalRef.componentInstance.editar = true;
    modalRef.result.then((result) => {
      this.getAllNotes();
    });
  }

  deleteNote(item: any) {
    const dialogRef = this.dialog.open(DeletarAnotacaoComponent, {
      width: '350px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancelou' && result !== undefined) {
        this.getAllNotes();
      }
    });
  }

  formatDate(date: any) {
    return moment(date).format('DD/MM/YYYY');
  }

  isToday(date: any) {
    return moment(date).isSame(moment(), 'day');
  }

  pastToday(date: any) {
    return moment(date).isBefore(moment(), 'day');
  }

  orderNotes(type: string) {
    this.order = this.order === 'ASC' ? 'DESC' : 'ASC';

    this.filters = this.filters.sort((a, b) => {
      if (type === 'title') {
        return this.order === 'ASC' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
      } else if (type === 'date') {
        return this.order === 'ASC' ? moment(a.createdAt).unix() - moment(b.createdAt).unix() : moment(b.createdAt).unix() - moment(a.createdAt).unix();
      } else if (type === 'user') {
        return this.order === 'ASC' ? a.userName.localeCompare(b.userName) : b.userName.localeCompare(a.userName);
      } else if (type === 'dueDate') {
        return this.order === 'ASC' ? moment(a.dueDate).unix() - moment(b.dueDate).unix() : moment(b.dueDate).unix() - moment(a.dueDate).unix();
      }
    });
  }

  filterNotes() {
    if (this.keyword === '') {
      this.filters = this.notes;
    }
    setTimeout(() => {
      if (this.keyword) {
        const searchText = (document.getElementById('searchtext') as any).value.toLowerCase();
        this.filters = this.notes.filter((note) =>
          note.title.toLowerCase().includes(searchText)
          || note.description.substr(0, 99).toLowerCase().includes(searchText)
          || note.userName.toLowerCase().includes(searchText)
        );
      }
    }, 400);
  }

  clearSearch() {
    this.keyword = '';
    this.filters = this.notes;
  }

}
