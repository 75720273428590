import {Component, Injectable, OnInit} from "@angular/core";
import {DateUtil} from "../../../../util/dateUtil";
import {Snackbar} from "../../../../util/snackbar";
import {ErrorUtil} from "../../../../util/error";
import {Chart} from 'chart.js';
import ChartDataLabels from "chartjs-plugin-datalabels";

@Component({
  selector: 'app-dashboard-charts',
  template: ``
})

@Injectable()
export class BalanceChart implements OnInit {

  public charts = [];
  public balanceChartData: any = {};

  public expanded: boolean = false;
  public hidden: boolean = true;
  public loadingGraf: boolean = false;
  public isForExport: boolean = false;
  public loading: boolean = false;

  public aux: any = [];
  public byMonth: any = [];


  public mesesExtensos = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  constructor(public dateUtil: DateUtil,
              public snackBar: Snackbar,
              public errorUtil: ErrorUtil,) {
  }

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  start() {
    this.aux = [];
    this.byMonth = [];
    this.hidden = true;
  }

  generate() {
    this.byMonth = [];

    this.formatData();
    this.destroyChart();

    if (this.byMonth == null || this.byMonth.length <= 0) {
      this.loadingGraf = false;
      this.loading = false;
      this.hidden = true;
    } else {
      this.balanceChartData = this.setChartData(this.byMonth);
      this.createChart();

      this.expanded = true;
      this.loadingGraf = false;
      this.loading = false;
      this.hidden = false;
    }

  }

  private formatData() {
    if (this.aux == null) return;
    let tmp = [];
    this.aux.forEach(element => {
      if (element != null) {
        tmp = [...tmp, ...this.filtrarFolhas(element)];
      }
    });

    tmp.sort((a, b) => {
      if (+a.customId < +b.customId) {
        return -1;
      }
      if (+a.customId > +b.customId) {
        return 1;
      }
      return 0;
    });

    this.formatByMonth(tmp);
  }

  private formatByMonth(arr) {
    if (arr == null || arr.length <= 0) return;
    let positive = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let negative = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let saldo = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let saldoAcumulado = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    arr.forEach((account) => {
      if (account.months) {
        for (let i = 0; i < 12; i++) {
          if (account.months[i] > 0) {
            positive[i] += account.months[i];
          } else if (account.months[i] < 0) {
            negative[i] += account.months[i];
          }
        }
      }
    });

    for (let i = 0; i <= 12; i++) {
      saldo[i] = positive[i] + negative[i];
      if (i > 0) saldoAcumulado[i] = saldoAcumulado[i - 1] + saldo[i];
      else saldoAcumulado[i] = saldo[i];
    }

    this.byMonth = {
      receitas: positive,
      despesas: negative,
      saldo: saldo,
      saldoAcumulado: saldoAcumulado
    }
  }

  filtrarFolhas(node: any) {
    if (node == null) return [];
    // Passo 2: Verifica se o nó atual é uma folha
    if (node["@type"] === "Leaf") {
      return [node];
    }

    // Passo 3 e 4: Processa os filhos, se houver, e concatena os resultados
    if (node.children) {
      return node.children.reduce((acc, child) => {
        const result = this.filtrarFolhas(child);
        if (result.length > 0) { // Verifica se o resultado não é vazio antes de concatenar
          return acc.concat(result);
        }
        return acc;
      }, []);
    }

    // Retorna um array vazio se o nó atual não é uma folha e não tem filhos
    return [];
  }

  setChartData(byMonth) {
    return {
      labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      incoming: byMonth.receitas,
      outgoing: byMonth.despesas,
      balance: byMonth.saldo,
      accumulated: byMonth.saldoAcumulado
    }
  }

  createChart() {
    document.getElementById('divChart2').style.height = this.isForExport ? "400px" : "300px";

    let chartBackgroundColors = this.generateColors();
    let meses = this.mesesExtensos;
    Chart.plugins.unregister(ChartDataLabels);

    this.charts[2] = new Chart('myChart2', {
      // type: 'scatter',
      type: 'bar',
      data: {
        labels: this.balanceChartData.labels,
        datasets: [
          {
            label: 'Saldo acumulado',
            type: 'line',
            fill: false,
            data: this.balanceChartData.accumulated,
            borderColor: chartBackgroundColors[14],
            order: 1
          },
          {
            label: 'Saldo',
            type: 'line',
            fill: false,
            data: this.balanceChartData.balance,
            borderColor: chartBackgroundColors[13],
            order: 2
          },
          {
            label: 'Receita',
            data: this.balanceChartData.incoming,
            backgroundColor: chartBackgroundColors[8],
            stack: 'Stack 0',
            order: 3
          },
          {
            label: 'Despesa',
            data: this.balanceChartData.outgoing,
            backgroundColor: chartBackgroundColors[10],
            stack: 'Stack 0',
            order: 3
          },
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom'
        },
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value: number, index, values) {
                return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
              }
            }
          }],
        },
        tooltips: {
          enabled: true,
          callbacks: {
            title: function (tooltipItems, data) {
              return data.datasets[tooltipItems[0].datasetIndex].label + ": " + meses[tooltipItems[0].index];
            },
            label: function (tooltipitems, data) {
              // @ts-ignore
              return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(data.datasets[tooltipitems.datasetIndex].data[tooltipitems.index]);
            }
          }
        }
      }
    });
  }

  destroyChart() {
    if (this.charts.length > 0) {
      this.charts.forEach((chart) => {
        chart.destroy();
      })
    }
    document.getElementById('divChart2').style.height = "0px";
  }

  generateColors() {
    return [
      `rgba(255, 211, 79, 1)`,
      `rgba(25, 197, 148, 0.7)`,
      `rgba(237, 85, 101, 0.93)`,
      `rgba(28, 132, 198, 0.78)`,
      `rgba(187, 107, 217, 0.85)`,
      `rgba(242, 152, 73, 0.95)`,
      `rgba(109, 146, 227, 0.89)`,
      `rgba(111, 63, 170, 0.67)`,
      `rgba(59, 214, 152, 0.84)`,
      `rgba(167, 166, 214, 0.84)`,
      `rgba(255, 99, 132, 0.8)`,
      `rgba(54, 162, 235, 0.8)`,
      `rgba(75, 192, 192, 0.8)`,
      `rgba(153, 102, 255, 1)`,
      `rgb(255, 234, 0)`,
    ];
  }

  getSuitableY(y, yArray = [], direction) {
    let result = y;
    yArray.forEach((existedY) => {
      if (existedY - 15 < result && existedY + 15 > result) {
        if (direction === "right") {
          result = existedY + 15;
        } else {
          result = existedY - 15;
        }
      }
    });

    return result;
  };
}
