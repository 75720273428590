import {Component, Injector, OnInit} from '@angular/core';
import {UserProvider} from "../../../../providers/user/user";
import {Router} from "@angular/router";
import {OAuthService} from "angular-oauth2-oidc";
import {Snackbar} from "../../../../util/snackbar";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  router: Router;
  currentRouter = this.routerAngular.url;



  constructor(public snackBar: Snackbar,private oauthService: OAuthService, public routerAngular: Router, private userProvider: UserProvider,
              public injector: Injector) { }

  ngOnInit() {
    console.log(localStorage.getItem('allow'));
    this.router = this.injector.get(Router);

  }


  checkAllowCliente() {
    // this.userProvider.getPermission().then((list) => {
    //   localStorage.setItem('allow', JSON.stringify(list));
    //   if(this.allowCompany()){
    //     this.router.navigate(['contador/clientes']);
    //   }else{
    //     this.snackBar.openLong('O sistema ainda está em manutenção!', 'erro');
    //   }
    // }).catch((error: HttpErrorResponse) => {
    //   let list = [1,3,4,5,12,17,19,20,25,26,27,30,31];
    //   localStorage.setItem('allow', JSON.stringify(list));
    // });
  }

  reLoad() {
    this.router.navigate([this.currentRouter])
  }


  private givenAuthorities(claim) {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims[claim];
  }

  private allowCompany(): boolean {
    console.log(this.givenAuthorities('details'));
    let companyId = this.givenAuthorities('details').office.id;
    if (companyId == undefined) {
      return true;
    } else {
      let list = JSON.parse(localStorage.getItem('allow'));
      console.log(list.findIndex(val => val == companyId));
      return list.findIndex(val => val == companyId) >= 0;

    }
  }

}
