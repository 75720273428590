import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment.prod";
import { Pessoa } from "../../model/Pessoa";
import { ConsultaDfe } from "../../model/ConsultaDfe";
import { Version } from "../../model/Version";

@Injectable()
export class UserProvider {
  constructor(public http: HttpClient, public oauthService: OAuthService) {}

  private apiBaseAddClient: string = "/user/v1/info/";
  private apiBase: string = "/user/v1/user_info/";
  private apiCollaborator: string = "/user/v1/user_info/collaborator/";
  private apiCompany: string = "/user/v1/company/";
  private apiCompanyCollaborator: string = "/user/v1/company_user/";
  private apiVersion: string = "/user/v1/front/version/";
  private apiRoles: string = "/user/v1/user_info/roles";
  private apiPassword: string = "/auth/v1/user/reset-password";
  private apiPerformance: string = "/user/v1/performance/"
  private apiConfig: string = "/user/v1/user-config"

  getLastVersion() {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiLogin + this.apiVersion + "last", {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result: Version) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  getAcessos(){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + '/user/v1/user_info/office-access-report', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
  returnDate(dateToFormat){
    var date = new Date(dateToFormat)
    var day, month
    if(date.getDay() < 10){ day = "0" + date.getDay() } else { day = date.getDay() }
    if(date.getMonth() < 10){ month = "0" + date.getMonth() } else { month = date.getMonth() }
    return (day + "/"+month +"/"+date.getFullYear() +" - "+ date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()).toString()
}
  getAllFarmerMembersByOffice() {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiLogin + this.apiBase + "member", {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result: Pessoa[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  redirectToFarmerNfe(){
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + '/invoice-issuer/v1/issuer/home/1231', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Pessoa[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getAllCompanys() {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiLogin + this.apiCompany, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result: Pessoa[]) => {
            resolve(result.filter((value) => !value.hide));
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  putCompanyUser(idCompany: number, arrayCompanies: number[]) {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          environment.apiLogin + this.apiBase + idCompany + "/role",
          arrayCompanies,
          {
            headers: { Authorization: this.oauthService.authorizationHeader() },
          }
        )
        .subscribe(
          (result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  putRolesUser(id, memberId,roles){
    let objeto;
    objeto = {
      issuerId: localStorage.getItem('idEmpresa'),
      id: id,
      memberId: memberId,
      roles: roles
    }

    //console.log("meu log",objeto)
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiRoles, objeto, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  getAllCollaborators() {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiLogin + this.apiCollaborator, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  postCollaborator(colaborador: Pessoa) {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiLogin + this.apiBase, colaborador, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  postNewClient(colaborador) {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiLogin + this.apiBaseAddClient, colaborador, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  putCollaborator(colaborador: Pessoa) {
    return new Promise((resolve, reject) => {
      this.http
        .put(environment.apiLogin + this.apiBase, colaborador, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  PutEditOffice(colaborador){
    //console.log("AAAA",colaborador)
    return new Promise((resolve, reject) => {
      this.http
        .put(environment.apiLogin + "/user/v1/office/update-office", colaborador, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            //console.log()
            reject(error);
          }
        );
    });
  }
  putUserDetails(userDetails) {
    return new Promise((resolve, reject) => {
      this.http
        .put(environment.apiLogin + this.apiBase + "me", userDetails, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  putUserPassword(newPassword) {
    return new Promise((resolve, reject) => {
      this.http
        .put(environment.apiLogin + this.apiBase + "password/me", newPassword, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  putUserRegenaretePassword(newPassword) {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          environment.apiLogin + this.apiBase + "regenerate-password",
          newPassword,
          {
            headers: { Authorization: this.oauthService.authorizationHeader() },
          }
        )
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  delCollaborator(colaborador: Pessoa) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(environment.apiLogin + this.apiBase + colaborador.id, {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  postCompanyCollaborator(idCollaborator, idCompany: any[]) {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          environment.apiLogin + this.apiCompanyCollaborator + idCollaborator,
          idCompany,
          {
            headers: { Authorization: this.oauthService.authorizationHeader() },
          }
        )
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  putCompanyCollaborator(idCollaborator, idCompany: any[]) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiCompanyCollaborator + idCollaborator, idCompany, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  delCompanyCollaborator(idCollaborator, idCompany) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          environment.apiLogin +
            this.apiCompanyCollaborator +
            idCollaborator +
            "/" +
            idCompany,
          {
            headers: { Authorization: this.oauthService.authorizationHeader() },
          }
        )
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getOverdueBillings() {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiLogin + "/user/v1/asaas_billing/overdue", {
          headers: { Authorization: this.oauthService.authorizationHeader() },
        })
        .subscribe(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  postNewPassword(email) {
    //console.log(email.email);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiPassword + "-new?platform=farmcont&email=" + email.email, {headers: { Authorization: this.oauthService.authorizationHeader() },})
        .subscribe((result) => {
            resolve(result);
          }, (error) => {
            reject(error);
          });
    });
  }

  resetPassword(token) {
    //console.log(token)
    //console.log("o endpoint",environment.apiLogin + this.apiPassword + "-after-token?token=" + token )
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiPassword + "-after-token?token=" + token, {headers: { Authorization: this.oauthService.authorizationHeader() },})
        .subscribe((result) => {
            resolve(result);
          }, (error) => {
            reject(error);
          });
    });
  }

  newResetPassword(obj) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiPassword + "-after-token-new", obj)
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

  postImageOffice(file: Blob) {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiBase + 'upload-image', formData, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao buscar arquivo', error);
          reject(error);
        });
    });
  }

  deleteImageOffice() {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiBase + 'delete-image', { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao buscar arquivo', error);
          reject(error);
        });
    });
  }

  getImageOffice(){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase + 'visualize-image', { headers: { Authorization: this.oauthService.authorizationHeader() }, responseType: 'blob' })
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

  getUserPerformance(period, type){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiPerformance + 'all-collaborators/' + type + '/' + period, { headers: { Authorization: this.oauthService.authorizationHeader() } })
      .subscribe((result) => {
        resolve(result);
      }, (error) => {
        reject(error);
      });
    })
  }

  getUserConfig(){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiConfig, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    })
  }

  getCompanyPerformance(period, type){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiPerformance + 'all-companies/' + type + '/' + period, { headers: { Authorization: this.oauthService.authorizationHeader() } })
      .subscribe((result) => {
        resolve(result);
      }, (error) => {
        reject(error);
      });
    })
  }

  postUserConfig(data){
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiConfig, data, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

  getCompanyReport(params) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiCompany}report`, params, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

}
