import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {Injectable} from "@angular/core";

@Injectable()
export class AutomationProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  apiAutomation: string = "/fiscal/v1/automations/";

  getAutomationsByOffice() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiAutomation, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getAutomationByGroupId(groupId) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiAutomation + 'by-group-id/' + groupId, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postAutomation(obj) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiAutomation, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putAutomation(obj) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiAutomation, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  deleteAutomation(code) {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiAutomation + code, {headers: {Authorization: this.oauthService.authorizationHeader()}}).subscribe
      ((result: any[]) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    })
  }

  putAutomations(obj) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiAutomation + 'update-priority', obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }


}
