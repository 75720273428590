import {Component} from '@angular/core';

import {NgbModal, NgbModalOptions, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PropertyProvider} from "src/providers/company/property/property";
import {ErrorUtil} from "src/util/error";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ImovelDTO} from "src/model/dto/ImovelDTO";
import {Snackbar} from "src/util/snackbar";
import {AuthProvider} from "src/providers/auth/authProvider";
import {FormBuilder, FormGroup, FormControl, Validators} from "@angular/forms";
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CadastroImovelComponent} from 'src/app/routes/minhasPropriedades/cadastro-imovel/cadastro-imovel.component';
import {ConvertObjectPipe} from "src/util/pipes/ConvertObjectPipe";
import {BatchProvider} from 'src/providers/batch/batchProvider';
import {Bookkeeping} from 'src/model/dto/bookkeping/Bookkeeping';
import Swal from 'sweetalert2';
import { CheckPermission } from 'src/service/checkPermission';

@Component({
    selector: 'app-editar-imovel',
    templateUrl: './editar-imovel.component.html',
    styleUrls: ['./editar-imovel.component.scss']
  })

export class EditarImovelComponent {
  closeResult = '';

  public enviando: boolean = false;
  public imoveis: ImovelDTO[];
  public erroImovel: boolean = false;
  public erro: string;
  public formPassword: FormGroup;
  public propertyFilterCtrl: FormControl = new FormControl();
  public filter_property: ReplaySubject<ImovelDTO[]> = new ReplaySubject<ImovelDTO[]>(1);
  protected _onDestroy = new Subject<void>();
  public lancamentoForm: FormGroup;
  public batchForm: FormGroup;
  public selectedBookkeeping: Bookkeeping[];
  public enviado: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private propertyProvider: PropertyProvider,
              public errorUtil: ErrorUtil,
              private authProvider: AuthProvider,
              public snackbar: Snackbar,
              public formBuilder: FormBuilder,
              private batchProvider: BatchProvider,
              public checkPermission: CheckPermission) {
                this.getImoveis();
                this.formPassword = this.formBuilder.group({
                  password: [null, Validators.required],
                });
                this.batchForm = this.formBuilder.group({
                  imovel: [null, Validators.required],
                });
              }

  open(content) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      ariaLabelledBy: 'modal-basic-title',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    this.modalService.open(content, modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async getImoveis() {
    await this.propertyProvider.getAllProperty().then((imoveis: ImovelDTO[]) => {
      this.imoveis = imoveis;
      // this.novaConsultaImovel();
      this.erroImovel = false;
      this.initializeSearchSelect();
    }).catch((result: HttpErrorResponse) => {
      this.erroImovel = true;
      this.erro = this.errorUtil.checkErrorStatus(result, result.status, result.error, 'property');
    });

  }

  async onYesClick() {
    this.enviado = true;
    if(this.batchForm.valid) {
      this.enviando = true;
      await this.authProvider.revalidateLogin(this.formPassword.value.password).then(async value => {
        let bookkeepingIds = this.selectedBookkeeping.map(b => b.doc.id);
        await this.batchProvider.postImovel(bookkeepingIds, this.batchForm.value.imovel.propertyCode).then((result: []) => {
          this.enviando = false;
          if(result.length == this.selectedBookkeeping.length && result.length != 0) {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Os lançamentos selecionados foram alterados com sucesso!',
              icon: 'success'
            });
          } else if(result.length > this.selectedBookkeeping.length && result.length != 0) {
            Swal.fire({
              title: 'Atenção!',
              text: 'Foi solicitada alteração em ' + (this.selectedBookkeeping.length) + ' lançamentos, porém, somente ' + (result.length) + ' foram alterados.',
              icon: 'warning'
            });
          } else if(result.length == 0) {
            Swal.fire({
              title: 'Erro!',
              text: 'Nenhum lançamento foi alterado',
              icon: 'error'
            });
          }
          this.activeModal.close(result);
        }).catch((response: HttpErrorResponse) => {
          this.enviando = false;
          this.activeModal.dismiss();
          if(response.error.error_description.includes("There is blocked periods")) {
            Swal.fire({
              title: 'Atenção!',
              text: 'Há lançamentos cadastrados dentro de um bloqueio de período. ' +
                     (this.checkPermission.isAccountantAdmin() ?
                     'Remova o bloqueio para poder alterar uma escrituração com um lançamento nesta data.' :
                     'Contate um administrador para que o bloqueio seja removido.'),
              icon: 'error'
            });
          } else {
            Swal.fire({
              title: 'Erro!',
              text: 'Nenhum lançamento foi alterado. Houve um erro de conexão com o servidor!',
              icon: 'error'
            });
          }
        });
      }).catch(reason => {
        this.enviando = false;
        this.activeModal.dismiss();
        Swal.fire({
          title: 'Falha na autenticação',
          text: 'Não foi possível alterar os lançamentos. Ocorreu um problema na autenticação!',
          icon: 'error'
        });
      });
    }

    this.enviando = false;
  }

  protected filterProperties() {
    if (!this.imoveis) {
      return;
    }
    // get the search keyword
    let search = this.propertyFilterCtrl.value;
    if (!search) {
      this.filter_property.next(this.imoveis.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filter_property.next(
      this.imoveis.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  initializeSearchSelect() {
    this.imoveis.sort((a, b) => parseInt(a.propertyCode) - parseInt(b.propertyCode));
    this.filter_property.next(this.imoveis.slice());
    this.propertyFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProperties();
      });
  }

  newOptionSelect() {
    this.openModalNewOption(CadastroImovelComponent, this.imoveis);
  }

  openModalNewOption(modalName, list) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(modalName, modalOptions);
    modalRef.result.then((result) => {
      let pipe = new ConvertObjectPipe();
        list.push(result);
        this.initializeSearchSelect();
      }, () => {});
  }

  // close(){ this.modalService.dismissAll();}
}
