import {AccountPlanComponents} from "../AccountPlanComponents";
import {json} from "ng2-validation/dist/json";


export abstract class AccountComponent {
  id: number;
  customId: number;
  description: string;
  featureType: string;
  companyId: number;
  historicId: number;
  participantIds: number[];
}
