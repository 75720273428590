import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {Injectable} from "@angular/core";
import {GrouperDTOResponse} from "../../model/dto/GrouperDTOResponse";

@Injectable()
export class FolderProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  apiBase: string = "/main-records/v1/folder/";

  getAllFolders() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + 'by_company/' + localStorage.getItem('idEmpresa'), {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: GrouperDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getFoldersByCompanyId(id){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase + 'by_company/' + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: GrouperDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getInactiveFolders() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase + 'by-company/status-false/' + localStorage.getItem('idEmpresa'), {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: GrouperDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postFolder(grouper) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBase + 'by_company/' + localStorage.getItem('idEmpresa'), grouper, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: GrouperDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putFolder(account) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase  + 'by_company/' +  localStorage.getItem('idEmpresa'), account, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: GrouperDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delFolder(id) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiBase + localStorage.getItem('idEmpresa') + '/' + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: GrouperDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
}
