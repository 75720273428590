import { Component, Inject, OnInit } from "@angular/core";
import { SettingsService } from "../../../core/settings/settings.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CustomValidators } from "ng2-validation";
import { OAuthService } from "angular-oauth2-oidc";
import { authPasswordFlowConfig } from "../../../../service/service";
import { Router } from "@angular/router";
import { Snackbar } from "../../../../util/snackbar";
import {
  menuConsultor,
  menuContador,
  menuContadorFuncionario,
  menuSuper,
} from "../../menu";
import { MenuService } from "../../../core/menu/menu.service";
import { UserProvider } from "../../../../providers/user/user";
import * as moment from "moment";
import { EstadosMunicipiosUtil } from "../../../../util/estados-municipios";
import { Banks } from "../../../../util/banks";
import { CheckPermission } from "../../../../service/checkPermission";
import { environment } from "../../../../environments/environment";
import Swal from "sweetalert2";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  valForm: FormGroup;
  private password: string;
  private loginFailed: boolean;
  logando = false;
  public captchaSolved = false;
  public siteKey: string;
  public isGerensia = false;
  public overdueBillings: boolean;

  constructor(
    public modalService: NgbModal,
    public settings: SettingsService,
    fb: FormBuilder,
    private snackBar: Snackbar,
    private menuService: MenuService,
    private userProvider: UserProvider,
    private checkPermissionUser: CheckPermission,
    private estadosMunicipiosUtil: EstadosMunicipiosUtil,
    private banksUtil: Banks,
    private router: Router,
    private oauthService: OAuthService
  ) {
    this.siteKey = "6LfHZ24aAAAAAEIwVK8cNzX2MEV7Zy19LvtQAV3p";
    this.valForm = fb.group({
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
      password: [null, Validators.required],
      recaptcha: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.alreadyAuthenticated();
    if (window.location.href.includes("gerensia")) {
      this.isGerensia = true;
    }
  }

  openSnackBar(mensagem: string, tipo: string) {
    this.snackBar.open(mensagem, tipo);
  }

  private loginWithPassword(user: string, password: string) {

    this.oauthService.configure(authPasswordFlowConfig);
    this.oauthService
      .fetchTokenUsingPasswordFlowAndLoadUserProfile(user, password).then((resp: LoginResponse) => {
      this.openSnackBar("Usuário logado com sucesso!", "success");
      document.cookie = `access_token=${localStorage.getItem('access_token')};path=/;domain=.farmcont.com.br`
  //    console.log("resp",resp)
      if (resp.details) {
        localStorage.setItem('idEmpresa', `${resp.details.companyIds[0]}`);
       // console.log("teste",localStorage.getItem('access_token'))

      }
      this.userProvider.getImageOffice().then((value: Blob) => {
        //console.log("VALOR VINDO DO LOGIN", value)
        if(value != null && value.type == "application/octet-stream"){
          //console.log("success, setting up chosen");
          const file = value;
          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.addEventListener('load', () => {
            const url = fr.result
            localStorage.setItem('userPicture', url as string);
            //console.log("picture in local storage", localStorage.getItem('userPicture'));
          })
        } else{
          //console.log("null or wrong type, setting up default");
          localStorage.setItem('userPicture', 'assets/img/user/02.png');
        }
      }).catch(err => {
        console.log(err);
        //console.log("error, setting up default");
        localStorage.setItem('userPicture', 'assets/img/user/02.png');
      });
     // localStorage.getItem("access_token")
    //  console.log( "aaaaaaaaaaaaaaaaaa",     localStorage.getItem("access_token")
     // )
      //console.log( "zzzzzzz",     localStorage.getItem("refresh_token")
      //)
      //document.cookie = `access_token=${localStorage.getItem("access_token")};path=/;domain=.farmcont.com.br`;
      //document.cookie = `token_type=${localStorage.getItem('token_type')};path=/;domain=.farmcont.com.br`;
      //document.cookie = `refresh_token=${localStorage.getItem('refresh_token')};path=/;domain=.farmcont.com.br`;
      //document.cookie = `expires_in=${ localStorage.getItem('expires_in')};path=/;domain=.farmcont.com.br`;
      //document.cookie = `scope=${ localStorage.getItem('scope')};path=/;domain=.farmcont.com.br`;

      this.checkPermission();
      this.setBasicData();
    }).catch(err => {
      if (err.status == 400) {
        this.openSnackBar("Usuário ou senha incorretos", "erro");
        this.logando = false;
      } else {
        this.openSnackBar("Não foi possível conectar", "erro");
        this.logando = false;
      }
      this.loginFailed = true;
    });
  }

  private setBasicData() {
    this.estadosMunicipiosUtil.setCountry();
    this.banksUtil.setBanks();
  }

  private alreadyAuthenticated() {

    if (this.oauthService.hasValidAccessToken()) {
      this.checkPermission();
    }
  }

  private givenAuthorities() {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims["authorities"];
  }

  private givenCompanyId() {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims["userAuthentication"].principal.companyIds[0];
  }

  // private checkPermission() {
  //   localStorage.setItem('workDate', (moment().format('YYYY-MM-DD')));
  //   this.menuService.menuItemsContador = [];
  //   this.menuService.menuItemsConsultor = [];
  //   if (this.checkPermissionUser.isSuperuser()) {
  //     this.menuService.addMenu(menuSuper, 2);
  //     this.router.navigate(['contador']);
  //   } else if (this.checkPermissionUser.isConsultant()) {
  //     this.menuService.addMenu(menuConsultor, 4);
  //     this.router.navigate(['consultor']);
  //   } else if (this.checkPermissionUser.isAccountant()) {
  //     if (this.checkPermissionUser.isAccountantAdmin()) {
  //       this.menuService.addMenu(menuContador, 2);
  //     } else {
  //       this.menuService.addMenu(menuContadorFuncionario, 2);
  //     }
  //     this.router.navigate(['contador']);
  //   } else {
  //       this.logout();
  //   }
  //   // this.menuService.addMenu(menuConsultor, 4);
  //   // this.router.navigate(['relatorios']);
  // }

  private checkPermission() {
    //console.log("itenobj",JSON.parse(localStorage.getItem("id_token_claims_obj")));

    localStorage.setItem('workDate', (moment().format('YYYY-MM-DD')));
    this.menuService.menuItemsContador = [];
    this.menuService.menuItemsConsultor = [];
    if (this.checkPermissionUser.isSuperuser()) {
      this.menuService.addMenu(menuSuper, 2);
      this.router.navigate(["contador"]);
    } else {
      if (this.checkPermissionUser.isAccountant()) {
        this.userProvider.getOverdueBillings().then((result) => {
          if (result > 0) {
            let timerInterval;
            Swal.fire({
              title: "<strong>AVISO</strong>",
              icon: "warning",
              html:
                "Você poderá prosseguir em <strong></strong> segundos.<br/><br/>" +
                "Detectamos que você tem " +
                result +
                " cobranças vencidas há mais de <b>10 dias</b>. O sistema ficará <b>bloqueado</b> até que as pendências sejam quitadas. Você será <b>redirecionado</b> para o menu financeiro. <p></p>",
              focusConfirm: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonText: "OK",
              timer: 10000,

              onOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                  Swal.getHtmlContainer().querySelector("strong").textContent =
                    (Swal.getTimerLeft() / 1000).toFixed(0);
                  //console.log((Swal.getTimerLeft() / 1000))
                  if (Swal.getTimerLeft() / 1000 < 0.1) Swal.stopTimer();
                }, 100);
                setTimeout(() => {
                  Swal.hideLoading();
                }, 10000);
              },
            }).then((result) => {
              clearInterval(timerInterval);
              this.menuService.clearMenu();
              this.router.navigate(["contador/cobrancas"]);
            });
          }
        });

        if (this.checkPermissionUser.isAccountantAdmin()) {
          //if (result == 0)
          this.menuService.addMenu(menuContador, 2);
          this.router.navigate(["contador"]);
        } else if (this.checkPermissionUser.isAccountantCollaborator()) {
          //if (result == 0)
          this.menuService.addMenu(menuContadorFuncionario, 2);
          this.router.navigate(["contador"]);
        }
      } else {
        this.logout();
      }
    }
  }

  submitForm($ev, value: any) {
    if (this.captchaSolved) {
      this.logando = true;
      $ev.preventDefault();
      for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
      }
      if (this.valForm.valid) {
        //console.log( "antesdologin",     localStorage.getItem("access_token")
        //)
        //console.log( "zzzzzzz",     localStorage.getItem("refresh_token")
        //)
        this.loginWithPassword(value.email, value.password);
      } else {
        this.logando = false;
      }
    } else {
      this.snackBar.open("Resolva o captcha antes de fazer login", "erro");
    }
  }

  private logout() {
    this.oauthService.logOut(true);
    this.router.navigate(["login"]);
    setTimeout(() => {
      this.snackBar.open("Usuário não possui permissão acesso!", "erro");
    });
  }

  public forgotPassword() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ForgotPasswordComponent,modalOptions);
    modalRef.result.then((result) => {
        console.log(result);
    }, () => {});
  }

  handleSuccess() {
    this.captchaSolved = true;
  }
}

class LoginResponse {
  authenticated: boolean;
  authorities: [{ authority: string }];
  details: {
    accountNonExpired: boolean;
    accountNonLocked: boolean;
    authorities: [{ authority: string }];
    collaboratorIds: number[];
    companyIds: number[];
    credentialsNonExpired: boolean;
    enabled: boolean;
    id: number;
    lastName: string;
    memberId?: number;
    memberUserIds: number[];
    name: string;
    notAfter?: any;
    notBefore?: any;
    office: {
      id: number;
      name: string;
    };
    userName: string;
  };
  oauth2Request: {
    approved: boolean;
    authorities: [];
    clientId: string;
    extensions: {};
    grantType: string;
    redirectUri: any;
    refresh: boolean;
    refreshTokenRequest: any;
    requestParameters: {
      grant_type: string;
      scope: string;
      username: string;
    };
    resourceIds: string[];
    responseTypes: [];
    scope: string[];
  };
  principal: {
    accountNonExpired: boolean;
    accountNonLocked: boolean;
    authorities: [{ authority: string }];
    collaboratorIds: number[];
    companyIds: number[];
    credentialsNonExpired: boolean;
    enabled: boolean;
    id: number;
    lastName: string;
    memberId: any;
    memberUserIds: [];
    name: string;
    notAfter: any;
    notBefore: any;
    office: { id: number; name: string };
    userName: string;
  };
  userAuthentication: {
    authenticated: boolean;
    authorities: [{ authority: string }];
    credentials: any;
    details: {
      grant_type: string;
      scope: string;
      username: string;
    };
    name: string;
    principal: {
      accountNonExpired: boolean;
      accountNonLocked: boolean;
      authorities: [{ authority: string }];
      collaboratorIds: number[];
      companyIds: number[];
      credentialsNonExpired: boolean;
      enabled: boolean;
      id: number;
      lastName: string;
      memberId: any;
      memberUserIds: [];
      name: string;
      notAfter: any;
      notBefore: any;
      office: { id: number; name: string };
      userName: string;
    };
  };
}
