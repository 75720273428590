import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {Version} from "../../model/Version";

@Injectable()
export class SuperUserProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  private apiBase = environment.apiLogin + "/user/v1/super/office";
  private apiUser = environment.apiLogin + "/auth/v1/user/change-user-password";
  private apiFile = environment.apiLogin + "/main-records/v1/account/import"

  getOffices() {
    return new Promise((resolve, reject) => {

      this.http.get(this.apiBase + '-list', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Version) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getOfficeById(id) {
    return new Promise((resolve, reject) => {

      this.http.get(this.apiBase + '-by-id/'+id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Version) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putOffice(officeId: number) {
    return new Promise((resolve, reject) => {

      this.http.put(this.apiBase, officeId, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Version) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return new Promise((resolve, reject) => {
      this.http.post(this.apiFile, formData, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Version) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  changeUserPassowrd(user: any) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUser, user, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Version) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

}
