import { HttpClient } from "@angular/common/http";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "../../environments/environment.prod";
import { Injectable } from "@angular/core";

@Injectable()
export class ExportProvider {

    constructor(public http: HttpClient, public oauthService: OAuthService) {
    }

    apiBase: string = "/main-records/v1/exports/export";

    getExports() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiLogin + this.apiBase, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    postExport(exportObj) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiLogin + this.apiBase, exportObj, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    putExport(exportObj) {
        return new Promise((resolve, reject) => {
            this.http.put(environment.apiLogin + this.apiBase, exportObj, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    deleteExport(id) {
        return new Promise((resolve, reject) => {
            this.http.delete(environment.apiLogin + this.apiBase + "/" + id, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    getExportMappingByExportId(id) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiLogin + this.apiBase + "-mapping/" + id, {
                headers:
                    { Authorization: this.oauthService.authorizationHeader() }
            })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    getExportMappingByAccountId(id) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiLogin + this.apiBase + "-mapping/by-account-plan-id/" + id, {
                headers:
                    { Authorization: this.oauthService.authorizationHeader() }
            })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    postExportMapping(obj) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiLogin + this.apiBase + "-mapping", obj, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }


    deleteExportMapping(id) {
        return new Promise((resolve, reject) => {
            this.http.delete(environment.apiLogin + this.apiBase + "-mapping/" + id, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }


    postLayout(obj) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiLogin + this.apiBase + "-layout", obj, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    getLayouts() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiLogin + this.apiBase + "-layout", { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    getLayout(id){
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiLogin + this.apiBase + "-layout/" + id, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    putLayout(obj) {
        return new Promise((resolve, reject) => {
            this.http.put(environment.apiLogin + this.apiBase + "-layout", obj, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }

    deleteLayout(id) {
        return new Promise((resolve, reject) => {
            this.http.delete(environment.apiLogin + this.apiBase + "-layout/" + id, { headers: { Authorization: this.oauthService.authorizationHeader() } })
                .subscribe((result) => {
                    resolve(result);
                },
                    (error) => {
                        reject(error);
                    });
        });
    }
}
