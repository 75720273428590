import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {HttpErrorResponse} from "@angular/common/http";
import {CompanyNotesProvider} from "../../../../../providers/company/company/company-notes";
import * as moment from 'moment';
import {TabsetComponent} from "ngx-bootstrap";

@Component({
  selector: 'app-modal-anotacoes',
  templateUrl: './modal-anotacoes.component.html',
  styleUrls: ['./modal-anotacoes.component.scss']
})
export class ModalAnotacoesComponent implements OnInit {

  public visualizar: boolean = false;
  public editar: boolean = false;
  public enviado: boolean = false;
  public enviando: boolean = false;
  public form: FormGroup;
  public existente;
  public first: boolean = true;

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public provider: CompanyNotesProvider,
              public snackBar: Snackbar,
              public erroUtil: ErrorUtil) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      notification: [false],
      date: [''],
    });

    if (this.existente != undefined) {
      this.form.patchValue({
        title: this.existente.title,
        description: this.existente.description,
      });
      if (this.existente.dueDate) {
        this.form.get('notification').setValue(true);
        this.form.patchValue({
          date: moment(this.existente.dueDate).format('YYYY-MM-DD'),
        });
      }
    }
  }

  newOrEdit() {
    this.enviado = true;
    this.enviando = true;
    if (this.checkForm()) {
      let obj: any = {
        title: this.form.get('title').value,
        description: this.form.get('description').value || null,
        createdAt: moment().format('YYYY-MM-DDTHH:mm:ss'),
        dueDate: null
      };
      if (this.form.get('notification').value == true) {
        obj.dueDate = moment(this.form.get('date').value).format('YYYY-MM-DDTHH:mm:ss');
      }
      if (!this.editar) {
        this.provider.post(obj).then((resp) => {
          this.snackBar.openLong("Sucesso! Anotação inserida com sucesso!", 'success');
          this.activeModal.close(resp);
        }).catch((result: HttpErrorResponse) => {
          console.log(result);
          this.enviando = false;
          this.snackBar.openLong('Não foi possível inserir! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'grouper'), 'erro');
        });
      } else {
        obj.id = this.existente.id;
        this.provider.put(obj).then((resp) => {
          this.snackBar.openLong("Sucesso! Anotação editada com sucesso!", 'success');
          this.activeModal.close(resp);
        }).catch((result: HttpErrorResponse) => {
          console.log(result);
          this.enviando = false;
          this.snackBar.openLong('Não foi possível editar! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'folder'), 'erro');
        });
      }
    } else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

  checkForm(): boolean {
    let obj = this.form.getRawValue();
    if (!obj.title && obj.title.length <= 0) return false;
    else if (this.form.get('notification').value == true && (!obj.date)) return false;
    return true;
  }

  formatDate(date: any) {
    return moment(date).format('DD/MM/YYYY');
  }

  isToday(date: any) {
    return moment(date).isSame(moment(), 'day');
  }

  pastToday(date: any) {
    return moment(date).isBefore(moment(), 'day');
  }
}
