import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ReplaySubject, Subject} from 'rxjs';
import {ImovelDTO} from 'src/model/dto/ImovelDTO';
import {PropertyProvider} from 'src/providers/company/property/property';
import {ErrorUtil} from 'src/util/error';
import {Snackbar} from 'src/util/snackbar';
import {ConvertObjectPipe} from "../../../../../util/pipes/ConvertObjectPipe";
import {takeUntil} from 'rxjs/operators';
import {ProductProvider} from 'src/providers/product/product';
import {CadastroProdutosComponent} from '../../../cadastros/produtos/cadastro-produtos/cadastro-produtos.component';
import {CadastroImovelComponent} from '../../../minhasPropriedades/cadastro-imovel/cadastro-imovel.component';
import * as moment from 'moment';
import {StockProvider} from 'src/providers/stock/stock';
import Swal from 'sweetalert2';
import {DateUtil} from 'src/util/dateUtil';
import {ParticipantProvider} from 'src/providers/participant/participant';
import {ParticipantDTOResponse} from 'src/model/dto/ParticipantDTOResponse';
import {ParticipantPageable} from 'src/model/dto/bookkeping/ParticipantPageable';
import {CadastroPessoasComponent} from "../../../cadastros/participantes/cadastro-pessoas/cadastro-pessoas.component";

@Component({
  selector: 'app-lancamento-estoque',
  templateUrl: './lancamento-estoque.component.html',
  styleUrls: ['./lancamento-estoque.component.scss']
})
export class LancamentoEstoqueComponent implements OnInit {

  lancamentoEstoqueForm: FormGroup;

  protected _onDestroy = new Subject<void>();

  public carregando: boolean = false;
  public enviando: boolean = false;
  public enviado: boolean = false;
  public editar: boolean = false;
  public lancamentoExistente = {};

  //Property
  public propertyFilterCtrl: FormControl = new FormControl();
  public filter_property: ReplaySubject<ImovelDTO[]> = new ReplaySubject<ImovelDTO[]>(1);
  public imoveis: ImovelDTO[] = [];

  //Product
  public productFilterCtrl: FormControl = new FormControl();
  public filter_product: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public products: any[] = [];

  //Participant
  public participantFilterCtrl: FormControl = new FormControl();
  public participantPage: number = 0;
  public participantes: ParticipantDTOResponse[] = [];
  public filter_participant: ReplaySubject<ParticipantDTOResponse[]> = new ReplaySubject<ParticipantDTOResponse[]>(1);
  public participantLoaded: boolean = false;
  public currentParticipant: ParticipantDTOResponse = new ParticipantDTOResponse();
  public firstFromForm: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public snackBar: Snackbar,
    public erroUtil: ErrorUtil,
    public propertyProvider: PropertyProvider,
    public productProvider: ProductProvider,
    public modalService: NgbModal,
    public stockProvider: StockProvider,
    public dateUtil: DateUtil,
    public participantProvider: ParticipantProvider
  ) {
  }

  ngOnInit() {
    this.initializeFormConfiguration();
    this.initializeData();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeData() {
    this.getProperties();
    this.getProducts();
    this.getParticipants();
    if (this.editar) {
      this.setLancamentoExistenteForm();
    }
  }

  setLancamentoExistenteForm() {
    let property = this.lancamentoExistente.hasOwnProperty('property') ? this.lancamentoExistente['property']['code'] : this.lancamentoExistente['propertyCode'];
    let product = this.lancamentoExistente.hasOwnProperty('product') ? this.lancamentoExistente['product']['id'] : this.lancamentoExistente['stockProductId'];
      this.lancamentoEstoqueForm.patchValue({
        companyId: localStorage.getItem('idEmpresa'),
        pessoa: this.currentParticipant,
        propertyCode: property,
        originId: this.lancamentoExistente['originId'],
        stockProductId: product,
        docNumber: this.lancamentoExistente['docNumber'],
        amount: this.lancamentoExistente['amount'],
        historic: this.lancamentoExistente['historic'],
        postDate: this.lancamentoExistente['postDate'],
        dfeId: this.lancamentoExistente['dfeId'],
        dfeProductId: this.lancamentoExistente['dfeProductId'],
      });
    let id = this.lancamentoExistente.hasOwnProperty('participant') ? this.lancamentoExistente['participant']['id'] : this.lancamentoExistente['participantId'];
    this.participantProvider.getParticipantById(id).then((response: ParticipantDTOResponse) => {
      this.selectParticipant(response);
    }).catch((result: HttpErrorResponse) => {
      this.snackBar.open('Não foi possível carregar o lançamento de estoque' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'stcck'), 'error');
    });
  }

  initializeFormConfiguration() {
    this.lancamentoEstoqueForm = this.fb.group({
      companyId: [localStorage.getItem('idEmpresa'), Validators.required],
      propertyCode: ['', Validators.required],
      originId: ['', Validators.required],
      stockProductId: ['', Validators.required],
      docNumber: [''],
      amount: ['', Validators.required],
      pessoa: [''],
      historic: [''],
      postDate: [this.dateUtil.removeTimeZone(localStorage.getItem('workDate')), Validators.required],
      dfeId: [''],
      dfeProductId: [''],
    });
  }

  getFirstPage() {
    let pipe = new ConvertObjectPipe();
    this.participantPage = 0;
    this.participantFilterCtrl.setValue('');
    this.participantProvider.getParticipantsByPage(this.participantFilterCtrl.value, this.participantPage).then((participantes: ParticipantPageable) => {
      this.participantes = pipe.transform(participantes.content, 'participantes');
      this.participantPage++;
      this.currentParticipant = this.lancamentoEstoqueForm.value.pessoa;
      if (this.currentParticipant == undefined) {
        this.firstFromForm = false;
      } else {
        this.firstFromForm = true;
        this.participantes.unshift(this.currentParticipant);
      }

      this.filterParticipants();
    });
  }
  getNextBatch(isScroll: boolean) {
    let search = '';
    if (this.participantFilterCtrl.value != undefined) {
      search = this.participantFilterCtrl.value;
    }

    let pipe = new ConvertObjectPipe();
    if (isScroll) {
      this.participantProvider.getParticipantsByPage(search, this.participantPage).then((participantes: ParticipantPageable) => {
        this.participantLoaded = participantes.last;
        this.participantPage++;

        this.participantes = this.participantes.concat(
          pipe.transform(participantes.content, 'participantes')
        );
        this.filterParticipants();
      });
    } else {
      if (search.length >= 3 || search.length == 0) {
        this.participantPage = 0;

        this.participantProvider.getParticipantsByPage(search, this.participantPage).then((participantes: ParticipantPageable) => {
          this.participantLoaded = participantes.last;
          this.participantPage++;
          this.participantes = pipe.transform(participantes.content, 'participantes');
          if (this.editar && search.length == 0) {
            this.participantes.unshift(this.currentParticipant);
            this.firstFromForm = true;
          } else {
            this.firstFromForm = false;
          }
          this.filterParticipants();
        });
      }
    }
  }

  protected filterParticipants() {
    this.filter_participant.next(this.participantes.slice());
  }

  newParticipant() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroPessoasComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result) {
        let pipe = new ConvertObjectPipe();
        this.participantes.push(pipe.transform(result, 'participantes'));
        this.filterParticipants();
        this.lancamentoEstoqueForm.get('pessoa').setValue(result);
      }
    }, () => {
    });
  }

  selectParticipant(participant) {
    let exist = false;
    let eventInsert = participant;
    this.participantes.forEach((participant, index: number) => {
      if (this.currentParticipant.id === eventInsert.id) {
        exist = true;
        this.snackBar.open('Participante já selecionado!', 'atencao');
      }
      if (!exist && this.participantes.length === index + 1) {
        this.participantes.push(eventInsert);
        this.participantFilterCtrl.setValue(null);
      }
    });
    if (this.participantes.length === 0) {
      this.participantes.push(eventInsert);
      this.participantFilterCtrl.setValue(null);
    }
    this.currentParticipant = participant;
    this.lancamentoEstoqueForm.get('pessoa').setValue(participant);
  }

  submitAndKeepData() {
    this.enviando = true;
    this.enviado = true;

    if (this.lancamentoEstoqueForm.valid) {
      let obj = this.lancamentoEstoqueForm.value;
      obj.postDate = moment(obj.postDate).format('YYYY-MM-DD');
      obj.participantId = obj.pessoa.id;
      delete obj.pessoa;
      if (this.editar) {
        this.putLancamento(obj);
      } else {
        this.postLancamento(obj);
      }
    } else {
      this.enviando = false;
      this.snackBar.openLong('Preencha todos os campos obrigatórios', 'erro');
    }
  }

  submitForm() {
    this.enviando = true;
    this.enviado = true;
    if (this.lancamentoEstoqueForm.valid) {
      let obj = this.lancamentoEstoqueForm.value;
      obj.postDate = moment(obj.postDate).format('YYYY-MM-DD');
      obj.participantId = obj.pessoa.id;
      delete obj.pessoa;
      if (this.editar) {
        this.putLancamento(obj, true);
      }
      else {
        this.postLancamento(obj, true);
      }
    } else {
      this.enviando = false;
      this.snackBar.openLong('Preencha todos os campos obrigatórios', 'erro');
    }
  }

  postLancamento(obj, close?: boolean) {
    this.stockProvider.postLancamento(obj).then((result: any) => {
      this.enviando = false;
      Swal.fire({
        title: 'Sucesso!',
        text: 'Lançamento de estoque realizado com sucesso.',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        if (close) {
          this.activeModal.close();
        } else {
          this.lancamentoExistente = obj;
          this.resetModal();
        }
      });
    }).catch((result: HttpErrorResponse) => {
      this.enviando = false;
      this.snackBar.open('Não foi possível realizar o lançamento de estoque ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'stcck'), 'error');
    });
  }

  putLancamento(obj, close?: boolean) {
    obj.id = this.lancamentoExistente['id'];
    this.stockProvider.putLancamento(this.lancamentoEstoqueForm.value).then((result: any) => {
      this.enviando = false;
      Swal.fire({
        title: 'Sucesso!',
        text: 'Edição de estoque realizada com sucesso.',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        if (close) {
          this.activeModal.close();
        } else {
          this.resetModal();
        }
      });
    }).catch((result: HttpErrorResponse) => {
      this.enviando = false;
      this.snackBar.open('Não foi possível realizar a edição de estoque ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'stcck'), 'error');
    });
  }

  resetModal() {
    this.enviado = false;
    this.enviando = false;
    this.editar = false;
    this.lancamentoEstoqueForm.reset();
    this.setLancamentoExistenteForm();
  }

  deleteLancamento() {
    Swal.fire({
      title: 'Tem certeza que deseja excluir o lançamento de estoque?',
      text: "Esta ação não poderá ser desfeita",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.enviando = true;
        this.stockProvider.deleteLancamento(this.lancamentoExistente['id']).then((result: any) => {
          this.enviando = false;
          this.snackBar.open('Lançamento de estoque excluído com sucesso', 'success');
          this.activeModal.close('deleted');
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          this.snackBar.open('Não foi possível excluir o lançamento de estoque' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'stcck'), 'error');
        });

      }
    })
  }

  getParticipants() {
    this.getFirstPage();
    this.filter_participant.next(this.participantes.slice());
    this.participantFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterParticipants();
      });
  }

  getProperties() {
    this.propertyProvider.getAllProperty().then((imoveis: any[]) => {
      let pipe = new ConvertObjectPipe();
      this.imoveis = pipe.transform(imoveis, 'imoveis');
      this.initializeSearchProperties();
      this.carregando = false;
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }

  initializeSearchProperties() {
    this.filter_property.next(this.imoveis.slice());
    this.propertyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterProperties();
    });
  }

  filterProperties() {
    if (!this.imoveis) {
      return;
    }
    let search = this.propertyFilterCtrl.value;
    if (!search) {
      this.filter_property.next(this.imoveis.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filter_property.next(
      this.imoveis.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  newProperty() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroImovelComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result !== 'cancelou') {
        this.getProperties();
      }
    }, () => {
    });
  }

  getProducts() {
    this.productProvider.getAllProducts().then((products: any[]) => {
      this.products = products;
      this.initializeSearchProducts();
      this.carregando = false;
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }

  initializeSearchProducts() {
    this.filter_product.next(this.products.slice());
    this.productFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterProducts();
    });
  }

  filterProducts() {
    if (!this.products) {
      return;
    }
    let search = this.productFilterCtrl.value;
    if (!search) {
      this.filter_product.next(this.products.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filter_product.next(
      this.products.filter(bank => bank.description.toLowerCase().indexOf(search) > -1)
    );
  }

  newProduct() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroProdutosComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result !== 'cancelou') {
        this.getProducts();
      }
    }, () => {
    });
  }
}
