import { Component, OnInit } from '@angular/core';

declare var Headway;

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutContadorComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        setTimeout(() => {
            Headway.init();
        });
    }
}
