import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {StockProvider} from 'src/providers/stock/stock';
import {GridComponent} from '@syncfusion/ej2-angular-grids';
import * as moment from 'moment';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {Snackbar} from "../../../../util/snackbar";
import {MemberProvider} from "../../../../providers/company/member/member";
import {CadastroSocioComponent} from "../../minhasPropriedades/cadastro-socio/cadastro-socio.component";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {ReportsUtil} from "../../../../util/Reports";
import {ModalExportComponent} from "../modal-export/modal-export.component";
import {ReportsHelper} from "../../../../util/ReportsHelper";
import {ConsultDateUtil} from "../../../../util/consultDateUtil";

@Component({
  selector: 'app-relatorio-estoque',
  templateUrl: './relatorio-estoque.component.html',
  styleUrls: ['./relatorio-estoque.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class RelatorioEstoqueComponent implements OnInit {

  @ViewChild('grid', { static: false }) grid: GridComponent;

  protected _onDestroy = new Subject<void>();

  public carregando: boolean = false;
  public relatorioForm: FormGroup;

  public dataSource: any[] = [];
  //Product
  public memberFilterCtrl: FormControl = new FormControl();
  public filter_member: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public members: any[] = [];

  public toolbarOptions: any[];

  public personalizado: boolean = false;

  public erro: boolean = false;
  public periodo = 'Personalizado'
  public contador: any = 0;
  public customAttributes: any;

  public lastOrder = {field: '', direction: '', searching: ""};

  constructor(
    public memberProvider: MemberProvider,
    public stockProvider: StockProvider,
    private _adapter: DateAdapter<any>,
    public snackBar: Snackbar,
    public fb: FormBuilder,
    public consultDateUtil: ConsultDateUtil,
    public reportsUtil: ReportsUtil,
    public reportsHelper: ReportsHelper,
    public modalService: NgbModal,
  ) { }

  ngOnInit() {
    this._adapter.setLocale('pt-BR');
    this.initializeFormConfiguration();
    this.initializeData();
    this.customAttributes = {class: 'customcss'};

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  actionBegin(args) {
    if (args.requestType === 'filterchoicerequest') {
      args.filterChoiceCount = 1000000; //here you can change the filterchoice count as per your requirement
    }
    if (args.requestType === 'sorting') {
      this.lastOrder.field = args.columnName;
      this.lastOrder.direction = args.direction;
    }
    if (args.requestType === 'searching') {
      this.lastOrder.searching = args.searchString;
    }
  }

  initializeFormConfiguration() {
    this.toolbarOptions = [
      {text: 'Exportar', tooltipText: 'Exportar', prefixIcon: 'e-pdfexport', id: 'export'},
    ];

    // this.setPeriod(true);

    this.relatorioForm = this.fb.group({
      ano: [this.consultDateUtil.year, Validators.required],
      startDate: [this.consultDateUtil.startDate, Validators.required],
      endDate: [this.consultDateUtil.endDate, Validators.required],
      memberCpfCnpj: [''],
    });
  }

  initializeData() {
    this.getMembers();
  }

  formatarValor(valor) {
    return new Intl.NumberFormat('pt-BR').format(valor);
  }

  toolbarClick(args: any): void {
    if (args.item.id === 'export') {
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : '',
      };
      const modalRef = this.modalService.open(ModalExportComponent, modalOptions);
      modalRef.componentInstance.texto = "os lançamentos de estoque";
      modalRef.componentInstance.relatorio = 'estoque';
      modalRef.result.then((result: any) => {
        if (result.type === 'pdf') {
          this.exportPDF(result.img);
        } else if (result.type === 'excel') {
          this.excelExport();
        }
      });
    }
  }

  exportPDF(img) {
    let period = this.consultDateUtil.year;
    if (this.personalizado) {
      let str = moment(this.consultDateUtil.startDate).format('DD/MM/YYYY');
      let str2 = moment(this.consultDateUtil.endDate).format('DD/MM/YYYY');

      period = str + ' - ' + str2;
    }

    const date = moment().format('DD/MM/YY HH:mm').toString();

    let members = 'Todos os produtores';

    if (this.relatorioForm.get('memberCpfCnpj').value != "") {
      members = this.relatorioForm.get('memberCpfCnpj').value.name + ' (' + this.relatorioForm.get('memberCpfCnpj').value.cpfCNPJ + ')';
    }

    let colunas = [
      {dataKey: 'previousAmount', halign: 'center'},
      {dataKey: 'initialStock',   halign: 'center'},
      {dataKey: 'birth',  halign: 'center'},
      {dataKey: 'purchase',  halign: 'center'},
      {dataKey: 'consumptionLosses',  halign: 'center'},
      {dataKey: 'sales',  halign: 'center'},
      {dataKey: 'entryAmount',  halign: 'center'},
      {dataKey: 'outputAmount',  halign: 'center'},
      {dataKey: 'finalBalance',  halign: 'center'},
    ];

    // @ts-ignore
    let font = this.reportsHelper.getFont((this.grid.getFilteredRecords().length > 0 ? this.grid.getFilteredRecords() : this.dataSource), this.lastOrder);
    let headers = this.reportsHelper.getHeaderFromGrid(this.grid);

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Estoque Geral - ${period}`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Estoque geral`,
        data: date,
        periodo: period,
        parametros: {
          "Produtor": members,
        },
        image: img,
        alturaExtra: !!img,
      },
      tabela: {
        colunas: headers,
        dados: font,
        estiloColunas: colunas,
        diminuir: true,
      },
    });
  }

  excelExport() {
    const date = moment().format('DD/MM/YY HH:mm').toString();

    let exportProperties = {
      fileName: `Relatorio de Estoque - ${date}.xlsx`,
    };
    this.grid.excelExport(exportProperties);
    this.grid.refresh();
  }

  async getRelatorio() {
    this.carregando = true;

    let data: any = {};
    if (this.personalizado) {
      this.consultDateUtil.monthly = false;
      data = this.consultDateUtil.getDateForRequest(false);
    } else {
      data.startDate = this.consultDateUtil.year + '-01-01';
      data.endDate = this.consultDateUtil.year + '-12-31';
    }

    let params = {
      startDate:  data.startDate,
      endDate: data.endDate,
      memberCpfCnpj: this.relatorioForm.get('memberCpfCnpj').value.cpfCNPJ
    }

    if (!params.startDate || !params.endDate) {
      this.snackBar.open('Informe o período', 'erro');
      this.carregando = false;
      this.erro = true;
      return;
    } else if (params.startDate > params.endDate) {
      this.snackBar.open('Data inicial não pode ser maior que a data final', 'erro');
      this.carregando = false;
      this.erro = true;
      return;
    }

    this.stockProvider.getRelatorioEstoque(params).then((result: any) => {
      this.dataSource = result;
      this.carregando = false;
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;

    });
  }

  getMembers() {
    this.memberProvider.getAllMembers().then((members: any[]) => {
      this.members = members;
      this.initializeSearchMembers();
      this.carregando = false;
    }).catch((result: HttpErrorResponse) => {
      this.carregando = false;
    });
  }

  newMember() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroSocioComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result) {
        this.getMembers();
      }
    }, () => {
    });
  }

  initializeSearchMembers() {
    this.filter_member.next(this.members.slice());
    this.memberFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterMembers();
    });
  }

  filterMembers() {
    if (!this.members) {
      return;
    }
    let search = this.memberFilterCtrl.value;
    if (!search) {
      this.filter_member.next(this.members.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filter_member.next(
      this.members.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  changeYear() {
    this.consultDateUtil.chosenYearHandler(this.relatorioForm.get('ano').value);
  }
}
