import {Injectable} from '@angular/core';

@Injectable()
export class MenuService {

  menuItems: Array<any>;
  menuItemsContador: any[];
  menuItemsConsultor: any[];

  constructor() {
    this.menuItems = [];
    this.menuItemsContador = [];
    this.menuItemsConsultor = [];
  }

  addMenu(items: Array<{
    text: string,
    heading?: boolean,
    link?: string,     // internal route links
    elink?: string,    // used only for external links
    target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
    parentAsTarget?: boolean,
    icon?: string,
    alert?: string,
    submenu?: Array<any>
  }>, type: number) {
    items.forEach((item) => {
      if(type==1){
        this.menuItems.push(item);
      }
      else if(type==2){
        this.menuItemsContador.push(item);
      }
      else if (type === 4) {
        this.menuItemsConsultor.push(item);
      }
    });
  }

  clearMenu(){
    this.menuItems = [];
    this.menuItemsContador = [];
    this.menuItemsConsultor = [];
  }

  getMenu() {
    return this.menuItems;
  }

  getMenuContador() {
    return this.menuItemsContador;
  }

  getMenuConsultor() {
    return this.menuItemsConsultor;
  }

}
