import {Component, OnInit, ViewChild} from '@angular/core';
import {DateUtil} from 'src/util/dateUtil';
import * as moment from 'moment';
import {DateAdapter} from '@angular/material';
import {FilterSettingsModel, parentsUntil, SelectionSettingsModel} from '@syncfusion/ej2-angular-grids';
import {BrMaskerIonicServices3} from 'brmasker-ionic-3';
import {Snackbar} from 'src/util/snackbar';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorUtil} from 'src/util/error';
import {StockProvider} from 'src/providers/stock/stock';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {LancamentoEstoqueComponent} from './lancamento-estoque/lancamento-estoque.component';
import {ProductProvider} from 'src/providers/product/product';
import {EnviarPlanilhaComponent} from './enviar-planilha/enviar-planilha.component';
import {AggregateRowModel} from "@syncfusion/ej2-grids/src/grid/models/models";
import {ReportsUtil} from "../../../util/Reports";


@Component({
  selector: 'app-estoque',
  templateUrl: './estoque.component.html',
  styleUrls: ['./estoque.component.scss']
})
export class EstoqueComponent implements OnInit {

  // DEFAULT CONFIGURATION
  public dateRange: ConsultDate = new ConsultDate();
  public activeMonth: any;
  public year: any;
  public years: any;
  public months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];

  // CARDS CONFIGURATION
  public estoqueInicial: number = 0;
  public saldo: number = 0;
  public totalPagamentos: number = 0;
  public totalRecebimentos: number = 0;
  public loadingCards: boolean = false;

  //TABLE CONFIGURATION
  @ViewChild('grid', { static: false }) grid;
  public customAttributes: any;
  public filterSettings: FilterSettingsModel;
  public filter: { type: string };
  public selectionSettings: SelectionSettingsModel;
  public monthly = true;
  public linksMonths = ['Mensal', 'Personalizado'];
  public activeLinkMonths = this.linksMonths[0];
  public tab: string = 'Saldo';
  public keyword: any;

  public lancamentos: [];
  public dataSourceMain: [];
  public produtos: [];
  public produtoSelecionado: any;

  public aggregates: AggregateRowModel[];

  public consultando: boolean;
  public erroDoc: boolean;
  public erroTipo: string;

  public contador: number = 0;

  constructor(
    public dateUtil: DateUtil,
    private _adapter: DateAdapter<any>,
    public snackBar: Snackbar,
    public errorUtil: ErrorUtil,
    public stockProvider: StockProvider,
    public productProvider: ProductProvider,
    public modalService: NgbModal,
    public reportsUtil: ReportsUtil,
    public brMasker: BrMaskerIonicServices3,

  ) { }

  ngOnInit() {
    this.defaultConfiguration();
    this.initializeData();
    this.tableConfiguration();
  }

  defaultConfiguration() {
    let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
    this.dateRange.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('year').format();
    this.dateRange.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('year').format();
    this.activeMonth = this.months[+moment.tz(workDate, 'America/Sao_Paulo').format('MM') - 1];
    this.year = +moment.tz(workDate, 'America/Sao_Paulo').format('YYYY');
    this.years = getYears(+moment().format('YYYY') - 20);
  }

  initializeData() {
    this.productProvider.getAllProducts().then((result: []) => {
      if (result) {
        this.produtos = result;
        this.produtoSelecionado = this.produtos.find((produto) => produto['id']);
        this.getStock(this.getDateForRequest(true).startDate, this.getDateForRequest(true).endDate);
      }
    })
  }

  changeProduto(id) {
    this.produtoSelecionado = this.produtos.find((produto) => produto['id'] == id);
    this.getByDate();
  }

  autoFillSaldo() {
    this.grid.autoFitColumns(['postDate', 'docNumber', 'amountEntrada', 'amountSaida', 'balanceFormated']);
    // this.grid.refreshHeader();
  }

  clearFilters() {
    this.clearSearch();
    this.grid.clearFiltering();
    this.grid.clearSorting();
    this.snackBar.open('Filtros removidos com sucesso', 'success');
  }

  clearSearch() {
    this.grid.searchSettings.key = '';
    this.keyword = '';
  }

  searchTable() {
    if (this.keyword === '') {
      this.grid.searchSettings.key = '';
    }
    const wordSearch = this.keyword;
    let time = 400;
    if (this.dataSourceMain.length > 500) {
      time = 800;
    }
    setTimeout(() => {
      if (wordSearch === this.keyword) {
        if (this.keyword) {
          const searchText: string = (document.getElementsByClassName('searchtext')[0] as any).value;
          this.grid.search(searchText);
        }
      } else {
        this.grid.searchSettings.key = '';
      }
    }, time);
  }

  novoLancamentoEstoque() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(LancamentoEstoqueComponent, modalOptions);
    modalRef.result.then((result: any) => {
      // if (result !== 'cancelou') {
      this.getStock(this.getDateForRequest(false).startDate, this.getDateForRequest(false).endDate);
      // }
    }, () => {
    });
  }

  importacaoPorPlanilha() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(EnviarPlanilhaComponent, modalOptions);
    modalRef.result.then((result: any) => {
      if (result !== 'cancelou') {
        this.getStock(this.getDateForRequest(false).startDate, this.getDateForRequest(false).endDate);
      }
    }, () => {
    });
  }

  editLancamentoEstoque(rowTable) {
    if (rowTable != null && rowTable.target.classList.contains('e-rowcell')) {
      if (rowTable.view.getSelection().type !== "Range") {
        const row = (this.grid.getRowObjectFromUID(parentsUntil(rowTable.target, 'e-row').getAttribute('data-uid')));
        let modalOptions: NgbModalOptions = {
          backdrop: 'static',
          windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
        };
        const modalRef = this.modalService.open(LancamentoEstoqueComponent, modalOptions);
        modalRef.componentInstance.editar = true;
        modalRef.componentInstance.lancamentoExistente = row.data;
        modalRef.result.then((result: any) => {
          this.getStock(this.getDateForRequest(false).startDate, this.getDateForRequest(false).endDate);
        }, () => {
        });
      }
    } else if (rowTable == null) {
      this.novoLancamentoEstoque();
    }
  }

  async getStock(startDate, endDate) {
    this.loadingCards = true;
    await this.stockProvider.getAllLancamentos(startDate, endDate, this.produtoSelecionado.id).then(async (lancamentos: any) => {
      //console.log("lancamentos:", lancamentos)
      this.estoqueInicial = lancamentos.initialBalance;
      this.lancamentos = this.mapData(lancamentos.stockPostRespDTOs);
      //console.log("this.lancamentos:", this.lancamentos)
      this.dataSourceMain = this.lancamentos;
      this.consultando = false;
      this.erroDoc = false;
      this.getIndicators();
    }).catch((result: HttpErrorResponse) => {
      this.snackBar.openLong('Erro ao carregar lançamentos!', 'erro');
      console.error(result);
      this.erroDoc = true;
      this.consultando = false;
      this.erroTipo = this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping');
    });
    this.loadingCards = false;
  }

  mapData(data) {
    data.forEach((lancamento) => {
      if (lancamento.amount > 0) {
        lancamento.amountEntrada = this.formatarValor(lancamento.amount);
      }
      if (lancamento.amount < 0) {
        lancamento.amountSaida = this.formatarValor(lancamento.amount);
      }
      lancamento.balanceFormated = this.formatarValor(lancamento.balance);
      switch (lancamento.originId) {
        case 1:
          lancamento.originMapped = 'Compra';
          break;
        case 2:
          lancamento.originMapped = 'Nascimento';
          break;
        case 3:
          lancamento.originMapped = 'Consumo/Perda';
          break;
        case 4:
          lancamento.originMapped = 'Venda';
          break;
        case 5:
          lancamento.originMapped = 'Estoque Inicial';
          break;
      }
    });
    return data
  }

  formatarValor(valorTotal) {
    return new Intl.NumberFormat('pt-BR').format(valorTotal);
  }

  getIndicators() {
    const data = this.dataSourceMain;
    this.totalPagamentos = 0.00;
    this.totalRecebimentos = 0.00;
    data.forEach((lancamento: any) => {
      if (lancamento.amountEntrada) {
        this.totalRecebimentos = lancamento.amount + this.totalRecebimentos;
      }
      if (lancamento.amountSaida) {
        this.totalPagamentos = lancamento.amount + this.totalPagamentos;
      }
    });
    this.saldo = this.totalRecebimentos + this.totalPagamentos;
    if(document.getElementById("saldo-id")){
      let saldo: string  = this.formatarValor((this.saldo + this.estoqueInicial).toFixed(2).toString())
      document.getElementById("saldo-id").innerHTML = saldo;
    }
  }

  tableConfiguration() {
    this._adapter.setLocale('pt-BR');
    this.customAttributes = { class: 'customcss' };
    this.filterSettings = { type: 'Menu' };
    this.filter = { type: 'CheckBox' };
    this.selectionSettings = { persistSelection: false, type: 'Multiple', checkboxOnly: true };
    this.aggregates = [{
      columns: [
        {
          type: 'Sum',
          field: 'amountEntrada',
          footerTemplate: '<b><span class="text-sm colorido" style="color: green !important;">${Sum}</span></b>'
        },
        {
          type: 'Sum',
          field: 'amountSaida',
          footerTemplate: '<b><span class="text-sm colorido" style="color: red !important;">${Sum}</span></b>'
        },
        {
          type: 'Custom',
          customAggregate: () => {return this.saldo + this.estoqueInicial},
          field: 'balanceFormated',
          footerTemplate: '<b><span class="text-sm" style="color: dimgray !important;" id="saldo-id">${Custom}</span></b>'
        }
      ],
    }];
  }

  getDateForRequest(firstRequest: boolean): ConsultDate {
    const consultDate: ConsultDate = new ConsultDate();
    if (this.monthly) {
      if (firstRequest) {
        let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
        consultDate.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
        consultDate.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
      } else {
        const month = this.getActiveMonth(this.activeMonth);
        const date = this.year + '-' + month + '-' + '01';
        consultDate.startDate = moment.tz(date, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
        consultDate.endDate = moment.tz(date, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
      }
    } else {
      consultDate.startDate = moment.tz(this.dateRange.startDate, 'America/Sao_Paulo').format('YYYY-MM-DD');
      consultDate.endDate = moment.tz(this.dateRange.endDate, 'America/Sao_Paulo').format('YYYY-MM-DD');
    }
    return consultDate;
  }

  getActiveMonth(mes) {
    switch (mes) {
      case 'JAN':
        mes = '01';
        break;
      case 'FEV':
        mes = '02';
        break;
      case 'MAR':
        mes = '03';
        break;
      case 'ABR':
        mes = '04';
        break;
      case 'MAI':
        mes = '05';
        break;
      case 'JUN':
        mes = '06';
        break;
      case 'JUL':
        mes = '07';
        break;
      case 'AGO':
        mes = '08';
        break;
      case 'SET':
        mes = '09';
        break;
      case 'OUT':
        mes = '10';
        break;
      case 'NOV':
        mes = '11';
        break;
      case 'DEZ':
        mes = '12';
        break;
      default:
        mes = '01';
    }
    return mes;
  }

  changeYear(type: string) {
    if (type === '+') {
      this.year = +this.year + 1;
    } else {
      this.year = +this.year - 1;
    }
    let existe = 0;
    this.years.forEach((ano: number) => {
      if (this.year === ano) {
        existe++;
      }
    });
    if (existe === 0) {
      this.years.push(this.year);
      this.years.sort(
        function (b, a) {
          return a < b ? -1 : a > b ? 1 : 0;
        });
    }
    this.getByDate();
  }

  async getByDate() {
    this.getStock(this.getDateForRequest(false).startDate, this.getDateForRequest(false).endDate);
  }

  tabsMonth(link: string) {
    this.monthly = link === 'Mensal';
    this.getStock(this.getDateForRequest(false).startDate, this.getDateForRequest(false).endDate);
  }

  changePeriod(data) {
    if (data.value != null && this.dateRange.startDate != null && this.dateRange.endDate != null) {
      this.getStock(this.getDateForRequest(false).startDate, this.getDateForRequest(false).endDate);
    }
  }

  excelQueryCellInfo(args: any): void {
    // Formata a data
    if (args.column.field == 'postDate') {
      args.value = moment(args.data.postDate).format('DD/MM/YYYY');
    }
  }


  relatorioExport(type) {
    const date = moment().format('DD/MM/YY HH:mm').toString();

    let period: string;
    if (this.activeLinkMonths !== 'Personalizado')
      period = this.activeMonth + ' - ' + this.year;
    else
      period = moment(this.dateRange.startDate).format('DD/MM/YY') + ' - ' + moment(this.dateRange.endDate).format('DD/MM/YY');

    if (type == 'pdfExport') {
      let headers = [
        {header: 'Data', dataKey: 'postDate'},
        {header: 'Documento', dataKey: 'docNumber'},
        {header: 'Entrada', dataKey: 'amountEntrada'},
        {header: 'Saída', dataKey: 'amountSaida'},
        {header: 'Saldo', dataKey: 'balanceFormated'},
        {header: 'Tipo', dataKey: 'originMapped'},
        {header: 'Imóvel', dataKey: 'property.name'},
        {header: 'Histórico', dataKey: 'historic'},
        {header: 'Participante', dataKey: 'participant.name'},
      ];

      let font: any = this.dataSourceMain;
      // @ts-ignore
      if (this.grid.getFilteredRecords().length > 0) font = this.grid.getFilteredRecords();

      let tmp = [];
      font.forEach((value: any) => {
        tmp.push({
          postDate: moment(value.postDate).format('DD/MM/YYYY'),
          docNumber: value.docNumber,
          amountEntrada: value.amountEntrada,
          amountSaida: value.amountSaida,
          balanceFormated: value.balanceFormated,
          originMapped: value.originMapped,
          "property.name": value.property ? value.property.code + " - " + value.property.name : "",
          historic: value.historic,
          "participant.name": value.participant ? value.participant.name + " - " + value.participant.cpfCNPJParticipant : "",
        });
      });

      let colunas = [
        {dataKey: 'description', cellWidth: 7.5},
        {dataKey: 'amountEntrada', halign: 'center'},
        {dataKey: 'amountSaida', halign: 'center'},
        {dataKey: 'balanceFormated', halign: 'center'},
      ];

      let totalEntrada = tmp.reduce((acc, cur) => acc + parseFloat(cur.amountEntrada), 0);
      let totalSaida = tmp.reduce((acc, cur) => acc + parseFloat(cur.amountSaida), 0);
      let totalSaldo = tmp[tmp.length - 1].balanceFormated;

      let footer = {
        postDate: "",
        docNumber: "",
        amountEntrada: !isNaN(totalEntrada) ?  totalEntrada : '',
        amountSaida: !isNaN(totalSaida) ?  totalSaida : '',
        balanceFormated: !isNaN(totalSaldo) ?  totalSaldo : '',
        originMapped: "",
        "property.name": "",
        historic: "",
        "participant.name": "",
      }

      headers = headers.filter((value) => {
        return this.grid.getVisibleColumns().find((column) => {
          return column.field === value.dataKey;
        });
      });

      this.reportsUtil.exportPDF({
        doc: {
          arquivo: `Estoque - ${period}`,
          orientacao: 'Landscape',
        },
        cabecalho: {
          titulo: `Lançamentos de estoque`,
          data: date,
          periodo: period,
          parametros: {
            "Produto": this.produtoSelecionado.description
          },
          image: false,
        },
        tabela: {
          colunas: headers,
          dados: tmp,
          footer: [footer],
          estiloColunas: colunas,
        },
      });

    } else  if (type == 'excelExport') {
      let exportProperties = {
        fileName: `Saldo - ${period}.xlsx`,
      };
      this.grid.excelExport(exportProperties);
    }



  }

}


function getYears(startYear) {
  const currentYear = new Date().getFullYear() + 1;
  const years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

class ConsultDate {
  endDate: any;
  startDate: any;
}
