import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccessKeyProvider } from "src/providers/dfe/access-key/access-key";
import { ErrorUtil } from "src/util/error";
import { Snackbar } from "src/util/snackbar";
import Swal from "sweetalert2";

@Component({
  selector: "app-check-key-modal",
  templateUrl: "./check-key-modal.component.html",
  styleUrls: ["./check-key-modal.component.scss"],
})
export class CheckKeyModalComponent implements OnInit {
  public formGroup: FormGroup;
  public carregando = false;
  public panelExpanded: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private accessKeyProvider: AccessKeyProvider,
    public snackBar: Snackbar,
    public erroUtil: ErrorUtil,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      chavesAcesso: ["", Validators.required],
    });
  }

  checkValue() {
    (document.getElementById("accessKeysTextArea") as HTMLInputElement).value =
      (
        document.getElementById("accessKeysTextArea") as HTMLInputElement
      ).value.replace(/[^0-9,\s]/g, "");
  }

  getAccessKeys() {
    const codigosAcessoRaw = (
      document.getElementById("accessKeysTextArea") as HTMLInputElement
    ).value.replace(/[\s]/g, ",");
    let codigosAcesso = codigosAcessoRaw.split(",").filter((a) => a);
    return codigosAcesso;
  }

  treatKeys(keys: any) {
    let keys_return: string =
      "<br/><br/><div style='text-align: center;'> <div style='display: inline-block; text-align: left;'>";

    keys.forEach((element) => {
      keys_return +=
        "<i class='far fa-times-circle' style='color: #f05050'></i> " +
        element +
        "<br/>";
    });

    keys_return += "</div></div>";

    return keys_return;
  }

  checkKeys() {
    this.carregando = true;
    if (this.formGroup.valid) {
      const accessKeys = this.getAccessKeys();
      this.accessKeyProvider
        .checkAccessKeys(localStorage.idEmpresa, accessKeys)
        .then((response: any) => {
          this.carregando = false;
          if (response.keys == null) {
            Swal.fire({
              title: "Chaves conferidas!",
              text: response.msg_ptbr,
              icon: "success",
            });
          } else {
            this.carregando = false;
            let accessKeys2 = this.treatKeys(response.keys[0]);
            //let accessKeys = response.keys[0].toString().split(',').join(', ')
            Swal.fire({
              title: "Chaves conferidas!",
              width: "600px", 
              html: response.msg_ptbr + accessKeys2,
              icon: "warning",
            });
          }
        })
        .catch((result) => {
          this.carregando = false;
          Swal.fire({
            title: "Erro!",
            html: `Ocorreu um erro ao conferir suas chaves!<br>${this.erroUtil.checkErrorStatus(
              result,
              result.status,
              result.error,
              "grouper"
            )}`,
            icon: "error",
          });
        });
    } else {
      this.carregando = false;
      this.snackBar.openLong("Preenchimento incorreto dos campos", "erro");
    }
  }
}
