import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Snackbar} from "../../../../../util/snackbar";
import {Pessoa} from "../../../../../model/Pessoa";
import {ErrorUtil} from "../../../../../util/error";
import {HttpErrorResponse} from "@angular/common/http";
import {UserProvider} from "../../../../../providers/user/user";

@Component({
  selector: 'app-deletar-colaborador',
  templateUrl: './deletar-colaborador.component.html',
  styleUrls: ['./deletar-colaborador.component.scss']
})
export class DeletarColaboradorComponent implements OnInit {


  enviando: boolean = false;


  constructor(public dialogRef: MatDialogRef<DeletarColaboradorComponent>,
              public userProvider: UserProvider,
              public snackbar: Snackbar,
              public errorUtil: ErrorUtil,
              @Inject(MAT_DIALOG_DATA) public data: Pessoa) {
  }


  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(): void {
    this.enviando = true;
    this.userProvider.delCollaborator(this.data).then(() => {
      this.snackbar.openLong('Excluído com sucesso!', 'success');
      this.dialogRef.close(this.data);
    }).catch((reason: HttpErrorResponse) => {
      console.error(reason);
      this.enviando = false;
      this.snackbar.openLong('Não foi possível excluir! ' + this.errorUtil.checkErrorStatus(reason, reason.status, reason.error, 'colaborator'), 'erro');
    })
    ;
  }


}
