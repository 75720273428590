import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Maintenance} from "../../model/dto/status/Maintenance";

@Injectable()
export class MaintenanceProvider {

  constructor(public http: HttpClient) {}

  getAllMaintenance() {
    return new Promise((resolve, reject) => {
      this.http.get("https://status.aplicativo.farmcont.com.br/")
        .subscribe((result: Maintenance[]) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao recuperar status do servidor', error);
          reject(error);
        });
    });
  }

  getAllIncident(){

    //using oath create an authoriation header using 634d2e510c03fe0be06e3c0dd7a889fa as token

    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer 634d2e510c03fe0be06e3c0dd7a889fa`});
    return new Promise((resolve, reject) => {
      this.http.get("https://api.instatus.com/v1/ckqv7o7d01283399vojo9z7jrsl/incidents/", { headers })
        .subscribe((result: any) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao recuperar status do servidor', error);
          reject(error);
        });
    });
  }

  getOverallData(){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer 634d2e510c03fe0be06e3c0dd7a889fa`});

    return new Promise((resolve, reject) => {
      this.http.get("https://api.instatus.com/v1/pages/", { headers })
        .subscribe((result: any) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao recuperar status do servidor', error);
          reject(error);
        });
    });
  }

}
