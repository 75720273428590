import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BankAccountProvider} from "../../../../../providers/company/bank_account/bank_account";
import {ContaBancaria} from "../../../../../model/ContaBancaria";
import {Snackbar} from "../../../../../util/snackbar";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorUtil} from "../../../../../util/error";

@Component({
  selector: 'app-deletar-conta',
  templateUrl: './deletar-conta.component.html',
  styleUrls: ['./deletar-conta.component.scss']
})
export class DeletarContaComponent implements OnInit {
  enviando: boolean = false;

  constructor(public dialogRef: MatDialogRef<DeletarContaComponent>,
              public banksAccountProvider: BankAccountProvider,
              public errorUtil: ErrorUtil,
              public  snackbar: Snackbar,
              @Inject(MAT_DIALOG_DATA) public data: ContaBancaria) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(): void {
    this.enviando = true;
    this.banksAccountProvider.deleteBankAccount(+this.data.id).then(() => {
      this.dialogRef.close(this.data);
      this.snackbar.openLong('Excluído com sucesso!', 'success');
    }).catch((result: HttpErrorResponse) => {
      console.error(result);
      this.enviando = false;
      this.snackbar.openLong('Não foi possível excluir! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bankAccount'), 'erro');
    })
    ;
  }

}
