export class LivroCaixa {
  tipo: number;

  constructor() {
  }

  getTipo(){
    // 0 = analógico
    // 1 = digital
    return +localStorage.getItem('livroCaixa');
  }

  setTipo(tipo: number){
    this.tipo = tipo;
  }

}
