import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ErrorUtil} from "../../../../../util/error";
import {AuthProvider} from "../../../../../providers/auth/authProvider";
import {Snackbar} from "../../../../../util/snackbar";
import {BatchProvider} from "../../../../../providers/batch/batchProvider";
import {CheckPermission} from "../../../../../service/checkPermission";
import {ReplaySubject, Subject} from "rxjs";
import {Bookkeeping} from "../../../../../model/dto/bookkeping/Bookkeeping";
import Swal from "sweetalert2";
import {HttpErrorResponse} from "@angular/common/http";
import * as moment from "moment";
import {DateUtil} from "../../../../../util/dateUtil";

@Component({
  selector: 'app-editar-data',
  templateUrl: './editar-data.component.html',
  styleUrls: ['./editar-data.component.scss']
})
export class EditarDataComponent implements OnInit {
  closeResult = '';

  public enviando: boolean = false;
  public enviado: boolean = false;
  public erro: string;
  public formPassword: FormGroup;
  public date: any;
  protected _onDestroy = new Subject<void>();
  public lancamentoForm: FormGroup;
  public batchForm: FormGroup;
  public selectedBookkeeping: Bookkeeping[];

  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              public errorUtil: ErrorUtil,
              private authProvider: AuthProvider,
              public dateUtil: DateUtil,
              public snackbar: Snackbar,
              public formBuilder: FormBuilder,
              private batchProvider: BatchProvider,
              public checkPermission: CheckPermission) {
  }

  ngOnInit() {
    // this.getImoveis();

    let workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
    this.date = moment.tz(workDate, 'America/Sao_Paulo').format('YYYY-MM-DD');

    this.formPassword = this.formBuilder.group({
      password: [null, Validators.required],
    });
    // this.dateInput = new FormControl(this.date, Validators.required);
    this.batchForm = this.formBuilder.group({
      date: [this.date, Validators.required],
    });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  changePeriod(data) {
    this.date = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
  }

  async onYesClick() {
    this.enviado = true;
    this.enviando = true;
    await this.authProvider.revalidateLogin(this.formPassword.value.password).then(async value => {
    this.enviando = true;
      let postingId = this.selectedBookkeeping.map(b => b.id);
      await this.batchProvider.postPaymentDate(postingId, this.date).then((result: []) => {
        this.enviando = false;
        this.enviado = false;
        if (result.length == this.selectedBookkeeping.length && result.length != 0) {
          Swal.fire({
            title: 'Sucesso!',
            text: 'Os lançamentos selecionados foram alterados com sucesso!',
            icon: 'success'
          });
        } else if (result.length > this.selectedBookkeeping.length && result.length != 0) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Foi solicitada alteração em ' + (this.selectedBookkeeping.length) + ' lançamentos, porém, somente ' + (result.length) + ' foram alterados.',
            icon: 'warning'
          });
        } else if (result.length == 0) {
          Swal.fire({
            title: 'Erro!',
            text: 'Nenhum lançamento foi alterado',
            icon: 'error'
          });
        }
        this.activeModal.close(result);
      }).catch((response: HttpErrorResponse) => {
        this.enviando = false;
        this.activeModal.dismiss();
        if (response.error.error_description.includes("There is blocked periods")) {
          Swal.fire({
            title: 'Atenção!',
            text: 'Há lançamentos cadastrados dentro de um bloqueio de período. ' +
              (this.checkPermission.isAccountantAdmin() ?
                'Remova o bloqueio para poder alterar uma escrituração com um lançamento nesta data.' :
                'Contate um administrador para que o bloqueio seja removido.'),
            icon: 'error'
          });
        } else {
          Swal.fire({
            title: 'Erro!',
            text: 'Nenhum lançamento foi alterado. Houve um erro de conexão com o servidor!',
            icon: 'error'
          });
        }
      });
    }).catch(reason => {
      this.enviando = false;
      this.activeModal.dismiss();
      Swal.fire({
        title: 'Falha na autenticação',
        text: 'Não foi possível alterar os lançamentos. Ocorreu um problema na autenticação!',
        icon: 'error'
      });
    });
    this.enviando = false;
  }
}
