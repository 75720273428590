import {Injectable} from '@angular/core';
import {Financiamento, FinanciamentoResumo} from "../../../model/Financiamento";
import {Subject} from "rxjs";

@Injectable()
export class FinancingsViewsProvider {

  private view = false;
  private new: boolean = false;
  private id: FinanciamentoResumo;
  private outViewSubject = new Subject<void>();

  constructor() {}

  changeView() {
    return this.view || this.new;
  }

  outView() {
    this.view = false;
    this.new = false;
    this.id = null;
    this.outViewSubject.next();
  }

  setView(value: boolean, financing?: FinanciamentoResumo) {
    this.view = value;
    if (!value) {
      this.id = null;
    } else {
      this.id = financing;
    }
  }

  setNew(value: boolean) {
    this.new = value;
    this.view = false;
    this.id = null;
  }

  getView() {
    return this.view;
  }

  getNew() {
    return this.new;
  }

  getFinancingId() {
    return this.id;
  }

  getOutViewObservable() {
    return this.outViewSubject.asObservable();
  }
}
