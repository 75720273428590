import {Component, OnInit} from '@angular/core';

import {UserblockService} from './userblock.service';
import {OAuthService} from "angular-oauth2-oidc";
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AdicionarImagemComponent } from 'src/app/routes/consultor/enviar-arquivos/adicionar-imagem/adicionar-imagem.component';
import { UserProvider } from 'src/providers/user/user';
import { ReceiptProvider } from 'src/providers/receipt/receipt';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
  usuario: any;
  user: any = {};
  editPictureIcon: boolean = false;
  public nome: string;

  constructor(public userblockService: UserblockService, private oauthService: OAuthService, public modalService: NgbModal, public userProvider: UserProvider, public receiptProvider: ReceiptProvider) {
    this.usuario = this.givenName();
  }


  ngOnInit() {
  }

  givenName() {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['principal'];
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

  setEditPictureIcon(bool){
    this.editPictureIcon = bool;
  }

  editPicture() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
        const modalRef = this.modalService.open(AdicionarImagemComponent, modalOptions).result.then((result) => {
          if(result != 'cancelou')(document.getElementById('userPicture') as any).src = localStorage.getItem('userPicture');
        }).catch((error) => {
          console.log('error', error);
        }
        );
    } catch (error) {
        console.error('', error);
    }
  }

  setUserPicture(){
    //console.log("set user picture", typeof(localStorage.getItem('userPicture')));
    if(localStorage.getItem('userPicture') == null){
      //console.log("image == null, looking for img");
      this.userProvider.getImageOffice().then((value: Blob) => {
        //console.log("VALOR VINDO DA SIDEBAR", value)
        if(value != null && value.type == "application/octet-stream"){
          //console.log("sucess, setting up chosen");
          const file = value;
          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.addEventListener('load', () => {
            const url = fr.result
            localStorage.setItem('userPicture', url as string);
            //console.log("fr.addEventListener ~ url:", url)
            //console.log("picture in local storage", localStorage.getItem('userPicture'));
          })
        } else{
          //console.log("null or wrong type, setting up default");
          localStorage.setItem('userPicture', 'assets/img/user/02.png');
        }
      }).catch((error) => {
        //console.log("error, setting up default");
        localStorage.setItem('userPicture', 'assets/img/user/02.png');
      })
    }
    if((document.getElementById('userPicture') as any).src != localStorage.getItem('userPicture')){
      //console.log("lel");
      (document.getElementById('userPicture') as any).src = localStorage.getItem('userPicture');
    }
  }

}
