import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorUtil} from "../../../../../util/error";
import {Snackbar} from "../../../../../util/snackbar";
import {HttpErrorResponse} from "@angular/common/http";
import {GrouperProvider} from "../../../../../providers/grouper/grouper";
import {GrouperDTOResponse} from "../../../../../model/dto/GrouperDTOResponse";

@Component({
  selector: 'app-deletar-agrupador',
  templateUrl: './deletar-agrupador.component.html',
  styleUrls: ['./deletar-agrupador.component.scss']
})
export class DeletarAgrupadorComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeletarAgrupadorComponent>,
              public grouperProvider: GrouperProvider,
              public errorUtil: ErrorUtil,
              public  snackbar: Snackbar,
              @Inject(MAT_DIALOG_DATA) public data: GrouperDTOResponse) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(): void {
    this.grouperProvider.delGrouper(this.data.id).then(() => {
      this.dialogRef.close(this.data);
      this.snackbar.openLong('Excluído com sucesso!', 'success');
    }).catch((reason: HttpErrorResponse) => {
      this.snackbar.openLong('Não foi possível excluir! ' + this.errorUtil.checkErrorStatus(reason, reason.status, reason.error, 'grouper'), 'erro');
    })
    ;
  }

}
