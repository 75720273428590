import {Component, Inject, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-export',
  templateUrl: './modal-export.component.html',
  styleUrls: ['./modal-export.component.scss']
})
export class ModalExportComponent implements OnInit {

  relatorio: any;
  texto: string;

  constructor(public activeModal: NgbActiveModal,) {
  }

  ngOnInit() {
  }

  cancelButtonClick() {
    this.activeModal.close({type: 'cancel'});
  }

  closeButton(type, img) {
    this.activeModal.close({type: type, img: img});
  }
}
