import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AccountProvider} from '../../../../providers/account/account';
import {BankAccountProvider} from '../../../../providers/company/bank_account/bank_account';
import {MemberProvider} from '../../../../providers/company/member/member';
import {ContaBancariaDTO} from '../../../../model/dto/ContaBancariaDTO';
import {PessoaFiscalDTO} from '../../../../model/dto/PessoaFiscalDTO';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import {
  ExcelExportProperties,
  GridComponent,
  GroupSettingsModel,
  SelectionService
} from '@syncfusion/ej2-angular-grids';
import {ImovelDTO} from '../../../../model/dto/ImovelDTO';
import {ClickEventArgs} from '@syncfusion/ej2-navigations';
import {ErrorUtil} from '../../../../util/error';
import {Snackbar} from '../../../../util/snackbar';
import {DateUtil} from 'src/util/dateUtil';
import {DatePicker} from '../../../../util/datePicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {AggregateRowModel} from "@syncfusion/ej2-grids/src/grid/models/models";
import {PropertyProvider} from "../../../../providers/company/property/property";
import {ReportsProvider} from "../../../../providers/reports/ReportsProvider";
import {SortSettingsModel} from '@syncfusion/ej2-treegrid';
import {historicoUtil} from 'src/util/historico';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {
  ExportarLancamentosComponent
} from '../../financeiro/financeiro/exportar-lancamentos/exportar-lancamentos.component';
import {ReportsUtil} from "../../../../util/Reports";
import {ModalExportComponent} from "../modal-export/modal-export.component";
import {ReportsHelper} from "../../../../util/ReportsHelper";
import {ConsultDateUtil} from "../../../../util/consultDateUtil";
import { CadastrarLancamentoComponent } from '../../financeiro/cadastrar-lancamento/cadastrar-lancamento.component';

@Component({
  selector: 'app-livro-caixa',
  templateUrl: './livro-caixa.component.html',
  styleUrls: ['./livro-caixa.component.scss'],
  providers: [SelectionService,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class LivroCaixaComponent implements OnInit {

  @ViewChild('grid', {static: false}) public grid: GridComponent;

  consultando: boolean;
  anual = true;
  personalizado = false;
  deducao: string;

  contas: ContaBancariaDTO[];
  socios: PessoaFiscalDTO[];
  imoveis: ImovelDTO[];
  members = new FormControl();
  bankAccount = new FormControl();
  property = new FormControl();

  groupOptions: GroupSettingsModel;
  initialSort: SortSettingsModel;
  toolbarOptions: any[];
  agregrates: AggregateRowModel[];
  public changed: boolean = false;
  public contador = 0;
  // public total = 0;

  public dataSourceMain: [];

  public filtersExportPostings: any = null;
  public lastOrder = {field: '', direction: '', searching: ""};

  constructor(public accountProvider: AccountProvider,
    public bancoProvider: BankAccountProvider,
    public errorUtil: ErrorUtil,
    public snackBar: Snackbar,
    private reportsProvider: ReportsProvider,
    public memberProvider: MemberProvider,
    public propertyProvider: PropertyProvider,
    public consultDateUtil: ConsultDateUtil,
    public datepicker: DatePicker,
    private _adapter: DateAdapter<any>,
    public reportsUtil: ReportsUtil,
    public reportsHelper: ReportsHelper,
    public historicUtil: historicoUtil,
    public modalService : NgbModal) {
  }

  ngOnInit() {
    this.initializeData();
    this.tableConfiguration();
  }

  tableConfiguration() {
    this.toolbarOptions = [
      {text: 'Exportar', tooltipText: 'Exportar', prefixIcon: 'e-pdfexport', id: 'export'},
      'ColumnChooser'
    ];
    this._adapter.setLocale('pt-BR');
    this.agregrates = [{columns: [{type: 'Sum', field: 'amountPaid', format: 'C2', groupFooterTemplate: '<b><span class="colorido" style="color: gray;">${Sum}</span></b>'}],}];
    this.groupOptions = { showDropArea: false, showGroupedColumn: false, columns: ['month'], allowReordering: true };
    this.initialSort = {columns: [{ field: 'paymentDate', direction: 'Ascending' }]};
  }

  actionBegin(args) {
    if (args.requestType === 'filterchoicerequest') {
      args.filterChoiceCount = 1000000; //here you can change the filterchoice count as per your requirement
    }
    if (args.requestType === 'sorting') {
      this.lastOrder.field = args.columnName;
      this.lastOrder.direction = args.direction;
    }
    if (args.requestType === 'searching') {
      this.lastOrder.searching = args.searchString;
    }
  }

  initializeData() {
    this.deducao = 'TODOS';
    this.consultando = true;
    this.dataSourceMain = [];

    this.memberProvider.getAllMembers().then((members: PessoaFiscalDTO[]) => {
      this.socios = members;
      this.bancoProvider.getAllBanksAccounts().then((accounts: ContaBancariaDTO[]) => {
        this.contas = accounts;
        this.contas.push({id: null, description: 'Sem conta', bankId: null, code: null, countryId: null, agencyWithoutDV: null, accountNumberWithDV: null, bankDTORespComp: null, countryDTORespComp: null, default: false});
        this.propertyProvider.getAllProperty().then((properties: ImovelDTO[]) => {
          this.imoveis = properties;
          this.consultando = false;
          this.initialSelects();
        }).catch((result: HttpErrorResponse) => {
          this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-livroCaixa');
          this.consultando = false;
          console.error(result);
        });
      });
    });
  }

  tabsTypeFilter() {
    this.consultando = true;
    this.dataSourceMain = [];
    // this.total = 0;

    let members = this.socios.map((value) => value.cpfCNPJ);
    if (this.members.value.length !== 0) {
      members = this.members.value.map((value) => value.cpfCNPJ);
    }

    let property = this.imoveis.map((value) => value.propertyCode);
    if (this.property.value.length !== 0) {
      property = this.property.value.map((value) => value.propertyCode);
    }

    let account = this.contas.map((value) => value.id);
    if (this.bankAccount.value.length !== 0) {
      account = this.bankAccount.value.map((value) => value.id);
    }

    let data: any = {};
    if (this.personalizado) {
      this.consultDateUtil.monthly = false;
      data = this.consultDateUtil.getDateForRequest(false);
    } else if (this.anual) {
      data.startDate = this.consultDateUtil.year + '-01-01';
      data.endDate = this.consultDateUtil.year + '-12-31';
    } else {
      this.consultDateUtil.monthly = true;
      data = this.consultDateUtil.getDateForRequest(false);
    }

    let realeseType = 0;
    if (this.deducao == 'DEDUTIVEIS') {
      realeseType = 1;
    } else if (this.deducao == 'NAODEDUTIVEIS') {
      realeseType = 2;
    }

    let params = {
      cpfCnpjs: members,
      postingReleaseType: realeseType,
      propertyCodes: property,
      bankAccountIds: account
    }

    this.filtersExportPostings = {filters: params, startDate: data.startDate, endDate: data.endDate};

    this.generateReport(params, data.startDate, data.endDate);
  }

  generateReport(params, initialDate, finalDate) {
    this.reportsProvider.getReportDairy(initialDate, finalDate, params).then((result: any) => {
      this.consultando = false;
      this.changed = false;
      this.dataSourceMain = result;
      //console.log(result)
    }).catch((result: HttpErrorResponse) => {
      this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-analiseParticipant');
      this.changed = false;
      this.consultando = false;
      console.error(result);
    });
  }


  updateChanged() {
    this.changed = true;
  }

  limpaMembers(bool) {
    this.members.setValue([])
    if (bool) {
      this.members.setValue(this.socios);
    }
  }

  limpaImoveis(bool) {
    this.property.setValue([])
    if (bool) {
      this.property.setValue(this.imoveis)
    }
  }

  limpaConta(bool) {
    this.bankAccount.setValue([])
    if (bool) {
      this.bankAccount.setValue(this.contas)
    }
  }

  initialSelects() {
    this.members.setValue([])
    this.property.setValue([])
    this.bankAccount.setValue([])
  }

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'export') {
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : '',
      };
      const modalRef = this.modalService.open(ModalExportComponent, modalOptions);
      modalRef.componentInstance.texto = "os lançamentos agrupados por mês";
      modalRef.componentInstance.relatorio = 'livro-caixa';
      modalRef.result.then((result: any) => {
        if (result.type === 'pdf') {
          this.exportPDF(result.img);
        } else if (result.type === 'excel') {
          this.exportExcel();
        } else if (result.type === 'txt'){
          this.exportTxt();
        }
      });
    }
  }

  exportTxt(){
    if (this.dataSourceMain.length == 0) {
      this.snackBar.openLong('Gere o relatório para poder exportar via .txt', 'erro');
      return;
    }

    // mandando uma bool para indicar lançamentos sem conta ao invés de mandando null
    if (this.filtersExportPostings.filters.bankAccountIds.includes(null)) {
      this.filtersExportPostings.filters.bankAccountIds = this.filtersExportPostings.filters.bankAccountIds.filter((value) => value != null);
      this.filtersExportPostings.filters.withoutBankAccount = true;
    } else {
      this.filtersExportPostings.filters.withoutBankAccount = false;
    }
    // aqui precisa ser mapeado o enum de postReleaseType usado no relatório para o de 4 itens do back
    if (this.filtersExportPostings.filters.postingReleaseType == 0) {
      this.filtersExportPostings.filters.postingReleaseType = null;
    } else if (this.filtersExportPostings.filters.postingReleaseType == 1) {
      this.filtersExportPostings.filters.postingReleaseType = ['REVENUE_RURAL_ACTIVITY', 'INVESTMENT_COST_EXPENSES', 'PRODUCTS_REVENUE_YEAR_ADVANCES_FINANCIAL_RESOURCES']
    } else if (this.filtersExportPostings.filters.postingReleaseType == 2) {
      this.filtersExportPostings.filters.postingReleaseType = ['NONE'];
    }

    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'xl',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ExportarLancamentosComponent, modalOptions);
    modalRef.componentInstance.filters = this.filtersExportPostings;
    modalRef.result.then((result: any) => {
      if (result !== 'cancelou') {
        this.snackBar.openLong('Exportação realizada com sucesso', 'sucesso');
      }
    }, () => {
    });

    return;
  }

  exportPDF(img){
    const date = moment().format('DD/MM/YY HH:mm').toString();

    let deducao: string;
    if (this.deducao === 'TODOS')
      deducao = 'Todos os valores';
    else if (this.deducao === 'DEDUTIVEIS')
      deducao = 'Dedutíveis';
    else
      deducao = 'Não dedutíveis';

    let properties = 'Todos os imóveis';
    if (this.property.value != null) {
      if (!(this.property.value.length == this.imoveis.length || this.property.value.length == 0)) {
        let tmp = [];
        this.property.value.forEach((value) => {
          tmp.push(value.propertyCode);
        });
        properties = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let accounts = 'Todas as contas';
    if (this.bankAccount.value != null) {
      if (!(this.bankAccount.value.length == this.contas.length || this.bankAccount.value.length == 0)) {
        let tmp = [];
        this.bankAccount.value.forEach((value) => {
          tmp.push(value.description);
        });
        accounts = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let members = 'Todos os produtores';
    if (this.members.value != null) {
      if (!(this.members.value.length == this.socios.length || this.members.value.length == 0)) {
        let tmp = [];
        this.members.value.forEach((value) => {
          tmp.push(value.name);
        });
        if (tmp.length > 1) {
          members = tmp.join(', ');
        } else if (tmp.length == 1) {
          members = `${this.members.value[0].name} (${this.members.value[0].cpfCNPJ})`;
        }
      }
    }

    let period;
    if (this.anual)
      period = this.consultDateUtil.year;
    else if (this.personalizado)
      period = moment(this.consultDateUtil.startDate).format('DD/MM/YY') + ' - ' + moment(this.consultDateUtil.endDate).format('DD/MM/YY');
    else
      period = this.consultDateUtil.month + ' - ' + this.consultDateUtil.year;

    // @ts-ignore
    let font = this.reportsHelper.getFont((this.grid.getFilteredRecords().length > 0 ? this.grid.getFilteredRecords() : this.dataSourceMain), this.lastOrder);

    let group: { [x: string]: any[]; } = {};
    font.forEach((value: any) => {
      if (!group[value.month]) {
        group[value.month] = [];
      }
      group[value.month].push({
        paymentDate: value.paymentDate,
        docNumber: value.docNumber,
        participant: value.participant + " - " + value.participantCnpj,
        movementAccount: value.movementAccount,
        property: value.property,
        historic: value.historic,
        amountPaid: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value.amountPaid),
        amount: value.amountPaid ? value.amountPaid : 0,
        account: value.account,
        balance: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value.balance),
        accumulatedBalance: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value.accumulatedBalance)
      });
    });

    let ordered = Object.keys(group)
      .sort((a, b) => parseInt(a.slice(0, 2)) - parseInt(b.slice(0, 2)))
      .reduce((acc, key) => {
        acc[key] = group[key];
        return acc;
      }, {});

    let tmp = [];
    for (let key in ordered) {
      tmp.push({
        month: key,
        paymentDate: "",
        docNumber: "",
        participant: "",
        movementAccount: "",
        property: "",
        historic: "",
        amountPaid: "",
        account: "",
        balance:"",
        depth: 0,
        children: true,
      });

      let saldo = 0;
      group[key].forEach((value: any) => {
        tmp.push(value);
        saldo += value.amount;
      });

      tmp.push({
        month: "",
        paymentDate: "",
        docNumber: "",
        participant: "",
        movementAccount: "",
        property: "",
        historic: "",
        amountPaid: Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(saldo),
        account: "",
        balance:"",
        depth: 0
      });
    }

    let colunas = [
      {dataKey: 'paymentDate', minCellWidth: 2},
      {dataKey: 'docNumber', minCellWidth: 2},
      {dataKey: 'participant', minCellWidth: 3},
      {dataKey: 'movementAccount', minCellWidth: 3},
      {dataKey: 'property', minCellWidth: 3},
      {dataKey: 'account', minCellWidth: 3},
      {dataKey: 'historic', minCellWidth: 5},
      {dataKey: 'amountPaid', minCellWidth: 2.5, halign: 'center'},
      {dataKey: 'balance', minCellWidth: 2.5, halign: 'center'},
      {dataKey: 'accumulatedBalance', minCellWidth: 2.5, halign: 'center'},
    ];

    let headers = this.reportsHelper.getHeaderFromGrid(this.grid).filter(header =>
      colunas.some(column => column.dataKey === header.dataKey)
    );

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Livro diario - ${period}`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Livro Diário`,
        data: date,
        periodo: period,
        parametros: {
          "Dedução": deducao,
          "Imóveis": properties,
          "Produtores": members,
          "Contas": accounts,
        },
        image: img,
      },
      tabela: {
        colunas: headers,
        dados: tmp,
        diminuir: true,
        estiloColunas: colunas,
        hierarquico: true,
      },
    });
  }

  exportExcel(){
    const exportProperties: ExcelExportProperties = {
      includeHiddenColumn: true,
      fileName: 'livrocaixa' + this.consultDateUtil.year + '.xlsx',
    };
    this.grid.excelExport(exportProperties);
  }

  queryCellInfo(args) {
    if (args.column.field === 'balance') {
      // tslint:disable-next-line: no-string-literal

      args.cell.setAttribute('style', 'color: green');
      if (args.data.balance < 0) {
        args.cell.setAttribute('style', 'color: red');
      }
    }

    if (args.column.field === 'accumulatedBalance') {
      // tslint:disable-next-line: no-string-literal
      args.cell.setAttribute('style', 'color: green');
      if (args.data.accumulatedBalance < 0) {
        args.cell.setAttribute('style', 'color: red');
      }
    }
    if (args.column.field === 'amountPaid') {
      // args.data.balance = this.total;
      if (args.data.amountPaid >= 0) {
        args.cell.setAttribute('style', 'color: green');
      } else if (args.data.amountPaid < 0) {
        args.cell.setAttribute('style', 'color: red');
      }
    }
  }

  excelQueryCellInfo(args: any): void {
    let textColor = '#000000';

    if (args.column.field === 'balance' || args.column.field === 'accumulatedBalance' || args.column.field == 'amountPaid') {
      if (args.value >= 0) {
        textColor = '#27c24c';
      } else if (args.value < 0) {
        textColor = '#f05050';
      }
      args.value = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(args.value);
    }

    args.style = {textBrushColor: textColor};
  }

  rowSelected(x) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastrarLancamentoComponent, modalOptions);
    modalRef.componentInstance.lancamentoExistenteID = Number(x.docId);
    modalRef.result.then((result) => {
      if (result) {
        this.tabsTypeFilter();
      }
    }, (result) => {
      if (result) {
        this.tabsTypeFilter();
      }
    });
  }

}
