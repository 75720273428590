import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PessoaFiscalDTO} from "../../../../../model/dto/PessoaFiscalDTO";

@Component({
  selector: 'app-modal-importacao',
  templateUrl: './modal-importacao.component.html',
  styleUrls: ['./modal-importacao.component.scss']
})

export class ModalImportacaoComponent implements OnInit {

  @Input() public xmlSended: any[];
  @Input() public dfeReceive: any[];
  @Input() public invalid: number;
  @Input() public members: PessoaFiscalDTO[];

  constructor(public modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  checkImportation(doc): boolean {
    return this.dfeReceive.find(dfe => dfe.key === doc.keyId);
  }

  checkMember(doc) {
    if(doc.modelType == 'nfse') return true;
      return this.members.find(member => doc.issuer === member.cpfCNPJ || doc.addressee === member.cpfCNPJ);
  }

}
