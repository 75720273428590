import { Injectable } from "@angular/core";
import { ErrorUtil } from "src/util/error";
import { OFXProvider } from "src/providers/bookkeeping/ofx/ofx";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { HttpErrorResponse } from "@angular/common/http";
import { ModalImportOfxComponent } from "./modal-import-ofx/modal-import-ofx.component";

@Injectable()
export class ImportarOFX {
  constructor(public errorUtil: ErrorUtil,
              public modalService: NgbModal,
              public ofxProvider: OFXProvider) {
  }

  ngOnInit() {
  }

  import(ofx, conta){    
    return new Promise((resolve, reject) => {
      if (ofx !== null && conta !== null) {
        this.ofxProvider.postOFX(ofx, conta).then((result: any) => {
          resolve(result);
        }).catch((result: HttpErrorResponse) => {
          reject(result);
        });
      } else {
        reject("OFX ou conta não informados");
      }
    });
  }
}
