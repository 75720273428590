import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup} from "@angular/forms";
import {historicoUtil} from 'src/util/historico';
import * as moment from "moment";

@Component({
  selector: 'app-historico-individual',
  templateUrl: './historico-individual.component.html',
  styleUrls: ['./historico-individual.component.scss']
})
export class HistoricoIndividualComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,public modalService: NgbModal,public historicoUtil: historicoUtil,public fb: FormBuilder,
    ) { }


  public historicForm: FormGroup
  @Input() public filter_historic
  @Input() public eidtable
  @Input() public doc
  @Input () public duplicate;

  @Input() public lancamentoForm
  @Input() public saleDfe
  ngOnInit() {
    //console.log(this.eidtable)

  this.historicForm  = this.fb.group({
      historico: [''],
      modelo: [''],

    });
    if(this.eidtable){
      //console.log("oi")
      this.historicForm.controls.modelo.setValue(this.eidtable.modelo)
      this.historicoUtil.historics = this.eidtable.modelo.split(' ')
      this.syncHistorico()
    }
  }
  save(){
   if(this.historicoUtil.historics.length == 1 && (this.historicoUtil.historics[0]=="" || this.historicoUtil.historics[0]==" ")){
    this.activeModal.close(null)
   }else{
      this.activeModal.close(
      {
        historico: this.historicForm.controls.historico.value,
        modelo:this.historicForm.controls.modelo.value
      }

      );
   }

  }
  change($event){
    //console.log("holy molly",$event)
    if($event.includes('#')){
         let re = new RegExp('#', "g");
    $event = $event.replace(re,'')

    this.historicForm.controls.modelo.setValue($event)
    }

    this.historicoUtil.historics = $event.split(' ')

    //console.log(this.historicoUtil.historics )
   this.syncHistorico()
  }

  syncHistorico(){

        let historic = this.convertHistoricModelToString(this.historicoUtil.genereteHistoric())

   this.historicForm.controls.historico.setValue(historic.trim());

    //console.log("teste123",this.historicForm.controls.historico)
  }
  convertHistoricModelToString(model){
    //console.log("aaaa23",model)
    let historicSplit = model.split("#");
    let historicString = '';
    historicSplit.forEach((split) => {
      if(split.includes('@')){
        historicString = historicString + ' ' + this.getIndividualHistoricTag(split);
      }
      else{
        historicString = historicString + ' ' + split;
      }
    });
    return historicString;
  }
  getIndividualHistoricTag(tag){
    switch (tag) {
      case '@participante@':
        if (this.saleDfe) {
          return this.doc.destinatario.nome.toUpperCase();
        } else {
          return this.doc.emitente.nome.toUpperCase();
        }
      case '@participante-CpfCnpj@':
        if (this.saleDfe) {
          return this.doc.destinatario.cpf ? this.doc.destinatario.cpf : this.doc.destinatario.cnpj;
        } else {
          return this.doc.emitente.cpf ? this.doc.emitente.cpf : this.doc.emitente.cnpj;
        }
      case '@numero-documento@':
        return this.doc.numeroDoc;
      case '@conta@':
        if(this.duplicate != null && this.duplicate.accountsChart){
          return this.duplicate.accountsChart.description.split('- ')[1];
        }
        let contaSplit = this.lancamentoForm.get('categoria').value.description? this.lancamentoForm.get('categoria').value.description.split('-'):'';
        return this.lancamentoForm.get('categoria').value.description ? contaSplit[1] : ' ';
      case '@mes@':
        return moment(this.lancamentoForm.get('dataPagamento').value).locale('pt-br').format('MMM').toUpperCase();
      case '@ano@':
        return moment(this.lancamentoForm.get('dataPagamento').value).format('Y');
      case '@ano-anterior@':
        return moment(this.lancamentoForm.get('dataPagamento').value).subtract(1, 'year').format('Y');
      case '@mes-anterior@':
        return moment(this.lancamentoForm.get('dataPagamento').value).subtract(1, 'month').locale('pt-br').format('MMM').toUpperCase();
      case '@mes-e-ano-anterior@':
        return moment(this.lancamentoForm.get('dataPagamento').value).subtract(1, 'month').locale('pt-br').format('MMM Y').toUpperCase();
      default:
        return tag;
    }
  }
}
