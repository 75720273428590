import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {ParcelasFinanciamento} from "../../../model/Parcelas-Financiamento";

@Injectable()
export class InstallmentsProvider {

  apiBase: string = "/bookkeeping/v1/financing/installment/";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAllByFinancingId(id) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/${id}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  post(financingId: number, obj: ParcelasFinanciamento) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/${financingId}`, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postInBatch(financingId: number, arr: ParcelasFinanciamento[]) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/${financingId}/in-batch`, arr, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  put(financingId: number, obj: ParcelasFinanciamento) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/${financingId}`, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putInBatch(financingId: number, arr: ParcelasFinanciamento[]) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/${financingId}/in-batch`, arr, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  delete(financingId: number, id: number) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/${financingId}/${id}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  togglePaid(financingId: number, id: number) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/${financingId}/${id}/paid`, {}, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
}
