import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Snackbar} from "../../../../util/snackbar";
import {EstadosMunicipiosUtil} from "../../../../util/estados-municipios";
import {PessoaFiscalDTO} from "../../../../model/dto/PessoaFiscalDTO";
import {MemberProvider} from "../../../../providers/company/member/member";
import {ErrorUtil} from "../../../../util/error";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {PessoaFiscal} from "../../../../model/PessoaFiscal";
import {Observable, ReplaySubject, Subject, Subscription} from "rxjs";
import {City} from "../../../../model/basicos/City";
import {Estate} from "../../../../model/basicos/Estate";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {map, startWith, take, takeUntil} from "rxjs/operators";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {CnpjValidator, CpfValidator} from "../../../../util/customValidators/cpfCnpjValidator";
import {ImovelDTO} from "../../../../model/dto/ImovelDTO";
import {PropertyProvider} from "../../../../providers/company/property/property";
import {MatSelect} from "@angular/material/select";
import {Country} from "../../../../model/basicos/Country";

@Component({
  selector: 'app-cadastro-socio',
  templateUrl: './cadastro-socio.component.html'
})
export class CadastroSocioComponent implements OnInit {

  @ViewChild('triggerCity', {static: false}) triggerCity: MatAutocompleteTrigger;
  @ViewChild('triggerState', {static: false}) triggerState: MatAutocompleteTrigger;
  @ViewChild('citySelect', {static: true}) citySelect: MatSelect;
  @ViewChild('stateSelect', {static: true}) stateSelect: MatSelect;

  @Input() enableClearOnEscapePressed = true;
  @Input() public socioExistente;

  socio: PessoaFiscal = new PessoaFiscal();
  socioAux: PessoaFiscalDTO = new PessoaFiscalDTO();
  editar: boolean = false;
  nomeMunicipio: string;
  properties: ImovelDTO[];
  socioForm: FormGroup;
  enviado: boolean = false;
  enviando: boolean = false;
  panelExpanded:boolean = false;

  states: Estate[] = this.estadosMunicipiosUtil.estados;
  public stateFilterCtrl: FormControl = new FormControl();
  public filteredStates: ReplaySubject<Estate[]> = new ReplaySubject<Estate[]>(1);
  protected _onDestroyState = new Subject<void>();

  cities: City[] = this.estadosMunicipiosUtil.cidades;
  public cityFilterCtrl: FormControl = new FormControl();
  public filteredCities: ReplaySubject<City[]> = new ReplaySubject<City[]>(1);
  protected _onDestroyCity = new Subject<void>();

  constructor(public activeModal: NgbActiveModal,
              public snackbar: Snackbar,
              public erroUtil: ErrorUtil,
              public fb: FormBuilder,
              public memberProvider: MemberProvider,
              public estadosMunicipiosUtil: EstadosMunicipiosUtil,
              public http: HttpClient,
  ) {
  }

  ngOnInit() {
    this.estadosMunicipiosUtil.setState();
    this.socioForm = this.fb.group({
      name: ['', Validators.required],
      cpfCNPJ: ['', Validators.compose( [Validators.required, CpfValidator, CnpjValidator])],
      telephone: [''],
      email: ['', Validators.compose( [Validators.email])],
      neighborhood: [''],
      complement: [''],
      estado: [''],
      municipio: [''],
      cep: [''],
      houseNumber: [''],
      street: [''],
      isClient: ['']
    });
    if (this.socioExistente != undefined) {
      Object.assign(this.socioAux, this.socioExistente);
      this.socioAux = Object.create(this.socioExistente);
      this.parseDTO();
      this.editar = true;
    }else{
      this.socioForm.controls.isClient.setValue(true)
    }

    this.configState();

  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroyState.next();
    this._onDestroyState.complete();
    this._onDestroyCity.next();
    this._onDestroyCity.complete();
  }

  parseDTO() {
    //console.log("FOPQJFPQWO",this.socioAux)
    this.socioForm.controls.cpfCNPJ.disable();
    this.socio.id = this.socioAux.id;
    this.socioForm.controls.name.setValue(this.socioAux.name);
    this.socioForm.controls.cpfCNPJ.setValue(this.socioAux.cpfCNPJ);
    this.socioForm.controls.isClient.setValue(this.socioAux.isClient)
    this.socio.cpfCNPJ = this.socioAux.cpfCNPJ;
    if (this.socioAux.addressDTORespComp != null) {
      this.socioForm.controls.telephone.setValue(this.socioAux.addressDTORespComp.telephone);
      this.socioForm.controls.email.setValue(this.socioAux.addressDTORespComp.email);
      this.socioForm.controls.street.setValue(this.socioAux.addressDTORespComp.street);
     // this.socioForm.controls.isClient.setValue(this.socioAux.isCLient)
      this.socioForm.controls.houseNumber.setValue(this.socioAux.addressDTORespComp.houseNumber);
      this.socioForm.controls.neighborhood.setValue(this.socioAux.addressDTORespComp.neighborhood);
      this.socioForm.controls.complement.setValue(this.socioAux.addressDTORespComp.complement);
      this.socioForm.controls.cep.setValue(this.socioAux.addressDTORespComp.cep);
      if (this.socioAux.addressDTORespComp.countryDTORespComp != null) {
        if ((this.socioAux.addressDTORespComp.countryDTORespComp.stateDTORespComp != null ||
          this.socioAux.addressDTORespComp.countryDTORespComp.stateDTORespComp != undefined) &&
          this.socioAux.addressDTORespComp.countryDTORespComp.stateDTORespComp.id != null) {
          this.socioForm.controls.estado.setValue(this.socioAux.addressDTORespComp.countryDTORespComp.stateDTORespComp);
          this.estadosMunicipiosUtil.setCity(this.socioAux.addressDTORespComp.countryDTORespComp.stateDTORespComp.id);
          this.socio.stateId = this.socioAux.addressDTORespComp.countryDTORespComp.stateDTORespComp.id;
          this.socioForm.controls.municipio.setValue(this.socioAux.addressDTORespComp.countryDTORespComp.stateDTORespComp.cityDTORespComp);
          this.socio.cityId = this.socioAux.addressDTORespComp.countryDTORespComp.stateDTORespComp.cityDTORespComp.id;
          this.configCity();
          this.nomeMunicipio = this.socioAux.addressDTORespComp.countryDTORespComp.stateDTORespComp.cityDTORespComp.name;
        }
      }
    }
  }

  protected setInitialValue() {

      this.filteredStates
        .pipe(take(1), takeUntil(this._onDestroyState))
        .subscribe(() => {
          this.stateSelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
        });

      this.filteredCities
        .pipe(take(1), takeUntil(this._onDestroyCity))
        .subscribe(() => {
          this.citySelect.compareWith = (a: Country, b: Country) => a && b && a.id === b.id;
        });

  }

  useCepToSetForm() {
    if (this.socioForm.controls.cep.value.length == 8) {
      return new Promise((resolve, reject) => {
        this.http.get('https://viacep.com.br/ws/' + this.socioForm.controls.cep.value + '/json/')
          .subscribe((result: any) => {
            resolve(result);
            //console.log("result", result);
            this.socioForm.controls.street.setValue(result.logradouro);
            this.socioForm.controls.neighborhood.setValue(result.bairro);
            this.socioForm.controls.estado.setValue(this.states.find(x => x.abbreviation == result.uf));
            this.getStateId();
            this.socioForm.controls.municipio.setValue(this.cities.find(x => x.name == result.localidade.normalize("NFD").replace(/[\u0300-\u036f]/g, "")));            
          });
      })
    }
  }

  configState() {
    this.estadosMunicipiosUtil.setState();
    this.states = this.estadosMunicipiosUtil.estados;
    this.filteredStates.next(this.states.slice());
    this.stateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyState))
      .subscribe(() => {
        this.filterStates();
      });
  }

  configCity() {
    this.cities = this.estadosMunicipiosUtil.cidades;

    this.filteredCities.next(this.cities.slice());
    this.cityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroyCity))
      .subscribe(() => {
        this.filterCities();
      });
  }

  filterCities() {
    if (!this.cities) {
      return;
    }
    // get the search keyword
    let search = this.cityFilterCtrl.value;
    if (!search) {
      this.filteredCities.next(this.cities.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countrys
    this.filteredCities.next(
      this.cities.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  filterStates() {
    if (!this.states) {
      return;
    }
    // get the search keyword
    let search = this.stateFilterCtrl.value;
    if (!search) {
      this.filteredStates.next(this.states.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countrys
    this.filteredStates.next(
      this.states.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  getStateId() {
    this.socio.stateId = this.socioForm.controls.estado.value.id;
    this.estadosMunicipiosUtil.setCity(this.socioForm.controls.estado.value.id);
    this.socio.cityId = undefined;
    this.socioForm.controls.municipio.setValue('');
    this.configCity();
  }

  getCityId() {
    this.socio.cityId = this.socioForm.controls.municipio.value.id;
  }

  setEditedObject(pessoa: PessoaFiscalDTO) {
    this.socioExistente.id = pessoa.id;
    this.socioExistente.name = pessoa.name;
    this.socioExistente.cnpjCPF = pessoa.cpfCNPJ;
    this.socioExistente.addressDTORespComp = pessoa.addressDTORespComp;
    this.socioExistente.certificateId = pessoa.certificateId;
    this.socioExistente.countryDTORespComp = pessoa.countryDTORespComp;
  }

  setSocioForm() {
    this.socio.name = this.socioForm.controls.name.value;
    this.socio.cpfCNPJ = this.socioForm.controls.cpfCNPJ.value;
    this.socio.telephone = this.socioForm.controls.telephone.value;
    this.socio.email = this.socioForm.controls.email.value;
    this.socio.street = this.socioForm.controls.street.value;
    this.socio.houseNumber = this.socioForm.controls.houseNumber.value;
    this.socio.neighborhood = this.socioForm.controls.neighborhood.value;
    this.socio.complement = this.socioForm.controls.complement.value;
    this.socio.cep = this.socioForm.controls.cep.value;
    this.socio.stateId = this.socioForm.controls.estado.value.id;
    this.socio.cityId = this.socioForm.controls.municipio.value.id;
    this.socio.isClient = this.socioForm.controls.isClient.value;
  }

  novoSocio() {
    //console.log(this.socioForm.value)
    this.enviando = true;
    if (this.socioForm.valid) {
      this.setSocioForm();
      this.memberProvider.postMember(this.socio).then((pessoa: PessoaFiscalDTO) => {
        this.snackbar.openLong("Sucesso! " + pessoa.name + " cadastrado com sucesso!", 'success');
        this.activeModal.close(pessoa);
      }).catch((error: HttpErrorResponse) => {
        this.enviando = false;
        this.snackbar.openLong(this.erroUtil.checkErrorStatus(error, error.status, error.error, 'member'), 'erro');
      });
    } else {
      this.enviando = false;

      this.enviado = true;
      this.snackbar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

  editarSocio() {
    this.enviando = true;
    if (this.socioForm.valid) {
      this.setSocioForm();
      //console.log("socio: ", this.socio);
      this.memberProvider.putMember(this.socio).then((pessoa: PessoaFiscalDTO) => {
        this.snackbar.openLong("Sucesso! " + pessoa.name + " editado com sucesso!", 'success');
        this.setEditedObject(pessoa);
        this.activeModal.close(pessoa);
      }).catch((error: HttpErrorResponse) => {
        this.enviando = false;
        this.snackbar.openLong(this.erroUtil.checkErrorStatus(error, error.status, error.error, 'member'), 'erro');
      });
    } else {
      this.enviando = false;
      this.enviado = true;
      this.snackbar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

}
