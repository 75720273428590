import {Component, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {ContaBancariaDTO} from '../../../../model/dto/ContaBancariaDTO';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorUtil} from '../../../../util/error';
import {PostingProvider} from '../../../../providers/bookkeeping/posting/posting';
import {BrMaskerIonicServices3} from 'brmasker-ionic-3';
import {DocType} from '../../../../model/enum/DocType';
import {Snackbar} from '../../../../util/snackbar';
import {MatDialog} from '@angular/material/dialog';
import {DocumentsBookkepingMapper} from '../../../../model/dto/mapper/documentsBookkepingMapper';
import {Bookkeeping} from '../../../../model/dto/bookkeping/Bookkeeping';
import {UserProvider} from '../../../../providers/user/user';
import {OAuthService} from 'angular-oauth2-oidc';
import {MatInput} from '@angular/material/input';
import {Column, InfiniteScrollService, parentsUntil} from '@syncfusion/ej2-angular-grids';
import {GrouperDTOResponse} from '../../../../model/dto/GrouperDTOResponse';
import {GrouperProvider} from '../../../../providers/grouper/grouper';
import {ExcelExportProperties, PdfExportProperties} from '@syncfusion/ej2-grids';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {L10n, loadCldr, setCulture, setCurrencyCode} from '@syncfusion/ej2-base';
import {pt} from 'src/util/translate/pt.json';
import 'moment-timezone';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {AggregateRowModel} from '@syncfusion/ej2-grids/src/grid/models/models';
import {FilterSettingsModel, SelectionSettingsModel} from '@syncfusion/ej2-grids/src/grid/base/grid-model';
import {DateUtil} from '../../../../util/dateUtil';
import {AccountPlanDTOResponse} from '../../../../model/dto/AccountPlanDTOResponse';
import {ImovelDTO} from '../../../../model/dto/ImovelDTO';
import {ParticipantDTOResponse} from '../../../../model/dto/ParticipantDTOResponse';
import {HistoricDTOResponse} from '../../../../model/dto/HistoricDTOResponse';
import {PropertyProvider} from '../../../../providers/company/property/property';
import {ParticipantProvider} from '../../../../providers/participant/participant';
import {BankAccountProvider} from '../../../../providers/company/bank_account/bank_account';
import {HistoricProvider} from '../../../../providers/bookkeeping/historic/historic';
import {AccountProvider} from '../../../../providers/account/account';
import {ConvertObjectPipe} from '../../../../util/pipes/ConvertObjectPipe';
import {Router} from '@angular/router';
import { DfeProvider } from 'src/providers/dfe/dfe';
import { ConsultaPdfXml } from 'src/providers/dfe/consultaPdfXml';
import {ReportsUtil} from "../../../../util/Reports";

setCulture('pt');
setCurrencyCode('BRL');

L10n.load({
  pt
});


@Component({
  selector: 'app-escrituracao',
  templateUrl: './escrituracao.component.html',
  styleUrls: ['./escrituracao.component.scss'],
  providers: [InfiniteScrollService,
  {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
  {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
]
})


export class EscrituracaoComponent implements OnInit {

  @ViewChild('TABLE', {static: false}) table: any;
  @ViewChild('picker', {static: false}) datePicker: MatDatepicker<any>;
  @ViewChild(MatInput, {static: false}) search: MatInput;
  @ViewChild('grid', {static: false}) grid;

  public years: any;
  public year: number;

  public links = ['Pagamentos', 'Recebimentos'];
  public activeLink = this.links[0];
  public linksMonths = ['Mensal', 'Personalizado'];
  public activeLinkMonths = this.linksMonths[0];
  public months = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
  public activeMonth = this.months[0];
  public changeMonth: boolean;
  public monthly = true;
  // tslint:disable-next-line: no-use-before-declare
  public dateRange: ConsultDate = new ConsultDate();

  public consultando: boolean;
  public erroDoc: boolean;
  public erroTipo: string;
  public tab: string;

  public filtros: any;
  public filter = {type: 'CheckBox'};

  public toppings = new FormControl();

  public contas: ContaBancariaDTO[];
  public agrupadores: GrouperDTOResponse;
  public lancamentos: Bookkeeping[];

  public saldo = 0;
  public saldoInicial = 0;
  public totalPagamentos: number;
  public totalRecebimentos: number;
  public totalNaoDetutiveis: number;
  public totalDedutiveis: number;

  public dtTime = false;
  public intervalFun: any;
  public stTime: any;
  // tslint:disable-next-line:ban-types
  public data: Object;
  public filterSettings: FilterSettingsModel;
  public selectionSettings: SelectionSettingsModel;
  public dataSourceMain: Bookkeeping[];
  public customAttributes: any;
  public aggregates: AggregateRowModel[];
  public keyword: any;
  public docsVisible = true;
  private savedColumns: any;
  public selectedBookkeeping: Bookkeeping[] = [];
  private categorias: AccountPlanDTOResponse[];
  private imoveis: ImovelDTO[];
  private participantes: ParticipantDTOResponse[];
  private historicos: HistoricDTOResponse[];
  public carregandoEscrituracao: boolean;

  constructor(public errorUtil: ErrorUtil,
              public snackBar: Snackbar,
              public dialog: MatDialog,
              private userProvider: UserProvider,
              public dateUtil: DateUtil,
              private oauthService: OAuthService,
              // tslint:disable-next-line:variable-name
              private _adapter: DateAdapter<any>,
              private brMasker: BrMaskerIonicServices3,
              private documentsBookkepingMapper: DocumentsBookkepingMapper,
              private postingProvider: PostingProvider,
              private imovelProvider: PropertyProvider,
              private participantProvider: ParticipantProvider,
              public reportsUtil: ReportsUtil,
              private contaBancariaProvider: BankAccountProvider,
              private historicProvider: HistoricProvider,
              private router: Router,
              private accountProvider: AccountProvider,
              private grouperProvider: GrouperProvider,
              private dfeProvider: DfeProvider,
              public consultaPdfXml: ConsultaPdfXml) {
  }

  ngOnInit() {
    this.defaultConfiguration();
    this.getLancamentosByPeriod();
    this.tableConfiguration();
    this._adapter.setLocale('pt-BR');
    this.customAttributes = {class: 'customcss'};
    this.getDataForBookkeeping();
    // tslint:disable-next-line: deprecation
    this.router.events.subscribe((val) => {
      this.clearSearch();
    });
  }

  getDataForBookkeeping() {
    this.carregandoEscrituracao = true;
    const pipe = new ConvertObjectPipe();
    // this.categorias = pipe.transform(categorias, 'categoria');
      this.imovelProvider.getAllProperty().then((imoveis: ImovelDTO[]) => {
        this.imoveis = pipe.transform(imoveis, 'imoveis');
        this.contaBancariaProvider.getAllBanksAccounts().then((contas: ContaBancariaDTO[]) => {
          this.contas = pipe.transform(contas, 'contasBancarias');
          this.carregandoEscrituracao = false;
        });
    // this.accountProvider.getAllAccountsLeafs().then((categorias: AccountPlanDTOResponse[]) => {

    }).catch(result => {
      this.carregandoEscrituracao = false;
      this.snackBar.openLong('Ocorreu um erro ao carregar os dados. Por favor recarregue a página!', 'erro');
    });
  }


  tableConfiguration() {
    this.customAttributes = {class: 'customcss'};
    this.filterSettings = {type: 'Menu'};
    this.filter = {type: 'CheckBox'};
    this.stTime = performance.now();
    this.aggregates = [{
      columns: [
        {
          type: 'Sum',
          format: 'C2',
          field: 'amountPaid',
          footerTemplate: '<b><font class="text-sm" color="black">${Sum}</font></b>'
        }
      ],
    }];
  }

  defaultConfiguration() {
    const workDate = localStorage.getItem('workDate');
    this.dateRange.startDate =  moment.tz(workDate, 'America/Sao_Paulo').startOf('year').format();
    this.dateRange.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('year').format();
    this.activeMonth = this.months[+moment.tz(workDate, 'America/Sao_Paulo').format('MM') - 1]; // set current month
    this.year = +moment(localStorage.getItem('workDate')).format('YYYY');  // set current year
    this.tab = 'Pagamentos'; // default tab
    this.years = getYears(+moment().format('YYYY') - 20);  // generate range of years
    this.grouperProvider.getAllGroupers().then((groupers: GrouperDTOResponse) => {
      this.agrupadores = groupers;
    });
  }

  searchTable() {
    if (this.keyword === '') {
      this.grid.searchSettings.key = '';
    }
    const wordSearch = this.keyword;
    let time = 400;
    if (this.dataSourceMain.length > 500) {
      time = 800;
    }
    setTimeout(() => {
      if (wordSearch === this.keyword) {
        if (this.keyword) {
          const searchText: string = (document.getElementsByClassName('searchtext')[0] as any).value;
          this.grid.search(searchText);
        }
      } else {
        this.grid.searchSettings.key = '';
      }
    }, time);
  }

  clearSearch() {
    this.grid.searchSettings.key = '';
    this.keyword = '';
  }

  getLancamentosByPeriod() {
    this.consultando = true;
    // tslint:disable-next-line: no-use-before-declare
    const consultDate: ConsultDate = new ConsultDate();
    if (this.monthly) {
      const month = this.getActiveMonth(this.activeMonth);
      const date = this.year + '-' + month + '-' + '01';
      consultDate.startDate = moment.tz(date, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
      consultDate.endDate = moment.tz(date, 'America/Sao_Paulo').endOf('month').format('YYYY-MM-DD');
    } else {
      consultDate.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
      consultDate.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
    }
    this.getPosting(consultDate.startDate, consultDate.endDate);
  }

  changeYear(type: string) {
    if (type === '+') {
      this.year = +this.year + 1;
    } else {
      this.year = +this.year - 1;
    }

    let existe = 0;
    this.years.forEach((ano: number) => {

      if (this.year === ano) {
        existe++;
      }

    });
    if (existe === 0) {
      this.years.push(this.year);
      // tslint:disable-next-line:only-arrow-functions
      this.years.sort(function(b, a) {
        return a < b ? -1 : a > b ? 1 : 0;
      });
    }
    this.getLancamentosByPeriod();
  }

  getPosting(startDate, endDate) {
    this.postingProvider.getAllPosting(startDate, endDate).then((lancamentos: []) => {
      this.lancamentos = this.documentsBookkepingMapper.arrayToClientModel(lancamentos);
      this.consultando = false;
      this.erroDoc = false;
      this.tabsTypeFilter();
      this.getInitialBalance(startDate);
      this.getIndicators();
    }).catch((result: HttpErrorResponse) => {
      this.snackBar.openLong('Erro ao carregar lançamentos!', 'erro');
      console.error(result);
      this.erroDoc = true;
      this.consultando = false;
      this.erroTipo = this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping');
    });
  }

  checkIfFinancialReleaseAlreadySelected(financialRelease): boolean {
    return this.selectedBookkeeping.findIndex(val => val.id === financialRelease.id) === -1;
  }

  selectFinancialReleaseForDelete(financialRelease) {
    if (this.checkIfFinancialReleaseAlreadySelected(financialRelease)) {
      this.selectedBookkeeping.push(financialRelease);
    } else {
      this.selectedBookkeeping.splice(this.selectedBookkeeping.findIndex(val => val.id === financialRelease.id), 1);
    }
  }

  checkIfIsAllSelected(): boolean {
    for (let i = 0; i < this.grid.getCurrentViewRecords().length; i++) {
      if (this.selectedBookkeeping.findIndex(value => value.id === this.grid.getCurrentViewRecords()[i].id) < 0) {
        return false;
      } else {
        if (i === (this.grid.getCurrentViewRecords().length - 1)) {
          return true;
        }
      }
    }
  }

  getInitialBalance(date) {
    const dateBalance = moment.tz(date, 'America/Sao_Paulo').subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    this.postingProvider.getBalance(dateBalance).then((saldo: number) => {
      this.saldoInicial = saldo;
    });
  }

  tabs(link: string) {
    this.clearSearch();
    this.tab = link;
    this.tabsTypeFilter();
  }

  tabsTypeFilter() {
    const lancamentosModificado = [...this.lancamentos];
    let lancamentosFiltrados = [];
    if (this.tab === 'Recebimentos') {
      this.totalRecebimentos = 0;
      lancamentosModificado.forEach((lancamento: any, index: number) => {
        if (lancamento.account.featureType !== 'EXPENSE') {
          this.totalRecebimentos = lancamento.amountPaid + this.totalRecebimentos;
          lancamentosFiltrados.push(lancamento);
        }
      });
    } else if (this.tab === 'Pagamentos') {
      this.totalPagamentos = 0;
      lancamentosModificado.forEach((lancamento: any, index: number) => {
        if (lancamento.account.featureType !== 'REVENUE') {
          this.totalPagamentos = lancamento.amountPaid + this.totalPagamentos;
          lancamentosFiltrados.push(lancamento);
        }
      });
    } else {
      lancamentosFiltrados = lancamentosModificado;
    }
    this.grouperFilter(lancamentosFiltrados);
  }

  getIndicators() {
    const data = this.dataSourceMain;
    this.totalPagamentos = 0;
    this.totalRecebimentos = 0;
    this.totalDedutiveis = 0;
    this.totalNaoDetutiveis = 0;
    data.forEach((lancamento: any) => {
      if (lancamento.account.featureType !== 'EXPENSE') {
        this.totalRecebimentos = lancamento.amountPaid + this.totalRecebimentos;
      }
      if (lancamento.account.featureType !== 'REVENUE') {
        this.totalPagamentos = lancamento.amountPaid + this.totalPagamentos;
      }
      if (lancamento.postingReleaseType === 'NONE') {
        this.totalNaoDetutiveis = this.totalNaoDetutiveis + lancamento.amountPaid;
      } else {
        this.totalDedutiveis = this.totalDedutiveis + lancamento.amountPaid;
      }
    });
    this.saldo = this.totalRecebimentos + this.totalPagamentos;
  }

  formatarValor(valorTotal: string) {
    return this.brMasker.writeValueMoney(valorTotal.toString(), {
      decimal: 2,
      decimalCaracter: ',',
      len: 0,
      mask: '',
      numberAndTousand: false,
      percent: false,
      person: false,
      phone: false,
      thousand: '.',
      type: undefined,
      userCaracters: false,
      money: true
    });
  }

  formatarDocType(docType: any) {
    return DocType[docType];
  }

  excelExport() {
    let period: string;
    if (this.activeLinkMonths !== 'Personalizado') {
      period = this.activeMonth + ' - ' + this.year;
    } else {
      period = moment(this.dateRange.startDate).format('DD/MM/YY') + ' - ' + moment(this.dateRange.endDate).format('DD/MM/YY');
    }
    const exportProperties: ExcelExportProperties = {
      fileName: this.activeLink + period + '.xlsx',
    };
    (this.grid.columns[0] as Column).visible = false;
    (this.grid.columns[2] as Column).visible = true;
    (this.grid.columns[7] as Column).visible = true;
    (this.grid.columns[8] as Column).visible = true;
    (this.grid.columns[9] as Column).visible = true;
    this.grid.excelExport(exportProperties);
    setTimeout(() => {
      (this.grid.columns[0] as Column).visible = true;
      this.grid.refreshColumns();
    }, 2000);
  }

  pdfExport() {
    const date = moment().format('DD/MM/YY HH:mm').toString();

    let period: string;
    if (this.activeLinkMonths !== 'Personalizado') {
      period = this.activeMonth + ' - ' + this.year;
    } else {
      period = moment(this.dateRange.startDate).format('DD/MM/YY') + ' - ' + moment(this.dateRange.endDate).format('DD/MM/YY');
    }

    let header = this.reportsUtil.PDFHeader({
      arquivo: `Escrituracao - ${this.activeLink} - ${period}`,
      titulo: `Documentos fiscais - ${this.activeLink}`,
      data: date,
      periodo: period,
      conteudo: {},
      orientacao: 'Landscape'
    });

    for (const cols of this.grid.columns) {
      if ((cols as any).field === 'postingType') {
        (cols as any).width = 80;
      }
      if ((cols as any).field === 'doc.number') {
        (cols as any).width = 60;
      }
      if ((cols as any).field === 'amountPaid') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'doc.property.name') {
        (cols as any).width = 80;
      }
      if ((cols as any).field === 'bankAccount.description') {
        (cols as any).width = 80;
      }
      if ((cols as any).field === 'paymentDate') {
        (cols as any).width = 80;
        (cols as any).format = 'yMd';
        (cols as any).type = 'date';
      }
      if ((cols as any).field === 'doc.participant.name') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'doc.docTypeFormated') {
        (cols as any).width = 80;
      }
      if ((cols as any).field === 'historic') {
        (cols as any).width = 120;
      }
      if ((cols as any).field === 'account.description') {
        (cols as any).width = 110;
      }
    }
    this.grid.pdfExport(header);
    setTimeout(() => {
      // this.grid.refreshColumns();
    }, 2000);
  }

  autoFillOthers() {
    this.grid.autoFitColumns(['amountPaid', 'doc.docTypeFormated',
      'doc.participant.name', 'historic', 'bankAccount.description', 'account.description',
      'postingType', 'doc.property.name', 'doc.number', 'paymentDate']);

  }


  grouperFilter(lancamentosModificado) {
    const lancamentosFiltrados = [];
    if ((this.toppings.value) == null || this.toppings.value.length === 0) {
      this.dataSourceMain = lancamentosModificado;
      if (this.monthly) {
        // this.applyFilter(this.search.value);
      }
    } else {
      this.toppings.value.forEach((accountSelect) => {
        lancamentosModificado.forEach((lancamento: any, index: number) => {
          if (lancamento.grouper.length !== 0) {
            let existGrouper: boolean;
            lancamento.grouper.forEach((grouper) => {
              if (!(grouper.name.toLowerCase().indexOf(accountSelect.name.toLowerCase()) > -1)) {
              } else {
                existGrouper = true;
              }
            });
            if (existGrouper) {
              lancamentosFiltrados.push(lancamento);
            }
          }
        });
        this.dataSourceMain = lancamentosFiltrados;
        if (this.monthly) {
          // this.applyFilter(this.search.value);
        }
      });
    }
  }

  getActiveMonth(mes) {
    switch (mes) {
      case 'JAN':
        mes = '01';
        break;
      case 'FEV':
        mes = '02';
        break;
      case 'MAR':
        mes = '03';
        break;
      case 'ABR':
        mes = '04';
        break;
      case 'MAI':
        mes = '05';
        break;
      case 'JUN':
        mes = '06';
        break;
      case 'JUL':
        mes = '07';
        break;
      case 'AGO':
        mes = '08';
        break;
      case 'SET':
        mes = '09';
        break;
      case 'OUT':
        mes = '10';
        break;
      case 'NOV':
        mes = '11';
        break;
      case 'DEZ':
        mes = '12';
        break;
      default:
        mes = '01';
    }
    return mes;
  }

  tabsMonth(link: string) {
    this.monthly = link === 'Mensal';
    this.getLancamentosByPeriod();
  }

  changePeriod(data) {
    if (data.value != null && this.dateRange.startDate != null && this.dateRange.endDate != null) {
      this.getLancamentosByPeriod();
    }
  }

  selectAllToggle() {
    if (!this.checkIfIsAllSelected()) {
      this.grid.getCurrentViewRecords().forEach(row => {
        if (this.selectedBookkeeping.findIndex(value => value.id === row.id) >= 0) {
          this.selectedBookkeeping.splice(this.selectedBookkeeping.findIndex(value => value.id === row.id), 1);
        }
        this.selectFinancialReleaseForDelete(row);
      });
    } else {
      this.grid.getCurrentViewRecords().forEach(row => {
        if (this.selectedBookkeeping.findIndex(value => value.id === row.id) >= 0) {
          this.selectedBookkeeping.splice(this.selectedBookkeeping.findIndex(value => value.id === row.id), 1);
        }
      });
    }
  }

  getFileDfe(planPaymentId, location) {
    this.carregandoEscrituracao = true;
    this.dfeProvider.getDfeByPlannedPayment(planPaymentId).then((dfe: any) => {
      const document = {
        xml: atob(dfe.dfeBytes),
        modelo: dfe.modelType,
        chave: dfe.key
      };
      switch (location) {
        case 'modal':
          this.consultaPdfXml.getPdfModal(document).then((result) => {
            this.carregandoEscrituracao = false;
          }).catch((result: HttpErrorResponse) => {
            this.carregandoEscrituracao = false;
            console.error(result);
            this.snackBar.openLong('Não foi possível consultar o PDF/XML. ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
          });
          break;
        case 'blank':
          this.consultaPdfXml.getPdf(document).then((result) => {
            this.carregandoEscrituracao = false;
          }).catch((result: HttpErrorResponse) => {
            this.carregandoEscrituracao = false;
            console.error(result);
            this.snackBar.openLong('Não foi possível consultar o PDF/XML. ' +
            this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
          });
          break;
      }
    }).catch((result: HttpErrorResponse) => {
      this.carregandoEscrituracao = false;
      console.error(result);
      this.snackBar.openLong('Não foi possível consultar o PDF/XML. ' +
        this.errorUtil.checkErrorStatus(result, result.status, result.error, 'documents'), 'error');
    });
  }

}

function getYears(startYear) {
  // tslint:disable-next-line:one-variable-per-declaration
  const currentYear = new Date().getFullYear() + 1, years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

class ConsultDate {
  endDate: any;
  startDate: any;
}
