
import {NgbActiveModal,NgbModalOptions,NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CadastroContaBancariaComponent} from "../../../minhasPropriedades/cadastro-conta-bancaria/cadastro-conta-bancaria.component";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild,Input } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import {ContaBancariaDTO} from "../../../../../model/dto/ContaBancariaDTO";
@Component({
  selector: 'app-imovel-individual',
  templateUrl: './imovel-individual.component.html',
  styleUrls: ['./imovel-individual.component.scss']
})
export class ImovelIndividualComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,public modalService: NgbModal,) { }
  @Input() public edit
  public imovelForm = new FormControl();
  @Input() public imovel;
  protected _onDestroy = new Subject<void>();

  public filter_imovel: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public imovelFilterCtrl: FormControl = new FormControl()

  ngOnInit() {
    //console.log(this.imovel)
    this.imovelForm.setValue(this.edit)

    this.filter_imovel.next(this.imovel.slice());

    this.imovelFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterImovel();
      });
  }
  protected filterImovel() {
    if (!this.imovel) {
      return;
    }
    // get the search keyword
    let search = this.imovelFilterCtrl.value;
    if (!search) {
      this.filter_imovel.next(this.imovel.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the planAccount
    this.filter_imovel.next(
      this.imovel.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }
  public save(){
    //console.log(this.imovelForm)
    this.activeModal.close(this.imovelForm.value);

  }
}
