import {Component, OnInit} from '@angular/core';

import {UserblockService} from './userblock.service';
import {OAuthService} from "angular-oauth2-oidc";
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {
  AdicionarImagemComponent
} from 'src/app/routes/cadastros/enviar-arquivos/adicionar-imagem/adicionar-imagem.component';
import {UserProvider} from 'src/providers/user/user';

@Component({
  selector: 'app-userblock',
  templateUrl: './userblock.component.html',
  styleUrls: ['./userblock.component.scss']
})
export class UserblockContadorComponent implements OnInit {
  usuario: any;
  user: any;
  public nome: string;
  editPictureIcon: boolean = false;

  constructor(public userblockService: UserblockService, private oauthService: OAuthService, public modalService: NgbModal, public userProvider: UserProvider) {

    this.usuario = this.givenName();
    this.getUserPicture();

  }

  ngOnInit() {
  }

  getUserPicture() {
    window.onload = () => {
      //console.log('onload');
      (document.getElementById('userPicture') as any).src = localStorage.getItem('userPicture');
    }
  }

  setEditPictureIcon(bool){
    this.editPictureIcon = bool;
  }

  editPicture() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    try {
        const modalRef = this.modalService.open(AdicionarImagemComponent, modalOptions).result.then((result) => {
          if(result != 'cancelou')(document.getElementById('userPicture') as any).src = localStorage.getItem('userPicture');
        }).catch((error) => {
          //console.log('error', error);
        }
        );
    } catch (error) {
        console.error('', error);
    }
  }

  givenName() {
    var claims = this.oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['principal'];
  }

  userBlockIsVisible() {
    return this.userblockService.getVisibility();
  }

  setUserPicture(){
    if(localStorage.getItem('userPicture') == null){
      this.userProvider.getImageOffice().then((value: Blob) => {
        const file = value;
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener('load', () => {
          const url = fr.result
          localStorage.setItem('userPicture', url as string);
        })
      }).catch((error) => {
        localStorage.setItem('userPicture', 'assets/img/user/02.png');
      })
    }
    if((document.getElementById('userPicture') as any).src != localStorage.getItem('userPicture')){
      //console.log("lel");
      (document.getElementById('userPicture') as any).src = localStorage.getItem('userPicture');
    }
  }

}
