import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Snackbar } from 'src/util/snackbar';
import { ReceiptProvider } from 'src/providers/receipt/receipt';
import { Receipts } from 'src/model/dto/receipts/ReceiptsDTOResponse';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-adicionar-arquivos',
  templateUrl: './adicionar-arquivos.component.html',
  styleUrls: ['./adicionar-arquivos.component.scss']
})
export class AdicionarArquivosComponent implements OnInit {

  public inputFiles: InputFile[] = [];
  public files: any[] = [];
  public enviandoArquivos = false;
  public formGroup: FormGroup;
  public resultPostFiles: any[] = [];

  constructor(public snackBar: Snackbar,
              public activeModal: NgbActiveModal,
              private receiptProvider: ReceiptProvider,
              public formBuilder: FormBuilder) { }

  ngOnInit() {
  }

  formConfiguration() {
    let group = {};
    this.inputFiles.forEach(inputFile => {
      group[inputFile.fileName] = new FormControl('', Validators.required);
    });
    this.formGroup = new FormGroup(group);
  }

  cancel() {
    this.snackBar.openLong('Operação cancelada', 'erro');
    this.activeModal.close('Cancelou');
  }

  post() {
    this.enviandoArquivos = true;
    this.files = Array.from((document.getElementById('files') as HTMLInputElement).files);
    if (this.inputFiles.filter(x => x.descricao === undefined || x.descricao === '').length > 0) {
      this.snackBar.openLong('Todos os arquivos devem possuir descrição', 'erro');
      this.enviandoArquivos = false;
    } else {
      this.postFiles(0);
    }
  }

  postFiles(index: number) {
    if (index < (this.files.length)) {
      this.receiptProvider.postReceiptAndFile(this.files[index], this.inputFiles[index].descricao).then((result: Receipts) => {
        if (result.id !== undefined && result.id != null) {
          this.resultPostFiles.push(result);
          this.postFiles(index + 1);
        }
      }).catch(exception => {
        this.enviandoArquivos = false;
        console.error('Erro ao enviar arquivo', exception);
        this.snackBar.openLong('Erro ao enviar arquivo', 'erro');
      });
    } else {
      this.enviandoArquivos = false;
      this.snackBar.openLong('Arquivo(s) enviado(s) com sucesso!', 'success');
      this.activeModal.close(this.resultPostFiles);
    }
  }

  insertFile() {
    try {
      document.getElementById('files').click();
    } catch (exception) {
      console.error('Erro ao inserir arquivo!', exception);
    }
  }

  removeFile(inputFile: InputFile) {
    const index = this.inputFiles.indexOf(inputFile);
    this.inputFiles.splice(index, 1);
  }

  setDescription(elem: HTMLInputElement, inputFile: InputFile) {
    inputFile.descricao = elem.value;
  }

  fileChange() {
    try {
      const files = Array.from((document.getElementById('files') as HTMLInputElement).files);
      Array.from(files).forEach(file => {
        const inputFile = new InputFile();
        inputFile.fileName = file.name;
        inputFile.descricao = '';
        if (this.inputFiles.length < 10) {
          this.inputFiles.push(inputFile);
        } else {
          this.snackBar.openLong('A quantidade máxima de arquivos permitida é 10.', 'erro');
        }
      });
      this.formConfiguration();
    } catch (exception) {
      console.error('Erro ao inserir arquivo!', exception);
    }
  }

}

class InputFile {
  descricao: string;
  fileName: string;
}
