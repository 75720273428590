import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CompanyProvider} from "../../../../../providers/company/company/company";
import {Pessoa} from "../../../../../model/Pessoa";
import {HttpErrorResponse} from "@angular/common/http";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";

@Component({
  selector: 'app-cadastrar-empresa',
  templateUrl: './cadastrar-empresa.component.html',
  styleUrls: ['./cadastrar-empresa.component.scss']
})
export class CadastrarEmpresaComponent implements OnInit {
  public clienteForm: FormGroup;
  enviado: boolean = false;
  editar: boolean = true;
  @Input() public clienteExistente;
  enviando: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public snackBar: Snackbar,
              public errorUtil: ErrorUtil,
              public companyProvider: CompanyProvider) {
  }

  ngOnInit() {
    this.configuracaoForms();
    this.checkOperation();
  }

  checkOperation(){
    if (this.clienteExistente != undefined) {
      this.editar = true;
      this.clienteForm.setValue(this.clienteExistente);
    }else{
      this.editar = false;
    }
  }

  configuracaoForms() {
    this.clienteForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      lcDigital: [false, Validators.required]
    });
  }

  setObjectEdit(cliente: Pessoa){
    this.clienteExistente.id = cliente.id;
    this.clienteExistente.name = cliente.name;
    this.clienteExistente.lcDigital = cliente.lcDigital;
  }

  novoCliente() {
    this.enviado = true;
    this.enviando = true;
    if (this.clienteForm.valid) {
      if(!this.editar){
      this.companyProvider.postCompany(this.clienteForm.value).then((cliente: Pessoa) => {
        this.snackBar.openLong("Sucesso! " + cliente.name + " inserido com sucesso!", 'success');
        this.activeModal.close(cliente);
      }).catch((result: HttpErrorResponse) => {
        this.snackBar.openLong('Não foi possível inserir! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'client'), 'erro');
        this.enviando = false;
      });
      }else{
        this.companyProvider.putCompany(this.clienteForm.value).then((cliente: Pessoa) => {
          this.snackBar.openLong("Sucesso! " + cliente.name + " editado com sucesso!", 'success');
          this.setObjectEdit(cliente);
          this.activeModal.close(cliente);
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          this.snackBar.openLong('Não foi possível editar! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'client'), 'erro');
        });
      }
    } else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

}
