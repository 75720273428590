import { NgModule } from '@angular/core';

import { UserblockService } from './sidebar/userblock/userblock.service';

import { SharedModule } from '../shared/shared.module';
import {LayoutContadorComponent} from './layout.component';
import {OffsidebarContadorComponent} from './offsidebar/offsidebar.component';
import {NavsearchContadorComponent} from './header/navsearch/navsearch.component';
import {SidebarContadorComponent} from './sidebar/sidebar.component';
import {UserblockContadorComponent} from './sidebar/userblock/userblock.component';
import {FooterContadorComponent} from './footer/footer.component';
import {HeaderContadorComponent} from './header/header.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import { PagesModule } from '../routes/pages/pages.module';

@NgModule({
    imports: [
        SharedModule,
        TranslateModule,
        // RouterModule,
        MatTooltipModule,
        PagesModule
    ],
    providers: [
        UserblockService
    ],
    declarations: [
        LayoutContadorComponent,
        SidebarContadorComponent,
        UserblockContadorComponent,
        HeaderContadorComponent,
        NavsearchContadorComponent,
        OffsidebarContadorComponent,
        FooterContadorComponent
    ],
    exports: [
        LayoutContadorComponent,
        SidebarContadorComponent,
        UserblockContadorComponent,
        HeaderContadorComponent,
        NavsearchContadorComponent,
        OffsidebarContadorComponent,
        FooterContadorComponent
    ]
})
export class LayoutContadorModule { }
