import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment.prod';
import {Receipts} from 'src/model/dto/receipts/ReceiptsDTOResponse';
import Swal from 'sweetalert2';

@Injectable()
export class ReceiptProvider {
  apiBase = '/main-records/v1/receipt/';

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getReceiptsByCompanyId() {
    const requestString = `${environment.apiLogin}${this.apiBase}by_company/${localStorage.getItem('idEmpresa')}`;
    return new Promise((resolve, reject) => {
      this.http.get(requestString, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao buscar arquivo', error);
          reject(error);
        });
    });
  }

  getReceiptsByPeriod(start, end) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}period/${localStorage.getItem('idEmpresa')}/${start}/${end}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao buscar arquivo', error);
          reject(error);
        });
    });
  }

  downloadFile(fileName: string) {
    const requestString = `${environment.apiLogin}${this.apiBase}download/${fileName}`;
    return new Promise((resolve, reject) => {
      this.http.get(requestString, {
        responseType: 'blob',
        headers: {Authorization: this.oauthService.authorizationHeader()}
      })
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao baixar arquivo', error);
          reject(error);
        });
    });
  }

  deleteFile(documentID) {
    const requestString = `${environment.apiLogin}${this.apiBase}delete-file/${localStorage.getItem('idEmpresa')}/${documentID}`;
    return new Promise((resolve, reject) => {
      this.http.delete(requestString, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
          Swal.fire(
            'Excluído!',
            'O arquivo foi excluído com sucesso.',
            'success'
          ).then((result) => {
            resolve(result);
          });
        }, (error) => {
          console.error('Erro ao deletar arquivo', error);
          reject(error);
        });
    });
  }

  postReceiptAndFile(file: Blob, description: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
    const requestString = `${environment.apiLogin}${this.apiBase}upload-file/${localStorage.getItem('idEmpresa')}`;
    return new Promise((resolve, reject) => {
      this.http.post(requestString, formData, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Receipts[]) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao buscar arquivo', error);
          reject(error);
        });
    });
  }

  downloadFileInBatch(files) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiBase}download/${localStorage.getItem('idEmpresa')}/in-batch`, files, {
        responseType: 'blob',
        headers: {Authorization: this.oauthService.authorizationHeader()}
      }).subscribe((result) => {
        resolve(result);
      }, (error) => {
        console.error('Erro ao baixar arquivo', error);
        reject(error);
      });
    });
  }
}
