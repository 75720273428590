import {Injectable} from "@angular/core";
import {ErrorUtil} from "../../../../util/error";
import {DfeProvider} from "../../../../providers/dfe/dfe";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {ModalImportacaoComponent} from "./modal-importacao/modal-importacao.component";
import {HttpErrorResponse} from "@angular/common/http";
import {DfeDTOResponse} from "../../../../model/dto/dfe/DfeDTOResponse";
import {Xml} from "../../../../model/Xml";
import {PessoaFiscalDTO} from "../../../../model/dto/PessoaFiscalDTO";
import {MemberProvider} from "../../../../providers/company/member/member";

@Injectable()
export class ImportarXML {

  public members: PessoaFiscalDTO[];
  public nfeTags = [
    {
      name: 'emit',
      type: 'ISSUER',
      subTag: [
        {
          name: 'CPF'
        },
        {
          name: 'CNPJ'
        }]
    },
    {
      name: 'dest',
      type: 'ADDRESSEE',
      subTag: [
        {
          name: 'CPF'
        },
        {
          name: 'CNPJ'
        }
      ]
    },
    {
    name: 'infNFe',
    type: 'KEY',
    subTag: [{
      name: 'Id'
    }]
  },
    {
      name: 'infNFe',
      type: 'KEY',
      subTag: [{
        name: 'Id'
      }]
    }];
  public cteTags = [
    {
      name: 'infCte',
      type: 'KEY',
      subTag: [{
        name: 'Id'
      }]
    },
    {
      name: 'infCTe',
      type: 'KEY',
      subTag: [{
        name: 'Id'
      }]
    },
    {
      name: 'emit',
      type: 'ISSUER',
      subTag: [
        {
          name: 'CPF'
        },
        {
          name: 'CNPJ'
        }]
    },
    {
      name: 'dest',
      type: 'ADDRESSEE',
      subTag: [
        {
          name: 'CPF'
        },
        {
          name: 'CNPJ'
        }
      ]
    },
    {
      name: 'receb',
      type: 'ADDRESSEE',
      subTag: [
        {
          name: 'CPF'
        },
        {
          name: 'CNPJ'
        }
      ]
    },
  ];

  constructor(public errorUtil: ErrorUtil,
              public dfeProvider: DfeProvider,
              private memberProvider: MemberProvider,
              public modalService: NgbModal) {
  }

  ngOnInit() {

  }

  import(event, button) {
    return new Promise((accept, reject) => {
      this.memberProvider.getAllMembers().then((members: PessoaFiscalDTO[]) => {
        this.members = members;
        let contagem = 0;
        let sucesso = 0;
        let erro = 0;
        let warning = 0;
        const xmls: Xml[] = [];
        const input = event.target;
        let leghtEnvio = input.files.length;
        for (let index = 0; index < input.files.length; index++) {
          const reader = new FileReader();
          reader.onload = () => {
            button.disabled = true;
            const xml = reader.result;
            const xmlModelo = new DOMParser().parseFromString(xml.toString(), 'text/xml');
            if (xmlModelo.getElementsByTagName('NFe').length !== 0) {
              const xlmnew = new Xml();

              if (xmlModelo.getElementsByTagName('emit').item(0).getElementsByTagName("CPF").length === 0) {
                if (xmlModelo.getElementsByTagName('emit').item(0).getElementsByTagName("CNPJ").length !== 0) {
                  xlmnew.issuer = xmlModelo.getElementsByTagName('emit').item(0).getElementsByTagName("CNPJ")[0].textContent;
                } else {
                  xlmnew.issuer = '';
                }
              } else {
                if (xmlModelo.getElementsByTagName('emit').item(0).getElementsByTagName("CPF").length !== 0) {
                  xlmnew.issuer = xmlModelo.getElementsByTagName('emit').item(0).getElementsByTagName("CPF")[0].textContent;
                } else {
                  xlmnew.issuer = '';
                }
              }
              if (xmlModelo.getElementsByTagName('dest').item(0).getElementsByTagName("CPF").length === 0) {
                if (xmlModelo.getElementsByTagName('dest').item(0).getElementsByTagName("CNPJ").length !== 0) {
                  xlmnew.addressee = xmlModelo.getElementsByTagName('dest').item(0).getElementsByTagName("CNPJ")[0].textContent;
                } else {
                  xlmnew.addressee = '';
                }
              } else {
                if (xmlModelo.getElementsByTagName('dest').item(0).getElementsByTagName("CPF").length !== 0) {
                  xlmnew.addressee = xmlModelo.getElementsByTagName('dest').item(0).getElementsByTagName("CPF")[0].textContent;
                } else {
                  xlmnew.addressee = '';
                }
              }
              xlmnew.cpfCNPJMember = null;
              xlmnew.companyId = localStorage.getItem('idEmpresa');
              xlmnew.modelType = 'nfe';
              xlmnew.keyId = this.getKeyDFe(xmlModelo, this.nfeTags , 'NFe');
              xlmnew.xml = xml.toString();
              xmls.push(xlmnew);

              // } else if (xmlModelo.getElementsByTagName('CTeOS').length !== 0) {
              //   const xlmnew = new Xml();
              //   xlmnew.cpfCNPJMember = null;
              //   if(xmlModelo.getElementsByTagName('emit').item(0).getElementsByTagName("CPF").length === 0){
              //     xlmnew.issuer = xmlModelo.getElementsByTagName('emit').item(0).getElementsByTagName("CNPJ")[0].textContent;
              //   } else {
              //     xlmnew.issuer = xmlModelo.getElementsByTagName('emit').item(0).getElementsByTagName("CPF")[0].textContent;
              //   }
              //   if(xmlModelo.getElementsByTagName('toma').item(0).getElementsByTagName("CPF").length === 0){
              //     xlmnew.addressee = xmlModelo.getElementsByTagName('toma').item(0).getElementsByTagName("CNPJ")[0].textContent;
              //   } else {
              //     xlmnew.addressee = xmlModelo.getElementsByTagName('toma').item(0).getElementsByTagName("CPF")[0].textContent;
              //   }
              //   xlmnew.companyId = localStorage.getItem('idEmpresa');
              //   xlmnew.modelType = 'cte';
              //   xlmnew.xml = xml.toString();
              //   if (xmlModelo.getElementsByTagName('infCte')[0].getAttribute('Id') !== null &&
              //     xmlModelo.getElementsByTagName('infCte')[0].getAttribute('Id') !== undefined) {
              //     xlmnew.keyId = xmlModelo.getElementsByTagName('infCte')[0].getAttribute('Id')
              //       .replace('CTe', '');
              //   } else {
              //     xlmnew.keyId = xmlModelo.getElementsByTagName('infCTe')[0].getAttribute('Id')
              //       .replace('CTe', '');
              //   }
              //   // xlmnew.keyId = xmlModelo.getElementsByTagName('infCte')[0].getAttribute('Id').replace('CTe', '');
              //   // xlmnew.keyId = xmlModelo.getElementsByTagName('chCTe')[0].textContent;
              //   xmls.push(xlmnew);
            } else if (xmlModelo.getElementsByTagName('CTe').length !== 0) {
              //CTE
              const newCte = new Xml();
              this.getCteInfo(newCte, xmlModelo);
              newCte.keyId = this.getKeyDFe(xmlModelo, this.cteTags, 'CTe');
              newCte.cpfCNPJMember = null;
              newCte.companyId = localStorage.getItem('idEmpresa');
              newCte.modelType = 'cte';
              newCte.xml = xml.toString();
              xmls.push(newCte);

            } else if (xmlModelo.getElementsByTagName('NFSe').length !== 0){
              // NFSE - NOTA FISCAL DE SERVIÇO ELETRÔNICA
              const xlmnew = new Xml();
              xlmnew.companyId = localStorage.getItem('idEmpresa');
              xlmnew.modelType = 'nfse';
              let tag = {
                name: "infNFSe",
                type: "KEY",
                subTag: [
                  {
                    "name": "Id"
                  }
                ]
              }
              xlmnew.keyId = this.getKeyDFe(xmlModelo, [tag] , 'NFS');
              xlmnew.xml = xml.toString();
              xmls.push(xlmnew);
            }  
            else if (xmlModelo.getElementsByTagName('NF3e').length !== 0){
              // NF3E - NOTA FISCAL DE ENERGIA ELÉTRICA ELETRÔNICA
              const xlmnew = new Xml();
              xlmnew.companyId = localStorage.getItem('idEmpresa');
              xlmnew.modelType = 'nf3e';
              let tag = {
                name: "infNF3e",
                type: "KEY",
                subTag: [
                  {
                    "name": "Id"
                  }
                ]
              }
              xlmnew.keyId = this.getKeyDFe(xmlModelo, [tag] , 'NF3e');
              xlmnew.xml = xml.toString();
              xmls.push(xlmnew);
            }
            // else if ((xmlModelo.getElementsByTagName('MDFe').length !== 0)) {
            //   //MDFE
            //   const xlmnew = new Xml();
            //   xlmnew.cpfCNPJMember = null;
            //   xlmnew.companyId = localStorage.getItem('idEmpresa');
            //   xlmnew.modelType = 'mdfe';
            //   if (xmlModelo.getElementsByTagName('infMDFe')[0].getAttribute('Id') !== null &&
            //     xmlModelo.getElementsByTagName('infMDFe')[0].getAttribute('Id') !== undefined) {
            //     xlmnew.keyId = xmlModelo.getElementsByTagName('infMDFe')[0].getAttribute('Id')
            //       .replace('MDFe', '');
            //   } else {
            //     xlmnew.keyId = xmlModelo.getElementsByTagName('infMdfe')[0].getAttribute('Id')
            //       .replace('MDFe', '');
            //   }
            //   xlmnew.xml = xml.toString();
            //   xlmnew.xml = xml.toString();
            //   xmls.push(xlmnew);
            // }
            else {
              console.warn('Arquivo nulo/inválido');
              erro++;
              leghtEnvio = leghtEnvio - 1;
            }
            if (xmls.length === leghtEnvio) {
              this.dfeProvider.postDfes(xmls).then((data: DfeDTOResponse[]) => {
                data.forEach((dfe: DfeDTOResponse) => {

                  if (dfe == null) {
                    warning++;
                    contagem++;
                  } else {
                    sucesso++;
                    contagem++;
                  }
                });

                if (data.length !== xmls.length) {
                  let erros = 0;
                  erros = xmls.length - data.length;
                  warning = warning + erros;
                  contagem = contagem + erros;
                }

                if (contagem === xmls.length && button.disabled !== false) {
                  this.resultImportation(xmls, data, erro, this.members);
                  button.disabled = false;
                  accept(true);
                }
              }).catch((result: HttpErrorResponse) => {
                button.disabled = false;
                reject(result);
              });
            }
          };
          reader.readAsText(input.files[index]);
        }
      });
    });
  }

  resultImportation(xmlSended, dfeReceive, invalid, members) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(ModalImportacaoComponent, modalOptions);
    modalRef.componentInstance.xmlSended = xmlSended;
    modalRef.componentInstance.dfeReceive = dfeReceive;
    modalRef.componentInstance.invalid = invalid;
    modalRef.componentInstance.members = members;
  }

  getCteInfo(cteNew, cteXml) {
    cteNew.issuer = this.getCteIssuerOrAddresseInfo(cteXml, 'ISSUER');
    cteNew.addressee = this.getCteIssuerOrAddresseInfo(cteXml, 'ADDRESSEE');
    return cteNew;
  }

  getKeyDFe(xml, tags, replaceTag) {
    let dfeKey = '';
    tags.filter(tag => tag.type === 'KEY');
    tags.forEach(tag => {
      if(xml.getElementsByTagName(tag.name).length !== 0) {
        tag.subTag.forEach(subtag => {
          if (xml.getElementsByTagName(tag.name)[0].getAttribute(subtag.name) !== null &&
            xml.getElementsByTagName(tag.name)[0].getAttribute(subtag.name) !== undefined) {
              dfeKey = xml.getElementsByTagName(tag.name)[0].getAttribute(subtag.name).replace(replaceTag, '');
          }
        });
      }
    });
    return dfeKey;
  }

  getCteIssuerOrAddresseInfo(cteXml, type) {
    let infoCte = '';
    const tagsIssuer: any = this.cteTags.filter(tag => tag.type === type);
    //console.log(tagsIssuer);
    tagsIssuer.forEach(tag => {
      if (cteXml.getElementsByTagName(tag.name).length !== 0) {
        tag.subTag.forEach(subtag => {
          if (cteXml.getElementsByTagName(tag.name).item(0).getElementsByTagName(subtag.name).length !== 0) {
            infoCte = cteXml.getElementsByTagName(tag.name).item(0).getElementsByTagName(subtag.name)[0].textContent;
          }
       });
      }
      });
    return infoCte;
  }


}

