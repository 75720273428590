import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {ActivatedRoute} from "@angular/router";
import {Injectable} from "@angular/core";
import {Xml} from "../../model/Xml";
import {environment} from "../../environments/environment.prod";
import {DfeDTOResponse} from "../../model/dto/dfe/DfeDTOResponse";
import {DuplicatesDTOResponse} from "../../model/dto/dfe/DuplicatesDTOResponse";

@Injectable()
export class PlannedPaymentProvider {
  apiDfe: string = "/fiscal/v1/planned_payment/";

  constructor(public http: HttpClient, public oauthService: OAuthService, private activeRoute: ActivatedRoute) {
  }

  postPayment(duplicates: DuplicatesDTOResponse[]) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiDfe + 'in_batch', duplicates, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DuplicatesDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putPayment(duplicates: DuplicatesDTOResponse[]) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiDfe + 'in_batch', duplicates, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DuplicatesDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delPayment(id, idDFE) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiDfe + localStorage.getItem('idEmpresa') + '/' + idDFE + '/' + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DfeDTOResponse) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

}
