import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {STEPPER_GLOBAL_OPTIONS} from "@angular/cdk/stepper";
import {ConsultaDfe} from "../../../../model/ConsultaDfe";
import {SelectionModel} from "@angular/cdk/collections";
import {Duplicatas} from "../../../../model/Duplicatas";
import {MatTableDataSource} from "@angular/material/table";
import {BrMaskerIonicServices3} from "brmasker-ionic-3";
import * as moment from "moment";
import {PagamentoPlanejadoComponent} from "../cadastrar-lancamento-dup/pagamento-planejado/pagamento-planejado.component";
import {ReplaySubject, Subject} from "rxjs";
import {ImovelDTO} from "../../../../model/dto/ImovelDTO";
import {ContaBancariaDTO} from "../../../../model/dto/ContaBancariaDTO";
import {HistoricDTOResponse} from "../../../../model/dto/HistoricDTOResponse";
import {AccountProvider} from "../../../../providers/account/account";
import {PropertyProvider} from "../../../../providers/company/property/property";
import {ParticipantProvider} from "../../../../providers/participant/participant";
import {BankAccountProvider} from "../../../../providers/company/bank_account/bank_account";
import {DocumentProvider} from "../../../../providers/bookkeeping/document/document";
import {HistoricProvider} from "../../../../providers/bookkeeping/historic/historic";
import {ParticipantDTOResponse} from "../../../../model/dto/ParticipantDTOResponse";
import {takeUntil} from "rxjs/operators";
import {AccountPlanDTOResponse} from "../../../../model/dto/AccountPlanDTOResponse";
import {DataTrabalhoDialog} from "../data-trabalho-dialog/data-trabalho-dialog.component";
import {ValueValidator} from "../../../../util/customValidators/valueValidator";
import {LengthValidator} from "../cadastrar-lancamento-dup/cadastrar-lancamento-dup.component";
import {Snackbar} from "../../../../util/snackbar";
import {LivroCaixa} from "../../../../util/livroCaixa";
import {PostingProvider} from "../../../../providers/bookkeeping/posting/posting";
import {DateUtil} from "../../../../util/dateUtil";
import {MatDialog} from "@angular/material/dialog";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorUtil} from "../../../../util/error";
import { DateLockProvider } from 'src/providers/bookkeeping/date-lock/date-lock';
import Swal from 'sweetalert2'
import { DateLock } from 'src/model/dto/bookkeping/DateLock';
import { CheckPermission } from 'src/service/checkPermission';

@Component({
  selector: 'app-cadastrar-lancamento-dup-venda',
  templateUrl: './cadastrar-lancamento-dup-venda.component.html',
  styleUrls: ['./cadastrar-lancamento-dup-venda.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class CadastrarLancamentoDupVendaComponent implements OnInit {

  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public lancamentoForm: FormGroup;

  public imoveis: ImovelDTO[];
  public contas: ContaBancariaDTO[];
  public historicos: HistoricDTOResponse[];
  public participantes: ParticipantDTOResponse[];
  public categorias: any[];

  public bookkeepingDuplicateDate: boolean;
  public valorTotal: number;
  public descontoProporcionalAtivo: boolean = false;
  public valorProporcional: string;
  public saleDfe = false;
  public changeMade: boolean;
  public enviado: boolean;

  public displayedColumns: string[] = ['select', 'id', 'data', 'valor'];
  public selection = new SelectionModel<any>(true, []);

  public filter_property: ReplaySubject<ImovelDTO[]> = new ReplaySubject<ImovelDTO[]>(1);
  public filter_bankAccount: ReplaySubject<ContaBancariaDTO[]> = new ReplaySubject<ContaBancariaDTO[]>(1);
  public filter_planAccount: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public filter_historic: ReplaySubject<HistoricDTOResponse[]> = new ReplaySubject<HistoricDTOResponse[]>(1);

  public propertyFilterCtrl: FormControl = new FormControl();
  public accountPlanFilterCtrl: FormControl = new FormControl();
  public bankAccountFilterCtrl: FormControl = new FormControl();
  public historicFilterCtrl: FormControl = new FormControl();

  protected _onDestroy = new Subject<void>();

  public locks: DateLock[] = [];

  @Input() public documentosExistentes: ConsultaDfe[];
  @Input() public categoriasCache: AccountPlanDTOResponse[];
  @Input() public imoveisCache: ImovelDTO[];
  @Input() public contasCache: ContaBancariaDTO[];
  @Input() public historicosCache: HistoricDTOResponse[];
  @Input() public participantesCache: ParticipantDTOResponse[];

  constructor(private _formBuilder: FormBuilder,
              public activeModal: NgbActiveModal,
              private brMasker: BrMaskerIonicServices3,
              public modalService: NgbModal,
              private accountProvider: AccountProvider,
              private imovelProvider: PropertyProvider,
              private participantesProvider: ParticipantProvider,
              private contaBancariaProvider: BankAccountProvider,
              private documentProvider: DocumentProvider,
              private historicProvider: HistoricProvider,
              private postingProvider: PostingProvider,
              public fb: FormBuilder,
              public errorUtil: ErrorUtil,
              public dateUtil: DateUtil,
              public snackbar: Snackbar,
              public dialog: MatDialog,
              public livroCaixa: LivroCaixa,
              public dateLockProvider: DateLockProvider,
              public checkPermission: CheckPermission) {}

  ngOnInit() {
    this.initializeDataForm();
    this.configuracaoInicialForm();
    this.getDateLocks();
    this.firstFormGroup = this._formBuilder.group({
      duplicatas: [this.selection.selected, Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
      dataPagamento: [moment(this.dateUtil.removeTimeZone(localStorage.getItem('workDate'))).format("YYYY-MM-DD"), Validators.required],
      imovel: ['', Validators.compose([Validators.required])],
      valorTotal: ['0', Validators.compose([ValueValidator, Validators.required])],
      categoria: ['', Validators.compose([ValueValidator, Validators.required])],
      contabancaria: this.livroCaixa.getTipo() == 1 ?
                      [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : '', Validators.compose([Validators.required])] :
                      [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : ''],
      historico: ['DEFAULT'],
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeDataForm() {
    this.imoveis = this.imoveisCache;
    this.categorias = this.categoriasCache;
    this.contas = this.contasCache;
    this.historicos = this.sortHistorics(this.historicosCache);
    this.participantes = this.participantesCache;
    this.initializePropertySearch();
    this.initializeAccountPlanSearch();
    this.initializeBankAccountSearch();
    this.initializeHistoricSearch();

    this.selection.changed.subscribe(() => {
      this.firstFormGroup.controls.duplicatas.setValue(this.selection.selected);
    })
  }

  initializeAccountPlanSearch() {
    this.filter_planAccount.next(this.categorias.slice());
    this.accountPlanFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAccountPlan();
      });
  }

  initializePropertySearch() {
    this.filter_property.next(this.imoveis.slice());
    this.propertyFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProperties();
      });
  }

  initializeHistoricSearch() {
    this.filter_historic.next(this.historicos.slice());
    this.historicFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterHistoric();
      });
  }

  initializeBankAccountSearch() {
    this.filter_bankAccount.next(this.contas.slice());
    this.bankAccountFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBankAccount();
      });
  }

  initializeSearchSelect() {
    this.initializeAccountPlanSearch();
    this.initializePropertySearch();
    this.initializeHistoricSearch();
    this.initializeBankAccountSearch();
  }


  sortHistorics(historicos) {
    return historicos.sort((a, b) => {
      if(a.code > b.code) {
        return 1;
      } else if(a.code < b.code) {
        return -1;
      } else {
        return 0;
      }
    })
  }

  configuracaoInicialForm() {
    this.lancamentoForm = this.fb.group({
      dataPagamento: [moment(this.dateUtil.removeTimeZone(localStorage.getItem('workDate'))).format("YYYY-MM-DD"), Validators.required],
      imovel: ['', Validators.compose([Validators.required])],
      valorTotal: ['0', Validators.compose([ValueValidator, Validators.required])],
      categoria: ['', Validators.compose([ValueValidator, Validators.required])],
      contabancaria: this.livroCaixa.getTipo() == 1 ?
                      [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : '', Validators.compose([Validators.required])] :
                      [this.contas && this.contas.length > 0 ? this.contas.find(obj => obj.default) : ''],
      historico: ['DEFAULT'],
      lancamentos: [this.selection.selected, Validators.compose([LengthValidator])]
    });
    if (+this.livroCaixa.getTipo() == 1) {
      this.lancamentoForm.controls.contabancaria.setValidators(Validators.compose([Validators.required]));
    }
    moment.locale("pt-br");
    //console.log(this.lancamentoForm);
  }


  convertPropertyObjectSearchSelect(imoveis) {
    let newImovel;
    if (Array.isArray(imoveis)) {
      let newImovelArray: ImovelDTO[] = [];
      newImovel = newImovelArray;
      imoveis.forEach((imovel) => {
        let imovelMod: any = {
          currency: imovel.currency,
          explorationType: imovel.explorationType,
          itrCode: imovel.itrCode,
          name: imovel.propertyCode + ' - ' + imovel.name,
          propertyCode: imovel.propertyCode,
        }
        newImovel.push(imovelMod);
      });
    } else {
      imoveis.name = imoveis.propertyCode + ' - ' + imoveis.name
      newImovel = imoveis;
    }
    return newImovel;
  }

  convertBankAccountObjectSearchSelect(contas) {
    if (Array.isArray(contas)) {
      contas.forEach((contas) => {
        let name = contas.description;
        contas.description = contas.code + ' - ' + name;
      });
    } else {
      let name = contas.description;
      contas.description = contas.code + ' - ' + name;
    }
    return contas;
  }

  convertPlanAccountObjectSearchSelect(planAccounts) {
    let newPlanAccounts: AccountPlanDTOResponse[] = [];
    planAccounts.forEach((categoria) => {
      let categoriaMod: any = {
        id: categoria.id,
        customId: categoria.customId,
        description: categoria.customId + ' - ' + categoria.description,
        featureType: categoria.featureType,
        participateRuralActivity: categoria.participateRuralActivity,
        releaseType: categoria.releaseType
      }
      newPlanAccounts.push(categoriaMod);
    });
    return newPlanAccounts;
  }


  checkWorkDate(data: any, button) {
    let haveDateOut: boolean;
    let workDate = localStorage.getItem('workDate');
    let startMonth =  moment.tz(workDate, 'America/Sao_Paulo').startOf('month').subtract(1, 'day');
    let endMonth =  moment.tz(workDate, 'America/Sao_Paulo').endOf('month').add(1, 'day');
    if (moment.tz(moment.tz(data.value, 'America/Sao_Paulo').format("YYYY-MM-DD"), 'America/Sao_Paulo').isBetween((startMonth), (endMonth))) {
    } else {
      haveDateOut = true;
    }
    if(this.isLocked) {
      Swal.fire({
        title: 'Atenção!',
        text: 'Há lançamentos cadastrados dentro de um bloqueio de período. ' +
                (this.checkPermission.isAccountantAdmin() ?
                'Remova o bloqueio para poder cadastrar um lançamento nesta data.' :
                'Contate um administrador para que o bloqueio seja removido.'),
        icon: 'error'
      }).then((result) => {
        if(result.isConfirmed) {
          button.disabled = false;
        }
      });
    } else {
      if (haveDateOut && !this.bookkeepingDuplicateDate) {
        const dialogRef = this.dialog.open(DataTrabalhoDialog, {disableClose: true});
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result == undefined || result != 'cancelou') {
            this.postNewDocuments(button);
          } else {
            button.disabled = false;
          }
        });
      } else {
        this.postNewDocuments(button);
      }
    }
  }

  protected filterBankAccount() {
    if (!this.contas) {
      return;
    }
    // get the search keyword
    let search = this.bankAccountFilterCtrl.value;
    if (!search) {
      this.filter_bankAccount.next(this.contas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filter_bankAccount.next(
      this.contas.filter(bank => bank.description.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterAccountPlan() {
    if (!this.categorias) {
      return;
    }
    // get the search keyword
    let search = this.accountPlanFilterCtrl.value;
    if (!search) {
      this.filter_planAccount.next(this.categorias.slice());
    } else {
      // filter the planAccount
      search = search.toLowerCase();
      this.filter_planAccount.next(
        this.categorias.filter(value => value.description.toLowerCase().indexOf(search) > -1)
          .sort((a, b) => a.description.indexOf(search) - b.description.indexOf(search))
      );
    }
  }

  protected filterHistoric() {
    if (!this.historicos) {
      return;
    }
    // get the search keyword
    let search = this.historicFilterCtrl.value;
    if (!search) {
      this.filter_historic.next(this.historicos.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the properties
    this.filter_historic.next(
      this.historicos.filter(historic => historic.description.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterProperties() {
    if (!this.imoveis) {
      return;
    }
    // get the search keyword
    let search = this.propertyFilterCtrl.value;
    if (!search) {
      this.filter_property.next(this.imoveis.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the properties
    this.filter_property.next(
      this.imoveis.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  returnDataForTable(duplicatas: Duplicatas[]): MatTableDataSource<Duplicatas> {
    duplicatas.sort(function (a, b) {
      return (a.vencimento) < (b.vencimento) ? -1 : (a.vencimento) > (b.vencimento) ? 1 : 0;
    });
    let datasource = new MatTableDataSource(duplicatas);
    return datasource;
  }

  checkboxLabel(row?: ConsultaDfe): string {
    if (!row) {
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  returnDateFormated(data: string) {
    return moment(data).format('DD/M/YY');
  }

  editPaymentPlan(pagamentoPlanejado: Duplicatas[], dfeId) {
    pagamentoPlanejado.forEach((dup) => {
      if (!dup.escriturada) {
        this.selection.deselect(dup);
      }
    });
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(PagamentoPlanejadoComponent, modalOptions);
    modalRef.componentInstance.duplicatesPlanned = pagamentoPlanejado;
    modalRef.componentInstance.dfeId = dfeId;
    modalRef.result.then(() => {
      this.changeMade = true;
      this.calculaValorTotal();
    }, () => {
      this.changeMade = true;
      this.calculaValorTotal();
    });
  }

  calculaValorTotal() {
    if (this.selection.selected.length != 0) {
      this.descontoProporcionalAtivo = false;
      let valorTotal: number = 0;
      this.selection.selected.forEach((dfe: any) => {
        valorTotal = +dfe.valor + valorTotal;
      });
      this.lancamentoForm.controls.dataPagamento.setValue(moment(this.dateUtil.removeTimeZone(this.selection.selected[0].vencimento)));
      this.valorTotal = valorTotal;
      this.lancamentoForm.controls.valorTotal.setValue(valorTotal);
    } else {
      this.valorTotal = 0;
    }
  }

  returnValueFormated(valorTotal: string) {
    if (valorTotal != undefined) {
      return this.brMasker.writeValueMoney(valorTotal.toString(), {
        decimal: 2,
        decimalCaracter: ",",
        len: 0,
        mask: "",
        numberAndTousand: false,
        percent: false,
        person: false,
        phone: false,
        thousand: ".",
        type: undefined,
        userCaracters: false,
        money: true
      });
    }
  }

  postNewDocuments(button) {
    let docs = [];
    this.documentosExistentes.forEach((dfe) => {
      let lancamentos = [];
      let dupEscrituradaID = 0;

      //verefica se o documento possui duplicatas já escrituradas e novas duplicatas a serem inseridas
      this.selection.selected.forEach((dupSelect) => {
        dfe.pagamentoPlanejado.forEach((dup) => {
          if (dup.escriturada) {
            dupEscrituradaID = dup.id;
          }
          if (dup.id == dupSelect.id) {
            lancamentos.push(dupSelect);
          }
        });
      });

      //verifica se é nota fiscal de venda
      let pessoaID: any;
      if (this.saleDfe) {
        pessoaID = dfe.destinatario;
      } else {
        pessoaID = dfe.emitente;
      }

      let dfePost = {
        lancamentos: lancamentos,
        pessoaID: pessoaID,
        escrituracaoExistenteID: dupEscrituradaID,
        docID: 0,
        paymentID: 0,
        numeroDfe: dfe.numeroDoc
      };
      if (dfePost.lancamentos.length != 0) {
        docs.push(dfePost);
      }
    });
    this.postObject(docs, 0, docs.length, button);
  }

  postObject(doc, index, length, button) {
    index++;
    if (doc[0].escrituracaoExistenteID != 0) {
      this.postLancamentosExistingDocument(doc, index, length, button);
    } else {
      this.postLancamentosNewDocument(doc, index, length, button);
    }
  }

  postLancamentosNewDocument(doc, index, length, button) {
    this.postingProvider.postPostingBatch(this.setObjectToPost(doc[0])).then((any: any) => {
      this.snackbar.openLong('Documento ' + (index) + ' de ' + length + ' escriturado com sucesso!', 'success');
      if (doc.length == 1) {
        this.activeModal.close();
      } else {
        doc.splice(0, 1);
        this.postObject(doc, index, length, button);
      }
    }).catch((result: HttpErrorResponse) => {
      button.disabled = false;
      this.snackbar.openLong('Não foi possível inserir o documento. ' + (index) + '! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'erro');
    });

  }

  postLancamentosExistingDocument(doc, index, length, button) {
    this.postingProvider.getDocAndPaymentIDByDoc(doc[0].escrituracaoExistenteID).then((bookkeeping: any) => {
      doc[0].docID = bookkeeping.docId;
      doc[0].paymentID = bookkeeping.paymentId;
      this.postingProvider.postPosting(this.setObjectToPost(doc[0]).docs[0].postings).then((any: any) => {
        this.snackbar.openLong('Documento ' + (index) + ' de ' + length + ' escriturado com sucesso!', 'success');
        if (doc.length == 1) {
          this.activeModal.close();
        } else {
          doc.splice(0, 1);
          this.postObject(doc, index, length, button);
        }
      }).catch((result: HttpErrorResponse) => {
        button.disabled = false;
        console.error(result);
        this.snackbar.openLong('Não foi possível inserir o documento. ' + (index) + '! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'bookkeeping'), 'erro');
      });
    });
  }

  setObjectToPost(docSelect) {  //transforma o objeto para envio de Documento com lançamentos em lote
    // let formData = this.lancamentoForm.getRawValue();
    let payment = new Payment();
    let doc = new Doc();
    // payment.companyId = +localStorage.getItem('idEmpresa');
    // payment.proofDoc = null;
    payment.docs = [];
    // doc.companyId = payment.companyId;
    // doc.participantId = docSelect.pessoaID.id; //vem da dfe
    // doc.number = docSelect.numeroDfe;
    // doc.propertyCode = formData.imovel.propertyCode;
    // doc.docType = "INVOICE";
    // doc.postings = [];
    // docSelect.lancamentos.forEach((lancamento) => {
    //   doc.postings.push(this.convertLancamentosToPostAndPut(lancamento, doc.companyId, formData, docSelect));
    // });
    payment.docs.push(doc);
    return payment;
  }

  newOptionSelect(property: string) {

  }

  console() {
    //console.log(this.firstFormGroup);
    //console.log(this.selection.selected);
  }


  getDateLocks() {
    this.dateLockProvider.getDateLocks().then((value: DateLock[]) => {
      this.locks = value;
      // console.log(value);
    });
  }

  isLocked() {
    // console.log(this.lancamentoForm);

    if(this.bookkeepingDuplicateDate) {
      for(let lancamento of this.lancamentoForm.value.lancamentos) {
        for(let lock of this.locks) {
          if(moment(lancamento.vencimento).isBetween(moment(lock.startDate), moment(lock.endDate), undefined, '[]') && lock.lock) {
            return true;
          }
        }
      }
    } else {
      for(let lock of this.locks) {
        if(this.lancamentoForm.value.dataPagamento.isBetween(moment(lock.startDate), moment(lock.endDate), undefined, '[]') && lock.lock) {
          return true;
        }
      }
    }

    return false;
  }
}


class Payment {
  id: number;
  companyId: number;
  proofDoc: any;
  docs: Doc[];
}

abstract class DocAbstract {
  id: number;
  companyId: number;
  number: number;
  docType: string;
}

class DocEdit extends DocAbstract {
  property: ImovelDTO;
  participant: ParticipantDTOResponse;
}

class Doc extends DocAbstract {
  propertyCode: string;
  participantId: string;
  postings: Posting[];
  docTypeFormated: string;
}

class Posting {
  id: number;
  companyId: number;
  amountPaid: string;
  planPaymentId: number;
  historic: string;
  accountsChartId: number;
  paymentDate: string;
  postingReleaseType: string;
  groupersIds: number[];
  bankAccountId: number;
  isDigital: boolean;
  docId: number;
  paymentId: number;
}
