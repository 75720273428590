import {Injectable} from "@angular/core";
// @ts-ignore
import moment from "moment";
import {PessoaFiscalDTO} from "../../../../model/dto/PessoaFiscalDTO";
import {Bookkeeping} from "../../../../model/dto/bookkeping/Bookkeeping";
import {DocTypeNumberLCDPR} from "../../../../model/enum/DocType";
import {ReleaseType} from "../../../../model/enum/ReleaseType";
import {ImovelDTO} from "../../../../model/dto/ImovelDTO";
import {ExplorationTypeLCDPR, ExplorationTypePartnerLCDPR, ExplorationTypeProd} from "../../../../model/enum/ExplorationType";
import {ContaBancariaDTO} from "../../../../model/dto/ContaBancariaDTO";

@Injectable()
export class GenerateLCDPR {

  private static linhasGenerate: number;
  private static erros: any[] = [];

  constructor() {
  }

  private static generate(lcdprForm, properties, bookkeeping: Bookkeeping[], periodResume) {
    this.erros = [];
    return new Promise((accept, reject) => {
      //console.log("teste", lcdprForm, properties, bookkeeping, periodResume)
      this.linhasGenerate = 4;
      const dataInicial = moment(lcdprForm.periodo[0]).format('DDMMYYYY');
      const dataFinal = moment(lcdprForm.periodo[1]).format('DDMMYYYY');
      let lcdprGenerate = GenerateLCDPR.generateAberturaIdentificacaoPessoaFisica(lcdprForm, dataInicial, dataFinal)
        + GenerateLCDPR.generateParametrosTributacao(lcdprForm)
        + GenerateLCDPR.generateDadosCadastrais(lcdprForm.produtor)
        + GenerateLCDPR.generateImoveis(lcdprForm, properties)
        + GenerateLCDPR.generateContas(lcdprForm.contas)
        + GenerateLCDPR.generateLancamentos(bookkeeping)
        + GenerateLCDPR.generateDemonstrativoFiscal(periodResume)
        + GenerateLCDPR.generateInformacoesContadorOuProdutor(lcdprForm.contador, this.linhasGenerate);

      if (this.erros.length == 0) {
        accept(lcdprGenerate.trim());
      } else {
        reject(this.erros);
      }
    });
  }

  private static optionalInfoValidation(value, condition, lcdprSection, parameter, id) {
    switch (lcdprSection) {
      case '0030':
        if ((value === undefined || value === null || value === '') && condition) {
          this.pushError(lcdprSection, id, 'PRODUTOR - CPF ' + id + ' não possui propriedade ' + parameter, parameter);
        }
        break
      case '0040':
        if ((value === undefined || value === null || value === '') && condition) {
          this.pushError(lcdprSection, id, 'IMÓVEL ' + id + ' - Não possui propriedade ' + parameter, parameter);
        }
        break;
      case '0045':
        if((value === undefined || value === null || value === '') && condition) {
          this.pushError(lcdprSection, id.property.propertyCode, 'EXPLORADOR ' + id.id + ' - Não possui tipo de contraparte', parameter);
        }
        break;
      case '0050':
        if ((value === undefined || value === null || value === '') && condition) {
          this.pushError(lcdprSection, id, 'CONTA BANCÁRIA ' + id + ' - Não possui propriedade ' + parameter, parameter);
        }
        break;
      case 'Q100':
        if ((value === undefined || value === null || value === '') && condition) {
          this.pushError(lcdprSection, id, 'LANÇAMENTO ' + id + ' - Não possui propriedade ' + parameter, parameter);
        }
        break;
    }
    return value;
  }

  private static pushError(lcdprSection, id, message, parameter) {
    this.erros.push({
      section: lcdprSection,
      id: id,
      message: message,
      parameter: parameter
    });
  }

  private static generateAberturaIdentificacaoPessoaFisica(lcdprForm, dataInicial, dataFinal) {
    return '\r\n0000|LCDPR|0013'
      + '|' + lcdprForm.produtor.cpfCNPJ
      + '|' + lcdprForm.produtor.name
      + '|' + lcdprForm.indicadorInicioPeriodo
      + '|' + lcdprForm.indicadorSitucaoEspecial
      + '|' + checkNull(moment.tz(lcdprForm.dataSituacaoEspecial, 'America/Sao_Paulo').format('DDMMYYYY'))
      + '|' + dataInicial
      + '|' + dataFinal;
  }

  private static generateParametrosTributacao(lcdprForm) {
    return '\r\n0010'
      + '|' + lcdprForm.formaApuracao;
  }

  private static generateInformacoesContadorOuProdutor(contador, linhasQtd) {
    return '\r\n9999'
      + '|' + checkNull(contador.nome)
      + '|' + checkNull(contador.cpfCNPJ)
      + '|' + checkNull(contador.CRC)
      + '|' + checkNull(contador.email)
      + '|' + checkNull(this.removedigitos(contador.telefone, 15, false))
      + '|' + linhasQtd;
  }

  private static generateDadosCadastrais(produtor) {
    let section = '0030';
    return '\r\n'
      + section
      + '|' + GenerateLCDPR.optionalInfoValidation((produtor.addressDTORespComp.street), true, section, 'LOGRADOURO', produtor.cpfCNPJ)
      + '|' + GenerateLCDPR.optionalInfoValidation((produtor.addressDTORespComp.houseNumber), true, section, 'NÚMERO', produtor.cpfCNPJ)
      + '|' + checkNull(produtor.addressDTORespComp.complement)
      + '|' + GenerateLCDPR.optionalInfoValidation(produtor.addressDTORespComp.neighborhood, true, section, 'BAIRRO', produtor.cpfCNPJ)
      + '|' + GenerateLCDPR.optionalInfoValidation(produtor.addressDTORespComp.countryDTORespComp.stateDTORespComp.abbreviation, true, section, 'ESTADO', produtor.cpfCNPJ)
      + '|' + GenerateLCDPR.optionalInfoValidation(produtor.addressDTORespComp.countryDTORespComp.stateDTORespComp.cityDTORespComp.id, true, section, 'MUNICÍPIO', produtor.cpfCNPJ)
      + '|' + GenerateLCDPR.optionalInfoValidation(produtor.addressDTORespComp.cep, true, section, 'CEP', produtor.cpfCNPJ)
      + '|' + this.removedigitos(checkNull(produtor.addressDTORespComp.telephone), 15, false)
      + '|' + GenerateLCDPR.optionalInfoValidation(produtor.addressDTORespComp.email, true, section, 'EMAIL', produtor.cpfCNPJ);
  }

  private static generateDemonstrativoFiscal(periodResume) {
    let demonstrativoGenerate = '';
    periodResume.forEach((mes) => {
      GenerateLCDPR.linhasGenerate++;
      demonstrativoGenerate = demonstrativoGenerate
        + ''
        + '\r\nQ200'
        + '|' + mes.month
        + '|' + mes.incoming.toFixed(2).replace('.', '')
        + '|' + (Math.abs(mes.outlay).toFixed(2)).replace('.', '')
        + '|' + (Math.abs(mes.balance).toFixed(2)).replace('.', '')
        + '|' + mes.nature
    });
    return demonstrativoGenerate;
  }

  private static generateLancamentos(lancamentos: Bookkeeping[]) {
    let lancamentosGenerate = '';
    lancamentos.forEach(lancamento => {
      if (lancamento.doc.participant === null) {
        GenerateLCDPR.optionalInfoValidation(lancamento.doc.participant, true, 'Q100', 'PARTICIPANTE', lancamento.doc.id);
      } else {
        GenerateLCDPR.linhasGenerate++;
        const paymentDate = moment.tz(lancamento.paymentDate, 'America/Sao_Paulo').format('DDMMYYYY');
        lancamentosGenerate = lancamentosGenerate
          + ''
          + '\r\nQ100'
          + '|' + paymentDate
          + '|' + lancamento.doc.property.propertyCode
          + '|' + lancamento.bankAccount.code
          + '|' + checkNull(lancamento.doc.number)
          + '|' + DocTypeNumberLCDPR[lancamento.doc.docType]
          + '|' + lancamento.historic.normalize('NFD').replace(/([\u0300-\u036f])/g, '').replace(/|/g,'').replace(/–/g,' ').replace(/"/g,' ').replace(/'/g,' ').replace(/\n\r/g,' ').replace(/\N\r/g,' ').replace(/(\r\n|\n|\r)/gm, " ").replace(/\\N/g,' ').replace(/\\n/g,' ')
          + '|' + lancamento.doc.participant.cpfCNPJParticipant
          + '|' + ReleaseType[lancamento.postingReleaseType]
          + '|' + GenerateLCDPR.returnValorFormatoLCDPR(lancamento.amountPaid, lancamento.account, 'in')  //entrada
          + '|' + GenerateLCDPR.returnValorFormatoLCDPR(lancamento.amountPaid, lancamento.account, 'out')   //saida
          + '|' + (Math.abs(lancamento.balance).toFixed(2)).replace('.', '')  //saldo
          + '|' + GenerateLCDPR.returnNaturezaSaldo(lancamento.balance) //valor positivo(P)/negativo(N)
      }
    });

    return lancamentosGenerate;
  }

  private static generateImoveis(lcdprForm, properties) {
    let imovelGenerate = '';
    let section = '0040';
    lcdprForm.imoveis.forEach((property) => {
      GenerateLCDPR.linhasGenerate++;
      const imovel: ImovelDTO = properties.find(imovel => imovel.propertyCode === property.imovel.propertyCode);
      GenerateLCDPR.imovelValidator(imovel, property, section);
      imovelGenerate = imovelGenerate
        + ''
        + '\r\n'
        + section
        + '|' + GenerateLCDPR.removedigitos(imovel.propertyCode, 3, false)
        + '|' + checkNull(imovel.address.countryDTORespComp.abbreviation)
        + '|' + checkNull(imovel.currency)
        + '|' + GenerateLCDPR.removedigitos(imovel.itrCode, 8, false)
        + '|' + GenerateLCDPR.removedigitos(property.exploradores.find(obj => obj.member.cpfCNPJ === lcdprForm.produtor.cpfCNPJ).caepf, 14, false)
        + '|' + checkNull(GenerateLCDPR.removedigitos(property.exploradores.find(obj => obj.member.cpfCNPJ === lcdprForm.produtor.cpfCNPJ).ie, 14, false))
        + '|' + checkNull(imovel.name)
        + '|' + checkNull(imovel.address.street)
        + '|' + checkNull(imovel.address.houseNumber)
        + '|' + checkNull(imovel.address.complement)
        + '|' + checkNull(imovel.address.neighborhood)
        + '|' + imovel.address.countryDTORespComp.stateDTORespComp.abbreviation
        + '|' + imovel.address.countryDTORespComp.stateDTORespComp.cityDTORespComp.id
        + '|' + imovel.address.cep
        + '|' + ExplorationTypeLCDPR[property.exploracao]
        + '|' + GenerateLCDPR.removedigitos(property.porcentagem, 5, true)
        + this.generateTerceiro(lcdprForm, imovel.propertyCode);
    });
    return imovelGenerate;
  }

  private static imovelValidator(imovel, exploracaoImovel, section) {
    let conditionBrazilCountry = imovel.address.countryDTORespComp.abbreviation === 'BR';
    let property = imovel.propertyCode;
    GenerateLCDPR.optionalInfoValidation(imovel.address.countryDTORespComp.abbreviation, true, section, 'PAÍS', property);
    GenerateLCDPR.optionalInfoValidation(imovel.currency, true, section, 'MOEDA', property);
    // GenerateLCDPR.optionalInfoValidation(imovel.itrCode, conditionBrazilCountry, section, 'CÓDIGO ITR', property);
    GenerateLCDPR.optionalInfoValidation(exploracaoImovel.exploradores.find(obj => obj.caepf !== null), conditionBrazilCountry, section, 'CAEPF', property);
    GenerateLCDPR.optionalInfoValidation(ExplorationTypeLCDPR[exploracaoImovel.exploracao], true, section, 'TIPO DE EXPLORAÇÃO', property);
    GenerateLCDPR.optionalInfoValidation(exploracaoImovel.porcentagem, true, section, '% DE EXPLORAÇÃO', property);
    GenerateLCDPR.optionalInfoValidation(imovel.address.street, true, section, 'RUA', property);
    GenerateLCDPR.optionalInfoValidation(imovel.address.neighborhood, true, section, 'BAIRRO', property);
    GenerateLCDPR.optionalInfoValidation(imovel.address.countryDTORespComp.stateDTORespComp.abbreviation, conditionBrazilCountry, section, 'ESTADO', property);
    GenerateLCDPR.optionalInfoValidation(imovel.address.cep, conditionBrazilCountry, section, 'CEP', property);
    GenerateLCDPR.optionalInfoValidation(imovel.address.countryDTORespComp.stateDTORespComp.cityDTORespComp.id, conditionBrazilCountry, section, 'MUNICÍPIO', property)
  }

  private static generateTerceiro(lcdprForm, propertyCode) {
    const imovel = lcdprForm.imoveis.find(property => property.imovel.propertyCode === propertyCode);
    if (imovel.exploradores != undefined) {
      let terceiroGenerate = '';
      imovel.exploradores.forEach((explorador) => {
        let terceiro: PessoaFiscalDTO = explorador;
        terceiro.name = explorador.member.name;
        terceiro.cpfCNPJ = explorador.member.cpfCNPJ;
        if(explorador.property.explorationType != "INDIVIDUAL_EXPLORATION") {
          GenerateLCDPR.terceiroValidator(terceiro, '0045');
        }
        if (terceiro !== undefined && terceiro.cpfCNPJ !== lcdprForm.produtor.cpfCNPJ) {
          // console.log(terceiro);
          terceiroGenerate = terceiroGenerate
            + ''
            + '\r\n0045'
            + '|' + GenerateLCDPR.removedigitos(propertyCode, 3, false)
            + '|' + (ExplorationTypeProd[explorador.explorationTypeProd] + 1)
            + '|' + terceiro.cpfCNPJ
            + '|' + terceiro.name
            + '|' + GenerateLCDPR.removedigitos(explorador.percentage, 5, true);
          GenerateLCDPR.linhasGenerate++;
        }
      });
      return terceiroGenerate;
    } else {
      return '';
    }
  }

  private static terceiroValidator(terceiro, section) {
    GenerateLCDPR.optionalInfoValidation(terceiro.explorationTypeProd, true, section, 'TIPO DE CONTRAPARTE', terceiro);
  }

  private static generateContas(contas: any[]) {
    let contasGenerate = '';
    let section = '0050';
    // console.log(contas);
    contas = contas.filter(conta => !conta.numerarioEmTransito);
    // console.log(contas);
    contas.forEach((conta) => {
      const contas: ContaBancariaDTO = conta.conta;
      if (contas.code !== '000' && contas.code !== '999') {
        let codeProperty = contas.code;
        if (contas.bankDTORespComp == null) {
          GenerateLCDPR.optionalInfoValidation(null, true, section, 'BANCO', contas.code);
        } else {
          let conditionBrazilCountry = contas.countryDTORespComp.id === 32;
          GenerateLCDPR.linhasGenerate++;
          contasGenerate = contasGenerate
            + ''
            + '\r\n'
            + section
            + '|' + GenerateLCDPR.removedigitos(contas.code, 3, false)
            + '|' + GenerateLCDPR.optionalInfoValidation(contas.countryDTORespComp.abbreviation, true, section, 'PAÍS', codeProperty)
            + '|' + GenerateLCDPR.optionalInfoValidation(("000" + contas.bankDTORespComp.id).slice(-3), conditionBrazilCountry, section, 'BANCO', codeProperty)
            + '|' + GenerateLCDPR.optionalInfoValidation(contas.bankDTORespComp.name, conditionBrazilCountry, section, 'NOME DO BANCO', codeProperty)
            + '|' + GenerateLCDPR.optionalInfoValidation(GenerateLCDPR.removedigitos(contas.agencyWithoutDV, 4, false), true, section, 'AGÊNCIA', codeProperty)
            + '|' + GenerateLCDPR.optionalInfoValidation(GenerateLCDPR.removedigitos(contas.accountNumberWithDV, 16, false), true, section, 'NUM. CONTA', codeProperty);
        }
      }
    });
    return contasGenerate;
  }

  private static returnNaturezaSaldo(saldo: number) {
    if (saldo >= 0) {
      return 'P';
    } else {
      return 'N';
    }
  }

  private static returnValorFormatoLCDPR(amount, account, type) {
    if (account.featureType === 'REVENUE') {
      switch (type) {
        case 'out':
          return '000';
        case 'in':
          return amount.toFixed(2).replace('.', '');
      }
    } else {
      switch (type) {
        case 'out':
          return (amount * -1).toFixed(2).replace('.', '');
        case 'in':
          return '000';
      }
    }
  }

  private static removedigitos(valor: any, size: number, decimal: boolean) {
    if (valor == null) {
      valor = '';
    }
    if (valor != '') {
      if (decimal) {
        valor = (Math.round(+valor * 100) / 100).toFixed(2);
      }
      const valorRemover = valor.toString().replace('.', '').replace(/([^\d])+/gim, '');

      if (valorRemover.length < size) {
        return ('0000000000000000000' + valorRemover).slice(-size);
      } else {
        return valorRemover;
      }
    } else {
      return valor;
    }
  }

  generate(lcdprForm, properties, bookkeeping: Bookkeeping[], periodResume) {
    return new Promise((accept, reject) => {
      GenerateLCDPR.generate(lcdprForm, properties, bookkeeping, periodResume).then(result => {
        accept(result);
      }).catch(reason => {
        reject(reason);
      });
    });
  }

}

function checkNull(valor: any) {
  if (valor === undefined || valor === null || valor === 'Invalid date') {
    return '';
  } else {
    return valor;
  }
}
