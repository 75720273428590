// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  appVersion: require('../../package.json').version,
  // appVersion: '1.0.23',
  production: false,
  clientId: +localStorage.getItem('idEmpresa'),
  apiLogin: 'https://api.aplicativo.farmcont.com.br',    // MAIN
  // apiLogin: 'https://d2xbe52wftm65r.cloudfront.net',   // TEST
  // apiLogin: 'http://192.168.0.71:6060',   // TEST ENV - INTERNO
  // apiLogin: 'http://ambtestesexterno.ddns.net:6060',  // TEST ENV - EXTERNO
  // apiLogin: 'http://192.168.0.214:6060', // EDIMAR
   apiNfe: 'https://emissor.farmcont.com.br', //NFE
  //
  // -------------------------------------------------------------
  //
  // apiLogin: "http://localhost:6060",
  // apiLogin: "http://177.86.166.92:6060",
  // apiLogin: "http://192.168.0.164:6060",
  // requireHttps: true
   requireHttps: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
