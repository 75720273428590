import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CnpjValidator, CpfValidator} from "../../../../../util/customValidators/cpfCnpjValidator";
import {Snackbar} from "../../../../../util/snackbar";
import {ParticipantProvider} from "../../../../../providers/participant/participant";
import {ParticipantDTOResponse} from "../../../../../model/dto/ParticipantDTOResponse";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorUtil} from "../../../../../util/error";
import {CheckCNPJProvider} from "../../../../../providers/basicos/checkCNPJ";
import {AccountProvider} from 'src/providers/account/account';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-cadastro-pessoas',
  templateUrl: './cadastro-pessoas.component.html'
})
export class CadastroPessoasComponent implements OnInit {

  participante: ParticipantDTOResponse = new ParticipantDTOResponse();
  participantForm: FormGroup;
  editar: boolean = false;
  enviado: boolean = false;
  enviando: boolean = false;
  consultandoCNPJ: boolean = false;
  erroConsultaCNPJ: boolean = false;
  alreadyExists: boolean = false;
  planoDeContas = []
  public AccountFilterCtrl: FormControl = new FormControl;
  public filteredAccounts;
  protected _onDestroy = new Subject<void>();
  accountForm: FormGroup;

  @Input() public participanteExistente;
  @Input() public participantes;

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public snackBar: Snackbar,
              public checkCNPJProvider: CheckCNPJProvider,
              public erroUtil: ErrorUtil,
              public participantProvider: ParticipantProvider,
              public accountProvider: AccountProvider) {
  }

  ngOnInit() {
    this.configForm();
    this.checkExist();
    this.getAccounts();

    this.filteredAccounts=this.planoDeContas;
    this.AccountFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAccounts();
      });
  }

  configForm() {
    this.participantForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      cpfCNPJParticipant: ['', Validators.compose([Validators.required, CpfValidator, CnpjValidator])],
      ie: [''],
      companyId: [+localStorage.getItem('idEmpresa')],
      autoGenerated: [false],
      accountId: [],
    });

  }

  getAccounts() {
    this.accountProvider.getAllAccounts().then((planoDeContas: any) => {
      this.processAccountLeaf(planoDeContas.accountComponents)
    }).catch((result: HttpErrorResponse) => {
      console.error(result);
    });
  }
  processAccountLeaf(data, depth = 0) {
    data.forEach((item) => {
      if(item['@type'] != "Composite")
      this.planoDeContas.push(item);

      if (item.accountComponents && item.accountComponents.length > 0) {
        this.processAccountLeaf(item.accountComponents,depth + 1);
      }
    });
  }
  protected filterAccounts() {
    if (!this.planoDeContas) {
      return;
    }
    // get the search keyword
    let search = this.AccountFilterCtrl.value;
    if (!search) {
      this.filteredAccounts=this.planoDeContas;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the this.planoDeContas
    this.filteredAccounts=
      this.planoDeContas.filter(account => (account.description.toLowerCase().indexOf(search) > -1 ||account.customId.toLowerCase().indexOf(search) > -1))

  }

  checkCNPJ() {
    this.erroConsultaCNPJ = false;
    if (this.participantForm.controls.cpfCNPJParticipant.valid) {
      // console.log("cnpj", this.participantForm.controls.cpfCNPJParticipant)
      if (this.participantForm.value.cpfCNPJParticipant.length == 14 && this.participantForm.value.cpfCNPJParticipant != '') {
        this.consultandoCNPJ = true;
        this.checkCNPJProvider.getCNPJWS(this.participantForm.value.cpfCNPJParticipant).then(((result: any) => {
          //console.log(result);
          this.participantForm.controls.name.setValue(result.razao_social);
          this.consultandoCNPJ = false;
        })).catch((response: HttpErrorResponse) => {
          //console.log("erro")
          this.erroConsultaCNPJ = true;
          this.participantForm.controls.name.setValue('');
          this.consultandoCNPJ = false;
        })
        // this.checkCNPJProvider.getCNPJ(this.participantForm.value.cpfCNPJParticipant).then(((result: any) => {
        //   this.participantForm.controls.name.setValue(result.nome);
        //   this.consultandoCNPJ = false;
        // })).catch((response: HttpErrorResponse) => {
        //   this.erroConsultaCNPJ = true;
        //   this.participantForm.controls.name.setValue('');
        //   this.consultandoCNPJ = false;
        // });
      }
      if(this.participantForm.value.cpfCNPJParticipant.length == 14 || this.participantForm.value.cpfCNPJParticipant.length == 11){
        this.checkIfCnpjCpfAlreadyExist();
      }
    }
  }

  checkIfCnpjCpfAlreadyExist() {
    this.participantProvider.getParticipantsByCpfCnpj(this.participantForm.value.cpfCNPJParticipant).then((result: any) => {
      //console.log(result);
      this.alreadyExists = (result.length > 0);
    })
  }

  checkExist() {
    if (this.participanteExistente != undefined) {
      this.editar = true;
      this.participantForm.controls.id.setValue(this.participanteExistente.id);
      this.participantForm.controls.name.setValue(this.participanteExistente.name);
      this.participantForm.controls.cpfCNPJParticipant.setValue(this.participanteExistente.cpfCNPJParticipant);
      this.participantForm.controls.ie.setValue(this.participanteExistente.ie);
      this.participantForm.controls.autoGenerated.setValue(this.participanteExistente.autoGenerated);
      this.participantForm.controls.accountId.setValue(this.participanteExistente.accountId);
    }
  }

  novaPessoa() {
    this.enviado = true;
    this.enviando = true;
    if (this.participantForm.valid) {
      this.participantProvider.postParticipant(this.participantForm.value).then((participante: ParticipantDTOResponse) => {
        this.snackBar.openLong("Sucesso! " + participante.name + " inserido com sucesso!", 'success');
        this.activeModal.close(participante);
      }).catch((result: HttpErrorResponse) => {
        this.enviando = false;
        this.snackBar.openLong(this.erroUtil.checkErrorStatus(result, result.status, result.error, 'participant'), 'erro');
      });
    } else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

  editarPessoa() {
    this.enviado = true;
    this.enviando = true;
    if (this.participantForm.valid) {
      this.participantProvider.putParticipant(this.participantForm.getRawValue()).then((participante: ParticipantDTOResponse) => {
        this.snackBar.openLong("Sucesso! " + participante.name + " editado com sucesso!", 'success');
        this.activeModal.close(participante);
      }).catch((result: HttpErrorResponse) => {
        this.enviando = false;
        this.snackBar.openLong(this.erroUtil.checkErrorStatus(result, result.status, result.error, 'participant'), 'erro');
      });
    } else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }
}

