import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProductProvider } from 'src/providers/product/product';
import { ErrorUtil } from 'src/util/error';
import { Snackbar } from 'src/util/snackbar';

@Component({
  selector: 'app-deletar-produto',
  templateUrl: './deletar-produto.component.html',
  styleUrls: ['./deletar-produto.component.scss']
})
export class DeletarProdutoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,
    public productProvider: ProductProvider,
    public errorUtil: ErrorUtil,
    public snackbar: Snackbar,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(): void {
    this.productProvider.deleteProduct(this.data.id).then(() => {
      this.dialogRef.close(this.data);
      this.snackbar.openLong('Excluído com sucesso!', 'success');
    }).catch((reason: HttpErrorResponse) => {
      this.snackbar.openLong('Não foi possível excluir! ' + this.errorUtil.checkErrorStatus(reason, reason.status, reason.error, 'grouper'), 'erro');
    })
      ;
  }

}
