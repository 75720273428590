import {HttpClient, HttpHeaders} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {Injectable} from "@angular/core";
import {AccountComponent} from "../../model/dto/AccountComponent/AccountComponent";

@Injectable()
export class AccountProvider {

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  apiBase: string = "/main-records/v1/account/";
  apiPayroll: string = "/main-records/v1/payroll/";

  getAllAccounts() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: AccountComponent) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getAllAccountsLeafs() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + 'leafs', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getAllExpenseAccounts() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase + 'account-expenses', {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postBindedForeignAccounts(accounts){
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiPayroll + 'binding', accounts, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postAccount(node, account) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBase + node, account, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: AccountComponent) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putAccount(account) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase, account, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: AccountComponent) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delAccount(node) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiBase + node, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: AccountComponent) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getAccountById(id){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase + 'one/' + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getDominioBindedAccounts() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiPayroll + 'obligatory-binds/DOMINIO', { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

}
