import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MenuService} from '../core/menu/menu.service';
import {SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages/pages.module';
import {
  MatButtonModule,
  MatDatepickerModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTabsModule
} from '@angular/material';


import {menu} from './menu';
import {routes} from './routes';
import {TranslatorService} from "../core/translator/translator.service";
import {GridModule} from '@syncfusion/ej2-angular-grids';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatCardModule} from "@angular/material/card";
import {NgxCurrencyModule} from "ngx-currency";
import {MatChipsModule} from "@angular/material/chips";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDividerModule} from "@angular/material/divider";
import {AutomacoesComponent} from "./automacoes/automacoes.component";
import {TreeModule} from "angular-tree-component";
import {TreeGridModule} from "@syncfusion/ej2-angular-treegrid";
import {MatSelectInfiniteScrollModule} from "ng-mat-select-infinite-scroll";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";


@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes),
    PagesModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSelectModule,
    MatMenuModule,
    MatTabsModule,
    MatIconModule,
    GridModule,
    NgxSkeletonLoaderModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatCardModule,
    NgxCurrencyModule,
    MatChipsModule,
    MatTooltipModule,
    MatDividerModule,
    TreeModule,
    TreeGridModule,
    MatSelectInfiniteScrollModule,
    NgxMatSelectSearchModule,
  ],
  exports: [
    RouterModule
  ],
  declarations: [AutomacoesComponent],
})

export class RoutesModule {
  constructor(public menuService: MenuService, tr: TranslatorService) {
    menuService.addMenu(menu, 1);
  }

}
