import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class ExportUtil {

    public ExportSystemForeignExportations = [
        "ALTERDATA",
        "CONTIMATIC",
        "DOMÍNIO",
        "OUTRO"
    ];


    public ExportLayoutGridDummyData = [
        {
            PAYMENT_DATE: '30/10/1998',
            DOC_NUMBER: '012345',
            DOC_TYPE: '1',
            AMOUNT: '123,45',
            PARTICIPANT_CNPJ: 'CPF/CNPJ Participante',
            PARTICIPANT_NAME: 'Nome do Participante',
            ACCOUNT_CODE: '123456',
            ACCOUNT_NAME: 'Nome do Plano de Contas',
            BANK_ACCOUNT_CODE: '123456',
            BANK_ACCOUNT_NAME: 'Nome da Conta',
            PROPERTY_CODE: '123456',
            PROPERTY_NAME: 'Nome do Imóvel',
            HISTORIC: 'Histórico',
        }
    ]

    public ExportLayoutGridExport = {
        PAYMENT_DATE: true,
        DOC_NUMBER: true,
        DOC_TYPE: true,
        AMOUNT: true,
        ACCOUNT_CODE: true,
        ACCOUNT_NAME: true,
        BANK_ACCOUNT_CODE: true,
        BANK_ACCOUNT_NAME: true,
        PROPERTY_CODE: true,
        PROPERTY_NAME: true,
        HISTORIC: true,
        PARTICIPANT_CNPJ: true,
        PARTICIPANT_NAME: true
    }

    public ExportLayoutGridColumns = [
        { field: 'PAYMENT_DATE', headerText: 'Data', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'DOC_NUMBER', headerText: 'Nº Documento', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'DOC_TYPE', headerText: 'Tipo Documento', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'AMOUNT', headerText: 'Valor', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'ACCOUNT_CODE', headerText: 'Código Conta', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'ACCOUNT_NAME', headerText: 'Nome Conta', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'BANK_ACCOUNT_CODE', headerText: 'Código Conta Movimento', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'BANK_ACCOUNT_NAME', headerText: 'Nome Conta Movimento', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'PROPERTY_CODE', headerText: 'Código Imóvel', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'PROPERTY_NAME', headerText: 'Nome Imóvel', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'HISTORIC', headerText: 'Histórico', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'PARTICIPANT_CNPJ', headerText: 'CPF/CNPJ Participante', width: '200', format: 'C2', textAlign: 'Center' },
        { field: 'PARTICIPANT_NAME', headerText: 'Nome Participante', width: '200', format: 'C2', textAlign: 'Center' }
    ]

    public ExportLayoutDateFormats = [
        "dd/MM/YYYY",
        "MM/dd/YYYY",
        "YYYY/MM/dd",
        "dd-MM-YYYY",
        "MM-dd-YYYY",
        "YYYY-MM-dd",
        "dd.MM.YYYY",
        "MM.dd.YYYY",
        "YYYY.MM.dd",
    ];

    public ExportLayoutDefaultFormValue = {
        columnSeparator: "|",
        decimalSeparator: ".",
        characterDelimiter: "\"",
        dateFormat: "dd/MM/YYYY",
    }

}