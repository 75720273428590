import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UserProvider } from "src/providers/user/user";
import { Snackbar } from "src/util/snackbar";
import Swal from "sweetalert2";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  public passwordForm: FormGroup;
  @Input() public emailExistente;
  enviando: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    public userProvider: UserProvider,
    public snackBar: Snackbar
  ) {}

  ngOnInit() {
    this.configuracaoForms();
  }

  configuracaoForms() {
    this.passwordForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  resetPassword() {
    this.enviando = true;
    if (this.passwordForm.valid) {
      this.userProvider
        .postNewPassword(this.passwordForm.value)
        .then((result) => {
          this.enviando = false;
          Swal.fire({
            title: '<strong>Sucesso</strong>',
            icon: 'success',
            text: 'Um email foi encaminhado para a redefinição de senha',
            showConfirmButton: true,
          }).then((result) =>{
            this.activeModal.close();
          })
        })
        .catch((result) => {
          this.enviando = false;
          //console.log(result.error.error_description)
          if(result.error.error_description.includes("could not be found"))
            Swal.fire({
              title: '<strong>Erro</strong>',
              icon: 'warning',
              text: 'Email não existente na plataforma',
              showConfirmButton: true,
            }).then((result) =>{
            })
        });
    }
    else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }
}
