import {Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {DfeProvider} from "../../../../providers/dfe/dfe";
import {DonwloadLoteDfe} from "../../../../providers/dfe/donwloadLoteDfe";
import { Snackbar } from "src/util/snackbar";

@Injectable()
export class DonwloadLote {

  constructor(private dfeProvider: DfeProvider,
              private donwloadLoteDfe: DonwloadLoteDfe,
              private snackBar: Snackbar) {
  }

  getFileDfeLote(documentsSelected, type) {
    return new Promise((accept, reject) => {
      let models = this.generateModel(documentsSelected);
      this.dfeProvider.getXmlDfeById(this.generateIds(documentsSelected)).then((dfeXml: any) => {
        dfeXml.forEach((xml) => {
          let xmlAtob = atob(xml);
          let chave;
          let modelo;
          const xmlModelo = new DOMParser().parseFromString(xmlAtob.toString(), 'text/xml');
          if (xmlModelo.getElementsByTagName('NFe').length !== 0) {
            if (xmlModelo.getElementsByTagName('infNFe')[0].getAttribute('Id') !== null &&
              xmlModelo.getElementsByTagName('infNFe')[0].getAttribute('Id') !== undefined) {
              chave = xmlModelo.getElementsByTagName('infNFe')[0].getAttribute('Id')
                .replace('NFe', '');
              modelo = 'nfe';
            } else if (xmlModelo.getElementsByTagName('infNfe')[0].getAttribute('Id') !== null &&
              xmlModelo.getElementsByTagName('infNfe')[0].getAttribute('Id') !== undefined) {
              chave = xmlModelo.getElementsByTagName('infNfe')[0].getAttribute('Id')
                .replace('NFe', '');
              modelo = 'nfe';
            }
          } else if (xmlModelo.getElementsByTagName('CTe').length !== 0) {
            if (xmlModelo.getElementsByTagName('infCte')[0].getAttribute('Id') !== null &&
              xmlModelo.getElementsByTagName('infCte')[0].getAttribute('Id') !== undefined) {
              chave = xmlModelo.getElementsByTagName('infCte')[0].getAttribute('Id')
                .replace('CTe', '');
            } else if (xmlModelo.getElementsByTagName('infCTe')[0].getAttribute('Id') !== null &&
              xmlModelo.getElementsByTagName('infCTe')[0].getAttribute('Id') !== undefined) {
              chave = xmlModelo.getElementsByTagName('infCTe')[0].getAttribute('Id')
                .replace('CTe', '');
            }
          } else if ((xmlModelo.getElementsByTagName('MDFe').length !== 0)) {
            if (xmlModelo.getElementsByTagName('infMDFe')[0].getAttribute('Id') !== null &&
              xmlModelo.getElementsByTagName('infMDFe')[0].getAttribute('Id') !== undefined) {
              chave = xmlModelo.getElementsByTagName('infMDFe')[0].getAttribute('Id')
                .replace('MDFe', '');
            } else if (xmlModelo.getElementsByTagName('infMdfe')[0].getAttribute('Id') !== null &&
              xmlModelo.getElementsByTagName('infMdfe')[0].getAttribute('Id') !== undefined) {
              chave = xmlModelo.getElementsByTagName('infMdfe')[0].getAttribute('Id')
                .replace('MDFe', '');
            }
          } else if (xmlModelo.getElementsByTagName('NFSe').length !== 0){
            if (xmlModelo.getElementsByTagName('infNFSe')[0].getAttribute('Id') !== null &&
              xmlModelo.getElementsByTagName('infNFSe')[0].getAttribute('Id') !== undefined) {
              chave = xmlModelo.getElementsByTagName('infNFSe')[0].getAttribute('Id')
                .replace('NFS', '');
              modelo = 'nfse';
            } else if (xmlModelo.getElementsByTagName('infNfse')[0].getAttribute('Id') !== null &&
              xmlModelo.getElementsByTagName('infNfse')[0].getAttribute('Id') !== undefined) {
              chave = xmlModelo.getElementsByTagName('infNfse')[0].getAttribute('Id')
                .replace('NFS', '');
              modelo = 'nfse';
            }
          }
          console.log("models", models)
          console.log("chave" , chave);
          models.find(model => model.chave === chave).xml = xmlAtob;
        });
        this.getFilesFromProvider(models, type);
        accept(true);
      }).catch((result: HttpErrorResponse) => {
        console.error(result);
        reject(result);
      });
    });
  }

  generateIds(documentsSelected) {
    let documentsIds = [];
    let controller = false
    documentsSelected.forEach(dfe => {
        controller = false
     //console.log("DFE",dfe)
     if(!dfe.parcelaId){
   documentsIds.push(dfe.id);
    }else{
      //console.log(controller, 'controller 1')   
      documentsIds.forEach( id => {
        if(id == dfe.parcelaId){
            controller = true
        }
      })
      //console.log(controller, 'controller 2')   

      if(controller == false){
         documentsIds.push(dfe.parcelaId);

      }
}
    });
    return documentsIds;
  }

  generateModel(documentsSelected) {
    let documents = [];
    documentsSelected.forEach(dfe => {
      const document = {
        xml: '',
        modelo: dfe.modelo,
        chave: dfe.chaveId
      };
      documents.push(document);
    });
    return documents;
  }

    filterSelectedDocs(selectedDocs){
      selectedDocs.forEach((element, i) => {
        if(element.xml == ""){
          delete selectedDocs[i]
          selectedDocs.length = selectedDocs.length -1
        }
});
//console.log(selectedDocs)
    return selectedDocs
    }

  getFilesFromProvider(selectedDocs, type) {
    //console.log("docs",selectedDocs)
    switch (type) {
      case 'pdf':
        this.donwloadLoteDfe.gerarDownloadLote(this.filterSelectedDocs(selectedDocs)).then((url: string) => {
          window.open('https://apinfe.farmcont.com.br/' + url);
        });
        break;
      case 'xml':
        this.donwloadLoteDfe.gerarDonwloadLoteXml(this.filterSelectedDocs(selectedDocs)).then((url: string) => {
          window.open('https://apinfe.farmcont.com.br/' + url);
        });
        break;
    }
  }

}
