import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "src/environments/environment.prod";
import {OAuthService} from "angular-oauth2-oidc";

@Injectable()
export class BatchProvider {

  constructor(public http: HttpClient, public oAuthService: OAuthService) {
  }

  postContaMovimento(postingIds, bankAccountId) {
    let request = {
      "postingsIds": postingIds,
      "bankAccountId": bankAccountId
    }

    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + '/bookkeeping/v1/posting/in_batch/' + localStorage.getItem('idEmpresa'),
        request, {headers: {Authorization: this.oAuthService.authorizationHeader()}})
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao recuperar status do servidor', error);
          reject(error);
        });
    });
  }

  postContaLancamento(postingsIds, accountchartId, postingReleaseType) {
    let request = {
      "postingsIds": postingsIds,
      "accountChartId": accountchartId.id,
      "postingReleaseType": postingReleaseType
    }

    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + '/bookkeeping/v1/posting/in_batch/' + localStorage.getItem('idEmpresa'),
        request, {headers: {Authorization: this.oAuthService.authorizationHeader()}})
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao recuperar status do servidor', error);
          reject(error);
        });
    });

  }

  postImovel(idEscrituracoes, imovel) {
    //console.log(idEscrituracoes);
    //console.log(imovel);

    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + '/bookkeeping/v1/doc/' + localStorage.getItem('idEmpresa') + '/' + imovel,
        idEscrituracoes, {headers: {Authorization: this.oAuthService.authorizationHeader()}})
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao recuperar status do servidor', error);
          reject(error);
        });
    });
  }

  postParticipant(idEscrituracoes, participantId) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}/bookkeeping/v1/doc/update-participant-in-batch/${localStorage.getItem('idEmpresa')}/${participantId}`,
        idEscrituracoes, {headers: {Authorization: this.oAuthService.authorizationHeader()}})
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao recuperar status do servidor', error);
          reject(error);
        });
    });
  }

  postPaymentDate(postingsIds, date) {
    console.log(date)
    console.log(postingsIds)
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}/bookkeeping/v1/posting/update-payment-date-in-batch/${localStorage.getItem('idEmpresa')}/${date}`,
        postingsIds, {headers: {Authorization: this.oAuthService.authorizationHeader()}})
        .subscribe((result) => {
          resolve(result);
        }, (error) => {
          console.error('Erro ao recuperar status do servidor', error);
          reject(error);
        });
    });
  }
}
