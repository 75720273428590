import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {historicoUtil} from "src/util/historico"

@Injectable()
export class PostingProvider {

  apiBase: string = "/bookkeeping/v1/posting/";
  api: string = "/bookkeeping/v1/in_batch/";
  apiNew: string = "/bookkeeping/v1/monthly-consolidation/";
  apiPayroll: string = "/bookkeeping/v1/payroll/";
  apiExport: string = "/bookkeeping/v1/exports/posting/";
  apiTaxes: string = "/bookkeeping/v1/tax-simulator/";

  constructor(public http: HttpClient, public oauthService: OAuthService, private historicoUtil: historicoUtil) {
  }

  getAllPosting(before, after) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase + 'by-company/' + localStorage.getItem('idEmpresa') + '/' + before  + '/' + after, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
          //console.log("teste",result)

           this.historicoUtil.checkHistoricOrObject(result)

          //console.log("teste",result)

            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
  getFilteredPostings(before, after,values){
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiBase +'ledger-reports/' +localStorage.getItem('idEmpresa')+ '/' + before  + '/' + after, values ,{headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            this.historicoUtil.checkHistoricOrObject(result)
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
  getAllPostingExtraFilters(before, after, filterString) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase + localStorage.getItem('idEmpresa') + '/' + before  + '/' + after+'/' + filterString, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            this.historicoUtil.checkHistoricOrObject(result)
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getAllPostingNew(before, filtros) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiNew + localStorage.getItem('idEmpresa') + '/' + before , filtros,{headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getPostingSimplificado(before, filtros) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiNew+ 'simplified-report/' + localStorage.getItem('idEmpresa') + '/' + before , filtros,{headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getLast30Entries() {
    const requestString = `${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/last`;
    return new Promise((resolve, reject) => {
      this.http.get(requestString, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
          this.historicoUtil.checkHistoricOrObject(result)
          resolve(result);
        }, (error) => {
          reject(error);
        });
    });
  }

  getBalance(date) { //yyyy-mm-dd
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase + 'balance/' + localStorage.getItem('idEmpresa') + '/' + date, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getDocAndPaymentIDByDoc(docId){
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + 'payment/' + docId, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getAllByDoc(docId) {
    //console.log("DOCID",docId)
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiBase + localStorage.getItem('idEmpresa') + '/' + docId, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            this.historicoUtil.checkHistoricOrObject(result)
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postPosting(Posting) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiBase + 'in_batch', Posting, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postPostingBatch(Posting) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.api, Posting, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postImportedResumePostingBatch(Posting){
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiPayroll + 'resume/in-batch/' + localStorage.getItem('idEmpresa'), Posting, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putPosting(Posting) {
    return new Promise((resolve, reject) => {

      this.http.put(environment.apiLogin + this.apiBase, Posting, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  delPosting(id) {
    return new Promise((resolve, reject) => {

      this.http.delete(environment.apiLogin + this.apiBase + localStorage.getItem('idEmpresa') + '/' + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postImportedFile(document: File, source) {
    const formData = new FormData();
    formData.append('file', document);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiPayroll + 'dominio/import-data/' +localStorage.getItem('idEmpresa') + '/' + source, formData, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any[]) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  deleteNotPostedImportedFile(fileName){
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiPayroll + 'delete-file/' + localStorage.getItem('idEmpresa') + '/' + fileName, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postImportedFileToPosting(file, type){
    const formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + '/main-records/v1/receipt/upload-file/' + localStorage.getItem('idEmpresa') + '/' + type, formData, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            if(error.status == 422){
              reject(error.error);
            }else{
              reject(error);
            }
          });
    });

  }

  importPayroll(lancamentos, importModel){
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiPayroll + 'in-batch/' + localStorage.getItem('idEmpresa') + '/' + importModel, lancamentos, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  exportPostings(filters, startDate, endDate) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiExport + localStorage.getItem('idEmpresa') + '/' + startDate + '/' + endDate, filters, { responseType: 'text', headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  postTaxSimulation(simulation) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiTaxes + localStorage.getItem('idEmpresa'), simulation, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any) => {
          resolve(result);
        },
          (error) => {
            reject(error);
          });
    });
  }

  saveTaxSimulation(simulation) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiTaxes}${localStorage.getItem('idEmpresa')}/save`, simulation, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getPaged(page: number) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiTaxes}${localStorage.getItem('idEmpresa')}/paged/${page}`, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  deleteSimulation(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiTaxes + localStorage.getItem('idEmpresa') + "/" + id, { headers: { Authorization: this.oauthService.authorizationHeader() } })
        .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
}
