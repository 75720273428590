import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {Bank} from "../../../model/basicos/Bank";
import {Imovel} from "../../../model/Imovel";

@Injectable()
export class PropertyProvider {

  apiProperty: string = "/main-records/v1/property/";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAllProperty() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiProperty}by_company/${localStorage.getItem('idEmpresa')}`,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getAllNonActiveProperty() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiProperty}by_company/${localStorage.getItem('idEmpresa')}/inactive`,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getPropertyById(idAccount) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiProperty + "1/" + idAccount,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Imovel[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

 postCloneProperty(oldCode, newCode) {
    let cloned_property = {
      companyId: localStorage.getItem('idEmpresa'),
      propertyCode: oldCode,
      newPropertyCode: newCode,
    }
    //console.log("endpoint de clonagem");
    //console.log(this.apiProperty + "clone-property", cloned_property);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiProperty + "clone-property", cloned_property,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Imovel[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
  postProperty(account: Imovel) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiProperty, account,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Imovel[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putProperty(account: Imovel) {
    //console.log(account);
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiProperty, account,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Bank[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  changeIsClient(isClient,cpfCnpj){
    let teste = {
      teste: 0
    }
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + `/main-records/v1/member/companyId/${localStorage.getItem('idEmpresa')}/cpfCnpj/${cpfCnpj}/isClient/${isClient}`,teste,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  deleteProperty(idAccount: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiProperty+ localStorage.getItem('idEmpresa') + "/" + idAccount,
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Imovel[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  toggleStatusProperty(propertyCode: any) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiProperty}${localStorage.getItem('idEmpresa')}/change-status/${propertyCode}`, "",
        {headers:{Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: Imovel[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

}
