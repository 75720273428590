import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImovelDTO } from "src/model/dto/ImovelDTO";
import { PropertyProvider } from "src/providers/company/property/property";
import { Snackbar } from "src/util/snackbar";
import { ErrorUtil } from "src/util/error";

@Component({
  selector: "app-duplicar-imovel",
  templateUrl: "./duplicar-imovel.component.html",
  styleUrls: ["./duplicar-imovel.component.scss"],
})
export class DuplicarImovelComponent implements OnInit {
  @ViewChild("trigger", { static: false }) trigger: MatAutocompleteTrigger;
  constructor(
    private formBuilder: FormBuilder,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public snackbar: Snackbar,
    public erroUtil: ErrorUtil,
    private propertyProvider: PropertyProvider
  ) {}
  public formGroup: FormGroup;
  public enviando: Boolean = false;
  public enviado: Boolean = false;
  public imovelExistente;
  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      codigo: ["", Validators.required],
    });
    console.log(this.imovelExistente);
  }

  duplicar() {
    this.enviando = true;
    if (this.formGroup.valid) {
      this.propertyProvider
        .postCloneProperty(
          this.imovelExistente.propertyCode,
          this.formGroup.controls.codigo.value
        )
        .then((imovelPost) => {
          this.snackbar.openLong(
            "Sucesso! Imóvel duplicado com sucesso!",
            "success"
          );
          this.activeModal.close(imovelPost);
        })
        .catch((error: HttpErrorResponse) => {
          this.enviando = false;
          this.snackbar.openLong(
            this.erroUtil.checkErrorStatus(
              error,
              error.status,
              error.error,
              "member"
            ),
            "erro"
          );
        });
    } else {
      this.enviando = false;
      this.snackbar.openLong(
        "Há erros no formulário. Confira antes de tentar enviar novamente!",
        "erro"
      );
    }
  }
}
