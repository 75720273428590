import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DateLock} from 'src/model/dto/bookkeping/DateLock';
import {DateLockProvider} from 'src/providers/bookkeeping/date-lock/date-lock';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Snackbar} from "src/util/snackbar";
// @ts-ignore
import moment from 'moment'
import Swal from 'sweetalert2';

@Component({
    selector: 'app-date-lock',
    templateUrl: './date-lock.component.html',
    styleUrls: ['./date-lock.component.scss']
  })

export class DateLockComponent {
  constructor(public activeModal: NgbActiveModal,
              public dateLockProvider: DateLockProvider,
              private modalService: NgbModal,
              public fb: FormBuilder,
              private snackbar: Snackbar) {}

  public locks: DateLock[] = [];
  public locksForm: FormGroup;

  public lockForm: FormGroup;

  public loadingGet: boolean = false;
  public loadingPost: boolean = false;
  public loadingPut: boolean = false;
  public putLoading: number;

  public enviado: boolean = false;

  ngOnInit() {
    this.startLockForm();
    this.getDateLocks();
  }

  startLocksForm() {
    this.locksForm = this.fb.group({
      dateLocks: this.fb.array([])
    })
  }

  get dateLocks() {
    return this.locksForm.controls["dateLocks"] as FormArray;
  }

  startLockForm() {
    this.enviado = false;
    this.lockForm = this.fb.group({
      companyId: [+localStorage.getItem('idEmpresa'), Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      // userName: [JSON.parse(localStorage.getItem('id_token_claims_obj')).name, Validators.required],
      lock: [false, Validators.required]
    });
  }

  async getDateLocks() {
    this.loadingGet = true;

    this.startLocksForm();

    await this.dateLockProvider.getDateLocks().then((value: DateLock[]) => {
      this.locks = value;
      console.log(value);
    })

    this.locks.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));

    for(let lock of this.locks) {
      const createdLock = this.fb.group({
        companyId: [lock.companyId, Validators.required],
        startDate: [{disabled: true, value: lock.startDate}, Validators.required],
        endDate: [{disabled: true, value: lock.endDate}, Validators.required],
        userName: [{disabled: true, value: lock.userName},],
        lock: [lock.lock, Validators.required],
        createdAt: [{disabled: true, value: lock.createdAt},],
        id: [lock.id]
      });

      this.dateLocks.push(createdLock);
    }

    this.loadingGet = false;
  }

  async lockUnlock(index: number) {
    this.loadingPut = true;
    this.putLoading = index;

    let lock: DateLock = {
      id: this.dateLocks.controls[index].value.id,
      lock: this.dateLocks.controls[index].value.lock,
      companyId: this.dateLocks.controls[index].value.companyId
    }

    await this.dateLockProvider.putDateLock(lock).then((result) => {
      this.snackbar.openLong('Bloqueio de período atualizado com sucesso! ', 'success');
    }).catch((error) => {
      this.snackbar.openLong('Erro ao atualizar bloqueio de período! ' + error, 'erro');
    }).then(() => { this.loadingPut = false; this.putLoading = undefined; });
  }

  async lockAll(value: boolean) {
    if(this.locks.length > 0) {
      await this.dateLockProvider.putAllDateLock(value).then((result) => {
        this.snackbar.openLong('Bloqueios de período atualizados com sucesso! ', 'success');
      }).catch((error) => {
        this.snackbar.openLong('Erro ao atualizar bloqueios de período! ' + error, 'erro');
      }).then(() => {
        this.getDateLocks();
        this.startLockForm();
      });
    } else {
      this.snackbar.openLong('Nenhum bloqueio de período a ser atualizado.', 'erro');
    }
  }

  async postLock() {
    this.enviado = true;
    this.loadingPost = true;
    console.log(this.lockForm.value.startDate);

    let lock: DateLock = {
      companyId: this.lockForm.value.companyId,
      // userName: this.lockForm.value.userName,
      lock: this.lockForm.value.lock,
      startDate: moment(this.lockForm.value.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.lockForm.value.endDate).format('YYYY-MM-DD'),
    }

    if(this.lockForm.valid && this.checkOrder()) {
      await this.dateLockProvider.postDateLock(lock).then((result) => {
        this.snackbar.openLong('Bloqueio de período enviado com sucesso! ', 'success');
      }).catch((error) => {
        this.snackbar.openLong('Erro ao enviar bloqueio de período! ' + error, 'erro');
      }).then(() => {
        this.getDateLocks();
        this.startLockForm();
        this.loadingPost = false;
      });
    } else {
      this.snackbar.openLong('Há erros no formulário. Preencha corretamente os campos e tente novamente.', 'erro');
      this.loadingPost = false;
    }
  }

  checkOrder() {
    return moment(this.lockForm.value.endDate).isSameOrAfter(moment(this.lockForm.value.startDate));
  }

  async deleteLock(index: number) {
    this.dateLockProvider.deleteDateLock(this.dateLocks.controls[index].value.id).then((result) => {
      this.snackbar.openLong('Bloqueio de período removido com sucesso! ', 'success');
    }).catch((error) => {
      this.snackbar.openLong('Erro ao deletar bloqueio de período! ' + error, 'erro');
    }).then(() => {
      this.getDateLocks();
      this.startLockForm();
    });
  }

  async deleteAllLock() {
    Swal.fire({
      title: 'Atenção!',
      text: 'Todos os bloqueios de período serão deletados. Deseja continuar?',
      icon: 'error',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonColor: '#f05050',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then(result => {
      if(result.isConfirmed) {
        this.dateLockProvider.deleteAllDateLock().then((result) => {
          this.snackbar.openLong('Bloqueios de período removidos com sucesso! ', 'success');
        }).catch((error) => {
          this.snackbar.openLong('Erro ao deletar bloqueios de período! ' + error, 'erro');
        }).then(() => {
          this.getDateLocks();
          this.startLockForm();
        });
      }
    });
  }

  open(content) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      ariaLabelledBy: 'modal-basic-title',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    this.modalService.open(content, modalOptions).result.then((result) => {
    //   this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // close(){ this.modalService.dismissAll(); }
}
