import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {PropertyProvider} from "../../../../../providers/company/property/property";
import {ProductProvider} from "../../../../../providers/product/product";
import {StockProvider} from "../../../../../providers/stock/stock";
import {DateUtil} from "../../../../../util/dateUtil";
import {ParticipantProvider} from "../../../../../providers/participant/participant";
import {BelongingsProvider} from "../../../../../providers/belongings/belongings";
import Swal from "sweetalert2";
import {HttpErrorResponse} from "@angular/common/http";
import * as moment from "moment";

@Component({
  selector: 'app-cadastro-bens',
  templateUrl: './cadastro-bens.component.html',
  styleUrls: ['./cadastro-bens.component.scss']
})
export class CadastroBensComponent implements OnInit {

  public description: FormControl;
  public code: FormControl;
  public years: FormGroup[] = [];

  public carregando: boolean = false;
  public enviando: boolean = false;
  public enviado: boolean = false;
  public edit: boolean = false;
  public exist: any;
  public allYears: any[] = [];

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public snackBar: Snackbar,
              public erroUtil: ErrorUtil,
              public propertyProvider: PropertyProvider,
              public productProvider: ProductProvider,
              public modalService: NgbModal,
              public stockProvider: StockProvider,
              public dateUtil: DateUtil,
              public participantProvider: ParticipantProvider,
              public provider: BelongingsProvider,) {
  }

  ngOnInit() {
    this.initializeFormConfiguration();
    if (this.exist) {
      this.setLancamentoExistenteForm();
    }
  }

  initializeFormConfiguration() {
    this.allYears = getYears(+moment().format('YYYY') - 20);
    this.allYears.sort((a, b) => b - a);
    this.description = new FormControl('', Validators.required);
    this.code = new FormControl('', Validators.required);
    this.addYear();
    this.years[0].get('year').setValue(+moment().format('YYYY'));
  }

  setLancamentoExistenteForm() {
    if (!this.exist) return;

    this.description.setValue(this.exist.description);
    this.code.setValue(this.exist.code);
    this.years = this.exist.years.map((year) => {
      return new FormGroup({
        year: new FormControl(year.year, Validators.required),
        value: new FormControl(year.value, Validators.required)
      });
    });
  }

  addYear() {
    this.years.push(new FormGroup({
      year: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required)
    }));
  }

  removeYear(i: number) {
    this.years.splice(i, 1);
  }

  hasDuplicateYears(): boolean {
    const years = this.years.map(group => group.get('year').value);
    return new Set(years).size !== years.length;
  }

  submitForm() {
    this.enviando = true;
    this.enviado = true;
    if (this.description.valid && this.code.valid && this.years.every(year => year.valid)) {
      if (this.hasDuplicateYears()) {
        this.enviando = false;
        this.snackBar.open('Não é permitido anos duplicados!', 'error');
        return;
      }

      const obj = {
        id: this.edit ? this.exist.id : null,
        companyId: localStorage.getItem('idEmpresa'),
        description: this.description.value,
        code: this.code.value,
        years: this.years.map(year => ({
          year: year.value.year,
          value: year.value.value
        }))
      };
      obj.years = obj.years.sort((a, b) => b.year - a.year);

      (this.edit ? this.provider.put(obj) : this.provider.post(obj)).then(result => {
        this.enviando = false;
        Swal.fire({
          title: 'Sucesso!',
          text: `Bem ${this.edit ? 'editado' : 'cadastrado'} com sucesso.`,
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => this.activeModal.close(result));
      }).catch((result: HttpErrorResponse) => {
        this.enviando = false;
        this.snackBar.open(`Não foi possível ${this.edit ? 'editar' : 'cadastrar'} o bem!`, 'error');
      });
    } else {
      this.enviando = false;
      this.snackBar.openLong('Preencha todos os campos obrigatórios', 'erro');
    }
  }

}

function getYears(startYear) {
  const currentYear = new Date().getFullYear() + 1;
  // tslint:disable-next-line: prefer-const
  let years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}
