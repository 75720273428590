import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProvider } from 'src/providers/user/user';
import { Snackbar } from 'src/util/snackbar';

@Component({
  selector: 'app-adicionar-imagem',
  templateUrl: './adicionar-imagem.component.html',
  styleUrls: ['./adicionar-imagem.component.scss']
})
export class AdicionarImagemComponent implements OnInit {

  userPicture: any = null;
  imageSrc: any = null;

  loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public userProvider: UserProvider,
    public snackBar: Snackbar
  ) { }

  ngOnInit() {
  }

  addImage() {
    this.loading = true;
    this.userProvider.postImageOffice(this.userPicture).then((result) => {
      this.userProvider.getImageOffice().then((value: Blob) => {
        const file = value;
        const fr = new FileReader();
        fr.readAsDataURL(file);
        fr.addEventListener('load', () => {
          const url = fr.result
          this.loading = false;
          this.snackBar.openLong('Imagem adicionada com sucesso!', 'success');
          localStorage.setItem('userPicture', url as string);
          this.activeModal.close(result);
        })
      });

    }).catch((error) => {
      this.loading = false;
      this.snackBar.openLong('Erro ao adicionar imagem', 'erro');
    });
  }

  delImage() {
    this.loading = true;
    this.userProvider.deleteImageOffice().then((result) => {
        this.loading = false;
        this.snackBar.openLong('Imagem removida com sucesso!', 'success');
        localStorage.setItem('userPicture', 'assets/img/user/02.png');
        this.activeModal.close(result);
    }).catch((error) => {
      this.loading = false;
      this.snackBar.openLong('Erro ao remover imagem', 'erro');
    });
  }

  removeImage() {
    this.userPicture = null;
  }

  cancel() {
    this.activeModal.close('Cancelou');
  }

  insertFile() {
    try {
      document.getElementById('files').click();
    } catch (exception) {
      console.error('Erro ao inserir arquivo!', exception);
    }
  }

  fileChange($event) {
    //console.log("fileChange ~ event:", $event)
    try {
      const file = $event.target.files[0];
      this.userPicture = file;
      const reader = new FileReader();
      if ($event.target.files && $event.target.files.length) {
        const file = $event.target.files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        };
      }
    } catch (exception) {
      console.error('Erro ao inserir arquivo!', exception);
    }
  }

}
