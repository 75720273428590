import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import { DateLock } from "src/model/dto/bookkeping/DateLock";

@Injectable()
export class DateLockProvider {
  apiBase: string = "/bookkeeping/v1/posting/date-lock";

  constructor(public http: HttpClient, 
              public oAuthService: OAuthService) {}

  getDateLocks() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiBase + '/' + localStorage.getItem('idEmpresa'),
                    {
                      headers: 
                      {Authorization: this.oAuthService.authorizationHeader()}
                    })
          .subscribe((result: DateLock[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postDateLock(dateLock: DateLock) {
    console.log(dateLock);
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiBase,
                     dateLock,
                     {headers: 
                       {Authorization: this.oAuthService.authorizationHeader()}
                     })
          .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putDateLock(dateLock: DateLock) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiBase,
                    dateLock,
                    {headers: 
                      {Authorization: this.oAuthService.authorizationHeader()}
                    })
          .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  putAllDateLock(value: boolean) {
    let dateLock = {
      companyId: +localStorage.getItem('idEmpresa'),
      lock: value
    }

    return new Promise((resolve, reject) => {
      this.http.put(environment.apiLogin + this.apiBase + '/in_batch',
                    dateLock,
                    {headers: 
                      {Authorization: this.oAuthService.authorizationHeader()}
                    })
          .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  deleteDateLock(id: number) {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiBase + '/' + localStorage.getItem('idEmpresa') + '/' + id,
                    {headers: 
                      {Authorization: this.oAuthService.authorizationHeader()}
                    })
          .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  deleteAllDateLock() {
    return new Promise((resolve, reject) => {
      this.http.delete(environment.apiLogin + this.apiBase + '/' + localStorage.getItem('idEmpresa') + '/in_batch',
                    {headers: 
                      {Authorization: this.oAuthService.authorizationHeader()}
                    })
          .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
}