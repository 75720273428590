import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Snackbar} from '../../../../../util/snackbar';
import {ErrorUtil} from '../../../../../util/error';
import {UserProvider} from '../../../../../providers/user/user';
import {Pessoa} from '../../../../../model/Pessoa';
import {HttpErrorResponse} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {DialogPasswordConfirmationComponent} from "../../../pages/dialog-password-confirmation/dialog-password-confirmation.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-cadastrar-colaborador',
  templateUrl: './cadastrar-colaborador.component.html',
  styleUrls: ['./cadastrar-colaborador.component.scss']
})
export class CadastrarColaboradorComponent implements OnInit {

  colaboradorForm: FormGroup;
  enviado: boolean = false;
  editar: boolean = true;
  @Input() public clienteExistente;
  enviando: boolean = false;
  adminColaborator: boolean = false;
  public userTypes = ['Contador', 'Consultor'];
  public selectedUserType: string = "Contador";
  public idOffice: number;
  public consultantOffice = false;

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public snackBar: Snackbar,
              public oauthService: OAuthService,
              public errorUtil: ErrorUtil,
              public dialog: MatDialog,
              public userProvider: UserProvider) {
  }

  ngOnInit() {
    const tokenObj: any = JSON.parse(localStorage.getItem('id_token_claims_obj'));
    this.idOffice = tokenObj.details.office.id;
    this.configuracaoForms();
    this.checkOperation();
    this.consultantOffice = (this.idOffice === 1 || this.idOffice === 2 || this.idOffice === 502 || this.idOffice === 805);
  }

  checkOperation() {
    if (this.clienteExistente != undefined) {
      this.editar = true;
      this.colaboradorForm.addControl('id', new FormControl('', Validators.required));
      this.colaboradorForm.patchValue(this.clienteExistente);
      if (this.clienteExistente.roles[0] == 'ROLE_ACCOUNTANT_ADMIN') {
        this.adminColaborator = true;
      }
      this.selectedUserType = this.clienteExistente.roles[0].includes("CONSULTANT") ? "Consultor" : "Contador";
     // console.log(this.selectedUserType);
    } else {
      this.editar = false;
      this.colaboradorForm.addControl('password', new FormControl('123456', [Validators.compose([Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d\\w\\W]{8,}$')])]));
    }
  }

  configuracaoForms() {
    this.colaboradorForm = this.fb.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      notBefore: [null],
      notAfter: [null],
      office: [],
      companyIds: [],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      roles: [],
    });
  }

  setObjectEdit(cliente: Pessoa) {
    this.clienteExistente.id = cliente.id;
    this.clienteExistente.name = cliente.name;
    this.clienteExistente.lastName = cliente.lastName;
    this.clienteExistente.email = cliente.email;
    this.clienteExistente.roles = cliente.roles;
  }

  novoColaborador() {
    if (this.selectedUserType !== "Consultor" && !this.editar) {
      this.colaboradorForm.controls.password.setValue("123456aA");
    }
    this.enviado = true;
    this.enviando = true;
    if (this.consultantOffice) {
      if (this.selectedUserType === 'Consultor') {
        this.colaboradorForm.controls.roles.setValue(['ROLE_CONSULTANT']);
      } else if (this.adminColaborator && this.selectedUserType === 'Contador') {
        this.colaboradorForm.controls.roles.setValue(['ROLE_ACCOUNTANT_ADMIN']);
      } else if (!this.adminColaborator && this.selectedUserType === 'Contador') {
        this.colaboradorForm.controls.roles.setValue(['ROLE_ACCOUNTANT_COLLABORATOR']);
      } else {
        this.snackBar.openLong('Um tipo de usuário deve ser selecionado', 'erro');
        this.enviado = false;
        this.enviando = false;
      }

      if (this.selectedUserType && (this.selectedUserType === 'Consultor' || this.selectedUserType === 'Contador')) {
        if (this.colaboradorForm.valid) {
          if (!this.editar) {
            this.userProvider.postCollaborator(this.colaboradorForm.value).then((cliente: Pessoa) => {
              this.snackBar.openLong('Sucesso! ' + cliente.name + ' inserido com sucesso!', 'success');
              this.activeModal.close(cliente);
            }).catch((result: HttpErrorResponse) => {
              this.enviando = false
              this.snackBar.openLong(
                'Não foi possível inserir! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error,
                  'colaborator'),
                'erro');
            });
          } else {
            this.userProvider.putCollaborator(this.colaboradorForm.getRawValue()).then((cliente: Pessoa) => {
              this.snackBar.openLong('Sucesso! ' + cliente.name + ' editado com sucesso!', 'success');
              this.setObjectEdit(cliente);
              this.activeModal.close(cliente);
            }).catch((result: HttpErrorResponse) => {
              console.error(result);
              this.enviando = false;
              this.snackBar.openLong(
                'Não foi possível editar! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error,
                  'colaborator'),
                'erro');
            });
          }
        } else {
          this.enviando = false;
          this.snackBar.openLong('Há erros no formulário. Confira antes de tentar enviar novamente!', 'erro');
        }
      }

      // se não for o super ou da SIA
    } else {
      if (this.adminColaborator) {
        this.colaboradorForm.controls.roles.setValue(['ROLE_ACCOUNTANT_ADMIN']);
      } else if (!this.adminColaborator) {
        this.colaboradorForm.controls.roles.setValue(['ROLE_ACCOUNTANT_COLLABORATOR']);
      } else {
        this.enviado = false;
        this.enviando = false;
      }

      if (this.colaboradorForm.valid) {
        if (!this.editar) {
          this.userProvider.postCollaborator(this.colaboradorForm.value).then((cliente: Pessoa) => {
            this.snackBar.openLong('Sucesso! ' + cliente.name + ' inserido com sucesso!', 'success');
            this.activeModal.close(cliente);
          }).catch((result: HttpErrorResponse) => {
            this.enviando = false;
            // tslint:disable-next-line: max-line-length
            this.snackBar.openLong('Não foi possível inserir! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'colaborator'), 'erro');
          });
        } else {
          this.userProvider.putCollaborator(this.colaboradorForm.getRawValue()).then((cliente: Pessoa) => {
            this.snackBar.openLong('Sucesso! ' + cliente.name + ' editado com sucesso!', 'success');
            this.setObjectEdit(cliente);
            this.activeModal.close(cliente);
          }).catch((result: HttpErrorResponse) => {
            console.error(result);
            this.enviando = false;
            // tslint:disable-next-line: max-line-length
            this.snackBar.openLong('Não foi possível editar! ' + this.errorUtil.checkErrorStatus(result, result.status, result.error, 'colaborator'), 'erro');
          });
        }
      } else {
        this.enviando = false;
        this.snackBar.openLong('Há erros no formulário. Confira antes de tentar enviar novamente!', 'erro');
      }
    }
  }

  changeUserType(userType: string) {
    this.selectedUserType = userType;
    if (!this.editar && this.selectedUserType !== "Consultor") {
      this.colaboradorForm.controls.password.setValue("123456aA");
    } else {
      if (!this.editar)
        this.colaboradorForm.controls.password.setValue(null);
    }
  }

  regeneratePassword() {
    const dialogRef = this.dialog.open(DialogPasswordConfirmationComponent, {
      width: '350px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userProvider.putUserRegenaretePassword({email: this.clienteExistente.email}).then(value => {
          this.snackBar.openLong("Nova senha gerada com sucesso! " +
            "Em instantes o colaborador receberá o e-mail contendo sua nova senha.", "success");
        }).catch(reason => {
          this.snackBar.openLong("Não foi possível gerar uma nova senha para o colaborador.", "erro");
        })
      }
    });
  }
}

