import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { Ng2TableModule } from 'ng2-table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatRadioModule } from '@angular/material/radio';
import { AuthConsultor } from 'src/service/auth-consultor';
import { RelatoriosModule } from '../relatorios/relatorios.module';
import { EscrituracaoComponent } from './escrituracao/escrituracao.component';
import { EnviarArquivosComponent } from './enviar-arquivos/enviar-arquivos.component';
import { GridModule, GridAllModule } from '@syncfusion/ej2-angular-grids';
import { MatDatepickerModule, MatAutocompleteModule, MatListModule, MatToolbarModule } from '@angular/material';
import { AdicionarArquivosComponent } from './enviar-arquivos/adicionar-arquivos/adicionar-arquivos.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgxCurrencyModule } from 'ngx-currency';
import { TextMaskModule } from 'angular2-text-mask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PipesModule } from 'src/util/pipes/pipes.module';
import { MonitorFiscalComponent } from './monitor-fiscal/monitor-fiscal.component';
import { AdicionarImagemComponent } from './enviar-arquivos/adicionar-imagem/adicionar-imagem.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [AuthConsultor],
    children: [
      {path: '', redirectTo: 'relatorios', pathMatch: 'full'},
      {path: 'relatorios', loadChildren: () => import('../relatorios/relatorios.module').then(m => m.RelatoriosModule)},
      // {path: 'monitor-fiscal', loadChildren: () => import('../consultor/monitor-fiscal/documentosFiscais.module').then(m => m.DocumentosFiscaisConsultorModule)}
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    MatNativeDateModule,
    MatInputModule,
    Ng2TableModule,
    NgxDatatableModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatMenuModule,
    MatIconModule,
    NgxDaterangepickerMd,
    MatButtonModule,
    MatSortModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    NgxSkeletonLoaderModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    MatRadioModule,
    MatDatepickerModule,
    GridModule,
    MatAutocompleteModule,
    DatePickerModule,
    MatInputModule,
    MatListModule,
    NgxCurrencyModule,
    TextMaskModule,
    GridAllModule,
    DragDropModule,
    MatToolbarModule,
    PipesModule,
  ],
  exports: [
    RouterModule,
    RelatoriosModule,
  ],
  declarations: [
    EnviarArquivosComponent,
    EscrituracaoComponent,
    AdicionarArquivosComponent,
    AdicionarImagemComponent,
    MonitorFiscalComponent
  ],
})
export class ConsultorModule {
}


