import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ErrorUtil} from "../../../../util/error";
import {DocumentProvider} from "../../../../providers/bookkeeping/document/document";
import {Snackbar} from "../../../../util/snackbar";
import moment from "moment";

/**
 * @title Dialog elements
 */
@Component({
  selector: 'app-data-trabalho-dialog',
  templateUrl: './data-trabalho-dialog.component.html',
  styleUrls: ['./data-trabalho-dialog.component.scss']
})

export class DataTrabalhoDialog {

  constructor(public dialogRef: MatDialogRef<DataTrabalhoDialog>) { }

  returnWorkPeriod(){
    return moment(localStorage.getItem('workDate')).format('MM/YYYY');
  }

  onNoClick() {
    this.dialogRef.close("cancelou");
  }

  onYesClick() {
    this.dialogRef.close();
  }
}

