import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentProvider} from 'src/providers/bookkeeping/document/document';
import {Snackbar} from 'src/util/snackbar';

@Component({
  selector: 'app-enviar-csv',
  templateUrl: './enviar-csv.component.html',
  styleUrls: ['./enviar-csv.component.scss']
})
export class EnviarCsvComponent implements OnInit {

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public snackBar: Snackbar,
    public documentProvider: DocumentProvider
  ) {
  }

  enviando: boolean = false
  file: any;

  ngOnInit() {
  }

  newFile() {
    let file: File;
    const fileInput = (<HTMLInputElement>document.getElementById('swal-file'));
    file = fileInput.files[0];
    this.file = file
  }

  inserir() {
    console.log("AAAAAAAAAAAA")
    this.enviando = true;
    this.documentProvider.postCsvEscrituracao(this.file).then((result: any) => {
      if (result) {
        this.snackBar.openLong('CSV enviado com sucesso! ' + result.postedCount + ' linha(s) adicionada(s).', 'success');
        this.enviando = false
        this.activeModal.close()
      }
    }).catch((result) => {
      //console.log(result)
      this.snackBar.openLong('Ocorreu algum erro ao enviar a planilha: ' + result.error.error_description, 'error');
      this.enviando = false
    });
  }

}
