import {AccountComponent} from "./AccountComponent";
import {type} from "os";


export class AccountComposite extends AccountComponent{
  accountComponents: AccountComposite[];
  // private _name_ = "Composite";


}
