import {Component, Injectable, OnInit, ViewChild} from '@angular/core';
import {TreeGridComponent} from '@syncfusion/ej2-angular-treegrid';
import {HttpErrorResponse} from '@angular/common/http';
import {ReleaseType} from '../../../../model/enum/ReleaseType';
import {Nodes} from './plano-de-contas.component';
import {AccountProvider} from '../../../../providers/account/account';
import {PdfQueryCellInfoEventArgs} from '@syncfusion/ej2-grids';

@Component({

  selector: 'app-export-plano-de-contas',
  template: `
    <ejs-treegrid #tree allowTextWrap='true' allowExcelExport='true' allowPdfExport='true'
                  [dataSource]='nodes' [treeColumnIndex]='1' childMapping='children'>
      <e-columns>
        <e-column field='id' [visible]='false' headerText='ID' textAlign='Left' width=60></e-column>
        <e-column field='name' headerText='Conta' textAlign='Left' width=220></e-column>
        <e-column field='customId' headerText='Código reduzido' textAlign='Center' width=100></e-column>
        <e-column field='releaseType' headerText='Movimentação' textAlign='Center' width=100></e-column>
      </e-columns>
    </ejs-treegrid>`
})

@Injectable()
export class ExportPlanoDeContas implements OnInit {

  @ViewChild('tree', {static: false}) public treeGridObj: TreeGridComponent;

  public nodes: any;
  public rootID: number;
  public customIds: string[] = [];
  public descriptions: string[] = [];

  constructor(public accountProvider: AccountProvider) {
    this.initicializeData();
  }

  ngOnInit(): void {

  }

  initicializeData() {
    this.accountProvider.getAllAccounts().then((planoDeContas: any) => {
      this.customIds = [];
      this.descriptions = [];
      this.rootID = planoDeContas.id;
      this.nodes = (this.accountToTree(planoDeContas));
    }).catch((result: HttpErrorResponse) => {
      console.error(result);
    });
  }

  accountToTree(accountComponent) {
    if (accountComponent.description === 'root') {
      return (this.printToTree(accountComponent.accountComponents));
    }
  }

  printToTree(accountComponent): any {
    const nodes = [];
    accountComponent.forEach((account: any) => {
      this.customIds.push(account.customId);
      this.descriptions.push(account.description);
      const node = new Nodes();
      node.name = account.description;
      node.id = account.id;
      node.customId = account.customId;
      node.children = [];
      node.featureType = account.featureType;
      node.participateRuralActivity = account.participateRuralActivity;
      node.releaseType = ReleaseType[account.releaseType];
      if (account.accountComponents !== undefined || account.accountComponents != null) {
        node.children = (this.printToTree(account.accountComponents));
      }
      nodes.push(node);
    });
    return nodes;
  }

}
