import { Pipe, PipeTransform } from '@angular/core';
import 'moment-timezone';
import {ImovelDTO} from "../../model/dto/ImovelDTO";
import {AccountPlanDTOResponse} from "../../model/dto/AccountPlanDTOResponse";
import {ParticipantDTOResponse} from "../../model/dto/ParticipantDTOResponse";
import { PessoaFiscalDTO } from 'src/model/dto/PessoaFiscalDTO';

@Pipe({
  name: 'convertBy',
})
export class ConvertObjectPipe implements PipeTransform {
  transform(value, type) {
    switch (type) {
      case 'imoveis':
        return this.convertPropertyObjectSearchSelect(value);
      case 'contasBancarias':
        return this.convertBankAccountObjectSearchSelect(value);
      case 'participantes':
        return this.convertParticipantObjectSearchSelect(value);
      case 'membros':
        return this.convertMemberObjectSearchSelect(value);
      case 'categoria':
        return this.convertPlanAccountObjectSearchSelect(value);
    }
  }

  convertMemberObjectSearchSelect(membros) {
    if (Array.isArray(membros)) {
      membros.forEach((membro: PessoaFiscalDTO) => {
        const name = membro.name;
        membro.name = name + ' - ' + membro.cpfCNPJ;
      });
    } else {
      const name = membros.name;
      membros.name = name + ' - ' + membros.cpfCNPJ;
    }
    return membros;
  }

  convertPropertyObjectSearchSelect(imoveis) {
    let newImovel;
    if (Array.isArray(imoveis)) {
      let newImovelArray: ImovelDTO[] = [];
      newImovel = newImovelArray;
      imoveis.forEach((imovel) => {
        let imovelMod: any = {
          currency: imovel.currency,
          explorationType: imovel.explorationType,
          itrCode: imovel.itrCode,
          default: imovel.default,
          name: imovel.propertyCode + ' - ' + imovel.name,
          propertyCode: imovel.propertyCode,
        }
        newImovel.push(imovelMod);
      });
    } else {
      imoveis.name = imoveis.propertyCode + ' - ' + imoveis.name
      newImovel = imoveis;
    }
    return newImovel;
  }

  convertParticipantObjectSearchSelect(participantes) {
    if (Array.isArray(participantes)) {
      participantes.forEach((participante: ParticipantDTOResponse) => {
        const name = participante.name;
        participante.name = name + ' - ' + participante.cpfCNPJParticipant;
      });
    } else {
      const name = participantes.name;
      participantes.name = name + ' - ' + participantes.cpfCNPJParticipant;
    }
    return participantes;
  }

  convertBankAccountObjectSearchSelect(contas) {
    if (Array.isArray(contas)) {
      contas.forEach((contas) => {
        let name = contas.description;
        contas.description = contas.code + ' - ' + name;
      });
    } else {
      let name = contas.description;
      contas.description = contas.code + ' - ' + name;
    }
    return contas;
  }

  convertPlanAccountObjectSearchSelect(planAccounts) {
    let newPlanAccounts: AccountPlanDTOResponse[] = [];
    planAccounts.forEach((categoria) => {
      let categoriaMod: any = {
        id: categoria.id,
        customId: categoria.customId,
        description: categoria.customId + ' - ' + categoria.description,
        featureType: categoria.featureType,
        participateRuralActivity: categoria.participateRuralActivity,
        releaseType: categoria.releaseType,
        historicId: categoria.historicId,
      }
      newPlanAccounts.push(categoriaMod);
    });
    return newPlanAccounts.sort((a, b) => a.customId - b.customId);
  }

}
