import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Snackbar} from "../../util/snackbar";
import {environment} from "../../environments/environment";
import { OAuthService } from "angular-oauth2-oidc";
import { log } from "console";

export class PdfLote{
  zip: string;
}

@Injectable()
export class DonwloadLoteDfe {

  constructor(
    public http: HttpClient,
    public snackbar: Snackbar,
    public oauthService: OAuthService
  ) {
  }

  public apiNfe = "/fiscal/v1/generator/zip/"

  gerarDownloadLote(documents) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
      this.http.post('https://apinfe.farmcont.com.br/viewpdfloteapp.php', documents)
        .subscribe((result: PdfLote) => {
            resolve(result.zip);
        },
          (error) => {
            reject(error);
            this.snackbar.open(' Erro ao gerar o download em lote!', 'erro');
            console.error(error);
          });

    });
  }


  gerarDonwloadLoteXml(documents) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
      this.http.post('https://apinfe.farmcont.com.br/viewxmlloteapp.php', documents)
        .subscribe((result: PdfLote) => {
            resolve(result.zip);
          },
          (error) => {
            reject(error);
            this.snackbar.open(' Erro ao gerar o download em lote!', 'erro');
            console.error(error);
          });

    });
  }

  downloadLoteDfes(ids, type) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiNfe + type + '/' + localStorage.getItem("idEmpresa"), ids, { headers: { Authorization: this.oauthService.authorizationHeader() }, responseType: 'blob' })
        .subscribe((result: Blob) => {
          resolve(URL.createObjectURL(new Blob([result], { type: 'application/zip' })));
        },
          (error) => {
            reject(error);
            this.snackbar.open(' Erro ao gerar o download em lote!', 'erro');
            console.error(error);
          });
    })
  }
}
