import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {AccountProvider} from '../../../../providers/account/account';
import {BankAccountProvider} from '../../../../providers/company/bank_account/bank_account';
import {MemberProvider} from '../../../../providers/company/member/member';
import {ContaBancariaDTO} from '../../../../model/dto/ContaBancariaDTO';
import {PessoaFiscalDTO} from '../../../../model/dto/PessoaFiscalDTO';
import {BrMaskerIonicServices3} from 'brmasker-ionic-3';
import {PostingProvider} from '../../../../providers/bookkeeping/posting/posting';
import {HttpErrorResponse} from '@angular/common/http';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import 'moment-timezone';
import {GridComponent, GroupSettingsModel, SelectionService} from '@syncfusion/ej2-angular-grids';
import {DocumentsBookkepingMapper} from '../../../../model/dto/mapper/documentsBookkepingMapper';
import {ExplorationDTOResponse} from '../../../../model/dto/ExplorationDTOResponse';
import {ExplorationProvider} from '../../../../providers/company/exploration/exploration';
import {ImovelDTO} from '../../../../model/dto/ImovelDTO';
import {AccountPlanDTOResponse} from '../../../../model/dto/AccountPlanDTOResponse';
import {ClickEventArgs} from '@syncfusion/ej2-navigations';
import {ExcelExportProperties} from '@syncfusion/ej2-grids';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {ErrorUtil} from '../../../../util/error';
import {DateUtil} from 'src/util/dateUtil';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {PropertyProvider} from 'src/providers/company/property/property';
import {CadastrarLancamentoComponent} from '../../financeiro/cadastrar-lancamento/cadastrar-lancamento.component';
import {ReportsUtil} from "../../../../util/Reports";
import {ModalExportComponent} from "../modal-export/modal-export.component";
import {AccountsCharts} from "./charts";
import {ReportsHelper} from "../../../../util/ReportsHelper";

class Saldo {
  idConta: number;
  jan: number;
  fev: number;
  mar: number;
  abr: number;
  mai: number;
  jun: number;
  jul: number;
  ago: number;
  set: number;
  out: number;
  nov: number;
  dez: number;
  total: number;
}

@Component({
  selector: 'app-livro-razao',
  templateUrl: './livro-razao.component.html',
  styleUrls: ['./livro-razao.component.scss'],
  providers: [SelectionService,
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class LivroRazaoComponent implements OnInit {

  @ViewChild('grid', {static: false}) public grid: GridComponent;

  consultando: boolean = false;
  anual = true;
  personalizado = false;
  year: number;
  years: any[];
  deducao: any = null;
  months = [{name: 'JAN', num: '01', max: "31"}, {name: 'FEV', num: '02', max: "28"}, {
    name: 'MAR',
    num: '03',
    max: '31'
  }, {name: 'ABR', num: '04', max: '30'}, {name: 'MAI', num: '05', max: '31'}, {
    name: 'JUN',
    num: '06',
    max: '30'
  }, {name: 'JUL', num: '07', max: '31'},
    {name: 'AGO', num: '08', max: '31'}, {name: 'SET', num: '09', max: '30'}, {
      name: 'OUT',
      num: '10',
      max: '31'
    }, {name: 'NOV', num: '11', max: '30'}, {name: 'DEZ', num: '12', max: '31'}];

  contas: ContaBancariaDTO[];
  socios: PessoaFiscalDTO[];
  lancamentos: any = [];
  exploration: ExplorationDTOResponse[];
  planosDeConta: AccountPlanDTOResponse[];

  members = new FormControl();
  contasMovimento = new FormControl();
  contasPlano = new FormControl();

  groupOptions: GroupSettingsModel;
  initialSort: any;
  month: any;
  toolbarOptions: any[];
  agregrates: any[];
  public property = new FormControl();
  changed = false
  public accountMultiCtrl: FormControl = new FormControl();
  public accountMultiFilterCtrl: FormControl = new FormControl();
  public filteredAccountsMulti: ReplaySubject<AccountPlanDTOResponse[]> = new ReplaySubject<AccountPlanDTOResponse[]>(1);

  // tslint:disable-next-line:variable-name
  protected _onDestroy = new Subject<void>();
  public erro: boolean;
  public errorMessage: any;
  public imoveis
  // tslint:disable-next-line: no-use-before-declare
  public dateRange = new ConsultDate();

  public links = ['Receita', 'Despesa'];
  // @ts-ignore
  public activeLink: 'Receita' | 'Despesa' = this.links[0];
  public lastOrder = {field: '', direction: '', searching: ""};

  constructor(private accountProvider: AccountProvider,
              private contasProvider: BankAccountProvider,
              public modalService: NgbModal,
              private brMasker: BrMaskerIonicServices3,
              private explorationProvider: ExplorationProvider,
              private documentsBookkepingMapper: DocumentsBookkepingMapper,
              private postingProvider: PostingProvider,
              private errorUtil: ErrorUtil,
              private memberProvider: MemberProvider,
              private dateUtil: DateUtil,
              private propertyProvider: PropertyProvider,
              public charts: AccountsCharts,
              public reportsUtil: ReportsUtil,
              public reportsHelper: ReportsHelper,
              // tslint:disable-next-line: variable-name
              private _adapter: DateAdapter<any>) {
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit() {
    this.groupOptions = {
      showDropArea: false,
      showGroupedColumn: false,
      columns: ['accountDescription'],
      disablePageWiseAggregates: true
    };

    this.accountMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      // tslint:disable-next-line: deprecation
      .subscribe(() => {
        this.filterAccountMulti();
      });

    this.toolbarOptions = [
      {text: 'Exportar', tooltipText: 'Exportar', prefixIcon: 'e-pdfexport', id: 'export'},
      'ColumnChooser'
    ];
    this._adapter.setLocale('pt-BR');
    this.month = moment(localStorage.getItem('workDate')).locale('pt-BR').format('MMM').toUpperCase();
    this.year = +moment(localStorage.getItem('workDate')).format('YYYY');
    this.years = getYears(+moment().format('YYYY') - 20);
    const workDate = this.dateUtil.removeTimeZone(localStorage.getItem('workDate'));
    // tslint:disable-next-line: deprecation
    this.dateRange.startDate = moment.tz(workDate, 'America/Sao_Paulo').startOf('month').format('YYYY-MM-DD');
    // tslint:disable-next-line: deprecation
    this.dateRange.endDate = moment.tz(workDate, 'America/Sao_Paulo').endOf('year').format('YYYY-MM-DD');
    this.deducao = null;
    this.charts.start();

    this.initializeData().then(result => {
      this.consultando = false;
    }).catch((result) => {
      this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-consolidacao');
      console.error(result);
      this.consultando = false;
      this.erro = true;
    });

    this.agregrates = [{
      columns: [
        {
          type: 'Sum',
          field: 'amountPaid',
          format: 'C2',
          groupFooterTemplate: '<b><font color="gray">${Sum}</font></b>'
        }],
    }];
  }

  actionBegin(args) {
    if (args.requestType === 'filterchoicerequest') {
      args.filterChoiceCount = 1000000; //here you can change the filterchoice count as per your requirement
    }
    if (args.requestType === 'sorting') {
      this.lastOrder.field = args.columnName;
      this.lastOrder.direction = args.direction;
    }
    if (args.requestType === 'searching') {
      this.lastOrder.searching = args.searchString;
    }
  }

  initializeData() {
    return new Promise((accept, reject) => {
      this.contasProvider.getAllBanksAccounts().then((accounts: ContaBancariaDTO[]) => {
        this.contas = accounts;
        this.memberProvider.getAllMembers().then((members: PessoaFiscalDTO[]) => {
          this.socios = members;
          this.accountProvider.getAllAccountsLeafs().then((categorias: []) => {
            this.planosDeConta = this.convertPlanAccountObjectSearchSelect(categorias);
            this.charts.accounts = this.planosDeConta;
            this.filteredAccountsMulti.next(this.planosDeConta.slice());
            this.explorationProvider.getByCompany().then((exploration: ExplorationDTOResponse[]) => {
              this.exploration = exploration;
              this.propertyProvider.getAllProperty().then((properties: ImovelDTO[]) => {
                this.imoveis = properties;
              }).catch(result => {
                this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-consolidacao');
              });
              accept(true);
            });
          });
        });
      }).catch((result: HttpErrorResponse) => {
        this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-consolidacao');
        reject(result);
      });
    });
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredAccountsMulti.pipe(take(1), takeUntil(this._onDestroy))
      // tslint:disable-next-line: deprecation
      .subscribe(val => {
        if (selectAllValue) {
          this.accountMultiCtrl.patchValue(val);
        } else {
          this.accountMultiCtrl.patchValue([]);
        }
      });
  }

  protected filterAccountMulti() {
    if (!this.planosDeConta) {
      return;
    }
    // get the search keyword
    let search = this.accountMultiFilterCtrl.value;
    if (!search) {
      this.filteredAccountsMulti.next(this.planosDeConta.slice());
    } else {
      // filter the planAccount
      search = search.toLowerCase();
      this.filteredAccountsMulti.next(
        this.planosDeConta.filter(value => value.description.toLowerCase().indexOf(search) > -1)
          .sort((a, b) => a.description.indexOf(search) - b.description.indexOf(search))
      );
    }
  }

  convertPlanAccountObjectSearchSelect(planAccounts) {
    const newPlanAccounts: AccountPlanDTOResponse[] = [];
    planAccounts.forEach((categoria) => {
      const categoriaMod: any = {
        id: categoria.id,
        customId: categoria.customId,
        description: categoria.customId + ' - ' + categoria.description,
        featureType: categoria.featureType,
        participateRuralActivity: categoria.participateRuralActivity,
        releaseType: categoria.releaseType
      };
      newPlanAccounts.push(categoriaMod);
    });
    return newPlanAccounts.sort((a, b) => a.customId - b.customId);
  }

  getLancamentos(ano, mes, contas, members, planoDecontas) {
    //console.log(ano, mes, contas, members, planoDecontas, this.property.value)
    let json = {
      bankAccountIds: [],
      propertyCodes: [],
      cpfCnpjs: [],
      accountPlanIds: [],
      deductible: this.deducao
    }
    if (this.property.value) {
      this.property.value.forEach(element => {
        json.propertyCodes.push(element.propertyCode)
      })
    }

    if (contas) {
      contas.forEach(element => {
        json.bankAccountIds.push(element.id)
      })
    }
    if (members) {
      members.forEach(element => {
        json.cpfCnpjs.push(element.cpfCNPJ)
      })
    }
    if (planoDecontas) {
      planoDecontas.forEach(element => {
        json.accountPlanIds.push(element.id)
      })
    }
    let startMonth = ano + '-' + '01' + '-01';
    let endMonth = ano + '-' + '12' + '-31';
    if (this.personalizado) {
      startMonth = this.dateRange.startDate;
      endMonth = this.dateRange.endDate;
    } else if (!this.personalizado && !this.anual) {
      startMonth = ano + '-' + this.month.num + '-01';
      endMonth = ano + '-' + this.month.num + '-' + this.month.max;
    }
    this.consultando = true
    this.postingProvider.getFilteredPostings(startMonth, endMonth, json).then((result: any) => {
      this.lancamentos = result
      this.charts.aux = result
      this.charts.generate();

      this.consultando = false
      this.erro = false
    }).catch(error => {
      console.log(error)
      this.consultando = false
      this.erro = true
    })
    this.groupOptions = {
      showDropArea: false,
      showGroupedColumn: false,
      columns: ['accountDescription'],
      disablePageWiseAggregates: true
    };
  }

  selectAllAcounts() {
    if (this.contasPlano.value.length > 1) {
      this.contasPlano.setValue([])
    } else {
      this.contasPlano.setValue(this.planosDeConta)

    }
  }

  selectAllMembers() {
    if (this.members.value.length > 1) {
      this.members.setValue([])
    } else {
      this.members.setValue(this.socios)

    }
  }
  findAllExpensesOrRevenue(type){
    this.contasPlano.setValue(this.planosDeConta.filter(it => { return it.featureType.includes(type)}))
  }
  selectAllAccountMovements() {
    if (this.contasMovimento.value.length > 1) {
      this.contasMovimento.setValue([])
    } else {
      this.contasMovimento.setValue(this.contas)

    }
  }

  selectAllImoveis() {
    if (this.property.value.length > 1) {
      this.property.setValue([])
    } else {
      this.property.setValue(this.imoveis)

    }
  }

  chosenYearHandler(ano: any) {
    this.year = ano;
  }

  rowSelected(x) {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastrarLancamentoComponent, modalOptions);
    modalRef.componentInstance.lancamentoExistenteID = Number(x.docId);
    modalRef.result.then((result) => {
      if (result) {
        this.tabsTypeFilter();
      }
    }, (result) => {
      if (result) {
        this.tabsTypeFilter();
      }
    });
  }

  tabsTypeFilter() {
    this.changed = false
    let members;   // se não existir um produtor selecionado, consulta com todos.
    let planoDeContas;   // se não existir um plano de contas selecionado, consulta com todos.

    if (this.members.value == null || this.members.value.length === 0) {
      members = this.socios;
    } else {
      members = this.members.value;
    }

    if (this.contasPlano.value == null || this.contasPlano.value.length === 0) {
      planoDeContas = this.planosDeConta;
    } else {
      planoDeContas = this.contasPlano.value;
    }
    this.getLancamentos(this.year, this.month, this.contasMovimento.value, members, planoDeContas);
  }

  toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'export') {
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : '',
      };
      const modalRef = this.modalService.open(ModalExportComponent, modalOptions);
      modalRef.componentInstance.texto = "os lançamentos agrupados por conta";
      modalRef.componentInstance.relatorio = 'livro-razao';
      modalRef.result.then((result: any) => {
        if (result.type === 'pdf') {
          this.exportPDF(result.img);
        } else if (result.type === 'excel') {
          this.exportExcel();
        }
      });
    }
  }

  exportPDF(img) {
    const date = moment().format('DD/MM/YY HH:mm').toString();

    let accounts = 'Todas as contas';
    let headerExtra = 0;
    if (this.contasPlano.value != null) {
      if (!(this.contasPlano.value.length == this.planosDeConta.length || this.contasPlano.value.length == 0)) {
        let tmp = [];
        this.contasPlano.value.forEach((value) => {
          tmp.push(value.customId);
        });
        accounts = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
        if (accounts.length > 150) {
          headerExtra = (Math.trunc((accounts.length - 150) / 180) + 1) * 10;
        }
      }
    }

    let properties = 'Todos os imóveis';
    if (this.property.value != null) {
      if (!(this.property.value.length == this.imoveis.length || this.property.value.length == 0)) {
        let tmp = [];
        this.property.value.forEach((value) => {
          tmp.push(value.propertyCode);
        });
        properties = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let bankAccounts = 'Todas as contas';
    if (this.contasMovimento.value != null) {
      if (!(this.contasMovimento.value.length == this.contas.length || this.contasMovimento.value.length == 0)) {
        let tmp = [];
        this.contasMovimento.value.forEach((value) => {
          tmp.push(value.description);
        });
        bankAccounts = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let members = 'Todos os produtores';
    if (this.members.value != null) {
      if (!(this.members.value.length == this.socios.length || this.members.value.length == 0)) {
        let tmp = [];
        this.members.value.forEach((value) => {
          tmp.push(value.name);
        });
        if (tmp.length > 1) {
          members = tmp.join(', ');
        } else if (tmp.length == 1) {
          members = `${this.members.value[0].name} (${this.members.value[0].cpfCNPJ})`;
        }
      }
    }

    let deducao: string;
    if (this.deducao === "null" || this.deducao === null)
      deducao = 'Todos os valores';
    else if (this.deducao === "true")
      deducao = 'Dedutíveis';
    else
      deducao = 'Não dedutíveis';

    let period;
    if (this.anual)
      period = this.year;
    else if (this.personalizado)
      period = moment(this.dateRange.startDate).format('DD/MM/YY') + ' - ' + moment(this.dateRange.endDate).format('DD/MM/YY');
    else
      period = this.month.name + ' - ' + this.year;

    // @ts-ignore
    let font = this.reportsHelper.getFont((this.grid.getFilteredRecords().length > 0 ? this.grid.getFilteredRecords() : this.lancamentos), this.lastOrder);

    let group: { [x: string]: any[]; } = {};
    font.forEach((value: any) => {
      let key = value.accountDescription;
      if (!group[key]) {
        group[key] = [];
      }
      group[key].push({
        paymentDateFormatted: value.paymentDateFormatted,
        participantName: value.participantName + " - " + value.participantCpfCnpj,
        movementAccount: value.movementAccount,
        propertyName: value.propertyName,
        historic: value.historic,
        amountPaidFormatted: Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(value.amountPaidFormatted.replaceAll(".", "").replace(',', '.')),
        accountDescription: '',
        balanceFormatted: Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }).format(value.balanceFormatted.replaceAll(".", "").replace(',', '.'))
      });
    });
    let tmp = [];
    for (let key in group) {
      tmp.push({
        accountDescription: key,
        paymentDateFormatted: "",
        participantName: "",
        movementAccount: "",
        propertyName: "",
        historic: "",
        amountPaidFormatted: "",
        balanceFormatted: "",
        depth: 0,
        children: true,
      })
      group[key].forEach((value: any) => {
        tmp.push(value);
      });
    }

    let colunas = [
      {dataKey: 'movementAccount', minCellWidth: 3},
      {dataKey: 'propertyName', minCellWidth: 3},
      {dataKey: 'historic', maxCellWidth: 4},
      {dataKey: 'amountPaidFormatted', minCellWidth: 2.5, halign: 'center'},
      {dataKey: 'balanceFormatted', minCellWidth: 2.5, halign: 'center'},
    ];

    let headers = [
      {header: 'Data', dataKey: 'paymentDateFormatted'},
      {header: 'Participante', dataKey: 'participantName'},
      {header: 'Conta Movimento', dataKey: 'movementAccount'},
      {header: 'Imóvel', dataKey: 'propertyName'},
      {header: 'Histórico', dataKey: 'historic'},
      {header: 'Valor', dataKey: 'amountPaidFormatted'},
      {header: 'Saldo', dataKey: 'balanceFormatted'}
    ].filter((value) => {
      return this.grid.getVisibleColumns().find((column) => {
        return column.field === value.dataKey;
      });
    });

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Livro razao - ${period}`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Livro Razão`,
        data: date,
        periodo: period,
        parametros: {
          "Dedução": deducao,
          "Imóveis": properties,
          "Produtores": members,
          "Contas Movimento": bankAccounts,
          "Contas": accounts,
        },
        image: img,
        alturaExtra: headerExtra,
      },
      tabela: {
        colunas: headers,
        dados: tmp,
        diminuir: true,
        estiloColunas: colunas,
        hierarquico: true,
      },
    });
  }

  exportExcel() {
    const exportProperties: ExcelExportProperties = {
      includeHiddenColumn: true,
      fileName: 'livrorazao' + this.year + '.xlsx',
    };
    this.grid.excelExport(exportProperties);
  }

  changePeriodStart(data) {
    if (data.value != null && this.dateRange.startDate != null && this.dateRange.endDate != null) {
      this.dateRange.startDate = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
      //this.tabsTypeFilter();
    }
  }

  changePeriodEnd(data) {
    if (data.value != null && this.dateRange.startDate != null && this.dateRange.endDate != null) {
      this.dateRange.endDate = moment.tz(data.value, 'America/Sao_Paulo').format('YYYY-MM-DD');
      // this.tabsTypeFilter();
    }
  }

  isNumeric(str) {
    return /^\d+$/.test(str);
  }

  tabs(link: 'Receita' | 'Despesa') {
    this.charts.changeTab(link);
  }
}

function getYears(startYear) {
  // tslint:disable-next-line:one-variable-per-declaration
  const currentYear = new Date().getFullYear() + 1, years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}

class ConsultDate {
  endDate: any;
  startDate: any;
}
