export class Duplicatas {
    numero: string;
    vencimento: string;
    valor: string;
    id: number;
    escriturada: boolean;
    historic: {
      modelo: string;
      historico: string;
    };
}
