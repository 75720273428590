import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-log-automacao',
  templateUrl: './log-automacao.component.html',
  styleUrls: ['./log-automacao.component.scss']
})
export class LogAutomacaoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
