import {NgbActiveModal,NgbModalOptions,NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CadastroContaBancariaComponent} from "../../../minhasPropriedades/cadastro-conta-bancaria/cadastro-conta-bancaria.component";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild,Input } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import {ContaBancariaDTO} from "../../../../../model/dto/ContaBancariaDTO";

@Component({
  selector: 'app-conta-movimento-individual',
  templateUrl: './conta-movimento-individual.component.html',
  styleUrls: ['./conta-movimento-individual.component.scss']
})
export class ContaMovimentoIndividualComponent implements OnInit {
  public enviando = false
  @Input() public edit
  public contasFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  public contaMovimento = new FormControl();
  @Input() public bankAccount: ContaBancariaDTO[];
  public filter_bankAccount:  ReplaySubject<ContaBancariaDTO[]> = new ReplaySubject<ContaBancariaDTO[]>(1);
  constructor(public activeModal: NgbActiveModal,public modalService: NgbModal,
    ) { }

  ngOnInit() {
    //console.log(this.bankAccount)

    if(this.edit){
        //console.log("AAAAA",this.edit)
         this.contaMovimento.setValue(this.edit)

    }

   this.filter_bankAccount.next(this.bankAccount.slice());
   //console.log(this.filter_bankAccount)
   this.contasFilterCtrl.valueChanges
     .pipe(takeUntil(this._onDestroy))
     .subscribe(() => {
       this.filterContas();
     });

  }
  log(){
    //console.log(this.contaMovimento)
  }
  save(){
     this.activeModal.close(this.contaMovimento.value);

  }

  protected filterContas() {
    //console.log("teste")
    if (!this.bankAccount) {
      return;
    }
    // get the search keyword
    let search = this.contasFilterCtrl.value;
    if (!search) {
      this.filter_bankAccount.next(this.bankAccount.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filter_bankAccount.next(
      this.bankAccount.filter(bankAccount => bankAccount.description.toLowerCase().indexOf(search) > -1)
    );
  }

  editContaMovimento(contaMovimento) {
    contaMovimento.description = contaMovimento.description.split(' - ')[1];
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroContaBancariaComponent, modalOptions);
    modalRef.componentInstance.contaExistente = contaMovimento;
    modalRef.result.then((result) => {

    }, () => {
    }).catch((result) => {
    });
  }
}
