import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../../environments/environment.prod";
import {Financiamento} from "../../../model/Financiamento";

@Injectable()
export class FinancingProvider {

  apiBase: string = "/bookkeeping/v1/financing/";

  constructor(public http: HttpClient, public oauthService: OAuthService) {
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getById(id: number) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/${id}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  post(obj: Financiamento) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}`, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  put(obj: Financiamento) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}`, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${environment.apiLogin}${this.apiBase}${localStorage.getItem('idEmpresa')}/${id}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }
}
