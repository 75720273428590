import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AutomationProvider} from 'src/providers/automation/automation';
import {FolderProvider} from 'src/providers/folder/folder';
import {UserProvider} from 'src/providers/user/user';
import {AutomationsConfig} from 'src/util/automations';
import {Snackbar} from 'src/util/snackbar';
import Swal from "sweetalert2";

@Component({
  selector: 'app-cadastro-automacao',
  templateUrl: './cadastro-automacao.component.html',
  styleUrls: ['./cadastro-automacao.component.scss']
})
export class CadastroAutomacaoComponent implements OnInit {


  protected _onDestroy = new Subject<void>();
  public loading: boolean = false;
  public panelExpanded: boolean = true;
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  @Input() public existingAutomation = null;

  // STEP 1 - Action
  public actionList: any = [];
  public automationAction: any = null;

  // STEP 2 - Condition
  public automationConditionFormArray: any = new FormArray([]);

  // STEP 3 -> Companies
  public automationActionValuesList: any = [];
  public automationActionFormArray: any = new FormArray([]);
  public officeCompanies: any[] = [];
  public companyFilterCtrl: FormControl = new FormControl();
  public filtered_companies: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  //STEP 4 - Review
  public automationGroupId: string = null;
  public automationDescription: string = "";

  constructor(
    public automationsConfig: AutomationsConfig,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public snackBar: Snackbar,
    public fb: FormBuilder,
    public userProvider: UserProvider,
    public folderProvider: FolderProvider,
    public automationProvider: AutomationProvider
  ) {
  }

  ngOnInit() {
    this.actionList = this.automationsConfig.actionList();
    this.initializeCompanies().then(() => {
      if (this.existingAutomation) {
        this.existingAutomationToForm();
      }
    });
  }

  existingAutomationToForm() {
    this.loading = true;

    const automationType = this.existingAutomation[0].automationType;
    this.automationAction = this.actionList.find((action) => action.action === automationType);

    this.automationAction.conditions.forEach(condition => {
      Object.entries(condition.operators).forEach(([operator]) => {
        if (this.existingAutomation[0].hasOwnProperty(operator)) {
          this.addAutomationConditionFormGroup();
          const formGroup = this.automationConditionFormArray.controls.at(-1);
          formGroup.controls.condition.setValue(condition);
          formGroup.controls.conditionOperator.setValue(operator);
          const conditionValue = this.existingAutomation[0][operator];
          if (Array.isArray(conditionValue)) {
            formGroup.controls.conditionExtraValues.setValue([...conditionValue]);
            formGroup.controls.conditionValue.setValue(" ");
          } else {
            formGroup.controls.conditionValue.setValue(conditionValue);
          }
        }
      });
    });

    this.existingAutomation.forEach((automation, index) => {
      const company = this.officeCompanies.find(company => company.id === automation.companyId);
      this.addAutomationActionFormGroup(company);
      this.companySelectActionValue(index, company).then(() => {
        const formGroup = this.automationActionFormArray.controls[index];
        formGroup.controls.id.setValue(automation.id);
        formGroup.controls.priority.setValue(automation.priority);
        formGroup.controls.startDate.setValue(automation.startDate);
        formGroup.controls.endDate.setValue(automation.endDate);
        const value = this.automationActionValuesList[index].find(folder => folder.id === automation.folderId);
        formGroup.controls.value.setValue(value);
        formGroup.controls.isActive.setValue(automation.isActive);
      });
    });

    this.automationGroupId = this.existingAutomation[0].groupId;
    this.automationDescription = this.existingAutomation[0].description;
    this.loading = false;
  }

  checkStep2Validity() {
    return this.automationConditionFormArray.controls.length > 0 &&
      this.automationConditionFormArray.controls.every(group => group.valid);
  }

  checkStep3Validity() {
    return this.automationAction && this.automationActionFormArray.controls.length > 0 &&
      this.automationActionFormArray.controls.every(group => group.valid);
  }

  checkStep4Validity() {
    return this.automationDescription.length > 3;
  }

  postAutomation() {
    this.automationProvider.postAutomation(this.formatAutomationForm())
      .then(result => this.activeModal.close(result))
      .catch(error => this.snackBar.openLong(error.message, 'erro'));
  }

  putAutomation() {
    this.automationProvider.putAutomation(this.formatAutomationForm())
      .then(result => this.activeModal.close(result))
      .catch(error => this.snackBar.openLong(error.message, 'erro'));
  }

  addAutomationActionFormGroup(company?) {
    const formGroup = this.fb.group({
      id: [null],
      priority: [null],
      company: [company || null, Validators.required],
      startDate: [null],
      endDate: [null],
      value: [null, Validators.required],
      isActive: [true, Validators.required],
    });
    this.automationActionFormArray.push(formGroup);
    this.automationActionValuesList.push(null);
  }

  removeAutomationActionFormGroup(index: number) {
    const formGroup = this.automationActionFormArray.controls[index];
    const id = formGroup.controls.id.value;

    if (id) {
      Swal.fire({
        title: 'Você tem certeza?',
        text: "Você não poderá reverter isso!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.automationProvider.deleteAutomation(id)
            .then(() => {
              this.automationActionFormArray.removeAt(index);
              this.automationActionValuesList.splice(index, 1);
              Swal.fire('Excluído!', 'A automação foi excluída.', 'success');
            })
            .catch(error => {
              this.snackBar.openLong(error.message, 'erro');
            });
        }
      });
    } else {
      this.automationActionFormArray.removeAt(index);
      this.automationActionValuesList.splice(index, 1);
    }
  }

  initializeCompanies() {
    this.loading = true;
    return this.userProvider.getAllCompanys().then((result: []) => {
      this.officeCompanies = result;
      this.filtered_companies.next(this.officeCompanies.slice());
      this.companyFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.filterCompanies();
      });
      this.loading = false;
    }).catch((error) => {
      this.loading = false;
      this.snackBar.openLong("Erro ao buscar empresas", "erro");
    });
  }

  async addAllActionCompanies() {
    const toRemove = this.automationActionFormArray.controls
      .filter(group => group.controls.company.value)
      .map(group => group.controls.company.value.id);

    for (const company1 of this.officeCompanies
      .filter(company => !toRemove.includes(company.id))) {
      this.addAutomationActionFormGroup(company1);
      let index = this.automationActionFormArray.controls.length - 1;
      await this.folderProvider.getFoldersByCompanyId(company1.id).then(folders => {
        this.automationActionValuesList[index] = folders
      }).catch(error => {
        this.snackBar.openLong("Erro ao buscar pastas", "erro");
      });
    }
  }

  removeAllActionCompanies() {
    this.automationActionFormArray.clear();
    this.automationActionValuesList.length = 0;
  }

  companySelectActionValue(index: number, event) {
    this.automationActionFormArray.controls.forEach((group, i) => {
      if (group.controls.company.value.id === event.id && i !== index) {
        this.snackBar.openLong("Empresa já selecionada", "erro");
        this.automationActionFormArray.controls[index].controls.company.setValue(null);
        return;
      }
    });

    if (this.automationAction.action === "MOVE_DFE_TO_FOLDER" || this.automationAction.action === "MOVE_CANCELLED_DFE_TO_FOLDER") {
      this.loading = true;
      return this.folderProvider.getFoldersByCompanyId(this.automationActionFormArray.controls[index].controls.company.value.id)
        .then(folders => {
          this.automationActionValuesList[index] = folders;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.snackBar.openLong("Erro ao buscar pastas", "erro");
        });
    }
  }

  setDateAllCompanies() {
    const startDate = this.automationActionFormArray.controls[0].controls.startDate.value;
    const endDate = this.automationActionFormArray.controls[0].controls.endDate.value;
    this.automationActionFormArray.controls.forEach(group => {
      group.controls.startDate.setValue(startDate);
      group.controls.endDate.setValue(endDate);
    });
  }

  addAutomationConditionFormGroup() {
    this.automationConditionFormArray.push(this.fb.group({
      condition: [null, Validators.required],
      conditionOperator: [null, Validators.required],
      conditionValue: [null],
      conditionExtraValues: [[]],
    }));
  }

  removeAutomationConditionFormGroup(index: number) {
    this.automationConditionFormArray.removeAt(index);
  }

  removeAllConditions() {
    this.automationConditionFormArray.clear();
  }

  setConditionValidator(value, formGroup) {
    const isMultiple = value.inputType.includes('multiple');
    formGroup.controls.conditionExtraValues.setValidators(isMultiple ? Validators.required : null);
    formGroup.controls.conditionValue.setValidators(isMultiple ? null : Validators.required);
  }

  handleKeyDownCondition(formGroup, event: KeyboardEvent) {
    const conditionValue = formGroup.controls.conditionValue.value.trim().replace(/\D/g, "");
    if ((event.key === " " || event.key === 'Enter') && conditionValue) {
      event.preventDefault();
      const conditionExtraValues = formGroup.controls.conditionExtraValues.value;
      if (!conditionExtraValues.includes(conditionValue)) {
        conditionExtraValues.push(conditionValue);
        formGroup.controls.conditionValue.setValue("");
        formGroup.controls.conditionExtraValues.setValue(conditionExtraValues);
      } else {
        this.snackBar.openLong("Valor já adicionado", "erro");
      }
    }
  }

  removeExtraValue(formGroup, index) {
    const extraValues = formGroup.controls.conditionExtraValues.value;
    extraValues.splice(index, 1);
    if (!extraValues.length) {
      formGroup.controls.conditionExtraValues.setErrors(Validators.required);
    }
  }

  formatAutomationForm() {
    let automations = this.automationActionFormArray.controls.map((group) => {
      const automation = {
        id: group.controls.id.value,
        priority: group.controls.priority.value,
        automationType: this.automationAction.action,
        companyId: group.controls.company.value.id,
        isActive: group.controls.isActive.value,
        startDate: group.controls.startDate.value,
        endDate: group.controls.endDate.value,
        groupId: this.automationGroupId,
        description: this.automationDescription,
        ...(this.automationAction.action === "MOVE_DFE_TO_FOLDER" && {folderId: group.controls.value.value.id}),
        ...(this.automationAction.action === "MOVE_CANCELLED_DFE_TO_FOLDER" && {folderId: group.controls.value.value.id})
      };

      this.automationConditionFormArray.controls.forEach(conditionGroup => {
        const operator = conditionGroup.controls.conditionOperator.value;
        automation[operator] = conditionGroup.controls.conditionExtraValues.value.length > 0
          ? conditionGroup.controls.conditionExtraValues.value
          : conditionGroup.controls.conditionValue.value;
      });

      return automation;
    });

    const generalPriorityItem = automations.find(item => item.priority !== null);
    const generalPriority = generalPriorityItem ? generalPriorityItem.priority : undefined;

    if (generalPriority !== undefined) {
      automations = automations.map(item => ({
        ...item,
        priority: item.priority === null ? generalPriority : item.priority
      }));
    }
    return automations;
  }

  formatConditionValue(value, mod?) {
    switch (typeof value) {
      case "boolean":
        return value ? "Verdadeiro" : "Falso";
      case "number":
        return new Intl.NumberFormat('pt-BR', {
          ...(mod.includes('currency') ? {style: 'currency'} : {}),
          currency: 'BRL'
        }).format(value);
      case "object":
        if (Array.isArray(value)) {
          return `{${value.map(this.formatConditionValue).join(", ou ")}}`;
        }
      default:
        return value;
    }
  }

  protected filterCompanies() {
    if (!this.officeCompanies) {
      return;
    }
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.filtered_companies.next(this.officeCompanies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filtered_companies.next(
      this.officeCompanies.filter(company => (company.name.toLowerCase().includes(search) || company.id == search))
    );
  }

  protected readonly
  Object = Object;
}
