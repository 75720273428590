import {Injectable, OnDestroy, OnInit} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ConsultaDfe} from "../../model/ConsultaDfe";
import {OAuthService} from "angular-oauth2-oidc";
import {environment} from "../../environments/environment.prod";
import {DfeDTOResponse} from "../../model/dto/dfe/DfeDTOResponse";
import {Xml} from "../../model/Xml";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Injectable()
export class DfeProvider {

  apiDfe: string = "/fiscal/v1/dfe/";

  constructor(public http: HttpClient, public oauthService: OAuthService, private activeRoute: ActivatedRoute) {
  }

  getAllDfes() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiDfe + localStorage.getItem('idEmpresa'), {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DfeDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getDfeByID(id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiDfe + localStorage.getItem('idEmpresa') + "/" + id + "/viewer", {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DfeDTOResponse) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  getDfeByDay(day: string) {  //AAAA-MM-DD
    return new Promise((resolve, reject) => {

      // tslint:disable-next-line:max-line-length
      this.http.get(environment.apiLogin + this.apiDfe + localStorage.getItem('idEmpresa') + "/" + day, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DfeDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getDfeRecent() {
    return new Promise((resolve, reject) => {

      this.http.get(environment.apiLogin + this.apiDfe + "recent/" + localStorage.getItem('idEmpresa') + "/count", {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DfeDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getDfeByPeriod(start: string, end: string) {  //AAAA-MM-DD
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + this.apiDfe + "period/" + localStorage.getItem('idEmpresa') + "/" + start + "/" + end, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
          //console.log("result", result);
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }
  getDfeByPeriodPlannedPay(start: string, end: string) {  //AAAA-MM-DD
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin +"/fiscal/v1/planned_payment/by-expiration-date-period/" + localStorage.getItem('idEmpresa') + "/" + start + "/" + end, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
          //console.log("result", result);
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getXmlDfeById(ids) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiDfe + "xml/by-ids", ids, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getDfeNcmCfop(obj, start, end){

    //console.log("GETTING DFE USING NCM - CFOP");
    //console.log("obj: ", obj);
    //console.log("start: ", start, " end: ", end);

    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + this.apiDfe + "ncm-cfop/" + localStorage.getItem('idEmpresa') + "/" + start + "/" + end, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getDfeNcmCfopVencimento(obj, start, end){

    //console.log("GETTING DFE USING NCM - CFOP");
    //console.log("obj: ", obj);
    //console.log("start: ", start, " end: ", end);

    return new Promise((resolve, reject) => {
      this.http.post(environment.apiLogin + "/fiscal/v1/planned_payment/by-ncm-cfop/" + localStorage.getItem('idEmpresa') + "/" + start + "/" + end, obj, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getDfeByPlannedPayment(id) {
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: max-line-length
      this.http.get(`${environment.apiLogin}${this.apiDfe}${localStorage.getItem('idEmpresa')}/by-planned-payment/${id}/viewer`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        // tslint:disable-next-line: deprecation
        .subscribe((result) => {
          resolve(result);
        },
        (error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  getDfeIdByPlannedPaymentId(id){
    return new Promise((resolve, reject) => {
      this.http.get(environment.apiLogin + "/fiscal/v1/planned_payment/dfe-id/" + localStorage.getItem('idEmpresa') + '/' + id, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

  postDfe(dfe: any) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiDfe, dfe, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DfeDTOResponse) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  postDfes(dfes: Xml[]) {
    return new Promise((resolve, reject) => {

      this.http.post(environment.apiLogin + this.apiDfe + "all", dfes, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DfeDTOResponse[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  putFolderDfes(dfes: {newFolderId: number, dfeIds: number[]}) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiLogin}${this.apiDfe}${localStorage.getItem('idEmpresa')}/folder`, dfes, {headers: {Authorization: this.oauthService.authorizationHeader()}})
      // this.http.put(environment.apiLogin + this.apiDfe, dfes, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: DfeDTOResponse) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });

    });
  }

  getAllDfesProducts(id: any) {
    return new Promise((resolve, reject) => {
    // console.log(`${environment.apiLogin}${this.apiDfe}products-dfe/${id}`);
      this.http.get(`${environment.apiLogin}${this.apiDfe}${localStorage.getItem('idEmpresa')}/products-dfe/${id}`, {headers: {Authorization: this.oauthService.authorizationHeader()}})
        .subscribe((result: any[]) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
    });
  }

}
