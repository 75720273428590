import { Component, OnInit, Input } from '@angular/core';
import {NgbActiveModal,NgbModalOptions,NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CadastroContaBancariaComponent} from "../../../minhasPropriedades/cadastro-conta-bancaria/cadastro-conta-bancaria.component";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, ReplaySubject, Subject} from "rxjs";
import { take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-conta-individual',
  templateUrl: './conta-individual.component.html',
  styleUrls: ['./conta-individual.component.scss']
})
export class ContaIndividualComponent implements OnInit {
  public enviando = false
  @Input() public edit
  public conta = new FormControl();
  @Input() public planAccount;
  protected _onDestroy = new Subject<void>();

  public filter_planAccount: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  public accountPlanFilterCtrl: FormControl = new FormControl()
  constructor(public activeModal: NgbActiveModal,public modalService: NgbModal,) { }

  ngOnInit() {
    //console.log(this.planAccount)
    //console.log("AAAAA",this.edit)
   this.conta.setValue(this.edit)

   this.filter_planAccount.next(this.planAccount.slice());
    this.accountPlanFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterAccountPlan();
      });
  }
  log(){
    //console.log(this.conta)
  }
  save(){
    //console.log(this.conta.value)
    //accountsChartId
    this.activeModal.close(this.conta.value);
  }
  protected filterAccountPlan() {
    if (!this.planAccount) {
      return;
    }
    // get the search keyword
    let search = this.accountPlanFilterCtrl.value;
    if (!search) {
      this.filter_planAccount.next(this.planAccount.slice());
    } else {
      // filter the planAccount
      search = search.toLowerCase();
      this.filter_planAccount.next(
        this.planAccount.filter(value => value.description.toLowerCase().indexOf(search) > -1)
          .sort((a, b) => a.description.indexOf(search) - b.description.indexOf(search))
      );
    }
  }
  editconta(conta) {
    conta.description = conta.description.split(' - ')[1];
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(CadastroContaBancariaComponent, modalOptions);
    modalRef.componentInstance.contaExistente = conta;
    modalRef.result.then((result) => {

    }, () => {
    }).catch((result) => {
    });
  }
}
