import {PessoaFiscalDTO} from "./PessoaFiscalDTO";
import {ImovelDTO} from "./ImovelDTO";
import { ExplorationTypeProd } from "../enum/ExplorationType";

export class ExplorationDTOResponse {
  id: number;
  companyId: number;
  member: PessoaFiscalDTO;
  cpfCNPJMember: string;
  propertyCode: string;
  property: ImovelDTO;
  percentage: number;
  caepf: string;
  ie: string;
  apportionment: boolean;
  explorationTypeProd: string;
}
