import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Snackbar} from "../../../../../util/snackbar";
import {CompanyProvider} from "../../../../../providers/company/company/company";
import {Pessoa} from "../../../../../model/Pessoa";
import {ErrorUtil} from "../../../../../util/error";
import {HttpErrorResponse} from "@angular/common/http";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {LoginModalComponent} from "../../../pages/login-modal/login-modal.component";
import {AuthProvider} from "../../../../../providers/auth/authProvider";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-deletar-empresa',
  templateUrl: './deletar-empresa.component.html',
  styleUrls: ['./deletar-empresa.component.scss']
})
export class DeletarEmpresaComponent implements OnInit {
  enviando: boolean = false;
  acceptTerms: boolean = false;
  formPassword: FormGroup;

  constructor(public dialogRef: MatDialogRef<DeletarEmpresaComponent>,
              private authProvider: AuthProvider,
              private companyProvider: CompanyProvider,
              public snackbar: Snackbar,
              public formBuilder: FormBuilder,
              public errorUtil: ErrorUtil,
              @Inject(MAT_DIALOG_DATA) public data: Pessoa) {
  }

  ngOnInit() {
    this.formPassword = this.formBuilder.group({
      password: [null, Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close("cancelou");
  }

  onYesClick(): void {
    this.enviando = true;
    this.authProvider.revalidateLogin(this.formPassword.value.password).then(value => {
      this.companyProvider.hideCompany(this.data.id).then(() => {
        this.snackbar.openLong('Excluído com sucesso!', 'success');
        this.dialogRef.close(this.data);
      }).catch((reason: HttpErrorResponse) => {
        console.error(reason);
        this.enviando = false;
        this.snackbar.openLong('Não foi possível excluir! ' + this.errorUtil.checkErrorStatus(reason, reason.status, reason.error, 'client'), 'erro');
      });
    }).catch(reason => {
      this.snackbar.openLong('Não foi possível validar seu usuário!', 'erro');
      this.enviando = false;
    });

  }

  acceptTermsChange(value) {
    //console.log(value);
    this.acceptTerms = value;
  }


}
