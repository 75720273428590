import {Component} from "@angular/core";
import {NgbActiveModal, NgbModal, NgbModalOptions,} from "@ng-bootstrap/ng-bootstrap";
import {ErrorUtil} from "src/util/error";
import {Snackbar} from "src/util/snackbar";
import {Subject} from "rxjs";
import {Bookkeeping} from "src/model/dto/bookkeping/Bookkeeping";
import {EditarImovelComponent} from "../editar-imovel/editar-imovel.component";
import {EditarContaComponent} from "../editar-conta/editar-conta.component";
import {EditarContaLancamentoComponent} from "../editar-conta-lancamento/editar-conta-lancamento.component";
import {EditarParticipanteComponent} from "../editar-participante/editar-participante.component";
import {EditarDataComponent} from "../editar-data/editar-data.component";

@Component({
  selector: "app-alterar-lote-modal",
  templateUrl: "./alterar-lote-modal.component.html",
  styleUrls: ["./alterar-lote-modal.component.scss"],
})
export class AlterarLoteModalComponent {
  closeResult = "";

  protected _onDestroy = new Subject<void>();

  public selectedBookkeeping: Bookkeeping[];
  public dataSource: Bookkeeping[];

  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              public errorUtil: ErrorUtil,
              public snackbar: Snackbar) {}

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: "modal-basic-title"})
      .result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
      }
    );
  }

  alterarLote(option) {
    if (option == "imovel") {
      this.openModalAlteracaoImovel();
    } else if (option == "conta") {
      this.openModalAlteracaoContaLancamento();
    } else if (option == "contaMovimento") {
      this.openModalAlteracaoConta();
    } else if (option == "participante") {
      this.openModalAlteracaoParticipante();
    } else if (option == "data") {
      this.openModalAlteracaoData();
    } else {
      this.snackbar.open("Selecione uma opção de alteração para prosseguir", "danger");
    }
  }

  openModalAlteracaoImovel() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(EditarImovelComponent, modalOptions);
    modalRef.componentInstance.selectedBookkeeping = this.selectedBookkeeping;
    modalRef.result.then((resp) => this.activeModal.close(resp));
  }

  openModalAlteracaoContaLancamento() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(EditarContaLancamentoComponent, modalOptions);
    modalRef.componentInstance.selectedBookkeeping = this.selectedBookkeeping;
    modalRef.result.then((resp) => this.activeModal.close(resp));
  }

  openModalAlteracaoConta() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(EditarContaComponent, modalOptions);
    modalRef.componentInstance.selectedBookkeeping = this.selectedBookkeeping;
    modalRef.result.then((resp) => this.activeModal.close(resp));
  }

  openModalAlteracaoParticipante() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(EditarParticipanteComponent, modalOptions);
    modalRef.componentInstance.selectedBookkeeping = this.selectedBookkeeping;
    modalRef.result.then((resp) => this.activeModal.close(resp));
  }

  openModalAlteracaoData() {
    let modalOptions: NgbModalOptions = {
      backdrop: 'static',
      windowClass: localStorage.getItem('theme') == 'dark'? 'dark-theme' : ''
    };
    const modalRef = this.modalService.open(EditarDataComponent, modalOptions);
    modalRef.componentInstance.selectedBookkeeping = this.selectedBookkeeping;
    modalRef.result.then((resp) => this.activeModal.close(resp));
  }
}

