import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Dialog} from "../../../../../model/Dialog";
import {Snackbar} from "../../../../../util/snackbar";

@Component({
  selector: 'app-user-info-app',
  templateUrl: './user-info-app.component.html',
  styleUrls: ['./user-info-app.component.scss']
})
export class UserInfoAppComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public snackbar: Snackbar) { }

  ngOnInit() {
  }

  copyMessage() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'Olá ' + this.data.name + '! Para acessar sua conta você deve realizar o download' +
      ' do aplicativo na loja do seu smartphone (Apple Store para Iphone e Play Store para Android).' +
      '\r\n' +
      '\r\nSeu usuário é: ' + this.data.email +
      '\r\nSua senha é: ' + this.data.password +
      '\r\n' +
      '\r\nApple Store: https://apps.apple.com/br/app/farmcont/id1499356431' +
      '\r\nPlay Store: https://play.google.com/store/apps/details?id=farmcontcdg.com.br';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackbar.open('Mensagem copiada!', 'success');
  }
}
