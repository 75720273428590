import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {BankAccountProvider} from "../../../../providers/company/bank_account/bank_account";
import {MemberProvider} from "../../../../providers/company/member/member";
import {DateUtil} from "../../../../util/dateUtil";
import {ErrorUtil} from "../../../../util/error";
import {PropertyProvider} from "../../../../providers/company/property/property";
import {ContaBancariaDTO} from "../../../../model/dto/ContaBancariaDTO";
import {PessoaFiscalDTO} from "../../../../model/dto/PessoaFiscalDTO";
import {ImovelDTO} from "../../../../model/dto/ImovelDTO";
import * as moment from "moment";
import {FormControl} from "@angular/forms";
import {TreeGridComponent} from "@syncfusion/ej2-angular-treegrid";
import {TreeTableAnual} from "./treeTable";
import {ExcelExportProperties} from "@syncfusion/ej2-grids";
import {Snackbar} from "../../../../util/snackbar";
import {AnnualCharts} from "./charts";
import {ModalExportComponent} from "../modal-export/modal-export.component";
import {ReportsUtil} from "../../../../util/Reports";

@Component({
  selector: 'app-comparativo-anual',
  templateUrl: './comparativo-anual.component.html',
  styleUrls: ['./comparativo-anual.component.scss']
})
export class ComparativoAnualComponent implements OnInit {

  @ViewChild('grid', {static: false}) public treegrid: TreeGridComponent;
  @ViewChild('treegridCollapse', {static: false}) public treeGridCollapse: TreeGridComponent;

  public contas: ContaBancariaDTO[];
  public socios: PessoaFiscalDTO[];
  public imoveis: ImovelDTO[];

  public bankAccount = new FormControl();
  public property = new FormControl();
  public members = new FormControl();

  public toolbarOptions: any[];
  public years: any;
  public ano: number;
  public ano2: number;
  public deducao: any;
  public filterSettings: any;

  public headers = []
  public data: any[] = [];
  public childMapping = 'children';

  public panelExpanded: boolean = true;
  public changed: boolean = false;

  public links = ['Receita', 'Despesa'];
  // @ts-ignore
  public activeLink: 'Receita' | 'Despesa' = this.links[0];
  private bodyPDF: any[];

  constructor(public modalService: NgbModal,
              public dateUtil: DateUtil,
              public snackBar: Snackbar,
              public treeTable: TreeTableAnual,
              public charts: AnnualCharts,
              public reportsUtil: ReportsUtil,
              private contasProvider: BankAccountProvider,
              private memberProvider: MemberProvider,
              private errorUtil: ErrorUtil,
              private propertyProvider: PropertyProvider,) {
  }

  ngOnInit() {
    this.charts.byAccountAux = [];
    this.charts.byAccount = [];
    this.charts.hidden = true;
    this.treeTable.data = [];
    this.treeTable.aux = [];
    this.defaultConfiguration();
    this.initializeData();
  }

  initializeData() {
    this.treeTable.consultando = true;
    this.contasProvider.getAllBanksAccounts().then((accounts: ContaBancariaDTO[]) => {
      this.contas = accounts;
      this.memberProvider.getAllMembers().then((members: PessoaFiscalDTO[]) => {
        this.socios = members;
        this.propertyProvider.getAllProperty().then((properties: ImovelDTO[]) => {
          this.imoveis = properties;
          this.initialSelects();
        }).catch(result => {
          this.errorUtil.checkErrorStatus(result, result.status, result.error, 'relatorios-consolidacao');
        });
      });
    });
  }

  defaultConfiguration() {
    this.years = getYears(+moment().format('YYYY') - 20);
    this.ano = +moment(this.dateUtil.removeTimeZone(localStorage.getItem('workDate'))).subtract(1, 'years').format('YYYY');
    this.ano2 = +moment(this.dateUtil.removeTimeZone(localStorage.getItem('workDate'))).format('YYYY');
    this.deducao = 'TODOS';
    this.toolbarOptions = [
      {
        text: 'Exportar', tooltipText: 'Exportar',
        prefixIcon: 'e-pdfexport',
        id: 'Export'
      },
    ];
    this.filterSettings = {type: 'Excel'};
  }

  updateChanged() {
    this.changed = true;
  }

  tabsTypeFilter() {
    if (this.ano == this.ano2) {
      this.snackBar.openLong('Os anos devem ser diferentes!', 'erro');
      return;
    }
    this.changed = false;

    let members = this.socios.map((value) => value.cpfCNPJ);
    if (this.members.value.length !== 0) {
      members = this.members.value.map((value) => value.cpfCNPJ);
    }

    let properties = this.imoveis.map((value) => value.propertyCode);
    if (this.property.value.length !== 0) {
      properties = this.property.value.map((value) => value.propertyCode);
    }

    let bankAccount = this.contas.map((value) => value.id);
    if (this.bankAccount.value.length !== 0) {
      bankAccount = this.bankAccount.value.map((value) => value.id);
    }

    let realeseType = 0;
    if (this.deducao == 'DEDUTIVEIS') {
      realeseType = 1;
    } else if (this.deducao == 'NAODEDUTIVEIS') {
      realeseType = 2;
    }

    this.headers = [this.ano.toString(), this.ano2.toString()]

    this.treeTable.getReport(this.ano.toString(), this.ano2.toString(), bankAccount, properties, members, realeseType);

    let column = (this.treegrid as TreeGridComponent).grid.getColumnByField('ano1');
    column.headerText = this.ano.toString();
    let column2 = (this.treegrid as TreeGridComponent).grid.getColumnByField('ano2');
    column2.headerText = this.ano2.toString();
    (this.treegrid as TreeGridComponent).refreshHeader();
  }

  limpaMembers(bool) {
    if (bool) {
      this.members.setValue(this.socios);
    } else {
      this.members.setValue([])
    }
  }

  limpaImoveis(bool) {
    if (bool) {
      this.property.setValue(this.imoveis)
    } else {
      this.property.setValue([])
    }
  }

  limpaConta(bool) {
    if (bool) {
      this.bankAccount.setValue(this.contas)
    } else {
      this.bankAccount.setValue([])
    }
  }

  initialSelects() {
    this.members.setValue([])
    this.property.setValue([])
    this.bankAccount.setValue([])
    this.treeTable.start();
  }

  collapseTable() {
    if (this.childMapping != 'children') {
      this.childMapping = 'children';
      this.treegrid.expandAtLevel(0);
    } else {
      this.childMapping = null;
      this.treegrid.collapseAtLevel(0);
    }
  }

  toolbarClick(args: any): void {
    if (args.item.id === 'Export') {
      let modalOptions: NgbModalOptions = {
        backdrop: 'static',
        windowClass: localStorage.getItem('theme') == 'dark' ? 'dark-theme' : '',
      };
      const modalRef = this.modalService.open(ModalExportComponent, modalOptions);
      modalRef.componentInstance.relatorio = 'comparativo-anual';
      modalRef.componentInstance.texto = "as despesas ou receitas de cada conta no ano";
      modalRef.result.then((result: any) => {
        if (result.type === 'pdf') {
          this.exportPDF(result.img);
        } else if (result.type === 'excel') {
          this.exportExcel();
        }
      });
    }
  }

  exportPDF(img: boolean) {
    const date = moment().format('DD/MM/YY HH:mm').toString();

    let properties = 'Todos os imóveis';
    if (this.property.value != null) {
      if (!(this.property.value.length == this.imoveis.length || this.property.value.length == 0)) {
        let tmp = [];
        this.property.value.forEach((value) => {
          tmp.push(value.propertyCode);
        });
        properties = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let accounts = 'Todas as contas';
    if (this.bankAccount.value != null) {
      if (!(this.bankAccount.value.length == this.contas.length || this.bankAccount.value.length == 0)) {
        let tmp = [];
        this.bankAccount.value.forEach((value) => {
          tmp.push(value.description);
        });
        accounts = (tmp.length > 1) ? tmp.join(', ') : tmp[0];
      }
    }

    let members = 'Todos os produtores';
    if (this.members.value != null) {
      if (!(this.members.value.length == this.socios.length || this.members.value.length == 0)) {
        let tmp = [];
        this.members.value.forEach((value) => {
          tmp.push(value.name);
        });
        if (tmp.length > 1) {
          members = tmp.join(', ');
        } else if (tmp.length == 1) {
          members = `${this.members.value[0].name} (${this.members.value[0].cpfCNPJ})`;
        }
      }
    }

    let deducao: string;
    if (this.deducao === 0) {
      deducao = 'Todos os valores';
    } else if (this.deducao === true) {
      deducao = 'Dedutíveis';
    } else {
      deducao = 'Não dedutíveis';
    }

    let headers = [
      {dataKey: 'conta', header: 'Conta'},
      {dataKey: 'ano1', header: this.ano},
      {dataKey: 'ano2', header: this.ano2},
      {dataKey: 'porcento', header: 'Diferença (%)'},
      {dataKey: 'absoluto', header: 'Diferença (R$)'}
    ];

    let colunas = [
      {dataKey: 'conta', cellWidth: 9},
      {dataKey: 'ano1', minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'ano2', minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'porcento', minCellWidth: 1.5, halign: 'center'},
      {dataKey: 'absoluto', minCellWidth: 1.5, halign: 'center'},
    ];

    this.bodyPDF = [];
    this.processBodyPDF(this.treeTable.data, this.childMapping == 'children');

    this.reportsUtil.exportPDF({
      doc: {
        arquivo: `Comparativo anual - ${this.ano} e ${this.ano2}`,
        orientacao: 'Landscape',
      },
      cabecalho: {
        titulo: `Comparativo Anual`,
        data: date,
        periodo: this.ano.toString() + ' e ' + this.ano2.toString(),
        parametros: {
          "Dedução": deducao,
          "Imóveis": properties,
          "Produtores": members,
          "Contas": accounts,
        },
        image: img,
      },
      tabela: {
        dados: this.bodyPDF,
        colunas: headers,
        estiloColunas: colunas,
        hierarquico: true
      },
    });
  }

  exportExcel() {
    const exportProperties: ExcelExportProperties = {
      includeHiddenColumn: true,
      fileName: `Comparativo anual - ${this.ano} e ${this.ano2}.xlsx`,
    };
    if (this.childMapping == 'children') {
      this.treegrid.excelExport(exportProperties);
    } else {
      this.treeGridCollapse.excelExport(exportProperties);
    }
  }

  processBodyPDF(data, children, depth = 0) {
    data.forEach((item) => {
      let tmp = {
        conta: "  ".repeat(depth * 2) + item.conta,
        ano1: this.formatNumber(item.ano1),
        ano2: this.formatNumber(item.ano2),
        absoluto: this.formatNumber(item.absoluto),
        porcento: this.formatPercent(item.porcento),
        depth: depth
      };

      this.bodyPDF.push(tmp);

      if (item.children.length > 0 && children) {
        this.processBodyPDF(item.children, true, depth + 1);
      }
    });
  }

  amountIsPositive(value): boolean {
    return +value >= 0;
  }

  formatNumber(value) {
    return Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(Math.abs(value));
  }

  formatPercent(number) {
    return number.toFixed(2).replace(".", ",") + '%';
  }
}

function getYears(startYear) {
  const currentYear = new Date().getFullYear() + 1, years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
}
