import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Column, GridComponent, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ExportProvider } from 'src/providers/export/export';
import { Snackbar } from 'src/util/snackbar';
import { ExportUtil } from 'src/util/exportUtil';
import { Layout } from 'src/model/Layout';

@Component({
  selector: 'app-cadastro-layouts',
  templateUrl: './cadastro-layouts.component.html',
  styleUrls: ['./cadastro-layouts.component.scss']
})
export class CadastroLayoutsComponent implements OnInit {
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  @ViewChild('grid', { static: false }) grid: GridComponent;
  public horizontalScroll: number = null;
  public selectionOptions: SelectionSettingsModel = { mode: 'Cell', type: 'Single' };
  public layoutGridColumns: any = [];
  public exportUtil = new ExportUtil();

  public layoutGridColumnsExport: any = { ... this.exportUtil.ExportLayoutGridExport };
  public dataSourceMain: any[] = this.exportUtil.ExportLayoutGridDummyData;

  public defaultFormValue: any = this.exportUtil.ExportLayoutDefaultFormValue;
  public dateFormats: any[] = this.exportUtil.ExportLayoutDateFormats;

  public enviado: boolean = false;
  public enviando: boolean = false;
  public editar: boolean = false;

  public layoutForm: FormGroup;
  public layoutExistente: any = {};

  constructor(
    public activeModal: NgbActiveModal,
    public snackBar: Snackbar,
    public exportProvider: ExportProvider,
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.layoutForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      columnSeparator: new FormControl(null, Validators.required),
      decimalSeparator: new FormControl(null, Validators.required),
      characterDelimiter: new FormControl(null, Validators.required),
      dateFormat: new FormControl(null, Validators.required),
    });
    this.editar ? this.setFormExistingLayout() : this.layoutGridColumns = this.exportUtil.ExportLayoutGridColumns;
  }

  setFormExistingLayout() {
    this.enviando = true;
    this.exportProvider.getLayout(this.layoutExistente.id).then((result: Layout) => {
      this.enviando = false;
      this.layoutExistente = result;
      this.layoutForm.patchValue(this.layoutExistente);
      this.layoutGridColumns = [];
      Object.keys(this.layoutExistente.columnOrder).forEach((key) => {
        this.layoutGridColumns.push(this.exportUtil.ExportLayoutGridColumns.find((column) => column.field == this.layoutExistente.columnOrder[key]));
      });
      this.exportUtil.ExportLayoutGridColumns.forEach((column) => {
        if (!this.layoutGridColumns.includes(column)){
          this.layoutGridColumns.push(column);
        }
      });
      this.layoutGridColumns = this.layoutGridColumns.filter((column) => column != undefined);
      this.layoutGridColumnsExport = {};
      Object.keys(this.layoutExistente.columnExport).forEach((key) => {
        this.layoutGridColumnsExport[key] = this.layoutExistente.columnExport[key];
      });
    }).catch((result) => {
      this.enviando = false;
    });
  }

  useDefaultLayout(){
    this.layoutForm.patchValue(this.defaultFormValue);
  }

  customiseCell(args: any) {
    args.cell.style.cursor = 'pointer';
    if (this.layoutGridColumnsExport[args.column.field] == false) {
      args.cell.style.background = "#f05050";
      args.cell.classList += ' bg-colorido';
    }
    // Quando reseta o grid, o scroll horizontal é perdido, então é necessário setar ele novamente
    if(this.horizontalScroll != null){
      this.grid.element.querySelectorAll(".e-content")[0].scrollLeft = this.horizontalScroll;
      this.horizontalScroll = null;
    }
  }

  cellSelected(event) {
    // Guarda o scroll horizontal atual
    this.horizontalScroll = this.grid.element.querySelectorAll(".e-content")[0].scrollLeft;
    this.layoutGridColumnsExport[(this.grid.columns[event.cellIndex.cellIndex] as Column).field] = !this.layoutGridColumnsExport[(this.grid.columns[event.cellIndex.cellIndex] as Column).field];
    this.grid.refresh();
  }

  submitStep1() {
    this.enviado = true;
    if (!this.layoutForm.valid) {
      this.snackBar.openLong('Preencha todos os campos obrigatórios!', 'erro');
      return;
    }
    this.stepper.selected.completed = true;
    this.enviado = false;
    this.stepper.next();
  }

  submitForm() {
    this.enviado = true;
    this.enviando = true;
    this.editar ? this.putLayout() : this.postLayout();
  }

  postLayout() {
    this.exportProvider.postLayout(this.setLayoutObj()).then((result) => {
      this.enviando = false;
      this.snackBar.openLong("Sucesso! Layout editado com sucesso!", 'success');
      this.activeModal.close(result);
    }).catch((result) => {
      this.enviando = false;
      this.snackBar.openLong('Não foi possível editar! ' + result.error.error_description, 'erro');
    });
  }

  putLayout() {
    this.exportProvider.putLayout(this.setLayoutObj()).then((result) => {
      this.enviando = false;
      this.snackBar.openLong("Sucesso! Layout editado com sucesso!", 'success');
      this.activeModal.close(result);
    }).catch((result) => {
      this.enviando = false;
      this.snackBar.openLong('Não foi possível editar! ' + result.error.error_description, 'erro');
    });
  }

  setLayoutObj() {
    let obj = this.layoutForm.getRawValue();
    this.editar ? obj['id'] = this.layoutExistente.id : '';
    obj['columnOrder'] = {};
    obj['columnExport'] = {};
    for (let i = 0; i < this.grid.columns.length; i++) {
      obj['columnOrder'][i] = ((this.grid.columns[i] as Column).field);
      obj['columnExport'][((this.grid.columns[i] as Column).field)] = this.layoutGridColumnsExport[((this.grid.columns[i] as Column).field)];
    }
    return obj;
  }
}
