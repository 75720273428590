import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal, NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {ErrorUtil} from "../../../../../util/error";
import {AuthProvider} from "../../../../../providers/auth/authProvider";
import {Snackbar} from "../../../../../util/snackbar";
import {BatchProvider} from "../../../../../providers/batch/batchProvider";
import {CheckPermission} from "../../../../../service/checkPermission";
import {ReplaySubject, Subject} from "rxjs";
import {Bookkeeping} from "../../../../../model/dto/bookkeping/Bookkeeping";
import {HttpErrorResponse} from "@angular/common/http";
import Swal from "sweetalert2";
import {takeUntil} from "rxjs/operators";
import {ParticipantProvider} from "../../../../../providers/participant/participant";
import {ParticipantDTOResponse} from "../../../../../model/dto/ParticipantDTOResponse";
import {CadastroPessoasComponent} from "../../../cadastros/participantes/cadastro-pessoas/cadastro-pessoas.component";
import {ConvertObjectPipe} from "../../../../../util/pipes/ConvertObjectPipe";
import {ParticipantPageable} from "../../../../../model/dto/bookkeping/ParticipantPageable";
import {GrouperDTOResponse} from "../../../../../model/dto/GrouperDTOResponse";
import * as moment from "moment";

@Component({
  selector: 'app-editar-participante',
  templateUrl: './editar-participante.component.html',
  styleUrls: ['./editar-participante.component.scss']
})
export class EditarParticipanteComponent implements OnInit {
  closeResult = '';

  public enviando: boolean = false;
  public participants: ParticipantDTOResponse[];
  public erroParticipant: boolean = false;
  public erro: string;
  public formPassword: FormGroup;
  public participantFilterCtrl: FormControl = new FormControl();
  public currentParticipant: ParticipantDTOResponse = new ParticipantDTOResponse();
  public participantLoaded: boolean = false;
  public filter_participant: ReplaySubject<ParticipantDTOResponse[]> = new ReplaySubject<ParticipantDTOResponse[]>(1);
  protected _onDestroy = new Subject<void>();
  public lancamentoForm: FormGroup;
  public participantPage: number = 0;
  public firstFromForm: boolean = true;
  public batchForm: FormGroup;
  public selectedBookkeeping: Bookkeeping[];

  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private participantProvider: ParticipantProvider,
              public errorUtil: ErrorUtil,
              private authProvider: AuthProvider,
              public snackbar: Snackbar,
              public formBuilder: FormBuilder,
              private batchProvider: BatchProvider,
              public checkPermission: CheckPermission) {
  }

  ngOnInit() {
    this.getParticipants();
    this.formPassword = this.formBuilder.group({
      password: [null, Validators.required],
    });
    this.batchForm = this.formBuilder.group({
      participant: [null, Validators.required],
    });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    });
  }

  async getParticipants() {
    let pipe = new ConvertObjectPipe();
    await this.participantProvider.getParticipantsByPage('', this.participantPage).then((participantes: ParticipantPageable) => {
      this.participants = pipe.transform(participantes.content, 'participantes');
      this.participantPage++;
      this.initializeSearchSelect();
    });
  }

  initializeSearchSelect() {
    // this.participants.sort((a, b) => parseInt(a.cpfCNPJParticipant) - parseInt(b.cpfCNPJParticipant));
    this.filter_participant.next(this.participants.slice());
    this.participantFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterParticipants();
      });
  }

  getFirstPage() {
    let pipe = new ConvertObjectPipe();
    this.participantPage = 0;
    this.participantFilterCtrl.setValue('');
    this.participantProvider.getParticipantsByPage(this.participantFilterCtrl.value, this.participantPage).then((participantes: ParticipantPageable) => {
      this.participants = pipe.transform(participantes.content, 'participantes');
      this.participantPage++;

      this.currentParticipant = this.lancamentoForm.value.pessoa;

      if (this.currentParticipant == undefined || this.currentParticipant == null) {
        this.firstFromForm = false;
      } else {
        this.firstFromForm = true;
        this.participants.unshift(this.currentParticipant);
      }

      this.filterParticipants();
      // this.initializeSearchSelect();
    });
  }


  getNextBatch(isScroll: boolean) {
    let search = '';
    if (this.participantFilterCtrl.value != undefined) {
      search = this.participantFilterCtrl.value;
    }

    let pipe = new ConvertObjectPipe();
    if (isScroll) {
      this.participantProvider.getParticipantsByPage(search, this.participantPage).then((participantes: ParticipantPageable) => {
        this.participantLoaded = participantes.last;
        this.participantPage++;

        this.participants = this.participants.concat(
          pipe.transform(participantes.content, 'participantes')
        );
        this.filterParticipants();
      });
    } else {
      if (search.length >= 3 || search.length == 0) {
        this.participantPage = 0;

        this.participantProvider.getParticipantsByPage(search, this.participantPage).then((participantes: ParticipantPageable) => {
          this.participantLoaded = participantes.last;
          this.participantPage++;

          //console.log(participantes);

          this.participants = pipe.transform(participantes.content, 'participantes');
          if (search.length == 0) {
            this.participants.unshift(this.currentParticipant);
            this.firstFromForm = true;
          } else {
            this.firstFromForm = false;
          }
          this.filterParticipants();
        });
      }
    }
  }
  protected filterParticipants() {
    this.filter_participant.next(this.participants.slice());
  }

  // protected filterParticipants() {
  //   if (!this.participants) return;
  //
  //   // get the search keyword
  //   let search = this.participantFilterCtrl.value;
  //   if (!search) {
  //     this.filter_participant.next(this.participants.slice());
  //     return;
  //   } else {
  //     search = search.toLowerCase();
  //   }
  //   // filter the banks
  //   this.filter_participant.next(
  //     this.participants.filter(participant => participant.name.toLowerCase().indexOf(search) > -1)
  //   );
  // }

  openModalNewOption(modalName, list) {
    const modalOptions: NgbModalOptions = {};
    modalOptions.backdrop = 'static';
    // modalOptions.keyboard = false;
    const modalRef = this.modalService.open(modalName, modalOptions);
    modalRef.result.then((result) => {
      if (result){
        this.batchForm.controls.participant.setValue(null);
        this.getFirstPage();
      }
    }, () => {
    });
  }

  newOptionSelect() {
    this.openModalNewOption(CadastroPessoasComponent, this.participants);
  }

  async onYesClick() {
    this.enviando = true;
    if (this.batchForm.valid) {
      this.enviando = true;
      await this.authProvider.revalidateLogin(this.formPassword.value.password).then(async value => {
        let bookkeepingIds = this.selectedBookkeeping.map(b => b.doc.id);
        await this.batchProvider.postParticipant(bookkeepingIds, this.batchForm.value.participant.id).then((result: []) => {
          this.enviando = false;
          if (result.length == this.selectedBookkeeping.length && result.length != 0) {
            Swal.fire({
              title: 'Sucesso!',
              text: 'Os lançamentos selecionados foram alterados com sucesso!',
              icon: 'success'
            });
          } else if (result.length > this.selectedBookkeeping.length && result.length != 0) {
            Swal.fire({
              title: 'Atenção!',
              text: 'Foi solicitada alteração em ' + (this.selectedBookkeeping.length) + ' lançamentos, porém, somente ' + (result.length) + ' foram alterados.',
              icon: 'warning'
            });
          } else if (result.length == 0) {
            Swal.fire({
              title: 'Erro!',
              text: 'Nenhum lançamento foi alterado',
              icon: 'error'
            });
          }
          this.activeModal.close(result);
        }).catch((response: HttpErrorResponse) => {
          this.enviando = false;
          this.activeModal.dismiss();
          if (response.error.error_description.includes("There is blocked periods")) {
            Swal.fire({
              title: 'Atenção!',
              text: 'Há lançamentos cadastrados dentro de um bloqueio de período. ' +
                (this.checkPermission.isAccountantAdmin() ?
                  'Remova o bloqueio para poder alterar uma escrituração com um lançamento nesta data.' :
                  'Contate um administrador para que o bloqueio seja removido.'),
              icon: 'error'
            });
          } else {
            Swal.fire({
              title: 'Erro!',
              text: 'Nenhum lançamento foi alterado. Houve um erro de conexão com o servidor!',
              icon: 'error'
            });
          }
        });
      }).catch(reason => {
        this.enviando = false;
        this.activeModal.dismiss();
        Swal.fire({
          title: 'Falha na autenticação',
          text: 'Não foi possível alterar os lançamentos. Ocorreu um problema na autenticação!',
          icon: 'error'
        });
      });
    }
    this.enviando = false;
  }
}
