import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormControl} from "@angular/forms";
import {ReplaySubject, Subject} from "rxjs";
import {GrouperDTOResponse} from "../../../../../model/dto/GrouperDTOResponse";
import {GrouperProvider} from "../../../../../providers/grouper/grouper";
import {takeUntil} from "rxjs/operators";

class Filtros {
  documento: string;
  categoria: string;
  agrupador: string;
  participante: string;
  historico: string;
  valor: string;
  imovel: string;
  exibir: boolean;
  tipoDocumento: string;
  contaMovimento: string;
}

@Component({
  selector: 'app-filtro-lancamentos',
  templateUrl: './filtro-lancamentos.component.html',
  styleUrls: ['./filtro-lancamentos.component.scss']
})
export class FiltroLancamentosComponent implements OnInit {

  @Input() public filtrosExistentes;
  @Input() public pastas;

  public filtros: Filtros;
  public groupers: GrouperDTOResponse[];
  public grouperFilterCtrl: FormControl = new FormControl();
  public filter_grouper: ReplaySubject<GrouperDTOResponse[]> = new ReplaySubject<GrouperDTOResponse[]>(1);

  protected _onDestroy = new Subject<void>();

  constructor(public activeModal: NgbActiveModal,
              public grouperProvider: GrouperProvider) {
  }

  ngOnInit() {
    this.initializeFilter();
    this.initializeDataFilter();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  initializeDataFilter() {
    this.grouperProvider.getAllGroupers().then((groupers: GrouperDTOResponse[]) => {
      this.groupers = groupers;
      this.initializeSearchSelect();
    });
  }

  initializeFilter() {
    if (this.filtrosExistentes != undefined) {
      this.filtros = this.filtrosExistentes;
    } else {
      this.filtros = new Filtros();
      this.filtros.valor = "0";
    }
  }

  initializeSearchSelect() {
    this.filter_grouper.next(this.groupers.slice());

    // listen for search field value changes
    this.grouperFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterGroupers();
      });
  }

  filtrar() {
    this.activeModal.close(this.filtros);
  }

  setTipoDocumento(value: any) {
    this.filtros.tipoDocumento = value;
  }

  setExibicaoDedutiveis(value: any) {
    this.filtros.exibir = value;
  }

  protected filterGroupers() {
    if (!this.groupers) {
      return;
    }
    // get the search keyword
    let search = this.grouperFilterCtrl.value;
    if (!search) {
      this.filter_grouper.next(this.groupers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the participants
    this.filter_grouper.next(
      this.groupers.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
