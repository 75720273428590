import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {CheckPermission} from './checkPermission';

@Injectable()
export class AuthConsultor implements CanActivate {
  constructor(public router: Router, private oauthService: OAuthService, private checkPermision: CheckPermission) {}
  canActivate(): boolean {
    if (!this.oauthService.hasValidAccessToken() || !this.verificaPermissao()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  private givenAuthorities(claim) {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) { return null; }
    return claims[claim];

  }

  private verificaPermissao() {
    // tslint:disable-next-line: max-line-length
    return this.checkPermision.isConsultant() || this.checkPermision.isFarmer() && !(this.checkPermision.isAccountant() || this.checkPermision.isSuperuser());
  }

  private allowCompany(): boolean {
    if (localStorage.getItem('allow') == null) {
      this.router.navigate(['manutencao']);
    }
    const companyId = this.givenAuthorities('details').office.id;
    if (companyId === undefined) {
      return true;
    } else {
      const list = JSON.parse(localStorage.getItem('allow'));
      return list.findIndex(val => val === companyId) >= 0;

    }
  }

}

