export class AccessKeysStatus {
    id: number;
    key: string;
    status: string;
    cpfCnpj: string;
    companyId: string;
    taskId?: string;
    requestDate: string;
    allowReintegration?: boolean;
    requesterName: string;
    productorName: string;
    document?: {
      id: number;
      modelType: string;
      downloadXMLDate: string;
    };
  }
