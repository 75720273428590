import {Pipe, PipeTransform} from "@angular/core";
import {ContaBancariaDTO} from "../../model/dto/ContaBancariaDTO";
import {ContaBancaria} from "../../model/ContaBancaria";
import {AddressDTOResponse} from "../../model/dto/AddressDTOResponse";
import {ExplorationType} from "../../model/enum/ExplorationType";

@Pipe({
  name: 'convertBankAccountDTO',
})
export class ConvertBankAccountDTO implements PipeTransform {

  transform(value: ContaBancariaDTO): ContaBancaria {
    let contaBancariaDTO: ContaBancariaDTO;
    contaBancariaDTO = value;

    let contaBancaria = new ContaBancaria();
    contaBancaria.accountNumberWithDV = contaBancariaDTO.accountNumberWithDV;
    contaBancaria.agencyWithoutDV = contaBancariaDTO.agencyWithoutDV;
    contaBancaria.bankId = contaBancariaDTO.bankDTORespComp ? contaBancariaDTO.bankDTORespComp.id : null;
    contaBancaria.code = contaBancariaDTO.code;
    contaBancaria.companyId = +localStorage.getItem('idEmpresa');
    contaBancaria.countryId = contaBancariaDTO.countryDTORespComp ? contaBancariaDTO.countryDTORespComp.id : null;
    contaBancaria.default = contaBancariaDTO.default;
    contaBancaria.description = contaBancariaDTO.description;
    contaBancaria.id = contaBancariaDTO.id;

    return contaBancaria;
  }
}
