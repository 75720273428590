import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Snackbar} from "../../../../../util/snackbar";
import {ErrorUtil} from "../../../../../util/error";
import {FolderDTOResponse} from "../../../../../model/dto/FolderDTOResponse";
import {HttpErrorResponse} from "@angular/common/http";
import {FolderProvider} from "../../../../../providers/folder/folder";

@Component({
  selector: 'app-cadastro-pastas',
  templateUrl: './cadastro-pastas.component.html'
})
export class CadastroPastasComponent implements OnInit {

  editar: boolean = false;
  enviado: boolean = false;
  enviando: boolean = false;
  pastasForm: FormGroup;

  @Input() public pastaExistente;

  constructor(public activeModal: NgbActiveModal,
              public fb: FormBuilder,
              public folderProvider: FolderProvider,
              public snackBar: Snackbar,
              public erroUtil: ErrorUtil) {
  }

  ngOnInit() {
    this.formConfiguration();
    this.checkIfExistFolder();
  }

  formConfiguration() {
    this.pastasForm = this.fb.group({
      id: [''],
      type: ['UNSEEN'],
      name: ['', Validators.compose([Validators.required])]
    });
  }

  checkIfExistFolder(){
    if (this.pastaExistente != undefined) {
      this.editar = true;
      let objPasta = {...this.pastaExistente};
      this.pastasForm.patchValue(objPasta);
    }
  }

  newOrEditFolder() {
    this.enviado = true;
    this.enviando = true;
    if (this.pastasForm.valid) {
      if (!this.editar) {
        this.folderProvider.postFolder(this.pastasForm.getRawValue()).then((pasta: FolderDTOResponse) => {
          this.snackBar.openLong("Sucesso! " + pasta.name + " inserido com sucesso!", 'success');
          this.activeModal.close(pasta);
        }).catch((result: HttpErrorResponse) => {
          this.enviando = false;
          this.snackBar.openLong('Não foi possível inserir! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'grouper'), 'erro');
        });
      } else {
        let pasta = this.pastasForm.getRawValue();
        pasta['status'] = this.pastaExistente.status;
        this.folderProvider.putFolder(pasta).then((pasta: FolderDTOResponse) => {
          this.snackBar.openLong("Sucesso! " + pasta.name + " editado com sucesso!", 'success');
          this.returnEditedFolder(pasta);
          this.activeModal.close(pasta);
        }).catch((result: HttpErrorResponse) => {
          console.error(result);
          this.enviando = false;
          this.snackBar.openLong('Não foi possível editar! ' + this.erroUtil.checkErrorStatus(result, result.status, result.error, 'folder'), 'erro');
        });
      }
    } else {
      this.enviando = false;
      this.snackBar.openLong("Há erros no formulário. Confira antes de tentar enviar novamente!", 'erro');
    }
  }

  returnEditedFolder(pasta: FolderDTOResponse) {
    this.pastaExistente.name = pasta.name;
    this.pastaExistente.id = pasta.id;
    this.pastaExistente.type = pasta.type;
  }

}
